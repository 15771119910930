import React from 'react';
import { pick, head, omit } from 'rambda';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { PageHeader } from '@fondy/layouts';
import { BooleanValueTableCell } from '@fondy/tables';
import { TabsHorizontalNavigation } from '@fondy/menu';
import { isCustomerIndividual, useApi } from '@fondy/utils';
import { ErrorMessage } from '@fondy/alerts';
import {
  partyTypesLabelsMap,
  countries,
  countriesNames,
  countriesNationalities,
  signupKeys,
  identificationDocumentTypesLabelsMap,
} from '@fondy/enums';
import { GridWidget, ObjectDataDisplay } from '@fondy/data-display';
import settingsPageTabs from './utils/settingsPageTabs';
import {
  combinedSoleTraderLabelsMap,
  combinedRegisteredCompanyLabelsMap,
} from './utils';
import { AppLayout } from '../../layouts';
import { stateSelectUserProfile } from '../../../redux';
import {
  accountKeys,
  partyRegistrationKeys,
  partyDetailsKeys,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  companyData: {
    marginTop: theme.spacing(3),
  },
  collapse: {
    padding: 0,
  },
  row: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  label: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      width: '45%',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      width: '35%',
    },
  },
  value: {
    display: 'flex',
    fontSize: theme.typography.fontSize,
    [theme.breakpoints.up('sm')]: {
      width: '55%',
    },
    [theme.breakpoints.up('md')]: {
      width: '65%',
    },
  },
  divider: {
    margin: theme.spacing(3, 0, 4, 0),
  },
  emailStatus: {
    marginLeft: theme.spacing(1),
  },
  error: {
    marginTop: theme.spacing(3),
  },
}));

const pickCombinedSoleTraderDetails = pick([
  partyDetailsKeys.PARTY_NAME,
  partyDetailsKeys.COUNTRY_OF_RESIDENCE,
  partyDetailsKeys.IDENTIFICATION_DOCUMENT_TYPE,
  partyDetailsKeys.IDENTIFICATION_DOCUMENT_NUMBER,
  partyDetailsKeys.IDENTIFICATION_DOCUMENT_EXPIRY,
  signupKeys.DATE_OF_BIRTH,
  partyDetailsKeys.COUNTRY_OF_BIRTH,
  partyDetailsKeys.NATIONALITY,
  partyRegistrationKeys.ADDRESS_LINE_1,
  partyRegistrationKeys.ADDRESS_LINE_2,
  partyRegistrationKeys.REGION,
  partyRegistrationKeys.TOWN,
  partyRegistrationKeys.POSTAL_CODE,
  partyRegistrationKeys.COUNTRY,
  signupKeys.PHONE_NUMBER,
  signupKeys.EMAIL,
  partyDetailsKeys.TAX_LIABILITY,
  partyDetailsKeys.TAX_NUMBER,
]);

const pickCombinedCompanyDetails = pick([
  partyDetailsKeys.PARTY_NAME,
  signupKeys.EMAIL,
  signupKeys.PHONE_NUMBER,
  partyDetailsKeys.TYPE,
  partyDetailsKeys.REGISTRATION_NUMBER,
  partyDetailsKeys.REGISTRATION_DATE,
  partyDetailsKeys.COUNTRY_OF_REGISTRATION,
  partyDetailsKeys.VAT_NUMBER,
  partyRegistrationKeys.ADDRESS_LINE_1,
  partyRegistrationKeys.ADDRESS_LINE_2,
  partyRegistrationKeys.POSTAL_CODE,
  partyRegistrationKeys.TOWN,
  partyRegistrationKeys.REGION,
  partyRegistrationKeys.COUNTRY,
  partyDetailsKeys.TAX_LIABILITY,
]);

const omitAddressLines = omit([
  partyRegistrationKeys.ADDRESS_LINE_1,
  partyRegistrationKeys.ADDRESS_LINE_2,
]);

function SettingsCompanyPage() {
  const classes = useStyles();
  const userProfile = useSelector(stateSelectUserProfile);
  const partyId = useSelector(
    (state) => head(state.parties.data)[accountKeys.PARTY_ID],
  );

  const { apiData, isLoading, isError, apiError, retry } = useApi(
    `/api/parties/${partyId}`,
    {
      dependencies: [partyId],
    },
  );

  const isSoleTrader = isCustomerIndividual(apiData);
  const data = apiData ? omitAddressLines(apiData) : null;

  const dataRenderer = isSoleTrader
    ? pickCombinedSoleTraderDetails
    : pickCombinedCompanyDetails;

  const dataLabels = isSoleTrader
    ? combinedSoleTraderLabelsMap
    : combinedRegisteredCompanyLabelsMap;

  return (
    <AppLayout>
      <PageHeader title="Settings" />
      {!isSoleTrader && (
        <TabsHorizontalNavigation
          activeTab={settingsPageTabs.COMPANY}
          tabs={[
            {
              label: 'Company',
              value: settingsPageTabs.COMPANY,
              to: '/settings',
            },
          ]}
        />
      )}

      <GridWidget>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            gutterBottom
            className={classes.pageTitle}
            data-aio-id="sectionTitle"
          >
            Company details
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            data-aio-id="sectionSubTitle"
          >
            An overview of your company details.
            {/* These can be updated using the Edit button. */}
          </Typography>

          {isError && (
            <ErrorMessage
              error={apiError}
              isLoading={isLoading}
              retryFn={retry}
              className={classes.error}
            />
          )}

          {!isError && (
            <ObjectDataDisplay
              classes={{
                item: classes.row,
                label: classes.label,
                value: classes.value,
                collapse: classes.collapse,
              }}
              isLoading={isLoading}
              className={classes.companyData}
              data={dataRenderer({
                ...userProfile,
                ...data,
                ...(apiData?.[partyRegistrationKeys.ADDRESS_LINE_1]
                  ? {
                      [partyRegistrationKeys.ADDRESS_LINE_1]:
                        apiData[partyRegistrationKeys.ADDRESS_LINE_1],
                    }
                  : {}),
                ...(apiData?.[partyRegistrationKeys.ADDRESS_LINE_2]
                  ? {
                      [partyRegistrationKeys.ADDRESS_LINE_2]:
                        apiData[partyRegistrationKeys.ADDRESS_LINE_2],
                    }
                  : {}),
              })}
              dataFieldsLabelsMap={dataLabels}
              dataFieldsMapper={{
                [partyDetailsKeys.IDENTIFICATION_DOCUMENT_TYPE]: (
                  identificationDocumentType,
                ) =>
                  identificationDocumentTypesLabelsMap.get(
                    identificationDocumentType,
                  ),
                [partyDetailsKeys.COUNTRY_OF_BIRTH]: (countryIso) =>
                  countriesNames.get(countries[countryIso]),
                [partyDetailsKeys.COUNTRY_OF_RESIDENCE]: (countryIso) =>
                  countriesNames.get(countries[countryIso]),
                [partyDetailsKeys.COUNTRY_OF_REGISTRATION]: (countryIso) =>
                  countriesNames.get(countries[countryIso]),
                [partyRegistrationKeys.COUNTRY]: (countryIso) =>
                  countriesNames.get(countries[countryIso]),
                [partyDetailsKeys.NATIONALITY]: (countryIso) =>
                  countriesNationalities.get(countries[countryIso]),
                [partyDetailsKeys.TAX_LIABILITY]: (value) => (
                  <BooleanValueTableCell value={value} />
                ),
                [partyDetailsKeys.TYPE]: (type) =>
                  partyTypesLabelsMap.get(type),
              }}
              itemSpacing={1}
              grid={{ xs: 12 }}
            />
          )}
        </Grid>
      </GridWidget>
    </AppLayout>
  );
}

export default SettingsCompanyPage;
