import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { avatarSizes, CurrentUserAvatar } from '@fondy/avatar';
import { useAuth } from '@fondy/keycloak';

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1.5),
    width: theme.spacing(8),
    height: theme.spacing(8),
    '& p': {
      fontSize: 26,
    },
  },
  user: {
    fontSize: theme.spacing(20),
  },
}));

export default function AvatarWithUserName() {
  const classes = useStyles();
  const { auth } = useAuth();
  return (
    <Box display="flex" justifyItems="center">
      <CurrentUserAvatar size={avatarSizes.LARGE} className={classes.avatar} />
      <div className={classes.user}>
        <Typography variant="h1">{`${auth.firstName} ${auth.lastName}`}</Typography>
        <Typography variant="subtitle1">{auth.email}</Typography>
      </div>
    </Box>
  );
}
