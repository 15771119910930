export const loginKeys = Object.freeze({
  EMAIL: 'email',
  PASSWORD: 'password',
});

export const loginKeysLabelsMap = new Map([
  [loginKeys.EMAIL, 'Email Address'],
  [loginKeys.PASSWORD, 'Password'],
]);

export const loginKeysDescriptionsMap = new Map([
  [loginKeys.EMAIL, null],
  [loginKeys.PASSWORD, null],
]);
