import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { Grid, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, buttonColor } from '@fondy/buttons';
import { DocumentIcon, AltSettingsIcon } from '@fondy/icons';
import AccountDetailsModal from '../AccountDetailsModal';
import AccountSettingsModal from '../AccountSettingsModal';
import { accountKeys } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  action: {
    fontSize: 12,
    '&:last-child': {
      marginLeft: theme.spacing(3),
    },
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5),
    },
  },
}));

const AccountActions = ({ account }) => {
  const classes = useStyles();

  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [showAccountSettings, setShowAccountSettings] = useState(false);

  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Button
        onClick={() => setShowAccountDetails(true)}
        color={buttonColor.PRIMARY}
        startIcon={
          <SvgIcon component={DocumentIcon} style={{ fontSize: 16 }} />
        }
        className={classes.action}
        link
        data-aio-id="viewAccountDetailsLink"
      >
        Details
      </Button>
      <Button
        onClick={() => setShowAccountSettings(true)}
        color={buttonColor.PRIMARY}
        startIcon={
          <SvgIcon component={AltSettingsIcon} style={{ fontSize: 16 }} />
        }
        className={classes.action}
        link
        data-aio-id="accountSettingsLink"
      >
        Settings
      </Button>

      <AccountDetailsModal
        open={showAccountDetails}
        account={account}
        onClose={() => setShowAccountDetails(false)}
      />

      <AccountSettingsModal
        open={showAccountSettings}
        account={account}
        onClose={() => setShowAccountSettings(false)}
      />
    </Grid>
  );
};

AccountActions.propTypes = {
  account: shape({
    [accountKeys.ALIAS]: string,
    [accountKeys.CURRENCY]: string,
    [accountKeys.NUMBER]: string,
    [accountKeys.SORT_CODE]: string,
    [accountKeys.IBAN]: string,
    [accountKeys.BIC_SWIFT]: string,
  }).isRequired,
};
AccountActions.defaultProps = {};

export default AccountActions;
