import { signupKeys, signupKeysLabelsMap } from '@fondy/enums';
import {
  partyDetailsKeys,
  partyDetailsKeysLabelsMap,
  partyRegistrationKeysLabelsMap,
} from '../../../../utils';

const combinedRegisteredCompanyLabelsMap = new Map([
  ...signupKeysLabelsMap,
  ...partyRegistrationKeysLabelsMap,
  ...partyDetailsKeysLabelsMap,
  [partyDetailsKeys.TYPE, 'Type'],
  [partyDetailsKeys.PARTY_NAME, 'Business Name'],
  [signupKeys.EMAIL, 'Admin Email'],
  [signupKeys.PHONE_NUMBER, 'Admin Phone number'],
]);

export default combinedRegisteredCompanyLabelsMap;
