import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { pick, pickBy, isNotNil, isNil } from 'rambda';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  CircularProgress,
  Divider,
  Grid,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import {
  bool,
  func,
  string,
  shape,
  oneOf,
  instanceOf,
  object,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import { DownloadIcon } from '@fondy/icons';
import { createPdfDocument, defaultPageMargins } from '@fondy/pdf';
import { formatDate, formatSortCodeValue } from '@fondy/utils';
import {
  AccountAliasTableCell,
  StatusTableCell,
  CurrencyTableCell,
} from '@fondy/tables';
import { useApplicationContext } from '@fondy/application-context';
import { Dialog, ObjectDataDisplay } from '@fondy/data-display';
import { conformToMask, ibanInputMaskPattern } from '@fondy/forms';
import {
  accountStatuses,
  accountStatusesLabels,
  accountTypes,
  countries,
  countriesNames,
} from '@fondy/enums';
import { getAccountsAction } from '../../../redux';
import { createAccountModalValidationSchema } from '../CreateAccountModal/utils';
import FormattedDateTime from '../../atoms/FormattedDateTime';
import { pdfHeaderCreator, pdfFooterCreator } from '../../atoms/pdfSections';
import {
  accountKeys,
  walletKeys,
  splitAccountWalletKeys,
  splitAccountWalletKeysLabelsMap,
} from '../../../utils/enums';

const getSplitAccountWalletDetails = pick([
  splitAccountWalletKeys.ALIAS,
  splitAccountWalletKeys.ID,
  splitAccountWalletKeys.STATUS,
  splitAccountWalletKeys.DATE_CREATED,
]);

const getSplitAccountWalletPayeeDetails = pick([
  splitAccountWalletKeys.PAYEE_ID,
  splitAccountWalletKeys.PAYEE_NAME,
  splitAccountWalletKeys.PAYEE_ACCOUNT_NUMBER,
  splitAccountWalletKeys.PAYEE_SORT_CODE,
  splitAccountWalletKeys.PAYEE_IBAN,
  splitAccountWalletKeys.PAYEE_CURRENCY,
  splitAccountWalletKeys.PAYEE_COUNTRY,
  splitAccountWalletKeys.PAYEE_CITY,
  splitAccountWalletKeys.PAYEE_STREET,
  splitAccountWalletKeys.PAYEE_BUILDING_NAME,
  splitAccountWalletKeys.PAYEE_BUILDING_NUMBER,
  splitAccountWalletKeys.PAYEE_POSTAL_CODE,
]);

export const modalSplitAccountKeysLabelsMap = new Map([
  ...splitAccountWalletKeysLabelsMap,
  [splitAccountWalletKeys.ALIAS, 'Wallet Name'],
  [splitAccountWalletKeys.ID, 'Wallet Id'],
  [splitAccountWalletKeys.PAYEE_ID, 'Payee Id'],
  [splitAccountWalletKeys.PAYEE_NAME, 'Name'],
  [splitAccountWalletKeys.PAYEE_ACCOUNT_NUMBER, 'Account Number'],
  [splitAccountWalletKeys.PAYEE_SORT_CODE, 'Sort Code'],
  [splitAccountWalletKeys.PAYEE_IBAN, 'IBAN'],
  [splitAccountWalletKeys.PAYEE_CURRENCY, 'Currency'],
]);

const useStyles = makeStyles((theme) => ({
  alias: {
    ...theme.typography.h3,
    lineHeight: 1.3,
    textAlign: 'left',
  },
  accountInfo: {
    width: 'auto',
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  dataDisplay: {
    margin: 0,
    '&:first-of-type': {
      marginTop: theme.spacing(1),
    },
  },
  collapse: {
    padding: 0,
  },
  row: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  value: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      justifyContent: 'space-between',
    },
  },
  progress: {
    color: theme.palette.common.white,
  },
  warning: {
    color: theme.palette.warning.main,
    flexWrap: 'nowrap',
  },
  divider: {
    width: '100%',
    margin: theme.spacing(3, 0),
  },
}));

const SplitAccountWalletDetailsModal = ({
  account,
  open,
  onClose,
  labelsMap,
  accountDetailsKeys,
  payeeDetailsKeys,
  copyableValues,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { fetch } = useApplicationContext();
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const [isOpen, setIsOpen] = useState(() => (!isNil(open) ? open : !!account));

  const forceRefreshAccounts = useCallback(
    () => dispatch(getAccountsAction(fetch, true)),
    [dispatch, fetch],
  );

  const payeeDetailsData = useMemo(
    () => pickBy((value) => isNotNil(value), payeeDetailsKeys(account)),
    [account, payeeDetailsKeys],
  );

  const pdfDataFieldsMapper = useMemo(
    () => ({
      [splitAccountWalletKeys.STATUS]: (value) =>
        accountStatusesLabels?.get(value) || value,
      [splitAccountWalletKeys.DATE_CREATED]: (value) =>
        formatDate(value, {
          dateFormat: 'dd/MM/yyyy',
        }),
      [splitAccountWalletKeys.PAYEE_SORT_CODE]: formatSortCodeValue,
      [splitAccountWalletKeys.PAYEE_IBAN]: (value) =>
        conformToMask(value, ibanInputMaskPattern, {
          guide: false,
        }).conformedValue,
      [splitAccountWalletKeys.PAYEE_COUNTRY]: (value) =>
        countriesNames.get(countries[value]),
    }),
    [],
  );

  const dataFieldsMapper = useMemo(
    () => ({
      [splitAccountWalletKeys.STATUS]: (value) =>
        accountStatusesLabels?.get(value) || value,
      [splitAccountWalletKeys.DATE_CREATED]: (value) => (
        <FormattedDateTime utcIsoDate={value} dateFormat="dd/MM/yy" />
      ),
      [splitAccountWalletKeys.PAYEE_SORT_CODE]: formatSortCodeValue,
      [splitAccountWalletKeys.PAYEE_IBAN]: (value) =>
        conformToMask(value, ibanInputMaskPattern, {
          guide: false,
        }).conformedValue,
      [splitAccountWalletKeys.PAYEE_CURRENCY]: (value) => (
        <CurrencyTableCell value={value} />
      ),
      [splitAccountWalletKeys.PAYEE_COUNTRY]: (value) =>
        countriesNames.get(countries[value]),
    }),
    [],
  );

  const handlePdfDownload = useCallback(async () => {
    try {
      await setIsGeneratingPdf(true);

      const date = new Date();
      const { pdfDocument } = await createPdfDocument();
      const { fontName } = await pdfDocument.getFont();

      await pdfHeaderCreator(pdfDocument, { theme, title: 'Wallet Details' });

      const walletData = accountDetailsKeys(account);

      // Wallet details labels
      await pdfDocument.text(
        Object.keys(walletData).map((i) => labelsMap.get(i) || i),
        defaultPageMargins.left,
        160,
        {
          lineHeightFactor: 2,
        },
      );

      await pdfDocument.setFont(fontName, 'normal');

      // Wallet details values
      await pdfDocument.text(
        Object.entries(walletData).map(
          ([key, value]) =>
            pdfDataFieldsMapper?.[key]?.(value) || value || 'n/a',
        ),
        defaultPageMargins.left + 150,
        160,
        {
          lineHeightFactor: 2,
        },
      );

      await pdfDocument.setFont(fontName, 'bold');

      const nextSectionY = 160 + (Object.keys(walletData).length - 1) * 30;

      // Payee details labels
      await pdfDocument.text(
        Object.keys(payeeDetailsData).map((i) => labelsMap.get(i) || i),
        defaultPageMargins.left,
        nextSectionY,
        {
          lineHeightFactor: 2,
        },
      );

      await pdfDocument.setFont(fontName, 'normal');

      // Payee details values
      await pdfDocument.text(
        Object.entries(payeeDetailsData).map(
          ([key, value]) =>
            pdfDataFieldsMapper?.[key]?.(value) || value || 'n/a',
        ),
        defaultPageMargins.left + 150,
        nextSectionY,
        {
          lineHeightFactor: 2,
        },
      );

      await pdfFooterCreator(pdfDocument, { theme });

      await pdfDocument.save(
        `${walletData[splitAccountWalletKeys.ALIAS]} - Wallet details`,
      );
    } catch (error) {
      console.warn(error);
    } finally {
      await setIsGeneratingPdf(false);
    }
  }, [
    payeeDetailsData,
    account,
    accountDetailsKeys,
    labelsMap,
    pdfDataFieldsMapper,
    theme,
  ]);

  const dataFieldsCopyableValues = useMemo(
    () => ({
      ...copyableValues,
      [splitAccountWalletKeys.DEFAULT_WALLET_ID]: true,
      [splitAccountWalletKeys.DEFAULT_PAYMENT_REFERENCE]: true,
      [splitAccountWalletKeys.EXTERNAL_ID]: true,
      [splitAccountWalletKeys.ID]: true,
      [splitAccountWalletKeys.PAYEE_ACCOUNT_NUMBER]: true,
      [splitAccountWalletKeys.PAYEE_BIC]: true,
      [splitAccountWalletKeys.PAYEE_IBAN]: true,
      [splitAccountWalletKeys.PAYEE_ID]: true,
      [splitAccountWalletKeys.PAYEE_IDENTITY_ID]: true,
      [splitAccountWalletKeys.PAYEE_SORT_CODE]: true,
      [splitAccountWalletKeys.PAYEE_NAME]: true,
      [splitAccountWalletKeys.PAYEE_COUNTRY]: true,
      [splitAccountWalletKeys.PAYEE_CITY]: true,
      [splitAccountWalletKeys.PAYEE_STREET]: true,
      [splitAccountWalletKeys.PAYEE_BUILDING_NAME]: true,
      [splitAccountWalletKeys.PAYEE_BUILDING_NUMBER]: true,
      [splitAccountWalletKeys.PAYEE_POSTAL_CODE]: true,
    }),
    [copyableValues],
  );

  const objectDataDisplayClasses = useMemo(
    () => ({
      item: classes.row,
      value: classes.value,
      collapse: classes.collapse,
    }),
    [classes.collapse, classes.row, classes.value],
  );

  const dataFieldsEditableValuesUrls = useMemo(() => {
    const shouldRenderEditButton =
      account?.[accountKeys.ID] &&
      (account[accountKeys.TYPE] !== accountTypes.WALLET ||
        (account[accountKeys.TYPE] === accountTypes.WALLET &&
          !account[walletKeys.IS_DEFAULT]));
    return {
      [accountKeys.ALIAS]: shouldRenderEditButton
        ? `/api/core-banking/accounts/${account[accountKeys.ID]}`
        : undefined,
    };
  }, [account]);

  const dataFieldsValidationSchema = useMemo(() => {
    const shouldRenderEditButton =
      account?.[accountKeys.ID] &&
      (account[accountKeys.TYPE] !== accountTypes.WALLET ||
        (account[accountKeys.TYPE] === accountTypes.WALLET &&
          !account[walletKeys.IS_DEFAULT]));
    return shouldRenderEditButton
      ? createAccountModalValidationSchema(account[accountKeys.TYPE], {
          walletAliasKey:
            account[accountKeys.TYPE] === accountTypes.WALLET
              ? 'alias'
              : undefined,
        })
      : undefined;
  }, [account]);

  const closeHandler = useCallback(() => {
    setIsOpen(false);
    if (onClose) onClose();
  }, [onClose]);

  useEffect(() => {
    setIsOpen(!isNil(account));
    return () => setIsOpen(false);
  }, [account]);

  if (!account) return null;

  return (
    <Dialog
      dense
      disableHeaderPadding
      open={!isNil(open) ? open : isOpen}
      onClose={closeHandler}
      centeredHeader={false}
      contentProps={{
        dividers: true,
      }}
      header={
        <Grid container spacing={1}>
          <Grid item>
            <AccountAliasTableCell
              alignTop
              showCurrencyIso
              classes={{
                alias: classes.alias,
              }}
              currencyIso={
                account[splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY]
              }
              accountAlias={account[splitAccountWalletKeys.ALIAS] || 'n/a'}
            />
          </Grid>
          <Grid item>
            <StatusTableCell status={account[splitAccountWalletKeys.STATUS]} />
          </Grid>
        </Grid>
      }
      footerActions={[
        {
          label: 'Download wallet details',
          color: 'secondary',
          size: 'large',
          onClick: handlePdfDownload,
          startIcon: isGeneratingPdf ? (
            <CircularProgress size={14} className={classes.progress} />
          ) : (
            <SvgIcon style={{ fontSize: 20 }} component={DownloadIcon} />
          ),
          disabled: isGeneratingPdf,
          'data-aio-id': 'downloadTransactionStatementButton',
        },
      ]}
    >
      <Grid container spacing={0}>
        <Grid item grid={{ xs: 12 }}>
          <ObjectDataDisplay
            classes={objectDataDisplayClasses}
            className={classes.dataDisplay}
            data={accountDetailsKeys(account)}
            onValueSubmit={forceRefreshAccounts}
            dataFieldsLabelsMap={labelsMap}
            dataFieldsCopyableValues={dataFieldsCopyableValues}
            dataFieldsMapper={dataFieldsMapper}
            // dataFieldsEditableValuesUrls={dataFieldsEditableValuesUrls}
            // dataFieldsValidationSchema={dataFieldsValidationSchema}
            grid={{ xs: 12 }}
            itemSpacing={1}
          />
        </Grid>

        <Divider orientation="horizontal" className={classes.divider} />

        <Grid item grid={{ xs: 12 }}>
          <Typography variant="h4">Payout Bank Details:</Typography>
          <ObjectDataDisplay
            classes={objectDataDisplayClasses}
            className={classes.dataDisplay}
            data={payeeDetailsData}
            onValueSubmit={forceRefreshAccounts}
            dataFieldsLabelsMap={labelsMap}
            dataFieldsCopyableValues={dataFieldsCopyableValues}
            dataFieldsMapper={dataFieldsMapper}
            grid={{ xs: 12 }}
            itemSpacing={1}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

SplitAccountWalletDetailsModal.propTypes = {
  open: bool,
  onClose: func,
  labelsMap: instanceOf(Map),
  account: shape({
    [splitAccountWalletKeys.ALIAS]: string,
    [splitAccountWalletKeys.ID]: string,
    [splitAccountWalletKeys.STATUS]: oneOf(Object.values(accountStatuses)),
    [splitAccountWalletKeys.DATE_CREATED]: string,
    [splitAccountWalletKeys.PAYEE_ID]: string,
    [splitAccountWalletKeys.PAYEE_NAME]: string,
    [splitAccountWalletKeys.PAYEE_ACCOUNT_NUMBER]: string,
    [splitAccountWalletKeys.PAYEE_SORT_CODE]: string,
    [splitAccountWalletKeys.PAYEE_IBAN]: string,
    [splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY]: string,
  }),
  accountDetailsKeys: func,
  payeeDetailsKeys: func,
  // eslint-disable-next-line react/forbid-prop-types
  copyableValues: object,
};

SplitAccountWalletDetailsModal.defaultProps = {
  open: null,
  account: null,
  onClose: null,
  labelsMap: modalSplitAccountKeysLabelsMap,
  accountDetailsKeys: getSplitAccountWalletDetails,
  payeeDetailsKeys: getSplitAccountWalletPayeeDetails,
  copyableValues: {},
};

export default SplitAccountWalletDetailsModal;
