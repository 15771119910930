import { head } from 'rambda';
import { createSelector } from 'reselect';
import { activeStatuses, complianceStatuses } from '@fondy/enums';
import { partyDetailsKeys } from '../../utils';

const userPartyStateValidationSelector = createSelector(
  (state) => state.parties.data,
  (parties) => {
    if (!parties || parties.length === 0) {
      return {
        hasActiveParty: false,
        hasCompliantParty: false,
        isPartyCompliantPending: false,
      };
    }
    const userParty = head(parties);
    return {
      hasActiveParty:
        userParty[partyDetailsKeys.STATUS] === activeStatuses.ACTIVE,
      hasCompliantParty:
        userParty[partyDetailsKeys.COMPLIANCE_STATUS] ===
        complianceStatuses.COMPLIANT,
      isPartyCompliantPending:
        userParty[partyDetailsKeys.COMPLIANCE_STATUS] ===
        complianceStatuses.PENDING,
    };
  },
);

export default userPartyStateValidationSelector;
