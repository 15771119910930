import { initialUiReduxState } from '@fondy/layouts';

const initialReduxState = {
  profile: {
    error: null,
    isFetching: false,
    data: null,
    lastUpdate: null,
  },
  parties: {
    error: null,
    isFetching: false,
    data: null,
    lastUpdate: null,
  },
  accounts: {
    getError: null,
    postError: null,
    isFetching: true,
    isPosting: false,
    data: [],
    lastUpdate: null,
  },
  ui: initialUiReduxState,
};

export default initialReduxState;
