import {
  MasterAccountIcon,
  StandardAccountIcon,
  WalletAccountIcon,
  ErrorIcon,
  SuccessIcon,
} from '@fondy/icons';
import createAccountModalStages from './createAccountModalStages';

const createAccountModalHeaderIcons = new Map([
  [createAccountModalStages.ACCOUNT_TYPE_SELECT, null],
  [createAccountModalStages.CREATE_STANDARD_ACCOUNT, StandardAccountIcon],
  [createAccountModalStages.CREATE_MASTER_ACCOUNT, MasterAccountIcon],
  [createAccountModalStages.CREATE_WALLET_ACCOUNT, WalletAccountIcon],
  [createAccountModalStages.ERROR, ErrorIcon],
  [createAccountModalStages.REQUEST_SUBMITTED, SuccessIcon],
]);

export default createAccountModalHeaderIcons;
