import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import { PrivateRoute } from '@fondy/keycloak';
import { NotFoundPage } from '@fondy/layouts';
import { SettingsCompanyPage } from '../../components/pages';
import { privateRoutesRequiredPartyProps } from '../utils';

export default function SettingsRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        {...privateRoutesRequiredPartyProps}
        path={path}
        exact
        enableOffline
      >
        <SettingsCompanyPage />
      </PrivateRoute>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
