import { transactionKeys } from './enums';
import exists from './exists';

const isTransactionBetweenOwnAccounts = (transaction) => {
  if (!exists(transaction)) return false;

  const hasCounterpartyAccountId = exists(
    transaction[transactionKeys.COUNTERPARTY_ACCOUNT_ID],
  );

  const hasCounterpartyAccountAlias = exists(
    transaction[transactionKeys.COUNTERPARTY_ACCOUNT_ALIAS],
  );

  return hasCounterpartyAccountId && hasCounterpartyAccountAlias;
};

export default isTransactionBetweenOwnAccounts;
