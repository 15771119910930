import React, { useState, useCallback, useMemo } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  useMediaQuery,
  Typography,
  Box,
  CircularProgress,
  SvgIcon,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { formatCurrencyValue, useApi } from '@fondy/utils';
import { GridWidget } from '@fondy/data-display';
import { StatusMessage } from '@fondy/alerts';
import {
  ChevronRightIcon,
  PaymentFailedIllustration,
  RefreshIcon,
} from '@fondy/icons';
import { Button, buttonColor, buttonSize, buttonVariant } from '@fondy/buttons';
import { useApplicationContext } from '@fondy/application-context';
import { payoutsMockedData, profilesMockedData } from './mock';
import {
  stateSelectSplitAccountDefaultWallet,
  stateSelectSplitAccountsIds,
  updateSplitAccountsDefaultWallet,
} from '../../../redux';
import { AppLayout } from '../../layouts';
import {
  EnumStatistics,
  BlurredComponent,
  WidgetOptionsDropdown,
  FormattedDateTime,
} from '../../atoms';
import {
  SplitAccountsSummaryWidget,
  PageTabsNavigation,
  ConnectedComposedAreaLineChart,
  ConnectedPieBgChart,
  SplitAccountPageHeader,
} from '../../organisms';
import {
  widgetsTimeRanges,
  widgetsTimeRangesHistoric,
  complianceProfileStatuses,
  splitAccountTransactionTypes,
  splitAccountTransactionKeys,
  splitAccountWalletKeys,
} from '../../../utils/enums';
import {
  splitAccountsIdsKeys,
  splitAccountPagesTabs,
  splitAccountPagesTabsLabelsMap,
  splitAccountPagesTabsRoutingMap,
  splitAccountPagesTabsDisabledFieldsMap,
  complianceProfileStatusesLabelsMap,
  complianceProfileStatusesColorsMap,
  getUrlParamsFromTimeRange,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    alignItems: 'flex-start',
  },
  lastUpdateTitle: {
    color: theme.palette.text.secondaryDark,
    paddingRight: theme.spacing(1),
  },
  refreshIcon: {
    cursor: 'pointer',
  },
  refreshIconRotate: {
    transition: 'transform 1s',
    transform: 'rotate(360deg)',
  },
  widgetPadding: {
    padding: theme.spacing(4, 0),
  },
}));

function SplitAccountDashboardPage() {
  const classes = useStyles();
  const { breakpoints, palette } = useTheme();
  const dispatch = useDispatch();

  const { fetch } = useApplicationContext();

  const { splitAccountDefaultWallet } = useSelector(
    stateSelectSplitAccountDefaultWallet,
  );
  const defaultWalletCurrency =
    splitAccountDefaultWallet[splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY];

  const [lastVisitedDate, setLastVisitedDate] = useState(new Date());
  const [isRefreshToggled, setIsRefreshToggled] = useState(false);

  const [payinCurrentTotalState, setPayinCurrentTotalState] = useState(0);
  const [payinPreviousTotalState, setPayinPreviousTotalState] = useState(0);

  const [payoutPreviousTotalState, setPayoutPreviousTotalState] = useState(0);
  const [payoutCurrentTotalState, setPayoutCurrentTotalState] = useState(0);

  const [payinsWidgetState, setPayinsWidgetState] = useState(
    widgetsTimeRanges.LAST_WEEK,
  );
  const [payoutsWidgetState, setPayoutsWidgetState] = useState(
    widgetsTimeRanges.LAST_WEEK,
  );

  const [hasProfiles, setHasProfiles] = useState(true);

  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });

  const { splitAccountsIds } = useSelector(stateSelectSplitAccountsIds);

  const accessorId =
    splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID];

  const {
    apiData: payinTransactionsResponse,
    isLoading: payinTransactionsIsLoading,
  } = useApi(
    `/api/core-banking/transactions/splitaccount?accountId=${
      splitAccountsIds[splitAccountsIdsKeys.DEFAULT_WALLET_REF]
    }&transactionType=${splitAccountTransactionTypes.PAYIN}&page=0&size=1`,
    {
      dependencies: [lastVisitedDate],
    },
  );

  const {
    apiData: payoutTransactionsResponse,
    isLoading: payoutTransactionsIsLoading,
  } = useApi(
    `/api/core-banking/transactions/splitaccount?accountId=${
      splitAccountsIds[splitAccountsIdsKeys.DEFAULT_WALLET_REF]
    }&transactionType=${splitAccountTransactionTypes.PAYOUT}&page=0&size=1`,
    {
      dependencies: [lastVisitedDate],
    },
  );

  const updateLastVisitedDate = useCallback(() => {
    if (isRefreshToggled) {
      return;
    }

    setIsRefreshToggled(true);

    setTimeout(() => {
      setIsRefreshToggled(false);
    }, 1000);

    setLastVisitedDate(new Date());

    setPayinPreviousTotalState(0);
    setPayinCurrentTotalState(0);
    setPayoutPreviousTotalState(0);
    setPayoutCurrentTotalState(0);

    dispatch(updateSplitAccountsDefaultWallet(fetch));
  }, [dispatch, fetch, isRefreshToggled]);

  const payinsChartDataMapper = useCallback(
    (data) => {
      const dataSource = payinTransactionsResponse?.empty
        ? payoutsMockedData.chart
        : data;

      setTimeout(() => {
        setPayinPreviousTotalState(() =>
          dataSource.content.reduce(
            (acc, item) => acc + (item?.['Previous period'] ?? 0),
            0,
          ),
        );
        setPayinCurrentTotalState(() =>
          dataSource.content.reduce(
            (acc, item) => acc + (item?.['Current period'] ?? 0),
            0,
          ),
        );
      }, 0);

      return {
        charts: dataSource.labels,
        data: dataSource.content,
      };
    },
    [payinTransactionsResponse],
  );

  const payoutsChartDataMapper = useCallback(
    (data) => {
      const dataSource = payoutTransactionsResponse?.empty
        ? payoutsMockedData.chart
        : data;

      setTimeout(() => {
        setPayoutPreviousTotalState(() =>
          dataSource.content.reduce(
            (acc, item) => acc + (item?.['Previous period'] ?? 0),
            0,
          ),
        );
        setPayoutCurrentTotalState(() =>
          dataSource.content.reduce(
            (acc, item) => acc + (item?.['Current period'] ?? 0),
            0,
          ),
        );
      }, 0);

      return {
        charts: dataSource.labels,
        data: dataSource.content,
      };
    },
    [payoutTransactionsResponse],
  );

  const profilesChartDataMapper = useCallback((data) => {
    const profilesCount = {
      [complianceProfileStatuses.ACTIVE]: 0,
      [complianceProfileStatuses.PENDING]: 0,
      [complianceProfileStatuses.ADDITIONAL_INFO]: 0,
      [complianceProfileStatuses.REJECTED]: 0,
      [complianceProfileStatuses.INACTIVE]: 0,
    };
    const hasCounters = Object.values(data).some((value) => value !== 0);
    const inputData = hasCounters ? data : profilesMockedData;

    setTimeout(() => {
      setHasProfiles(() => hasCounters);
    }, 0);

    Object.keys(inputData).forEach((key) => {
      const profileKey = key.toUpperCase();

      if (Object.hasOwnProperty.call(profilesCount, profileKey)) {
        profilesCount[profileKey] = inputData[key];
      } else {
        profilesCount[complianceProfileStatuses.PENDING] += inputData[key];
      }
    });

    return Object.entries(profilesCount)
      .filter(([, value]) => value !== 0)
      .map(([key, value]) => ({
        key,
        value,
        name: complianceProfileStatusesLabelsMap.get(key),
      }));
  }, []);

  const chartValueFormatter = useCallback(
    (value, name) => [
      formatCurrencyValue(value, {
        currencyIso: defaultWalletCurrency,
      }),
      name,
    ],
    [defaultWalletCurrency],
  );

  const chartValueTickFormatter = useCallback(
    (value) =>
      formatCurrencyValue(value, {
        currencyIso: defaultWalletCurrency,
        abbreviate: true,
      }),
    [defaultWalletCurrency],
  );

  const handlePayinsWidgetChange = useCallback(
    ({ item }) => setPayinsWidgetState(item),
    [],
  );

  const handlePayoutsWidgetChange = useCallback(
    ({ item }) => setPayoutsWidgetState(item),
    [],
  );

  const payinsWidgetUrlParams = useMemo(
    () => ({
      startDate: getUrlParamsFromTimeRange(payinsWidgetState, lastVisitedDate),
      endDate: lastVisitedDate.toISOString(),
      walletId: splitAccountsIds[splitAccountsIdsKeys.DEFAULT_WALLET_REF],
      [splitAccountTransactionKeys.TYPE]: splitAccountTransactionTypes.PAYIN,
    }),
    [lastVisitedDate, payinsWidgetState, splitAccountsIds],
  );

  const payoutsWidgetUrlParams = useMemo(
    () => ({
      startDate: getUrlParamsFromTimeRange(payoutsWidgetState, lastVisitedDate),
      endDate: lastVisitedDate.toISOString(),
      walletId: splitAccountsIds[splitAccountsIdsKeys.DEFAULT_WALLET_REF],
      [splitAccountTransactionKeys.TYPE]: splitAccountTransactionTypes.PAYOUT,
    }),
    [lastVisitedDate, payoutsWidgetState, splitAccountsIds],
  );

  const spacing = isMobile ? 0 : undefined;

  return (
    <AppLayout>
      <SplitAccountPageHeader
        actions={[
          <Grid key="last-updated" container alignItems="center">
            <Typography variant="body2" className={classes.lastUpdateTitle}>
              Last update{' '}
              <FormattedDateTime
                utcIsoDate={lastVisitedDate.toISOString()}
                dateFormat="dd.MM.yy, HH:mm"
              />
            </Typography>
            <SvgIcon
              component={RefreshIcon}
              color="primary"
              className={clsx(classes.refreshIcon, {
                [classes.refreshIconRotate]: isRefreshToggled,
              })}
              onClick={updateLastVisitedDate}
            />
          </Grid>,
        ]}
        classes={{
          toolbar: classes.toolbar,
        }}
        balanceDependencies={[lastVisitedDate]}
      />

      <PageTabsNavigation
        tabs={splitAccountPagesTabs}
        tabsLabelsMap={splitAccountPagesTabsLabelsMap}
        tabsRoutingMap={splitAccountPagesTabsRoutingMap}
        disabledTabsMap={splitAccountPagesTabsDisabledFieldsMap}
      />

      <GridWidget transparent noPadding>
        <GridWidget
          title="Payins"
          headerActions={
            !payinTransactionsIsLoading && !payinTransactionsResponse?.empty ? (
              <WidgetOptionsDropdown
                options={widgetsTimeRangesHistoric}
                onChange={handlePayinsWidgetChange}
              />
            ) : null
          }
          footer={
            !payinTransactionsIsLoading && !payinTransactionsResponse?.empty ? (
              <Button
                fullWidth={isMobile}
                to={`/split-account/transactions?${splitAccountTransactionKeys.TYPE}=${splitAccountTransactionTypes.PAYIN}`}
                color={buttonColor.PRIMARY}
                variant={buttonVariant.TEXT}
                size={buttonSize.MEDIUM}
                endIcon={
                  <SvgIcon
                    component={ChevronRightIcon}
                    style={{ fontSize: 16 }}
                    color="primary"
                  />
                }
              >
                View all payins
              </Button>
            ) : null
          }
          grid={{
            sm: 12,
            md: 6,
          }}
          spacing={spacing}
        >
          <BlurredComponent
            blur={
              !payinTransactionsIsLoading && payinTransactionsResponse?.empty
            }
            message="Here will be displayed the statistics for the payins"
          >
            <Grid item xs={12} data-aio-id="payinsChartPeriods">
              <EnumStatistics
                xs={6}
                invert
                separators
                keys={['Previous period', 'Current period']}
                data={{
                  'Previous period': payinPreviousTotalState,
                  'Current period': payinCurrentTotalState,
                }}
                colorsMap={
                  new Map([
                    ['Previous period', palette.secondaryPrimary.main],
                    ['Current period', palette.primary.main],
                  ])
                }
                formatter={(value) =>
                  formatCurrencyValue(value, {
                    currencyIso: defaultWalletCurrency,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} data-aio-id="payinsChart">
              {payinTransactionsIsLoading || isRefreshToggled ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={250}
                >
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <ConnectedComposedAreaLineChart
                  height={250}
                  showLegend={false}
                  url="/api/core-banking/reporting/splitaccount/summary"
                  unit={defaultWalletCurrency}
                  urlParams={payinsWidgetUrlParams}
                  cartesianGridProps={{
                    strokeDasharray: 1,
                  }}
                  dataMapper={payinsChartDataMapper}
                  xAxisProps={{
                    dataKey: 'date',
                  }}
                  yAxisProps={{
                    tickFormatter: chartValueTickFormatter,
                  }}
                  tooltipProps={{
                    formatter: chartValueFormatter,
                  }}
                  emptyComponent={
                    <StatusMessage
                      message={
                        <Typography variant="body2" gutterBottom>
                          No payins found for selected period
                        </Typography>
                      }
                      renderConfirmButton={false}
                      illustration={PaymentFailedIllustration}
                    />
                  }
                />
              )}
            </Grid>
          </BlurredComponent>
        </GridWidget>

        <GridWidget
          title="Payouts"
          headerActions={
            !payoutTransactionsIsLoading &&
            !payoutTransactionsResponse?.empty ? (
              <WidgetOptionsDropdown
                options={widgetsTimeRangesHistoric}
                onChange={handlePayoutsWidgetChange}
              />
            ) : null
          }
          footer={
            !payoutTransactionsIsLoading &&
            !payoutTransactionsResponse?.empty ? (
              <Button
                fullWidth={isMobile}
                to={`/split-account/transactions?${splitAccountTransactionKeys.TYPE}=${splitAccountTransactionTypes.PAYOUT}`}
                color={buttonColor.PRIMARY}
                variant={buttonVariant.TEXT}
                size={buttonSize.MEDIUM}
                endIcon={
                  <SvgIcon
                    component={ChevronRightIcon}
                    style={{ fontSize: 16 }}
                    color="primary"
                  />
                }
              >
                View all payouts
              </Button>
            ) : null
          }
          grid={{
            sm: 12,
            md: 6,
          }}
          spacing={spacing}
        >
          <BlurredComponent
            blur={
              !payoutTransactionsIsLoading && payoutTransactionsResponse?.empty
            }
            message="Here will be displayed the statistics for the payouts"
          >
            <Grid item xs={12} data-aio-id="payoutsChartPeriods">
              <EnumStatistics
                xs={6}
                invert
                separators
                keys={['Previous period', 'Current period']}
                data={{
                  'Previous period': payoutPreviousTotalState,
                  'Current period': payoutCurrentTotalState,
                }}
                colorsMap={
                  new Map([
                    ['Previous period', palette.secondaryPrimary.main],
                    ['Current period', palette.primary.main],
                  ])
                }
                formatter={(value) =>
                  formatCurrencyValue(value, {
                    currencyIso: defaultWalletCurrency,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} data-aio-id="payoutsChart">
              {payoutTransactionsIsLoading || isRefreshToggled ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={250}
                >
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <ConnectedComposedAreaLineChart
                  height={250}
                  showLegend={false}
                  url="/api/core-banking/reporting/splitaccount/summary"
                  unit={defaultWalletCurrency}
                  urlParams={payoutsWidgetUrlParams}
                  cartesianGridProps={{
                    strokeDasharray: 1,
                  }}
                  dataMapper={payoutsChartDataMapper}
                  xAxisProps={{
                    dataKey: 'date',
                  }}
                  yAxisProps={{
                    tickFormatter: chartValueTickFormatter,
                  }}
                  tooltipProps={{
                    formatter: chartValueFormatter,
                  }}
                  emptyComponent={
                    <StatusMessage
                      message={
                        <Typography variant="body2" gutterBottom>
                          No payouts found for selected period
                        </Typography>
                      }
                      renderConfirmButton={false}
                      illustration={PaymentFailedIllustration}
                    />
                  }
                />
              )}
            </Grid>
          </BlurredComponent>
        </GridWidget>
      </GridWidget>

      <GridWidget key={lastVisitedDate} transparent noPadding>
        <SplitAccountsSummaryWidget />

        <GridWidget
          title="User Profiles"
          classes={{
            content: classes.widgetPadding,
          }}
          footer={
            !hasProfiles ? null : (
              <Button
                fullWidth={isMobile}
                to="/split-account/profiles"
                color={buttonColor.PRIMARY}
                variant={buttonVariant.TEXT}
                size={buttonSize.MEDIUM}
                endIcon={
                  <SvgIcon
                    component={ChevronRightIcon}
                    style={{ fontSize: 16 }}
                    color="primary"
                  />
                }
              >
                View all user profiles
              </Button>
            )
          }
          grid={{
            sm: 12,
            md: 6,
          }}
          spacing={spacing}
        >
          <BlurredComponent
            blur={!hasProfiles}
            message="Here will be displayed the statistics for the profiles"
          >
            <Grid item xs={12}>
              <ConnectedPieBgChart
                url={`/api/core-banking/${accessorId}/onboarding/compliance/profile/count`}
                dataMapper={profilesChartDataMapper}
                colorsMap={complianceProfileStatusesColorsMap}
              />
            </Grid>
          </BlurredComponent>
        </GridWidget>
      </GridWidget>
    </AppLayout>
  );
}

export default SplitAccountDashboardPage;
