import React, { useState } from 'react';
import { isNil, pick } from 'rambda';
import { useSelector } from 'react-redux';
import { Grid, Tooltip, IconButton } from '@material-ui/core';
import { VisibilityRounded } from '@material-ui/icons';
import SplitAccountWalletDetailsModal, {
  modalSplitAccountKeysLabelsMap,
} from '../../organisms/SplitAccountWalletDetailsModal';
import {
  splitAccountsIdsKeys,
  splitAccountWalletKeys,
} from '../../../utils/enums';
import {
  stateSelectSplitAccountDefaultWallet,
  stateSelectSplitAccountsIds,
} from '../../../redux';

const accountDetailsKeys = pick([
  splitAccountWalletKeys.ALIAS,
  splitAccountWalletKeys.DEFAULT_WALLET_ID,
  splitAccountsIdsKeys.SPLIT_ACCOUNT_ID,
  splitAccountWalletKeys.STATUS,
  splitAccountWalletKeys.DATE_CREATED,
]);

const defaultSplitAccountWalletModalLabelsMap = new Map([
  ...modalSplitAccountKeysLabelsMap,
  [splitAccountWalletKeys.ALIAS, 'Default Wallet Name'],
  [splitAccountWalletKeys.DEFAULT_WALLET_ID, 'Default Wallet ID'],
  [splitAccountsIdsKeys.SPLIT_ACCOUNT_ID, 'Split Account ID'],
  [splitAccountWalletKeys.PAYEE_ID, 'Payee ID'],
  [splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY, 'Currency'],
]);

// Used only fo subpages on Default SA
const SplitAccountPageHeaderPreTitle = () => {
  const { splitAccountsIds } = useSelector(stateSelectSplitAccountsIds);
  const { splitAccountDefaultWallet } = useSelector(
    stateSelectSplitAccountDefaultWallet,
  );

  const [showAccountDetails, setShowAccountDetails] = useState(false);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          {splitAccountDefaultWallet?.[splitAccountWalletKeys.ALIAS]}
        </Grid>
        <Grid item>
          <Tooltip
            title="Click to view Split Account Default Wallet details"
            arrow
          >
            <IconButton
              color="secondary"
              aria-label="view default wallet details"
              component="span"
              onClick={() => setShowAccountDetails(true)}
              size="small"
              data-aio-id="viewAccountDetailsLink"
              data-test-id="viewAccountDetailsLink"
            >
              <VisibilityRounded />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {showAccountDetails && !isNil(splitAccountDefaultWallet) && (
        <SplitAccountWalletDetailsModal
          account={{
            ...splitAccountDefaultWallet,
            [splitAccountsIdsKeys.SPLIT_ACCOUNT_ID]:
              splitAccountsIds[splitAccountsIdsKeys.SPLIT_ACCOUNT_ID],
          }}
          open={showAccountDetails}
          onClose={() => setShowAccountDetails(false)}
          labelsMap={defaultSplitAccountWalletModalLabelsMap}
          accountDetailsKeys={accountDetailsKeys}
          copyableValues={{
            [splitAccountsIdsKeys.SPLIT_ACCOUNT_ID]: true,
          }}
        />
      )}
    </>
  );
};

export default SplitAccountPageHeaderPreTitle;
