import { combineReducers } from 'redux';
import { uiReducer } from '@fondy/layouts';
import accountsReducer from './accountsReducer';
import userProfileReducer from './userProfileReducer';
import partyReducer from './partyReducer';
import initialReduxState from '../initialReduxState';
import userProfileActionTypes from '../constants/userProfileActionTypes';

const appReducer = combineReducers({
  accounts: accountsReducer,
  profile: userProfileReducer,
  parties: partyReducer,
  ui: uiReducer,
});

const rootReducer = (state = initialReduxState, action) =>
  appReducer(
    action.type === userProfileActionTypes.USER_LOGOUT
      ? initialReduxState
      : state,
    action,
  );

export default rootReducer;
