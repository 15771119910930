import React, { useCallback, useEffect } from 'react';
import { bool, node, oneOfType, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { Grid, SvgIcon, useMediaQuery } from '@material-ui/core';
import { DefaultLayout } from '@fondy/layouts';
import { menuItemTypes } from '@fondy/menu';
import {
  Button,
  buttonColor,
  buttonSize,
  buttonShape,
  buttonVariant,
} from '@fondy/buttons';
import { useAuth, useKeycloak } from '@fondy/keycloak';
import { useApplicationContext } from '@fondy/application-context';
import { BasicAlert, alertTypes } from '@fondy/alerts';
import { ChevronLeftIcon } from '@fondy/icons';
import {
  sidebarNavigation,
  bottomSidebarNavigation,
  footerNavigation,
} from './appNavigation';
import {
  userPartyStateValidationSelector,
  getAccountsAction,
} from '../../../redux';

function AppLayout({ children, backButtonUrl, backButtonLabel }) {
  const dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const { keycloak, initialized } = useKeycloak();
  const { fetch } = useApplicationContext();
  const isMobile = useMediaQuery(breakpoints.down('sm'), { noSsr: true });
  const { isPartyCompliantPending, hasActiveParty } = useSelector(
    userPartyStateValidationSelector,
  );
  const hasToolbarBackButton =
    !isMobile && !!backButtonLabel && !!backButtonUrl;
  const renderProfileLink =
    keycloak &&
    keycloak.realm &&
    keycloak.clientId &&
    initialized &&
    keycloak.authenticated;

  const fetchAccounts = useCallback(
    (force = true) => dispatch(getAccountsAction(fetch, force)),
    [dispatch, fetch],
  );

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  return (
    <DefaultLayout
      authHook={useAuth}
      sidebar={{
        dense: true,
        customerLogo: 'flow',
        navigation: sidebarNavigation,
        bottomNavigation: bottomSidebarNavigation,
      }}
      toolbar={{
        userToolbarProps: {
          authHook: useAuth,
          navigation: renderProfileLink
            ? [
                {
                  type: menuItemTypes.LINK,
                  label: 'Profile',
                  'data-aio-id': 'userKeycloakProfileLink',
                  to: keycloak.createAccountUrl({
                    redirectUri: window.location.origin,
                  }),
                },
              ]
            : [],
          disableMobileNavigation: !hasActiveParty,
        },
        children: hasToolbarBackButton ? (
          <Button
            link
            to={backButtonUrl}
            color={buttonColor.PRIMARY}
            variant={buttonVariant.TEXT}
            shape={buttonShape.ROUNDED}
            size={buttonSize.MEDIUM}
            startIcon={<SvgIcon component={ChevronLeftIcon} />}
            data-aio-id="layoutBackButton"
          >
            {backButtonLabel}
          </Button>
        ) : undefined,
      }}
      footer={{
        navigation: footerNavigation,
        transparent: true,
      }}
    >
      {isPartyCompliantPending && (
        <Grid item xs={12}>
          <BasicAlert
            type={alertTypes.INFO}
            heading="Your application is on the review"
            message="We will let you know when you will be able to use our service"
          />
        </Grid>
      )}
      {children}
    </DefaultLayout>
  );
}

AppLayout.propTypes = {
  children: node.isRequired,
  backButtonUrl: oneOfType([string, bool]),
  backButtonLabel: oneOfType([string, bool]),
};

AppLayout.defaultProps = {
  backButtonUrl: false,
  backButtonLabel: false,
};

export default AppLayout;
