import React, { useState, useCallback, useMemo } from 'react';
import { isNil, omit } from 'rambda';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, SvgIcon } from '@material-ui/core';
import { bool, func, string, shape } from 'prop-types';
import { useSelector } from 'react-redux';
import { DownloadIcon } from '@fondy/icons';
import { Drawer, ObjectDataDisplay } from '@fondy/data-display';
import {
  AccountAliasTableCell,
  AvatarDataCell,
  StatusTableCell,
} from '@fondy/tables';
import { formatSortCodeValue, useApi } from '@fondy/utils';
import { accountTypes } from '@fondy/enums';
import {
  accountKeys,
  transactionKeys,
  splitAccountsIdsKeys,
  transactionKeysLabelsMap,
  transactionKeysDetailsLabelsMap,
  isTransactionBetweenOwnAccounts,
  splitAccountWalletKeys,
  splitAccountTransactionKeys,
  splitAccountTransactionKeysDetailsLabelsMap,
  splitAccountTransactionTypesLabelsMap,
  ibanToStringFormatter,
} from '../../../utils';
import FormattedDateTime from '../../atoms/FormattedDateTime';
import createTransactionDetailsPdf from '../../atoms/pdfSections/transactionDetails/createTransactionDetailsPdf';
import PaymentTransactionDetailsModalHeader from '../../atoms/PaymentTransactionDetailsModalHeader';
import {
  stateSelectAccountsData,
  stateTimeZoneSelector,
  stateSelectSplitAccountsIds,
} from '../../../redux';

const excludeSortCodeAndNumber = omit([
  transactionKeys.COUNTERPARTY_SORT_CODE,
  transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER,
]);

const getTransactionDetails = (
  transaction,
  { isDebit, isSplitAccount, isSplitAccountWallet } = {},
) => ({
  Status: 'COMPLETED',
  ...(isDebit
    ? {}
    : {
        [transactionKeys.COUNTERPARTY_NAME]:
          transaction[transactionKeys.COUNTERPARTY_NAME],
      }),
  ...(!isNil(transaction[transactionKeys.ACCOUNT_ID])
    ? {
        [transactionKeys.ACCOUNT_ID]: transaction[transactionKeys.ACCOUNT_ID],
      }
    : {}),
  ...(isDebit
    ? {
        [transactionKeys.COUNTERPARTY_NAME]:
          transaction[transactionKeys.COUNTERPARTY_NAME],
      }
    : {}),
  ...(!isNil(transaction[transactionKeys.TRANSACTION_REFERENCE])
    ? {
        [transactionKeys.TRANSACTION_REFERENCE]:
          transaction[transactionKeys.TRANSACTION_REFERENCE],
      }
    : {}),
  ...(!isNil(transaction[transactionKeys.COUNTERPARTY_SORT_CODE])
    ? {
        [transactionKeys.COUNTERPARTY_SORT_CODE]:
          transaction[transactionKeys.COUNTERPARTY_SORT_CODE],
      }
    : {}),
  ...(!isNil(transaction[transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER])
    ? {
        [transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER]:
          transaction[transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER],
      }
    : {}),
  ...(!isNil(transaction[transactionKeys.COUNTERPARTY_IBAN])
    ? {
        [transactionKeys.COUNTERPARTY_IBAN]:
          transaction[transactionKeys.COUNTERPARTY_IBAN],
      }
    : {}),
  ...(isSplitAccount || isSplitAccountWallet
    ? {
        [splitAccountTransactionKeys.TYPE]:
          transaction[splitAccountTransactionKeys.TYPE],
      }
    : {}),
  [transactionKeys.ID]: transaction[transactionKeys.ID],
});

const transactionKeysDetailsMergedLabelsMap = new Map([
  ...transactionKeysLabelsMap,
  ...transactionKeysDetailsLabelsMap,
  ...splitAccountTransactionKeysDetailsLabelsMap,
]);

const useStyles = makeStyles((theme) => ({
  dataDisplay: {
    margin: 0,
  },
  collapse: {
    padding: 0,
  },
  row: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  label: {
    [theme.breakpoints.up('sm')]: {
      width: 160,
      minWidth: 160,
    },
  },
  value: {
    display: 'flex',
    flex: 1,
  },
  headerPadding: {
    paddingRight: theme.spacing(3),
  },
}));

const TransactionDetailsModal = ({
  open,
  transaction,
  onClose,
  datetimeFormat,
  isSplitAccount,
  isSplitAccountWallet,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);
  const { splitAccountsIds } = useSelector(stateSelectSplitAccountsIds);
  const {
    selectedAccount,
    selectedAccountIsDefaultSplitAccount,
    hasAccounts,
    isLoading,
  } = useSelector(
    stateSelectAccountsData(
      transaction ? transaction[transactionKeys.ACCOUNT_ID] : null,
    ),
  );

  const {
    apiData: splitAccountWallets,
    isLoading: splitAccountWalletsLoading,
    isError: isSplitAccountWalletsError,
    apiError: splitAccountsWalletsError,
    retry,
  } = useApi(
    `/api/core-banking/splitaccount/${
      splitAccountsIds?.[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID]
    }/wallet`,
    {
      lazy:
        !!selectedAccount &&
        isNil(splitAccountsIds?.[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID]),
      dependencies: [
        splitAccountsIds?.[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID],
      ],
    },
  );

  const {
    selectedSplitAccountWallet,
    selectedAccountIsWalletSplitAccount,
  } = useMemo(() => {
    if (
      splitAccountWalletsLoading ||
      isSplitAccountWalletsError ||
      isNil(transaction) ||
      isNil(splitAccountWallets)
    ) {
      return {
        selectedSplitAccountWallet: null,
        selectedAccountIsWalletSplitAccount: false,
      };
    }
    const selectedWallet = splitAccountWallets.find(
      (wallet) =>
        wallet[splitAccountWalletKeys.ID] ===
        transaction[transactionKeys.ACCOUNT_ID],
    );
    return {
      selectedSplitAccountWallet: selectedWallet,
      selectedAccountIsWalletSplitAccount: isNil(selectedAccount),
    };
  }, [
    isSplitAccountWalletsError,
    selectedAccount,
    splitAccountWallets,
    splitAccountWalletsLoading,
    transaction,
  ]);

  const selectedAccountData = useMemo(
    () => selectedAccount || selectedSplitAccountWallet,
    [selectedAccount, selectedSplitAccountWallet],
  );

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const internalAccountsLinkResolver = useCallback(() => {
    const id = selectedAccountData?.[accountKeys.ID];
    if (!id) return null;
    if (selectedAccountIsWalletSplitAccount) {
      return `/split-account/wallets/${id}/transactions`;
    }
    if (selectedAccountIsDefaultSplitAccount) {
      return '/split-account/transactions';
    }
    return `/accounts/transactions/${id}`;
  }, [
    selectedAccountData,
    selectedAccountIsDefaultSplitAccount,
    selectedAccountIsWalletSplitAccount,
  ]);

  const createTransactionOwnSourceAccountButton = useCallback(
    ({ accountAlias, accountType, currencyIso }) => () => (
      <AccountAliasTableCell
        noWrap={false}
        accountAlias={accountAlias}
        accountType={accountType}
        currencyIso={currencyIso}
        fontSize={14}
        medium
        alignTop
      />
    ),
    [],
  );

  if (!transaction || !open || !hasAccounts || isLoading) return null;

  const isDebit =
    transaction[transactionKeys.ACCOUNT_OPENING_BALANCE] >
    transaction[transactionKeys.ACCOUNT_CLOSING_BALANCE];
  const transactionDirectionSymbol = isDebit ? '- ' : '+ ';

  const toOwnAccount = isTransactionBetweenOwnAccounts(transaction);

  return (
    <Drawer
      headerProps={{
        className: classes.headerPadding,
      }}
      open={open}
      onClose={onClose}
      contentProps={{
        dividers: true,
      }}
      footerActions={
        !isNil(selectedAccountData)
          ? [
              {
                label: 'Download confirmation',
                color: 'secondary',
                size: 'large',
                onClick: async () => {
                  createTransactionDetailsPdf({
                    transaction,
                    selectedAccount: selectedAccountData,
                    toOwnAccount,
                    isSplitAccount,
                    isSplitAccountWallet,
                    datetimeFormat,
                    theme,
                    timeZone: legalEntityTimezone,
                    loadingStateSetter: setIsGeneratingPdf,
                    filename: () =>
                      `${
                        transaction[transactionKeys.TRANSACTION_REFERENCE]
                      } transaction details.pdf`,
                  });
                },
                startIcon: isGeneratingPdf ? (
                  <CircularProgress size={14} className={classes.progress} />
                ) : (
                  <SvgIcon style={{ fontSize: 20 }} component={DownloadIcon} />
                ),
                disabled: isGeneratingPdf,
                'data-aio-id': 'downloadTransactionStatementButton',
              },
            ]
          : undefined
      }
      header={
        <PaymentTransactionDetailsModalHeader
          amountValuePrefix={transactionDirectionSymbol}
          amount={transaction[transactionKeys.TRANSACTION_AMOUNT]}
          currency={transaction[transactionKeys.TRANSACTION_CURRENCY]}
          originalAmount={
            transaction[transactionKeys.TRANSACTION_ORIGINAL_AMOUNT]
          }
          originalCurrency={
            transaction[transactionKeys.TRANSACTION_ORIGINAL_CURRENCY]
          }
          createdAt={
            isSplitAccount || isSplitAccountWallet
              ? transaction[transactionKeys.BOOKING_DATE]
              : transaction[transactionKeys.CREATED_AT]
          }
          fxRate={transaction[transactionKeys.FX_RATE]}
          datetimeFormat={datetimeFormat}
        />
      }
      data-aio-id="transactionDetailsModal"
    >
      <ObjectDataDisplay
        classes={{
          item: classes.row,
          value: classes.value,
          label: classes.label,
          collapse: classes.collapse,
        }}
        className={classes.dataDisplay}
        data={getTransactionDetails(
          toOwnAccount ? excludeSortCodeAndNumber(transaction) : transaction,
          {
            isDebit,
            isSplitAccount,
            isSplitAccountWallet,
          },
        )}
        dataFieldsLabelsMap={
          new Map([
            ...transactionKeysDetailsMergedLabelsMap,
            [transactionKeys.ACCOUNT_ID, `Paid ${isDebit ? 'from' : 'to'}`],
            [
              transactionKeys.COUNTERPARTY_NAME,
              `Paid ${isDebit ? 'to' : 'from'}`,
            ],
          ])
        }
        dataFieldsMapper={{
          [transactionKeys.ACCOUNT_ID]: !isNil(
            selectedAccountData?.[accountKeys.ID],
          )
            ? createTransactionOwnSourceAccountButton({
                url: internalAccountsLinkResolver(),
                accountAlias: selectedAccountData[accountKeys.ALIAS],
                accountType: selectedAccountIsWalletSplitAccount
                  ? accountTypes.WALLET
                  : selectedAccountData[accountKeys.TYPE],
                currencyIso:
                  selectedAccountData[accountKeys.CURRENCY] ||
                  selectedAccountData[
                    splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY
                  ],
              })
            : null,
          [transactionKeys.COUNTERPARTY_NAME]: toOwnAccount
            ? createTransactionOwnSourceAccountButton({
                url:
                  isSplitAccount || isSplitAccountWallet
                    ? `/split-account/wallets/${
                        transaction[transactionKeys.COUNTERPARTY_ACCOUNT_ID]
                      }/transactions`
                    : `/accounts/transactions/${
                        transaction[transactionKeys.COUNTERPARTY_ACCOUNT_ID]
                      }`,
                accountAlias:
                  transaction[transactionKeys.COUNTERPARTY_ACCOUNT_ALIAS],
                accountType:
                  transaction[transactionKeys.COUNTERPARTY_ACCOUNT_TYPE],
                currencyIso:
                  transaction[transactionKeys.COUNTERPARTY_ACCOUNT_CURRENCY],
              })
            : (value) => (
                <AvatarDataCell
                  title={value}
                  avatarSize="small"
                  titleProps={{ style: { fontSize: 14 } }}
                  alignTop
                />
              ),
          [transactionKeys.COUNTERPARTY_SORT_CODE]: formatSortCodeValue,
          [transactionKeys.COUNTERPARTY_IBAN]: ibanToStringFormatter,
          Status: (value) => (
            <StatusTableCell
              status={value}
              labelsMap={new Map([['COMPLETED', 'Completed']])}
            />
          ),
          [splitAccountTransactionKeys.TYPE]: (value) =>
            value ? (
              <StatusTableCell
                status={value}
                labelsMap={splitAccountTransactionTypesLabelsMap}
              />
            ) : null,
          [transactionKeys.BOOKING_DATE]: (value) => (
            <FormattedDateTime utcIsoDate={value} dateFormat={datetimeFormat} />
          ),
          [transactionKeys.CREATED_AT]: (value) => (
            <FormattedDateTime utcIsoDate={value} dateFormat={datetimeFormat} />
          ),
        }}
        grid={{ xs: 12 }}
        itemSpacing={1}
        isLoading={splitAccountWalletsLoading}
      />
    </Drawer>
  );
};

TransactionDetailsModal.propTypes = {
  open: bool,
  transaction: shape({
    [transactionKeys.ID]: string.isRequired,
    [transactionKeys.ACCOUNT_ID]: string,
    [transactionKeys.COUNTERPARTY_NAME]: string,
    [transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER]: string,
    [transactionKeys.COUNTERPARTY_ACCOUNT_CURRENCY]: string,
    [transactionKeys.COUNTERPARTY_ACCOUNT_ID]: string,
    [transactionKeys.COUNTERPARTY_SORT_CODE]: string,
    [transactionKeys.BOOKING_DATE]: string.isRequired,
  }),
  onClose: func,
  datetimeFormat: string,
  isSplitAccount: bool,
  isSplitAccountWallet: bool,
};

TransactionDetailsModal.defaultProps = {
  open: false,
  onClose: undefined,
  transaction: null,
  datetimeFormat: 'dd MMM yyyy HH:mm',
  isSplitAccount: false,
  isSplitAccountWallet: false,
};

export default TransactionDetailsModal;
