import React from 'react';
import { node, number, string } from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AmountTableCell } from '@fondy/tables';
import {
  formatCurrencyValue,
  getRenderPropsFromCurrencyIsoCode,
} from '@fondy/utils';
import { flagIconStyles } from '@fondy/forms';

const useStyles = makeStyles((theme) => ({
  amountContainer: {
    textAlign: 'right',
  },
  amount: {
    fontWeight: 600,
    fontSize: theme.typography.htmlFontSize,
  },
  fees: {
    fontSize: 12,
  },
  flagIconStyles,
}));

const PaymentAmountWithFees = ({
  value,
  currencyIso,
  sourceCurrencyIso,
  justifyContent,
  feeValue,
  inputAdornment,
  ...restProps
}) => {
  const classes = useStyles();
  const { currencyFlag } = getRenderPropsFromCurrencyIsoCode(currencyIso);

  return (
    <Box
      {...restProps}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display="flex"
        alignItems="center"
        className={classes.flagIconStyles}
      >
        {currencyFlag ? <img src={currencyFlag} alt={currencyIso} /> : null}
        <Typography noWrap>{currencyIso}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box className={classes.amountContainer}>
          <AmountTableCell
            value={value}
            currencyIso={currencyIso}
            justifyContent={justifyContent}
            className={classes.amount}
          />
          {feeValue && feeValue > 0 ? (
            <span className={classes.fees}>{`+${formatCurrencyValue(feeValue, {
              currencyIso: sourceCurrencyIso,
            })} payment fee`}</span>
          ) : null}
        </Box>
        {inputAdornment}
      </Box>
    </Box>
  );
};

PaymentAmountWithFees.propTypes = {
  value: number.isRequired,
  feeValue: number,
  sourceCurrencyIso: string.isRequired,
  currencyIso: string.isRequired,
  justifyContent: string,
  inputAdornment: node,
};
PaymentAmountWithFees.defaultProps = {
  justifyContent: 'flex-start',
  feeValue: null,
  inputAdornment: null,
};

export default PaymentAmountWithFees;
