import React from 'react';
import { Radio, SvgIcon } from '@material-ui/core';
import { RadioIcon, RadioCheckedIcon } from '@fondy/icons';

const RadioStyled = (props) => {
  return (
    <Radio
      {...props}
      icon={<SvgIcon component={RadioIcon} />}
      checkedIcon={<SvgIcon component={RadioCheckedIcon} />}
    />
  );
};

export default RadioStyled;
