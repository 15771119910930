export const newPaymentKeys = Object.freeze({
  ACCOUNT_ID: 'accountId',
  DESTINATION_ACCOUNT_ID: 'creditorAccountId',
  PAYMENT_CURRENCY: 'currency',
  AMOUNT: 'amount',
  DESCRIPTION: 'description',
  PARTY_ID: 'partyId',
  PAYEE_ACCOUNT_NUMBER: 'payeeAccountNumber',
  PAYEE_IBAN: 'payeeIban',
  PAYEE_ID: 'payeeId',
  PAYEE_NAME: 'payeeName',
  PAYEE_SORT_CODE: 'payeeSortCode',
  PAYEE_CURRENCY: 'payeeCurrency',
  TRANSFER_TYPE: 'transferType',
  TWO_FACTOR_CODE: 'tfaCode',

  PAYEE_ADDRESS: 'payeeAddress',
  PAYEE_COUNTRY: 'countryCode',
  PAYEE_CITY: 'city',
  PAYEE_STREET: 'street',
  PAYEE_BUILDING_NAME: 'buildingName',
  PAYEE_BUILDING_NUMBER: 'buildingNumber',
  PAYEE_POSTAL_CODE: 'postalCode',

  PAYEE_TYPE: 'payeeType',
  PAYEE_BANK_DETAILS_TYPE: 'payeeBankDetailsType',

  PAYEE_FIRST_NAME: 'payeeFirstName',
  PAYEE_LAST_NAME: 'payeeLastName',

  PAYEE_COMPANY_NAME: 'payeeCompanyName',
});

export const newPaymentKeysLabelsMap = new Map([
  [newPaymentKeys.ACCOUNT_ID, null],
  [newPaymentKeys.DESTINATION_ACCOUNT_ID, null],
  [newPaymentKeys.PAYMENT_CURRENCY, 'Currency'],
  [newPaymentKeys.PAYEE_ID, 'Counterparty Id'],
  [newPaymentKeys.AMOUNT, 'Amount'],
  [newPaymentKeys.DESCRIPTION, 'Description'],
  [newPaymentKeys.PARTY_ID, 'Party Id'],
  [newPaymentKeys.PAYEE_ACCOUNT_NUMBER, 'Account number'],
  [newPaymentKeys.PAYEE_IBAN, 'IBAN'],
  [newPaymentKeys.PAYEE_NAME, 'Counterparty'],
  [newPaymentKeys.PAYEE_SORT_CODE, 'Sort code'],
  [newPaymentKeys.PAYEE_CURRENCY, 'Currency'],
  [newPaymentKeys.PAYEE_COUNTRY, 'Country'],
  [newPaymentKeys.PAYEE_CITY, 'City'],
  [newPaymentKeys.PAYEE_STREET, 'Street'],
  [newPaymentKeys.PAYEE_BUILDING_NAME, 'Building name'],
  [newPaymentKeys.PAYEE_BUILDING_NUMBER, 'Building №'],
  [newPaymentKeys.PAYEE_POSTAL_CODE, 'Postal code'],
  [newPaymentKeys.PAYEE_TYPE, 'Counterparty type'],
  [newPaymentKeys.PAYEE_BANK_DETAILS_TYPE, 'Choose which bank details to use'],
  [newPaymentKeys.PAYEE_FIRST_NAME, 'First name'],
  [newPaymentKeys.PAYEE_LAST_NAME, 'Last name'],
  [newPaymentKeys.PAYEE_COMPANY_NAME, 'Company name'],
  [newPaymentKeys.TRANSFER_TYPE, null],
  [newPaymentKeys.TWO_FACTOR_CODE, null],
]);
