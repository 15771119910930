import { orderTypesLabels } from '@fondy/enums';
import { defaultPageMargins } from '@fondy/pdf';
import pdfFooterCreator from '../pdfFooterCreator';
import { transactionKeysLabelsMap } from '../../../../utils';

const createTransactionsPdfStatementFooter = async ({
  pdfDocument,
  theme,
  sortConfig: { order, key },
  margin = defaultPageMargins,
} = {}) => {
  const { fontName } = await pdfDocument.getFont();
  await pdfDocument.setFont(fontName, 'italic');
  await pdfDocument.setTextColor(theme.palette.text.secondary);
  await pdfDocument.text(
    `All transactions are ${orderTypesLabels.get(
      order,
    )} by ${transactionKeysLabelsMap.get(key)}`,
    margin.left,
    pdfDocument.lastAutoTable.finalY + 30,
  );

  await pdfFooterCreator(pdfDocument, { theme });
};

export default createTransactionsPdfStatementFooter;
