import { isNil } from 'rambda';
import { createSelector } from 'reselect';
import { accountStatuses, accountTypes, activeStatuses } from '@fondy/enums';
import { accountKeys, splitAccountWalletKeys } from '../../utils';

const findAccountById = ({ accountId, accounts = [], mapper = null } = {}) => {
  const account =
    accounts.length > 0 ? accounts?.find(({ id }) => id === accountId) : null;
  if (!account) return null;
  return !isNil(mapper) ? mapper(account) : account;
};

const filterNotClosedAccounts = (i) =>
  i[accountKeys.STATUS] !== accountStatuses.CLOSED;

const stateSelectAccountsData = (accountId) =>
  createSelector(
    (state) => ({
      accounts: state.accounts,
      splitAccounts: !isNil(state.parties.data?.[0]?.splitAccountsDefaultWallet)
        ? [state.parties.data?.[0].splitAccountsDefaultWallet]
        : [],
    }),
    ({ accounts, splitAccounts }) => {
      const selectedAccountSplitAccount = findAccountById({
        accounts: splitAccounts,
        accountId,
        mapper: (account) => ({
          ...account,
          [accountKeys.CURRENCY]:
            account[splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY],
        }),
      });
      const selectedAccount = accountId
        ? findAccountById({ accounts: accounts.data, accountId })
        : null;
      const selectedAccountData =
        selectedAccount || selectedAccountSplitAccount;
      return {
        selectedAccountIsDefaultSplitAccount:
          isNil(selectedAccount) && !isNil(selectedAccountSplitAccount),
        selectedAccount: selectedAccountData,
        allNotClosedAccounts: accounts.data.filter(filterNotClosedAccounts),
        allAccounts: accounts.data,
        lastUpdate: accounts.lastUpdate,
        isLoading: accounts.isFetching,
        totalBalance: accounts.data.reduce((sum, account) => {
          if (!!accountId && accountId !== account[accountKeys.ID]) return sum;
          if (!!accountId && accountId === account[accountKeys.ID]) {
            return sum + account[accountKeys.CURRENT_BALANCE];
          }
          if (
            account[accountKeys.STATUS] === activeStatuses.ACTIVE &&
            account[accountKeys.TYPE] !== accountTypes.WALLET
          ) {
            return sum + account[accountKeys.CURRENT_BALANCE];
          }
          return sum;
        }, 0),
        hasAccounts:
          (selectedAccount ? [selectedAccount] : accounts.data).length > 0 &&
          !accounts.isFetching,
      };
    },
  );

export default stateSelectAccountsData;
