import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { orderTypes } from '@fondy/enums';
import { GridWidget } from '@fondy/data-display';
import { ConnectedFilteredTable } from '@fondy/tables';
import { stateSelectSplitAccountsIds } from '../../../redux';
import { AppLayout } from '../../layouts';
import { PageTabsNavigation, SplitAccountPageHeader } from '../../organisms';
import { splitAccountWalletsTableColumns } from '../../atoms/tableColumns';
import {
  splitAccountsIdsKeys,
  splitAccountPagesTabs,
  splitAccountWalletKeys,
  splitAccountPagesTabsLabelsMap,
  splitAccountPagesTabsRoutingMap,
  splitAccountPagesTabsDisabledFieldsMap,
} from '../../../utils';

const useStyles = makeStyles(() => ({}));

const SplitAccountWalletsListPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const { splitAccountsIds } = useSelector(stateSelectSplitAccountsIds);

  const accessorId =
    splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID];

  const handleCellClick = useCallback(
    (_, row) =>
      history.push(
        `/split-account/wallets/${row[splitAccountWalletKeys.ID]}/transactions`,
      ),
    [history],
  );

  return (
    <AppLayout>
      <SplitAccountPageHeader
      // actions={[<MakePaymentButton key="new-payout" label="Make a payout" />]}
      />

      <PageTabsNavigation
        tabs={splitAccountPagesTabs}
        tabsLabelsMap={splitAccountPagesTabsLabelsMap}
        tabsRoutingMap={splitAccountPagesTabsRoutingMap}
        disabledTabsMap={splitAccountPagesTabsDisabledFieldsMap}
      />

      <GridWidget container noBottomPadding spacing={0}>
        <ConnectedFilteredTable
          defaultOrderBy={splitAccountWalletKeys.ID}
          defaultOrderDirection={orderTypes.DESCENDING}
          rowId={splitAccountWalletKeys.ID}
          url={`/api/core-banking/splitaccount/${accessorId}/wallet`}
          dependencies={[accessorId]}
          emptyComponent="You don't have any wallets yet"
          columns={splitAccountWalletsTableColumns}
          onCellClick={handleCellClick}
          data-aio-id="walletsTable"
          pagination={{
            enabled: false,
          }}
        />
      </GridWidget>
    </AppLayout>
  );
};

export default SplitAccountWalletsListPage;
