import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { endOfDay, startOfDay, subDays, subYears } from 'date-fns';
import { orderTypes } from '@fondy/enums';
import { formatTimeZonedDate, useApi } from '@fondy/utils';
import { GridWidget } from '@fondy/data-display';
import { ConnectedFilteredTable, filterFieldTypes } from '@fondy/tables';
import {
  createTransactionsPdfStatementHeader,
  createTransactionsPdfStatementFooter,
  createTransactionsPdfStatementTableFooter,
} from '../../atoms';
import {
  stateTimeZoneSelector,
  stateSelectSplitAccountsIds,
  stateSelectSplitAccountDefaultWallet,
} from '../../../redux';
import { AppLayout } from '../../layouts';
import {
  TransactionDetailsModal,
  PageTabsNavigation,
  SplitAccountPageHeader,
} from '../../organisms';
import {
  splitAccountTransactionsTableColumns,
  splitAccountTransactionsPdfTableColumns,
} from '../../atoms/tableColumns';
import {
  splitAccountPagesTabs,
  splitAccountPagesTabsLabelsMap,
  splitAccountPagesTabsRoutingMap,
  splitAccountPagesTabsDisabledFieldsMap,
  splitAccountTransactionKeys,
  splitAccountsIdsKeys,
  splitAccountWalletKeys,
  splitAccountTransactionKeysLabelsMap,
  tableEmptyMessageResolver,
  csvValueAsStringFormatter,
  splitAccountTransactionTypesLabelsMap,
  splitAccountTransactionTypes,
} from '../../../utils';

const useStyles = makeStyles(() => ({}));

const SplitAccountTransactionsListPage = () => {
  const classes = useStyles();
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);
  const { splitAccountsIds } = useSelector(stateSelectSplitAccountsIds);
  const { splitAccountDefaultWallet } = useSelector(
    stateSelectSplitAccountDefaultWallet,
  );
  const [transactionDetails, setTransactionDetails] = useState(null);
  const {
    apiData: userPartyData,
    isLoading: isPartyLoading,
    isError: hasPartyFetchError,
  } = useApi(
    `/api/parties/${
      splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID]
    }`,
    {
      dependencies: [
        splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID],
      ],
    },
  );

  const handleTransactionRecordClick = useCallback(
    async (_, row) => setTransactionDetails(row),
    [],
  );

  const handleTransactionDetailsModalClose = useCallback(
    () => setTransactionDetails(null),
    [],
  );

  const currentDate = new Date();

  return (
    <AppLayout>
      <SplitAccountPageHeader />

      <PageTabsNavigation
        tabs={splitAccountPagesTabs}
        tabsLabelsMap={splitAccountPagesTabsLabelsMap}
        tabsRoutingMap={splitAccountPagesTabsRoutingMap}
        disabledTabsMap={splitAccountPagesTabsDisabledFieldsMap}
      />

      <GridWidget container noBottomPadding spacing={0}>
        <ConnectedFilteredTable
          defaultOrderBy={splitAccountTransactionKeys.BOOKING_DATE}
          defaultOrderDirection={orderTypes.DESCENDING}
          rowId={splitAccountTransactionKeys.ID}
          url={`/api/core-banking/transactions/splitaccount?accountId=${
            splitAccountsIds[splitAccountsIdsKeys.DEFAULT_WALLET_REF]
          }`}
          dependencies={[
            splitAccountsIds[splitAccountsIdsKeys.DEFAULT_WALLET_REF],
          ]}
          emptyComponent={tableEmptyMessageResolver('transactions')}
          columns={splitAccountTransactionsTableColumns}
          pagination={{
            size: 10,
          }}
          onCellClick={handleTransactionRecordClick}
          data-aio-id="transactionsTable"
          data-test-id="transactionsTable"
          filters={{
            [splitAccountTransactionKeys.TYPE]: {
              label: splitAccountTransactionKeysLabelsMap.get(
                splitAccountTransactionKeys.TYPE,
              ),
              values: splitAccountTransactionTypes,
              valuesLabelsMap: splitAccountTransactionTypesLabelsMap,
              type: filterFieldTypes.SELECT,
            },
          }}
          dateFilters={{
            timeZone: legalEntityTimezone,
            dependencies: [
              splitAccountsIds[splitAccountsIdsKeys.SPLIT_ACCOUNT_ID],
            ],
            minDate: startOfDay(subYears(currentDate, 1)),
            maxDate: endOfDay(currentDate),
            twoFactorIsActive: false,
            dateFrom: {
              label: 'Start date',
              defaultValue: startOfDay(subDays(currentDate, 30)),
            },
            dateTo: {
              label: 'End date',
              defaultValue: endOfDay(currentDate),
            },
          }}
          fileExport={{
            allowExportAll: true,
            enabled: true,
            buttonLabel: 'Export statement',
            disabledButton: isPartyLoading || hasPartyFetchError,
            pdfColumns: splitAccountTransactionsPdfTableColumns(
              legalEntityTimezone,
            ),
            csvColumns: [
              splitAccountTransactionKeys.BOOKING_DATE,
              splitAccountTransactionKeys.TRANSACTION_REFERENCE,
              splitAccountTransactionKeys.ID,
              splitAccountTransactionKeys.COUNTERPARTY_NAME,
              splitAccountTransactionKeys.COUNTERPARTY_SORT_CODE,
              splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_NUMBER,
              splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_ALIAS,
              splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_ID,
              splitAccountTransactionKeys.DEBIT_CREDIT_CODE,
              splitAccountTransactionKeys.TYPE,
              splitAccountTransactionKeys.TRANSACTION_CURRENCY,
              splitAccountTransactionKeys.TRANSACTION_AMOUNT,
              splitAccountTransactionKeys.ACCOUNT_CLOSING_BALANCE,
            ],
            csvColumnsNamesMap: splitAccountTransactionKeysLabelsMap,
            csvDataMapper: {
              [splitAccountTransactionKeys.TYPE]: (value) =>
                splitAccountTransactionTypesLabelsMap.get(value) || value,
              [splitAccountTransactionKeys.BOOKING_DATE]: (value) =>
                formatTimeZonedDate(value, legalEntityTimezone, {
                  dateFormat: 'dd/MM/yyyy',
                }),
              [splitAccountTransactionKeys.TRANSACTION_REFERENCE]: csvValueAsStringFormatter,
              [splitAccountTransactionKeys.COUNTERPARTY_NAME]: csvValueAsStringFormatter,
            },
            filename: () =>
              `Default Split Account - ${
                splitAccountDefaultWallet[splitAccountWalletKeys.ALIAS]
              } - statement`,
            headerRenderer: (props) =>
              createTransactionsPdfStatementHeader({
                ...props,
                account: splitAccountDefaultWallet,
                party: userPartyData,
              }),
            footerRenderer: createTransactionsPdfStatementFooter,
            tableFooterRenderer: (props) =>
              createTransactionsPdfStatementTableFooter({
                ...props,
                currencyIso:
                  splitAccountDefaultWallet[
                    splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY
                  ],
              }),
          }}
        />
      </GridWidget>

      {transactionDetails && (
        <TransactionDetailsModal
          open={!!transactionDetails}
          transaction={transactionDetails}
          onClose={handleTransactionDetailsModalClose}
          isSplitAccount
        />
      )}
    </AppLayout>
  );
};

export default SplitAccountTransactionsListPage;
