export const splitAccountPagesTabs = Object.freeze({
  OVERVIEW: 1,
  TRANSACTIONS: 2,
  WALLETS: 3,
  // PAYOUTS: 4,
  PROFILES: 5,
});

export const splitAccountPagesTabsLabelsMap = new Map([
  [splitAccountPagesTabs.OVERVIEW, 'Overview'],
  [splitAccountPagesTabs.TRANSACTIONS, 'Transactions'],
  [splitAccountPagesTabs.WALLETS, 'Wallets'],
  [splitAccountPagesTabs.PAYOUTS, 'Payouts'],
  [splitAccountPagesTabs.PROFILES, 'User Profiles'],
]);

export const splitAccountPagesTabsDisabledFieldsMap = new Map([
  [splitAccountPagesTabs.OVERVIEW, false],
  [splitAccountPagesTabs.TRANSACTIONS, false],
  [splitAccountPagesTabs.WALLETS, false],
  [splitAccountPagesTabs.PAYOUTS, true],
  [splitAccountPagesTabs.PROFILES, false],
]);

export const splitAccountPagesTabsRoutingMap = new Map([
  [splitAccountPagesTabs.OVERVIEW, '/split-account'],
  [splitAccountPagesTabs.TRANSACTIONS, '/split-account/transactions'],
  [splitAccountPagesTabs.WALLETS, '/split-account/wallets'],
  [splitAccountPagesTabs.PAYOUTS, '/split-account/payouts'],
  [splitAccountPagesTabs.PROFILES, '/split-account/profiles'],
]);
