import React from 'react';
import { isEmpty, isNil } from 'rambda';
import { Route } from 'react-router-dom';
import privateRoutesRequiredPartyProps from './privateRoutesRequiredPartyProps';
import { SplitAccountCtaPage } from '../../components/pages';
import { stateSelectSplitAccountsIds } from '../../redux/stateSelectors';

const privateSplitAccountsRoutesRequiredProps = {
  reduxSelectorFn: ({ parties }) => ({
    data: privateRoutesRequiredPartyProps.reduxSelectorFn({ parties }),
    splitAccountsIds: stateSelectSplitAccountsIds({ parties }).splitAccountsIds,
  }),
  reduxRedirectorFn: ({ state, keycloak }) => {
    const maybeLogoutUnauthenticated = privateRoutesRequiredPartyProps.reduxRedirectorFn(
      {
        state,
        keycloak,
      },
    );

    if (maybeLogoutUnauthenticated) return maybeLogoutUnauthenticated;

    if (
      isNil(state.splitAccountsIds) ||
      (Array.isArray(state.splitAccountsIds) && isEmpty(state.splitAccountsIds))
    ) {
      return () => <Route render={() => <SplitAccountCtaPage />} />;
    }

    return null;
  },
};

export default privateSplitAccountsRoutesRequiredProps;
