/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  shape,
  any,
  func,
  instanceOf,
  arrayOf,
  string,
  bool,
  number,
} from 'prop-types';
import clsx from 'clsx';
import { FiberManualRecordRounded } from '@material-ui/icons';
import {
  Grid,
  List,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Box,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  items: {
    flexFlow: 'row',
  },
  item: {
    justifyContent: 'center',
  },
  separator: {
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.secondary.light}`,
    },
  },
}));

const EnumStatistics = ({
  keys,
  data,
  labelsMap,
  colorsMap,
  isLoading,
  formatter,
  invert,
  xs,
  separators,
  className,
}) => {
  const classes = useStyles();

  const label = useMemo(
    () => (key) => (
      <Typography component="div" variant="inherit" align="center">
        {labelsMap?.get(key) || key}
      </Typography>
    ),
    [labelsMap],
  );

  const value = useMemo(
    () => (key) => (
      <Typography
        component="div"
        variant="h3"
        align="center"
        style={{
          color: colorsMap.get(key),
        }}
      >
        {isLoading ? (
          <Skeleton variant="text" />
        ) : formatter ? (
          formatter(data?.[key])
        ) : (
          data?.[key]
        )}
      </Typography>
    ),
    [colorsMap, data, formatter, isLoading],
  );

  const itemRenderer = useCallback(
    (key) => {
      const labelComponent = invert ? value(key) : label(key);
      const valueComponent = invert ? label(key) : value(key);
      return (
        <Grid
          item
          xs={xs}
          container
          spacing={1}
          className={clsx(className, classes.item, {
            [classes.separator]: !!separators,
          })}
          key={key}
        >
          <Grid item xs={12}>
            {labelComponent}
          </Grid>
          <Grid item xs={12}>
            {valueComponent}
          </Grid>
        </Grid>
      );
    },
    [
      className,
      classes.item,
      classes.separator,
      invert,
      label,
      separators,
      value,
      xs,
    ],
  );

  return (
    <Grid container spacing={1} className={classes.items}>
      {keys.map(itemRenderer)}
    </Grid>
  );
};

EnumStatistics.propTypes = {
  isLoading: bool,
  invert: bool,
  separators: bool,
  formatter: func,
  xs: number,
  className: string,
  keys: arrayOf(string).isRequired,
  data: shape(),
  labelsMap: instanceOf(Map),
  colorsMap: instanceOf(Map),
};

EnumStatistics.defaultProps = {
  isLoading: false,
  invert: false,
  separators: false,
  labelsMap: null,
  colorsMap: null,
  formatter: null,
  data: null,
  className: null,
  xs: 4,
};

export default EnumStatistics;
