import React, { useCallback, useState, useMemo } from 'react';
import clsx from 'clsx';
import { isNil } from 'rambda';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ExpandMoreRounded, VisibilityRounded } from '@material-ui/icons';
import {
  Menu,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { bool } from 'prop-types';
import { AccountAliasTableCell } from '@fondy/tables';
import Link from '@fondy/link';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonVariant,
  buttonSize,
} from '@fondy/buttons';
import { useApi } from '@fondy/utils';
import { stateSelectSplitAccountsIds } from '../../../redux';
import SplitAccountWalletDetailsModal from '../SplitAccountWalletDetailsModal';
import { splitAccountsIdsKeys, splitAccountWalletKeys } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  },
  list: {
    padding: theme.spacing(0, 1, 0, 1),
  },
  listItem: {
    margin: 0,
    padding: 0,
  },
  itemText: {
    margin: 0,
    color: theme.palette.text.primary,
  },
  itemLink: {
    width: '100%',
    padding: theme.spacing(1.5, 1),
    '&:hover': {
      textDecoration: 'none',
    },
  },
  itemInset: {
    paddingLeft: theme.spacing(3),
  },
  aliasTextContainer: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  alias: {
    maxWidth: 250,
    overflow: 'hidden',
    fontWeight: 'normal',
    lineHeight: 1.3,
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  dropdownIconOpen: {
    transform: 'rotate(180deg)',
  },
  flex: {
    display: 'flex',
  },
}));

// Used only on payout wallets
const PageHeaderSplitAccountWalletSelect = ({ isLoading, ...rest }) => {
  const classes = useStyles();
  const { splitAccountWalletId } = useParams();
  const { splitAccountsIds } = useSelector(stateSelectSplitAccountsIds);
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });

  const {
    apiData: splitAccountWallets,
    isLoading: splitAccountWalletsLoading,
    isError: isSplitAccountWalletsError,
    apiError: splitAccountsWalletsError,
    retry,
  } = useApi(
    `/api/core-banking/splitaccount/${
      splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID]
    }/wallet`,
    {
      dependencies: [
        splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID],
      ],
    },
  );

  const [anrchorElement, setAnchorElement] = useState(null);
  const [showAccountDetails, setShowAccountDetails] = useState(false);

  const handleOpen = useCallback(
    (event) => setAnchorElement(event.currentTarget),
    [],
  );

  const selectedSplitAccountWallet = useMemo(
    () =>
      (splitAccountWallets || []).find(({ id }) => id === splitAccountWalletId),
    [splitAccountWalletId, splitAccountWallets],
  );

  const handleClose = useCallback(() => setAnchorElement(null), []);

  const renderElement = useCallback(
    (account) => (
      <ListItem
        key={account[splitAccountWalletKeys.ID]}
        className={classes.listItem}
        selected={
          selectedSplitAccountWallet[splitAccountWalletKeys.ID] ===
          account[splitAccountWalletKeys.ID]
        }
        onClick={handleClose}
        button
      >
        <Link
          className={classes.itemLink}
          to={`/split-account/wallets/${
            account[splitAccountWalletKeys.ID]
          }/transactions`}
        >
          <ListItemText
            classes={{
              root: classes.itemText,
            }}
          >
            <AccountAliasTableCell
              medium
              fontSize={16}
              currencyIso={
                account[splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY]
              }
              accountAlias={account[splitAccountWalletKeys.ALIAS]}
              classes={{
                alias: classes.alias,
                textContainer: classes.aliasTextContainer,
              }}
            />
          </ListItemText>
        </Link>
      </ListItem>
    ),
    [
      classes.alias,
      classes.aliasTextContainer,
      classes.itemLink,
      classes.itemText,
      classes.listItem,
      handleClose,
      selectedSplitAccountWallet,
    ],
  );

  const renderTag = useCallback(
    (account) => (
      <AccountAliasTableCell
        medium
        fontSize={16}
        key={account[splitAccountWalletKeys.ID]}
        currencyIso={account[splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY]}
        accountAlias={account[splitAccountWalletKeys.ALIAS]}
        classes={{
          alias: classes.alias,
          textContainer: classes.aliasTextContainer,
        }}
      />
    ),
    [classes.alias, classes.aliasTextContainer],
  );

  const somethingIsLoading = isLoading || splitAccountWalletsLoading;

  if (isNil(selectedSplitAccountWallet)) return null;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          {somethingIsLoading ? (
            <Skeleton width={220} height={50} />
          ) : (
            <>
              <Button
                {...rest}
                onClick={handleOpen}
                aria-controls="accounts-drop-menu"
                aria-haspopup="true"
                color={buttonColor.PRIMARY}
                variant={buttonVariant.TEXT}
                shape={buttonShape.ROUNDED}
                size={buttonSize.LARGE}
                className={clsx(classes.actionButton, rest.className)}
                data-aio-id="accountSelectDropdown"
                endIcon={
                  <ExpandMoreRounded
                    color="inherit"
                    className={clsx({
                      [classes.dropdownIconOpen]: Boolean(anrchorElement),
                    })}
                  />
                }
              >
                {renderTag(selectedSplitAccountWallet)}
              </Button>
              <Menu
                keepMounted
                id="accounts-drop-menu"
                anchorEl={anrchorElement}
                open={Boolean(anrchorElement)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                PaperProps={{
                  style: {
                    maxHeight: isMobile ? '80%' : '50%',
                  },
                }}
              >
                <List component="nav" className={classes.list}>
                  {(splitAccountWallets || []).map(renderElement)}
                </List>
              </Menu>
            </>
          )}
        </Grid>
        <Grid item className={classes.flex}>
          <Tooltip title="View selected wallet details" arrow>
            <IconButton
              color="secondary"
              aria-label="view selected wallet details"
              component="span"
              onClick={() => setShowAccountDetails(true)}
              size="small"
              data-aio-id="viewAccountDetailsLink"
              data-test-id="viewAccountDetailsLink"
              disabled={somethingIsLoading}
            >
              <VisibilityRounded />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {!isNil(selectedSplitAccountWallet) && (
        <SplitAccountWalletDetailsModal
          open={!!selectedSplitAccountWallet && showAccountDetails}
          account={selectedSplitAccountWallet}
          onClose={() => setShowAccountDetails(false)}
        />
      )}
    </>
  );
};

PageHeaderSplitAccountWalletSelect.propTypes = {
  isLoading: bool,
};

PageHeaderSplitAccountWalletSelect.defaultProps = {
  isLoading: false,
};

export default PageHeaderSplitAccountWalletSelect;
