import React, { useCallback, useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { head } from 'rambda';
import { bool, func, oneOf } from 'prop-types';
import { useParams } from 'react-router-dom';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import {
  ArrowBackIosRounded,
  CloseRounded,
  AddRounded,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useApplicationContext } from '@fondy/application-context';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonVariant,
  buttonSize,
} from '@fondy/buttons';
import {
  ControlledTextField,
  LazySelectField,
  getTextFieldPropsFromKey,
  flagIconStyles,
  SubmitButton,
} from '@fondy/forms';
import { Dialog } from '@fondy/data-display';
import {
  accountTypes,
  accountTypesLabelsMap,
  currency,
  currencyCountries,
  accountStatuses,
} from '@fondy/enums';
import {
  StandardAccountIcon,
  MasterAccountIcon,
  EuFlag,
  countriesFlags,
} from '@fondy/icons';
import {
  createAccountModalStages,
  createAccountModalHeaderSubtitles,
  createAccountModalHeaderTitles,
  createAccountModalHeaderIcons,
  createAccountModalValidationSchema,
} from './utils';
import { ProductCard, AccountAutoCompleteDropdownInput } from '../../atoms';
import {
  addAccountAction,
  stateSelectAccountsData,
  getAccountsAction,
} from '../../../redux';
import {
  accountKeys,
  accountKeysLabelsMap,
  productKeys,
  walletKeys,
  walletKeysLabelsMap,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.md - 200,
  },
  centered: {
    textAlign: 'center',
  },
  flagStyles: flagIconStyles,
  noFlag: {
    paddingLeft:
      theme.spacing(1) +
      flagIconStyles['& img'].width +
      flagIconStyles['& img'].marginRight,
  },
  productSelector: {
    '& > div > div': {
      display: 'flex',
    },
  },
  hidden: {
    pointerEvents: 'none',
    display: 'none',
  },
  gutterTop: {
    marginTop: theme.spacing(2),
  },
}));

const getFieldProps = getTextFieldPropsFromKey({
  labelsMap: accountKeysLabelsMap,
});

const createAccountModalStateToAccountType = new Map([
  [createAccountModalStages.CREATE_MASTER_ACCOUNT, accountTypes.MASTER],
  [createAccountModalStages.CREATE_STANDARD_ACCOUNT, accountTypes.STANDARD],
  [createAccountModalStages.CREATE_WALLET_ACCOUNT, accountTypes.WALLET],
]);

const accountTypeToModalStateMap = new Map([
  [accountTypes.MASTER, createAccountModalStages.CREATE_MASTER_ACCOUNT],
  [accountTypes.STANDARD, createAccountModalStages.CREATE_STANDARD_ACCOUNT],
  [accountTypes.WALLET, createAccountModalStages.CREATE_WALLET_ACCOUNT],
]);

const createAccountModalStagesModalWidths = new Map([
  [createAccountModalStages.ACCOUNT_TYPE_SELECT, 1000],
  [createAccountModalStages.CREATE_MASTER_ACCOUNT, 400],
  [createAccountModalStages.CREATE_STANDARD_ACCOUNT, 400],
  [createAccountModalStages.CREATE_WALLET_ACCOUNT, 500],
  [createAccountModalStages.ERROR, 500],
  [createAccountModalStages.REQUEST_SUBMITTED, 500],
]);

const CreateAccountModal = ({ open, initialStage, onClose, disabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const { fetch, keycloak } = useApplicationContext();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
  const { accountId } = useParams();
  const { selectedAccount, allAccounts } = useSelector(
    stateSelectAccountsData(accountId),
  );

  const forceRefreshAccounts = useCallback(
    () => dispatch(getAccountsAction(fetch, true)),
    [dispatch, fetch],
  );

  const selectedAccountIsMaster = useMemo(
    () => selectedAccount?.[accountKeys.TYPE] === accountTypes.MASTER,
    [selectedAccount],
  );

  const [modalStage, setModalStage] = useState(() =>
    selectedAccountIsMaster
      ? createAccountModalStages.CREATE_WALLET_ACCOUNT
      : initialStage,
  );
  const [modalError, setModalError] = useState(null);
  const [isProductsLoading, setIsProductsLoading] = useState(
    ![
      createAccountModalStages.ERROR,
      createAccountModalStages.CREATE_WALLET_ACCOUNT,
      createAccountModalStages.REQUEST_SUBMITTED,
      createAccountModalStages.ACCOUNT_TYPE_SELECT,
    ].includes(initialStage),
  );

  const addAccount = useCallback(
    (newAccountData, throwErrors = true) =>
      dispatch(
        addAccountAction({ fetch, keycloak, newAccountData, throwErrors }),
      ),
    [dispatch, fetch, keycloak],
  );

  const newAccountType = createAccountModalStateToAccountType.get(modalStage);

  const productsMapper = useCallback(
    (products) =>
      products.reduce(
        // eslint-disable-next-line no-unused-vars
        (allProducts, product) => {
          const currencyId = currency[product[productKeys.CURRENCY]];
          const currencyDefaultCountryId = currencyCountries.get(currencyId);
          const currencyFlag =
            currencyId === currency.EUR
              ? EuFlag
              : countriesFlags.get(currencyDefaultCountryId);
          const currencyLabel = product[productKeys.CURRENCY];
          return [
            ...allProducts,
            ...(newAccountType === product[accountKeys.TYPE]
              ? [
                  {
                    label: product[productKeys.NAME],
                    key: product.productId,
                    value: product.productId,
                    className: clsx(classes.flagStyles, {
                      [classes.noFlag]: !currencyFlag,
                    }),
                    render: () => (
                      <span>
                        {currencyFlag ? (
                          <img src={currencyFlag} alt={currencyLabel} />
                        ) : null}
                        <span>{currencyLabel}</span>
                      </span>
                    ),
                  },
                ]
              : []),
          ];
        },
        [],
      ),
    [classes.flagStyles, classes.noFlag, newAccountType],
  );

  const formState = useFormik({
    initialValues: {
      [accountKeys.TYPE]: newAccountType,
      [walletKeys.PAYOUT_ACCOUNT_ID]:
        selectedAccountIsMaster &&
        selectedAccount[accountKeys.STATUS] === accountStatuses.ACTIVE
          ? accountId
          : null,
    },
    onSubmit: async (newAccountData, { setSubmitting, setFieldError }) => {
      await setSubmitting(true);
      await setModalError(null);

      try {
        await addAccount(newAccountData);
        await forceRefreshAccounts();
        await setSubmitting(false);
        await setModalStage(createAccountModalStages.REQUEST_SUBMITTED);
      } catch (error) {
        if (error.affectedField) {
          await setFieldError(
            error.affectedField,
            error.message ||
              error.errorCode ||
              'Something went wrong, please contact Fondy Support',
          );
        } else {
          await setModalError(error);
        }
        await setSubmitting(false);
      }
    },
    validationSchema: createAccountModalValidationSchema(newAccountType),
  });

  const ModalIcon = useMemo(
    () => createAccountModalHeaderIcons.get(modalStage),
    [modalStage],
  );

  const {
    handleSubmit,
    handleReset,
    isSubmitting,
    setFieldValue,
    setFieldError,
    resetForm,
  } = formState;

  const resetModalState = useCallback(() => {
    setModalStage(initialStage);
    setModalError(null);
    resetForm();
  }, [initialStage, resetForm]);

  const handleClose = useCallback(
    (event) => {
      if (onClose) onClose(event);
      resetModalState();
    },
    [onClose, resetModalState],
  );

  useEffect(() => {
    if (newAccountType) {
      setFieldValue(accountKeys.TYPE, newAccountType);
    }
  }, [newAccountType, setFieldValue]);

  useEffect(() => {
    if (selectedAccountIsMaster) {
      setModalStage(createAccountModalStages.CREATE_WALLET_ACCOUNT);
      setFieldValue(walletKeys.PAYOUT_ACCOUNT_ID, accountId);
    }
  }, [accountId, selectedAccountIsMaster, setFieldValue]);

  const productOnChangeHandler = useCallback(
    ({ target: { value: selectedProductId } = {} }, products) => {
      const selectedProduct = products.find(
        (i) => i.productId === selectedProductId,
      );
      if (!selectedProduct?.[productKeys.CODE]) return undefined;
      setFieldValue(
        accountKeys.CURRENCY,
        selectedProduct[productKeys.CURRENCY],
      );
      return setFieldValue(
        accountKeys.PRODUCT_CODE,
        selectedProduct[productKeys.CODE],
      );
    },
    [setFieldValue],
  );

  const productOnSuccessHandler = useCallback(
    () => setIsProductsLoading(false),
    [],
  );

  const setSelectedAccountForm = useCallback((newModalStage) => {
    setModalStage(newModalStage);
  }, []);

  const onAvailableProductsError = useCallback(
    (error) => {
      if (error?.affectedField) {
        setFieldError(error.affectedField, error.message);
      } else {
        setModalError(error);
        setModalStage(createAccountModalStages.ERROR);
      }
    },
    [setFieldError],
  );

  const newAccountData = useMemo(
    () =>
      allAccounts.find(
        (i) =>
          i[accountKeys.ALIAS] === formState.values[accountKeys.ALIAS] ||
          (!!formState.values.ownerName &&
            i[accountKeys.ALIAS] === formState.values.ownerName),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allAccounts, formState.values[accountKeys.ALIAS]],
  );

  const handleAnotherAccountCreation = useCallback(async () => {
    await resetForm();
    await setModalStage(
      accountTypeToModalStateMap.get(newAccountData?.[accountKeys.TYPE]),
    );
  }, [newAccountData, resetForm]);

  return (
    <Dialog
      fullScreen
      centeredFooter
      disableHeaderPadding
      disableBackdropClick
      fullWidth
      dense={isMobile}
      open={open}
      onClose={handleClose}
      data-aio-id="createAccountModal"
      contentProps={{
        style: {
          maxWidth: createAccountModalStagesModalWidths.get(modalStage),
          margin: 'auto',
        },
      }}
      footerActions={
        [
          createAccountModalStages.ACCOUNT_TYPE_SELECT,
          createAccountModalStages.CREATE_MASTER_ACCOUNT,
          createAccountModalStages.CREATE_STANDARD_ACCOUNT,
          createAccountModalStages.CREATE_WALLET_ACCOUNT,
        ].includes(modalStage)
          ? [
              {
                label: (() => {
                  switch (modalStage) {
                    case createAccountModalStages.ACCOUNT_TYPE_SELECT:
                      return 'Cancel';
                    case createAccountModalStages.CREATE_WALLET_ACCOUNT:
                      return 'Back to Master account';
                    default:
                      return 'Back to choosing account type';
                  }
                })(),
                color: 'primary',
                variant: buttonVariant.OUTLINED,
                shape: buttonShape.ROUNDED,
                size: buttonSize.MEDIUM,
                link: true,
                onClick:
                  modalStage === initialStage ? handleClose : resetModalState,
                startIcon: (() => {
                  switch (modalStage) {
                    case createAccountModalStages.ACCOUNT_TYPE_SELECT:
                      return <CloseRounded color="inherit" fontSize="small" />;
                    default:
                      return (
                        <ArrowBackIosRounded
                          color="inherit"
                          fontSize="inherit"
                        />
                      );
                  }
                })(),
                disabled: isSubmitting,
                'data-aio-id': 'backButton',
              },
            ]
          : null
      }
    >
      <Grid className={classes.root} spacing={3} container>
        {ModalIcon && (
          <Grid item xs={12} className={classes.centered}>
            <ModalIcon />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            {createAccountModalHeaderTitles.get(modalStage)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center" gutterBottom>
            {!modalError &&
            !modalError?.message &&
            !modalError?.description &&
            !!newAccountData &&
            newAccountData[accountKeys.TYPE] === accountTypes.WALLET
              ? 'Your wallet was created and is ready to be used'
              : createAccountModalHeaderSubtitles.get(modalStage) ||
                modalError?.message ||
                modalError?.description ||
                ''}
          </Typography>
        </Grid>

        {modalStage === createAccountModalStages.REQUEST_SUBMITTED && (
          <>
            <Grid item xs={12} className={classes.centered}>
              <Button
                onClick={handleClose}
                to={`/accounts/transactions/${
                  newAccountData?.[accountKeys.ID]
                }`}
                color={buttonColor.PRIMARY}
                variant={buttonVariant.CONTAINED}
                shape={buttonShape.ROUNDED}
                size={buttonSize.MEDIUM}
                data-aio-id="createAccountModalGoToWalletButton"
              >
                {`Go to ${accountTypesLabelsMap.get(
                  newAccountData?.[accountKeys.TYPE],
                )}`}
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.centered}>
              <Button
                onClick={handleAnotherAccountCreation}
                color={buttonColor.PRIMARY}
                variant={buttonVariant.OUTLINED}
                shape={buttonShape.ROUNDED}
                size={buttonSize.MEDIUM}
                data-aio-id="createAccountModalCreateNewWalletButton"
                startIcon={<AddRounded color="inherit" fontSize="small" />}
                link
              >
                {`Create new ${accountTypesLabelsMap.get(
                  newAccountData?.[accountKeys.TYPE],
                )}`}
              </Button>
            </Grid>
          </>
        )}

        {!isProductsLoading &&
          modalStage === createAccountModalStages.ACCOUNT_TYPE_SELECT && (
            <>
              <Grid item xs={12} sm={6}>
                <ProductCard
                  title={accountTypesLabelsMap.get(accountTypes.STANDARD)}
                  icon={StandardAccountIcon}
                  actionText={`Create ${accountTypesLabelsMap.get(
                    accountTypes.STANDARD,
                  )}`}
                  onActionClick={() =>
                    setSelectedAccountForm(
                      createAccountModalStages.CREATE_STANDARD_ACCOUNT,
                    )
                  }
                  features={[
                    'Receive your own business IBAN',
                    'Create up to three Standard accounts with Fondy Flow',
                    'Send and receive funds instantly via the Faster Payments network',
                    'Great for managing revenue and expenses, and paying suppliers',
                    'Manage settlements from your online business',
                    'Perfect for small to medium-sized enterprises (SMEs).',
                    '',
                    '',
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ProductCard
                  title={accountTypesLabelsMap.get(accountTypes.MASTER)}
                  icon={MasterAccountIcon}
                  actionText={`Create ${accountTypesLabelsMap.get(
                    accountTypes.MASTER,
                  )}`}
                  onActionClick={() =>
                    setSelectedAccountForm(
                      createAccountModalStages.CREATE_MASTER_ACCOUNT,
                    )
                  }
                  features={[
                    'Receive your own business IBAN',
                    'Create as many free Wallets (sub-accounts) as you need',
                    'Use Wallets to separate funds by business location, department, model, etc',
                    'Get funds directed to specific Wallets using unique business references',
                    'Simplify reconciliation between different partners, suppliers or clients',
                    'Great for getting an overview of your Master account balance, i.e the sum of all Wallets under the same IBAN.',
                    'Perfect for large-sized corporations.',
                  ]}
                />
              </Grid>
            </>
          )}

        {[
          createAccountModalStages.CREATE_STANDARD_ACCOUNT,
          createAccountModalStages.CREATE_MASTER_ACCOUNT,
          createAccountModalStages.CREATE_WALLET_ACCOUNT,
        ].includes(modalStage) && (
          <Grid item xs={12}>
            <form onSubmit={handleSubmit} onReset={handleReset} noValidate>
              <Grid item container spacing={1} xs={12}>
                {[
                  createAccountModalStages.CREATE_STANDARD_ACCOUNT,
                  createAccountModalStages.CREATE_MASTER_ACCOUNT,
                ].includes(modalStage) && (
                  <>
                    <Grid item xs={12}>
                      <ControlledTextField
                        {...getFieldProps(accountKeys.ALIAS)}
                        label={accountKeysLabelsMap.get(accountKeys.NAME)}
                        formState={formState}
                        disabled={isSubmitting || disabled || isProductsLoading}
                        fullWidth
                        autoFocus
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LazySelectField
                        lazy={false}
                        noOptionsTextLabel="No Currencies found in the system"
                        placeholder="Choose Currency"
                        url={`/api/core-banking/available-products?${accountKeys.TYPE}=${newAccountType}`}
                        optionsMapperFn={productsMapper}
                        onSuccess={productOnSuccessHandler}
                        onChange={productOnChangeHandler}
                        onError={onAvailableProductsError}
                        formState={formState}
                        {...getFieldProps('productId')}
                        label="Currency"
                        disabled={isSubmitting || disabled || isProductsLoading}
                        className={clsx(
                          classes.flagStyles,
                          classes.productSelector,
                        )}
                        required
                      />
                    </Grid>
                    <ControlledTextField
                      {...getFieldProps(accountKeys.CURRENCY)}
                      formState={formState}
                      className={classes.hidden}
                      disabled
                      required
                    />
                    <ControlledTextField
                      {...getFieldProps(accountKeys.PRODUCT_CODE)}
                      formState={formState}
                      className={classes.hidden}
                      disabled
                      required
                    />
                  </>
                )}

                {modalStage ===
                  createAccountModalStages.CREATE_WALLET_ACCOUNT && (
                  <>
                    <Grid item xs={12}>
                      <AccountAutoCompleteDropdownInput
                        disableOptionsListSubheaders
                        {...getFieldProps(walletKeys.PAYOUT_ACCOUNT_ID)}
                        label={walletKeysLabelsMap.get(
                          walletKeys.PAYOUT_ACCOUNT_ID,
                        )}
                        formState={formState}
                        noOptionsText="You don't have any active Master accounts"
                        getOptionDisabledFn={(option) =>
                          option[accountKeys.TYPE] !== accountTypes.MASTER ||
                          option[accountKeys.STATUS] !== accountStatuses.ACTIVE
                        }
                        filterMasterAccountsFn={(option) =>
                          option[accountKeys.TYPE] === accountTypes.MASTER
                        }
                        defaultValue={accountId}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ControlledTextField
                        {...getFieldProps(walletKeys.OWNER_ID)}
                        label={walletKeysLabelsMap.get(walletKeys.OWNER_ID)}
                        formState={formState}
                        disabled={isSubmitting || disabled}
                        fullWidth
                        autoFocus
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ControlledTextField
                        {...getFieldProps('ownerName')}
                        label={walletKeysLabelsMap.get(walletKeys.OWNER_NAME)}
                        formState={formState}
                        disabled={isSubmitting || disabled}
                        fullWidth
                        required
                      />
                    </Grid>
                  </>
                )}

                <ControlledTextField
                  {...getFieldProps(accountKeys.TYPE)}
                  formState={formState}
                  className={classes.hidden}
                  disabled
                  required
                />

                <Grid
                  item
                  xs={12}
                  className={clsx(classes.centered, classes.gutterTop)}
                >
                  <SubmitButton
                    disabled={isSubmitting || disabled || isProductsLoading}
                    formState={formState}
                  >
                    {`Create ${accountTypesLabelsMap.get(newAccountType)}`}
                  </SubmitButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        )}

        {!isProductsLoading && modalStage === createAccountModalStages.ERROR && (
          <Grid item xs={12} className={classes.centered}>
            <Button
              onClick={handleClose}
              color={buttonColor.PRIMARY}
              variant={buttonVariant.CONTAINED}
              shape={buttonShape.ROUNDED}
              size={buttonSize.MEDIUM}
              data-aio-id="createAccountModalContinueButton"
            >
              Continue
            </Button>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

CreateAccountModal.propTypes = {
  open: bool,
  disabled: bool,
  initialStage: oneOf(Object.values(createAccountModalStages)),
  onClose: func,
};

CreateAccountModal.defaultProps = {
  open: false,
  disabled: false,
  initialStage: createAccountModalStages.ACCOUNT_TYPE_SELECT,
  onClose: null,
};

export default CreateAccountModal;
