import createAccountModalStages from './createAccountModalStages';

const createAccountModalHeaderTitles = new Map([
  [createAccountModalStages.ACCOUNT_TYPE_SELECT, 'Create an account'],
  [
    createAccountModalStages.CREATE_STANDARD_ACCOUNT,
    'Create a Standard account',
  ],
  [createAccountModalStages.CREATE_MASTER_ACCOUNT, 'Create a Master account'],
  [createAccountModalStages.CREATE_WALLET_ACCOUNT, 'Create a Wallet account'],
  [createAccountModalStages.ERROR, 'Unable to create account'],
  [createAccountModalStages.REQUEST_SUBMITTED, 'Request submitted'],
]);

export default createAccountModalHeaderTitles;
