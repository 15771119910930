import { generateApiErrorFromResponse } from '@fondy/utils';

const fetchSplitAccountsUUID = async ({ customerId, fetch } = {}) => {
  const response = await fetch(
    `/api/core-banking/splitaccount/${customerId}/splitaccountdetails`,
    {
      cache: 'no-cache',
      credentials: 'same-origin',
      mode: 'same-origin',
      headers: {
        'Content-type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw await generateApiErrorFromResponse(response);
  }

  return response.json();
};

export default fetchSplitAccountsUUID;
