import React, { useCallback } from 'react';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { AccountAliasTableCell } from '@fondy/tables';
import { accountKeys } from '../../../utils';

const AccountByIdTableCell = ({ id, searchQueryHighlight, ...restProps }) => {
  const selectedAccountStateSelector = useCallback(
    (state) => ({
      accounts: state.accounts,
      selectedAccount: state.accounts.data.find(
        (account) => account[accountKeys.ID] === id,
      ),
      accountsLoading: state.accounts.isFetching || state.accounts.isPosting,
    }),
    [id],
  );

  const { selectedAccount, accountsLoading, accounts } = useSelector(
    selectedAccountStateSelector,
  );

  if (accountsLoading || !selectedAccount) {
    return <Skeleton height="100%" />;
  }

  return (
    <AccountAliasTableCell
      {...restProps}
      small
      searchQueryHighlight={searchQueryHighlight}
      currencyIso={selectedAccount[accountKeys.CURRENCY]}
      accountAlias={selectedAccount[accountKeys.ALIAS]}
      accountType={selectedAccount[accountKeys.TYPE]}
      isLoading={accountsLoading}
    />
  );
};

AccountByIdTableCell.propTypes = {
  id: string.isRequired,
  searchQueryHighlight: string,
};

AccountByIdTableCell.defaultProps = {
  searchQueryHighlight: null,
};

export default AccountByIdTableCell;
