import { countries, countriesNames } from '@fondy/enums';
import { formatSortCodeValue, formatTimeZonedDate } from '@fondy/utils';
import { defaultPageMargins } from '@fondy/pdf';
import { conformToMask, ibanInputMaskPattern } from '@fondy/forms';
import pdfHeaderCreator from '../pdfHeaderCreator';
import {
  accountKeys,
  accountKeysLabelsMap,
  partyDetailsKeys,
  partyRegistrationKeys,
} from '../../../../utils';

const createTransactionsPdfStatementHeader = async ({
  pdfDocument,
  // eslint-disable-next-line no-unused-vars
  theme,
  party,
  account,
  dateRange,
  margin = defaultPageMargins,
  dateFormat = 'dd/MM/yyyy',
} = {}) => {
  const pageWidth = await pdfDocument.getPageWidth(0);
  const { fontName } = await pdfDocument.getFont();
  const hasDateRange = dateRange.dateFrom && dateRange.dateTo;
  const halfPage = (pageWidth - (margin.left + margin.right)) / 2;
  const homeAddress = party[partyRegistrationKeys.HOME_ADDRESS];

  await pdfHeaderCreator(pdfDocument, { theme });

  // party details on left

  await pdfDocument.text(
    [
      party[partyDetailsKeys.PARTY_NAME],
      homeAddress[partyRegistrationKeys.ADDRESS_LINE_1] ?? '',
      ...(party[
        partyRegistrationKeys.HOME_ADDRESS[partyRegistrationKeys.ADDRESS_LINE_2]
      ]
        ? [homeAddress[partyRegistrationKeys.ADDRESS_LINE_2]]
        : []),
      `${homeAddress[partyRegistrationKeys.REGION] ?? ''}, ${
        homeAddress[partyRegistrationKeys.TOWN] ?? ''
      } ${homeAddress[partyRegistrationKeys.POSTAL_CODE] ?? ''}`,
      countriesNames.get(
        countries[homeAddress[partyRegistrationKeys.COUNTRY]],
      ) ?? '',
    ],
    margin.left,
    120,
    { lineHeightFactor: 1.5, maxWidth: halfPage },
  );

  // account details on right
  await pdfDocument.setFont(fontName, 'bold');
  await pdfDocument.text(
    [
      `${accountKeysLabelsMap.get(accountKeys.NAME)}:`,
      `${accountKeysLabelsMap.get(accountKeys.IBAN)}:`,
      `${accountKeysLabelsMap.get(accountKeys.SORT_CODE)}:`,
      `${accountKeysLabelsMap.get(accountKeys.NUMBER)}:`,
    ],
    pageWidth - margin.right - 180,
    120,
    { lineHeightFactor: 1.5, maxWidth: halfPage, align: 'right' },
  );

  await pdfDocument.setFont(fontName, 'normal');
  await pdfDocument.text(
    [
      account[accountKeys.ALIAS],
      conformToMask(account[accountKeys.IBAN], ibanInputMaskPattern, {
        guide: false,
      }).conformedValue || 'n/a',
      formatSortCodeValue(account[accountKeys.SORT_CODE]) || 'n/a',
      account[accountKeys.NUMBER] || 'n/a',
    ],
    pageWidth - margin.right - 170,
    120,
    { lineHeightFactor: 1.5, maxWidth: halfPage, align: 'left' },
  );

  await pdfDocument.setFontSize(15);
  await pdfDocument.text(
    `${account[accountKeys.ALIAS]} statement`,
    margin.left,
    220,
  );

  await pdfDocument.setFontSize(9);
  if (hasDateRange) {
    await pdfDocument.text(
      `${formatTimeZonedDate(
        dateRange.dateFrom.toISOString(),
        party[partyDetailsKeys.TIMEZONE],
        {
          dateFormat,
        },
      )} - ${formatTimeZonedDate(
        dateRange.dateTo.toISOString(),
        party[partyDetailsKeys.TIMEZONE],
        {
          dateFormat,
        },
      )}`,
      margin.left,
      235,
    );
  }

  return hasDateRange ? 260 : 240;
};

export default createTransactionsPdfStatementHeader;
