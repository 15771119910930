/* eslint-disable react/prop-types */
import React from 'react';
import {
  StatusTableCell,
  AmountTableCell,
  AccountAliasTableCell,
} from '@fondy/tables';
import { formatSortCodeValue } from '@fondy/utils';
import {
  splitAccountWalletKeys,
  splitAccountWalletKeysLabelsMap,
} from '../../../utils';

export const splitAccountWalletsTableColumns = [
  {
    id: splitAccountWalletKeys.ALIAS,
    label: 'Wallet Name',
    render: ({ row }) => (
      <AccountAliasTableCell
        small
        noWrap={false}
        accountAlias={row[splitAccountWalletKeys.ALIAS]}
        currencyIso={row[splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY]}
      />
    ),
  },
  {
    id: splitAccountWalletKeys.STATUS,
    numeric: false,
    label: splitAccountWalletKeysLabelsMap.get(splitAccountWalletKeys.STATUS),
    render: ({ value }) => <StatusTableCell status={value} />,
    style: {
      textOverflow: 'unset',
      width: 120,
    },
  },
  {
    id: splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY,
    numeric: false,
    label: 'Wallet Currency',
    style: { width: 120 },
  },
  {
    id: splitAccountWalletKeys.PAYEE_CURRENCY,
    numeric: false,
    label: 'Payout Currency',
    style: { width: 130 },
  },
  {
    id: splitAccountWalletKeys.CURRENT_AMOUNT,
    numeric: true,
    disablePadding: false,
    label: splitAccountWalletKeysLabelsMap.get(
      splitAccountWalletKeys.CURRENT_AMOUNT,
    ),
    align: 'right',
    style: { width: 130 },
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY]}
        justifyContent="flex-end"
        data-aio-id="walletAmountCell"
        variant="body2"
        valueWrapperFn={(formattedValue) => (
          <span style={{ fontWeight: 600 }}>{formattedValue}</span>
        )}
      />
    ),
  },
];

export const splitAccountOverviewWalletsTableColumns = [
  {
    id: splitAccountWalletKeys.ALIAS,
    style: { width: '50%', paddingLeft: 0 },
    label: 'Wallet',
    render: ({ row }) => (
      <AccountAliasTableCell
        small
        noWrap={false}
        accountAlias={row[splitAccountWalletKeys.ALIAS]}
        subtitle={row[splitAccountWalletKeys.PAYEE_NAME]}
        currencyIso={row[splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY]}
      />
    ),
  },
  {
    id: splitAccountWalletKeys.CURRENT_AMOUNT,
    style: { width: '50%', paddingRight: 0 },
    numeric: true,
    label: splitAccountWalletKeysLabelsMap.get(
      splitAccountWalletKeys.CURRENT_AMOUNT,
    ),
    align: 'right',
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY]}
        justifyContent="flex-end"
        data-aio-id="walletAmountCell"
        variant="body2"
        valueWrapperFn={(formattedValue) => (
          <span style={{ fontWeight: 600 }}>{formattedValue}</span>
        )}
      />
    ),
  },
];
