export const partyDetailsKeys = Object.freeze({
  ID: 'id',
  TYPE: 'partyType',
  STATUS: 'partyStatus',
  COMPLIANCE_STATUS: 'complianceStatus',
  NATIONALITY: 'nationality',
  COUNTRY_OF_BIRTH: 'countryOfBirth',
  COUNTRY_OF_RESIDENCE: 'countryOfResidence',
  IDENTIFICATION_DOCUMENT_EXPIRY: 'identificationDocExpiry',
  IDENTIFICATION_DOCUMENT_TYPE: 'identificationDocumentType',
  IDENTIFICATION_DOCUMENT_NUMBER: 'identificationNumber',
  LEGAL_ENTITY_ID: 'legalEntityId',
  PARTY_NAME: 'partyName',
  TAX_LIABILITY: 'taxLiability',
  TAX_NUMBER: 'taxNumber',
  VAT_NUMBER: 'vatNumber',
  TIMEZONE: 'timezone',
  REGISTRATION_NUMBER: 'registrationNumber',
  REGISTRATION_DATE: 'registrationDate',
  COUNTRY_OF_REGISTRATION: 'countryOfRegistration',
});

export const partyDetailsKeysLabelsMap = new Map([
  [partyDetailsKeys.ID, 'Id'],
  [partyDetailsKeys.TYPE, 'Party type'],
  [partyDetailsKeys.STATUS, 'Status'],
  [partyDetailsKeys.COMPLIANCE_STATUS, 'Compliance status'],
  [partyDetailsKeys.NATIONALITY, 'Nationality'],
  [partyDetailsKeys.COUNTRY_OF_BIRTH, 'Country of birth'],
  [partyDetailsKeys.COUNTRY_OF_RESIDENCE, 'Country of residence'],
  [
    partyDetailsKeys.IDENTIFICATION_DOCUMENT_EXPIRY,
    'Identification doc expiry date',
  ],
  [
    partyDetailsKeys.IDENTIFICATION_DOCUMENT_TYPE,
    'Identification document type',
  ],
  [partyDetailsKeys.IDENTIFICATION_DOCUMENT_NUMBER, 'Identification number'],
  [partyDetailsKeys.LEGAL_ENTITY_ID, 'Legal entity Id'],
  [partyDetailsKeys.PARTY_NAME, 'Full name'],
  [partyDetailsKeys.TAX_LIABILITY, 'Tax liability (FATCA)'],
  [partyDetailsKeys.TAX_NUMBER, 'Tax number'],
  [partyDetailsKeys.TIMEZONE, 'Time Zone'],
  [partyDetailsKeys.REGISTRATION_NUMBER, 'Registration number'],
  [partyDetailsKeys.REGISTRATION_DATE, 'Registration date'],
  [partyDetailsKeys.COUNTRY_OF_REGISTRATION, 'Country of registration'],
  [partyDetailsKeys.VAT_NUMBER, 'VAT Number'],
]);
