import React, { useCallback } from 'react';
import { number } from 'prop-types';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { GridWidget } from '@fondy/data-display';
import { useApplicationContext } from '@fondy/application-context';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonSize,
  buttonVariant,
} from '@fondy/buttons';
import {
  currency,
  orderTypes,
  currencyIsoCodes,
  currencyDecimalSeparator,
  accountTypes,
  accountStatuses,
} from '@fondy/enums';
import { formatCurrencyValue } from '@fondy/utils';
import { accountKeys } from '../../../../utils/enums';
import AccountsTable from '../../AccountsTable';
import CreateAccountButton from '../../CreateAccountButton';
import { getAccountsSummaryTableColumns } from '../../../atoms';
import { stateSelectAccountsData, getAccountsAction } from '../../../../redux';

const useStyles = makeStyles((theme) => ({
  rootWidget: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  tableWrapper: {
    minHeight: 70,
    width: 'inherit',
    [theme.breakpoints.up('md')]: {
      minHeight: 190,
    },
  },
  accountsSummaryWrapper: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    margin: theme.spacing(0, 0, 3, 0),
  },
  value: {
    display: 'block',
    width: '100%',
    textAlign: 'right',
  },
  progressBar: {
    minHeight: 8,
    borderRadius: 100,
    margin: theme.spacing(0, 0, 3, 0),
  },
  pendingCounter: {
    display: 'block',
    textAlign: 'right',
    fontSize: 12,
  },
  gridSummaryContent: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  accountsTableHeader: {
    [theme.breakpoints.down('sm')]: {
      borderTop: theme.border.thin,
    },
  },
  accountsTableTitle: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
    },
  },
  accountsTableCell: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  skeletonCell: {
    padding: theme.spacing(1, 0),
    '&:not(:last-child)': {
      paddingRight: theme.spacing(3),
    },
  },
}));

const AccountsSummaryWidget = ({ currencyId }) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const { fetch } = useApplicationContext();
  const { totalBalance } = useSelector(stateSelectAccountsData(accountId));
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });

  const formattedTotalBalance = formatCurrencyValue(totalBalance, {
    currencyIso: currencyIsoCodes.get(currencyId),
  });
  const decimalSeparator = currencyDecimalSeparator.get(currencyId);
  const splittedTotalBalance = formattedTotalBalance.split(decimalSeparator);

  const fetchAccounts = useCallback(
    (force = true) => dispatch(getAccountsAction(fetch, force)),
    [dispatch, fetch],
  );

  const accountsSelectorFn = useCallback(
    (state) => ({
      ...state.accounts,
      data: state.accounts.data.filter(
        (account) =>
          account[accountKeys.STATUS] === accountStatuses.ACTIVE &&
          account[accountKeys.TYPE] !== accountTypes.WALLET,
      ),
    }),
    [],
  );

  const spacing = 0;

  return (
    <GridWidget
      spacing={spacing}
      classes={{ content: classes.rootWidget }}
      noPadding
    >
      <GridWidget
        title="All accounts summary"
        transparent
        grid={{ sm: 12, md: 4, lg: 5, xl: 5 }}
        footer={<CreateAccountButton />}
        footerProps={{
          justifyContent: 'flex-start',
        }}
        classes={{
          content: classes.gridSummaryContent,
        }}
        spacing={spacing}
      >
        <Grid item className={classes.accountsSummaryWrapper}>
          <Typography variant="button" color="secondary">
            All accounts balance
          </Typography>
          <Typography variant="h2" data-aio-id="allAccountsBalance">
            {splittedTotalBalance[0]}
            <Typography
              display="inline"
              variant="h3"
              variantMapping={{ h3: 'span' }}
            >{`${decimalSeparator}${splittedTotalBalance[1]}`}</Typography>
          </Typography>
        </Grid>
      </GridWidget>

      <GridWidget
        title="All accounts overview"
        transparent
        spacing={spacing}
        grid={{ sm: 12, md: 8, lg: 7, xl: 7 }}
        classes={{
          header: classes.accountsTableHeader,
          title: classes.accountsTableTitle,
        }}
        footer={
          <Button
            fullWidth={isMobile}
            to="/accounts"
            color={buttonColor.SECONDARY}
            variant={buttonVariant.CONTAINED}
            shape={buttonShape.ROUNDED}
            size={buttonSize.MEDIUM}
          >
            View all accounts
          </Button>
        }
      >
        <Grid item className={classes.tableWrapper}>
          <AccountsTable
            renderRowsOnly
            max={3}
            disableRowHover
            columns={getAccountsSummaryTableColumns(isMobile)}
            onRetryFetchClick={fetchAccounts}
            classes={{
              cell: classes.accountsTableCell,
              skeletonCell: classes.skeletonCell,
            }}
            skeletonProps={{
              height: 30,
            }}
            renderCollapsible={false}
            defaultOrderBy={accountKeys.CURRENT_BALANCE}
            sortConfigOverride={{
              key: accountKeys.CURRENT_BALANCE,
              order: orderTypes.DESCENDING,
            }}
            accountsSelectorFn={accountsSelectorFn}
          />
        </Grid>
      </GridWidget>
    </GridWidget>
  );
};

AccountsSummaryWidget.propTypes = {
  currencyId: number,
};

AccountsSummaryWidget.defaultProps = {
  currencyId: currency.GBP,
};

export default AccountsSummaryWidget;
