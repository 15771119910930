import { accountsActionTypes } from '../constants';

const accountsReducer = (state = {}, action) => {
  switch (action.type) {
    case accountsActionTypes.GET_ACCOUNTS_IS_FETCHING:
      return { ...state, getError: null, isFetching: true };
    case accountsActionTypes.ADD_ACCOUNT_IS_FETCHING:
      return { ...state, postError: null, isPosting: true };
    case accountsActionTypes.GET_ACCOUNTS:
      return {
        ...state,
        getError: null,
        isFetching: false,
        data: action.accounts,
        lastUpdate: new Date(),
      };
    case accountsActionTypes.ADD_ACCOUNT:
      return {
        ...state,
        postError: null,
        isFetching: false,
        isPosting: false,
        data: [...state.data, action.account],
        lastUpdate: new Date(),
      };
    case accountsActionTypes.GET_ACCOUNTS_ERROR:
      return {
        ...state,
        getError: action.error,
        isFetching: false,
        lastUpdate: new Date(),
      };
    case accountsActionTypes.ADD_ACCOUNT_ERROR:
      return {
        ...state,
        postError: action.error,
        isPosting: false,
        lastUpdate: new Date(),
      };
    default:
      return state;
  }
};

export default accountsReducer;
