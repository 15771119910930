import { head } from 'rambda';
import { httpMethods, accountTypes } from '@fondy/enums';
import { generateApiErrorFromResponse, fetchErrorHandler } from '@fondy/utils';
import { walletKeys } from '../../utils';
import { accountsActionTypes } from '../constants';
import { accountKeys } from '../../utils/enums/accountKeys';

export const getAccountsAction = (fetch, forceFetch = false) => async (
  dispatch,
  getState,
) => {
  const { accounts } = await getState();

  if ((accounts.data.length === 0 && !accounts.isFetching) || forceFetch) {
    try {
      dispatch({
        type: accountsActionTypes.GET_ACCOUNTS_IS_FETCHING,
      });
      const response = await fetch('/api/core-banking/accounts');

      if (!response.ok) {
        throw await generateApiErrorFromResponse(response);
      }

      const json = await response.json();
      dispatch({
        type: accountsActionTypes.GET_ACCOUNTS,
        accounts: json.accounts,
      });
    } catch (error) {
      dispatch({
        type: accountsActionTypes.GET_ACCOUNTS_ERROR,
        error,
      });
    }
  }
};

export const addAccountAction = ({
  fetch,
  keycloak,
  newAccountData,
  throwErrors = true,
} = {}) => async (dispatch, getState) => {
  const { parties } = getState();
  try {
    if (!parties.data || parties.data.length === 0) {
      const partyError = new Error('No party registered!');
      partyError.name = 'User error';
      throw partyError;
    }

    const response = await fetch('/api/core-banking/accounts', {
      method: httpMethods.POST,
      mode: 'same-origin',
      cache: 'no-cache',
      headers: {
        'Content-type': 'application/json',
      },
      body: await JSON.stringify({
        account: (() => {
          switch (newAccountData[accountKeys.TYPE]) {
            case accountTypes.WALLET:
              return {
                [walletKeys.TYPE]: newAccountData[accountKeys.TYPE],
                [walletKeys.PAYOUT_ACCOUNT_ID]:
                  newAccountData[walletKeys.PAYOUT_ACCOUNT_ID],
                [walletKeys.OWNER_ID]: newAccountData[walletKeys.OWNER_ID],
                ownerName: newAccountData.ownerName,
                [accountKeys.ALIAS]: newAccountData.ownerName,
                [accountKeys.PARTY_ID]: head(parties.data)[
                  accountKeys.PARTY_ID
                ],
              };
            case accountTypes.MASTER:
            case accountTypes.STANDARD:
            default:
              return {
                [walletKeys.TYPE]: newAccountData[accountKeys.TYPE],
                [accountKeys.CURRENCY]: newAccountData[accountKeys.CURRENCY],
                [accountKeys.ALIAS]: newAccountData[accountKeys.ALIAS],
                [accountKeys.PARTY_ID]: head(parties.data)[
                  accountKeys.PARTY_ID
                ],
                [accountKeys.PRODUCT_CODE]:
                  newAccountData[accountKeys.PRODUCT_CODE],
              };
          }
        })(),
      }),
    });

    if (!response.ok) {
      throw await fetchErrorHandler({ response, keycloak });
    }
  } catch (error) {
    dispatch({
      type: accountsActionTypes.ADD_ACCOUNT_ERROR,
      error,
    });
    if (throwErrors) throw error;
  }
};
