import React, { useMemo, useState } from 'react';
import { isNil, isEmpty, pick, omit } from 'rambda';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, SvgIcon } from '@material-ui/core';
import { bool, func, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { formatSortCodeValue } from '@fondy/utils';
import {
  paymentStatuses,
  paymentStatusesLabelsMap,
  transactionDirectionTypes,
} from '@fondy/enums';
import {
  AccountAliasTableCell,
  AvatarDataCell,
  StatusTableCell,
} from '@fondy/tables';
import { Drawer, ObjectDataDisplay } from '@fondy/data-display';
import { DownloadIcon } from '@fondy/icons';
import {
  accountKeys,
  isPaymentBetweenOwnAccounts,
  PAYMENT_SUBTYPE_MCPO,
  paymentsKeys,
  paymentsKeysDetailsLabelsMap,
  paymentsKeysLabelsMap,
  paymentsRejectionsMap,
  ibanToStringFormatter,
} from '../../../utils';
import {
  FormattedDateTime,
  createPaymentDetailsPdf,
  PaymentTransactionDetailsModalHeader,
} from '../../atoms';
import { stateSelectAccountsData, stateTimeZoneSelector } from '../../../redux';

const getPaymentDetails = (payment) =>
  pick(
    [
      paymentsKeys.STATUS,
      ...(payment[paymentsKeys.STATUS] === paymentStatuses.REJECTED
        ? [paymentsKeys.REJECTION_CODE]
        : []),
      paymentsKeys.ACCOUNT_ID,
      paymentsKeys.COUNTERPARTY_NAME,
      paymentsKeys.DESCRIPTION,
      ...(payment[paymentsKeys.COUNTERPARTY_IBAN]
        ? [paymentsKeys.COUNTERPARTY_IBAN]
        : [
            paymentsKeys.COUNTERPARTY_SORT_CODE,
            paymentsKeys.COUNTERPARTY_ACCOUNT_NUMBER,
          ]),
      paymentsKeys.ID,
      paymentsKeys.END_2_END_ID,
    ],
    {
      ...payment,
      ...(payment[paymentsKeys.DIRECTION] === transactionDirectionTypes.IN
        ? {
            [paymentsKeys.ACCOUNT_ID]: payment[paymentsKeys.COUNTERPARTY_NAME],
            [paymentsKeys.COUNTERPARTY_NAME]: payment[paymentsKeys.ACCOUNT_ID],
          }
        : {}),
    },
  );

const excludeSortCodeAndNumber = omit([
  paymentsKeys.COUNTERPARTY_SORT_CODE,
  paymentsKeys.COUNTERPARTY_ACCOUNT_NUMBER,
]);

const paymentKeysDetailsMergedLabelsMap = new Map([
  ...paymentsKeysLabelsMap,
  ...paymentsKeysDetailsLabelsMap,
]);

const rejectionMapping = (isAssessmentFailure) => (code) => {
  if (isAssessmentFailure) return 'Invalid payment details';
  return (
    paymentsRejectionsMap.get(code) ||
    'Payment rejected and reason has not been specified'
  );
};

const renderAvatarComponent = (value) => (
  <AvatarDataCell
    title={value}
    avatarSize="small"
    titleProps={{ style: { fontSize: 14 } }}
  />
);

const renderAccount = (account) => {
  if (isEmpty(account) || isNil(account)) return null;
  return (
    <AccountAliasTableCell
      accountAlias={account[accountKeys.ALIAS]}
      currencyIso={account[accountKeys.CURRENCY]}
      accountType={account[accountKeys.TYPE]}
      fontSize={14}
      medium
      alignTop
    />
  );
};

const useStyles = makeStyles((theme) => ({
  dataDisplay: {
    margin: 0,
  },
  collapse: {
    padding: 0,
  },
  row: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  label: {
    whiteSpace: 'initial',
    [theme.breakpoints.up('sm')]: {
      width: 170,
      minWidth: 170,
    },
  },
  value: {
    display: 'flex',
    flex: 1,
  },
  headerPadding: {
    paddingRight: theme.spacing(3),
  },
}));

const PaymentDetailsModal = ({
  open,
  payment,
  onClose,
  datetimeFormat,
  isSettlement,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);
  const toOwnAccount = isPaymentBetweenOwnAccounts(payment);
  const {
    selectedAccount: sourceAccount,
    hasAccounts,
    isLoading,
  } = useSelector(
    stateSelectAccountsData(payment ? payment[paymentsKeys.ACCOUNT_ID] : null),
  );
  const { selectedAccount: targetAccount } = useSelector(
    stateSelectAccountsData(
      !!payment && toOwnAccount
        ? payment[paymentsKeys.COUNTERPARTY_ACCOUNT_ID]
        : null,
    ),
  );
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const isIncoming =
    payment?.[paymentsKeys.DIRECTION] === transactionDirectionTypes.IN;

  const sourceAccountButton = useMemo(() => renderAccount(sourceAccount), [
    sourceAccount,
  ]);

  const targetAccountButton = useMemo(() => renderAccount(targetAccount), [
    targetAccount,
  ]);

  if (!payment || !open || !sourceAccount || !hasAccounts || isLoading) {
    return null;
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      headerProps={{
        className: classes.headerPadding,
      }}
      contentProps={{
        dividers: true,
      }}
      footerActions={[
        {
          label: 'Download confirmation',
          color: 'secondary',
          size: 'large',
          onClick: async () =>
            createPaymentDetailsPdf({
              payment: {
                ...getPaymentDetails(payment),
                ...pick(
                  [
                    paymentsKeys.CREATED_AT,
                    paymentsKeys.AMOUNT,
                    paymentsKeys.ORIGINAL_AMOUNT,
                    paymentsKeys.DESCRIPTION,
                    paymentsKeys.CURRENCY,
                    paymentsKeys.ORIGINAL_CURRENCY,
                    paymentsKeys.FX_RATE,
                    paymentsKeys.PAYMENT_SUBTYPE,
                  ],
                  payment,
                ),
              },
              theme,
              sourceAccount,
              targetAccount,
              rejectionMapping: rejectionMapping(
                payment[paymentsKeys.ASSESSMENT_FAILED],
              ),
              timeZone: legalEntityTimezone,
              isSettlement,
              loadingStateSetter: setIsGeneratingPdf,
              filename: () =>
                `${payment[paymentsKeys.DESCRIPTION]} payment details.pdf`,
            }),
          startIcon: isGeneratingPdf ? (
            <CircularProgress size={14} className={classes.progress} />
          ) : (
            <SvgIcon style={{ fontSize: 20 }} component={DownloadIcon} />
          ),
          disabled: isGeneratingPdf,
        },
      ]}
      header={
        <PaymentTransactionDetailsModalHeader
          amount={payment[paymentsKeys.AMOUNT]}
          currency={payment[paymentsKeys.CURRENCY]}
          originalAmount={payment[paymentsKeys.ORIGINAL_AMOUNT]}
          originalCurrency={payment[paymentsKeys.ORIGINAL_CURRENCY]}
          createdAt={payment[paymentsKeys.CREATED_AT]}
          fxRate={payment[paymentsKeys.FX_RATE]}
          datetimeFormat={datetimeFormat}
        />
      }
    >
      <ObjectDataDisplay
        classes={{
          item: classes.row,
          label: classes.label,
          value: classes.value,
          collapse: classes.collapse,
        }}
        className={classes.dataDisplay}
        data={getPaymentDetails(
          toOwnAccount && targetAccount
            ? excludeSortCodeAndNumber(payment)
            : payment,
        )}
        dataFieldsLabelsMap={paymentKeysDetailsMergedLabelsMap}
        dataFieldsMapper={{
          [paymentsKeys.ACCOUNT_ID]: (value) =>
            isIncoming
              ? targetAccountButton || renderAvatarComponent(value)
              : sourceAccountButton,
          [paymentsKeys.COUNTERPARTY_NAME]: (value) =>
            isIncoming
              ? sourceAccountButton
              : targetAccountButton || renderAvatarComponent(value),
          [paymentsKeys.COUNTERPARTY_SORT_CODE]: formatSortCodeValue,
          [paymentsKeys.COUNTERPARTY_IBAN]: ibanToStringFormatter,
          [paymentsKeys.CREATED_AT]: (value) => (
            <FormattedDateTime utcIsoDate={value} dateFormat={datetimeFormat} />
          ),
          [paymentsKeys.STATUS]: (value) => (
            <StatusTableCell
              status={value}
              labelsMap={paymentStatusesLabelsMap}
            />
          ),
          [paymentsKeys.REJECTION_CODE]: rejectionMapping(
            payment[paymentsKeys.ASSESSMENT_FAILED],
          ),
        }}
        grid={{ xs: 12 }}
        itemSpacing={1}
      />
    </Drawer>
  );
};

PaymentDetailsModal.propTypes = {
  open: bool,
  datetimeFormat: string,
  payment: shape({
    [paymentsKeys.ID]: string.isRequired,
    [paymentsKeys.ACCOUNT_ID]: string.isRequired,
    [paymentsKeys.COUNTERPARTY_NAME]: string.isRequired,
    [paymentsKeys.COUNTERPARTY_ACCOUNT_NUMBER]: string,
    [paymentsKeys.COUNTERPARTY_SORT_CODE]: string,
    [paymentsKeys.COUNTERPARTY_IBAN]: string,
    [paymentsKeys.STATUS]: string.isRequired,
    [paymentsKeys.CREATED_AT]: string.isRequired,
    [paymentsKeys.END_2_END_ID]: string.isRequired,
  }),
  onClose: func,
  isSettlement: bool,
};

PaymentDetailsModal.defaultProps = {
  open: false,
  onClose: undefined,
  payment: null,
  datetimeFormat: 'dd MMM yyyy HH:mm',
  isSettlement: false,
};

export default PaymentDetailsModal;
