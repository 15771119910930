export const splitAccountWalletKeys = Object.freeze({
  ID: 'id',
  ADITIONAL_INFORMATION: 'additionalInformation',
  ALIAS: 'alias',
  DATE_CREATED: 'dataCreated',
  AVAILABLE_AMOUNT: 'availableAmount',
  AVAILABLE_AMOUNT_CURRENCY: 'availableAmountCurrency',
  CURRENT_AMOUNT: 'currentAmount',
  CURRENT_AMOUNT_CURRENCY: 'currentAmountCurrency',
  DEFAULT_PAYMENT_REFERENCE: 'defaultPaymentReference',
  DEFAULT_WALLET_ID: 'defaultWalletId',
  EXTERNAL_ID: 'externalId',
  STATUS: 'status',

  PAYEE_ID: 'payeeId',
  PAYEE_IDENTITY_ID: 'payeeIdentityId',
  PAYEE_NAME: 'payeeName',
  PAYEE_ACCOUNT_NUMBER: 'payeeAccountNumber',
  PAYEE_SORT_CODE: 'payeeSortCode',
  PAYEE_BIC: 'payeeBic',
  PAYEE_IBAN: 'payeeIban',
  PAYEE_CURRENCY: 'payeeCurrency',

  PAYEE_COUNTRY: 'countryCode',
  PAYEE_CITY: 'city',
  PAYEE_STREET: 'street',
  PAYEE_BUILDING_NAME: 'buildingName',
  PAYEE_BUILDING_NUMBER: 'buildingNumber',
  PAYEE_POSTAL_CODE: 'postalCode',
});

export const splitAccountWalletKeysLabelsMap = new Map([
  [splitAccountWalletKeys.ID, 'ID'],
  [splitAccountWalletKeys.DATE_CREATED, 'Date created'],
  [splitAccountWalletKeys.ADITIONAL_INFORMATION, 'Additional Information'],
  [splitAccountWalletKeys.ALIAS, 'Name'],
  [splitAccountWalletKeys.CURRENT_AMOUNT, 'Balance'],
  [splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY, 'Currency'],
  [
    splitAccountWalletKeys.DEFAULT_PAYMENT_REFERENCE,
    'Default Payment Reference',
  ],
  [splitAccountWalletKeys.DEFAULT_WALLET_ID, 'Default Wallet Id'],
  [splitAccountWalletKeys.EXTERNAL_ID, 'External Id'],
  [splitAccountWalletKeys.PAYEE_ID, 'Payout Id'],
  [splitAccountWalletKeys.PAYEE_IDENTITY_ID, 'Payout Identity Id'],
  [splitAccountWalletKeys.PAYEE_NAME, 'Payout Name'],
  [splitAccountWalletKeys.PAYEE_ACCOUNT_NUMBER, 'Payout Account Number'],
  [splitAccountWalletKeys.PAYEE_SORT_CODE, 'Payout Sort Code'],
  [splitAccountWalletKeys.PAYEE_BIC, 'Payout BIC'],
  [splitAccountWalletKeys.PAYEE_IBAN, 'Payout IBAN'],
  [splitAccountWalletKeys.STATUS, 'Status'],
  [splitAccountWalletKeys.PAYEE_COUNTRY, 'Country'],
  [splitAccountWalletKeys.PAYEE_CITY, 'City'],
  [splitAccountWalletKeys.PAYEE_STREET, 'Street'],
  [splitAccountWalletKeys.PAYEE_BUILDING_NAME, 'Building Name'],
  [splitAccountWalletKeys.PAYEE_BUILDING_NUMBER, 'Building Number'],
  [splitAccountWalletKeys.PAYEE_POSTAL_CODE, 'Postal Code'],
]);
