import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { formatTimeZonedDate, isValidTimezoneIANAString } from '@fondy/utils';
import { stateTimeZoneSelector } from '../../../redux';

const FormattedDateTime = ({ utcIsoDate, dateFormat }) => {
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);

  if (!isValidTimezoneIANAString) {
    throw new Error(
      `"${legalEntityTimezone}" is not valid IANA timezone string!`,
    );
  }

  return formatTimeZonedDate(utcIsoDate, legalEntityTimezone, {
    dateFormat,
  });
};

FormattedDateTime.propTypes = {
  utcIsoDate: string.isRequired,
  dateFormat: string,
};

FormattedDateTime.defaultProps = {
  dateFormat: 'dd MMM yyyy',
};

export default FormattedDateTime;
