import { accountKeys } from '../../utils';

const stateSelectAccountsAliases = (state) => ({
  accountsAliases: state.accounts.data.reduce(
    (activeAccounts, account) => ({
      ...activeAccounts,
      [account[accountKeys.ALIAS]]: account[accountKeys.ALIAS],
    }),
    {},
  ),
  isLoading: state.accounts.isFetching,
  isError: !!state.accounts.getError,
  apiError: state.accounts.getError,
});

export default stateSelectAccountsAliases;
