import React, { forwardRef } from 'react';
import { number } from 'prop-types';
import { Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  iconBadgeRoot: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  iconBadge: {
    position: 'initial',
    transformOrigin: 'unset',
    transform: 'unset',
    alignSelf: 'center',
  },
  hidden: {
    display: 'none',
  },
}));

const BadgeTableCell = forwardRef(({ badgeContent, ...restProps }, ref) => {
  const classes = useStyles();

  return (
    <Badge
      color="primary"
      overlap="rectangular"
      classes={{
        root: classes.iconBadgeRoot,
        badge: classes.iconBadge,
        invisible: classes.hidden,
      }}
      badgeContent={badgeContent}
      ref={ref}
      showZero
      {...restProps}
    />
  );
});

BadgeTableCell.propTypes = {
  badgeContent: number,
};
BadgeTableCell.defaultProps = {
  badgeContent: 0,
};

export default BadgeTableCell;
