const newPaymentErrorCodesMap = new Map([
  [
    'DAILY_LIMIT_EXCEEDED',
    'You have exceeded the daily payment limit on this account.',
  ],
  [
    'INSUFFICIENT_BALANCE',
    'Insufficient funds. Your balance does not cover the payment fee amount.',
  ],
]);

export default newPaymentErrorCodesMap;
