import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect,
} from 'react';

import clsx from 'clsx';
import { head, omit, equals, isNil, pickBy, isEmpty } from 'rambda';
import { bool, func, string, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AddRounded } from '@material-ui/icons';
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { AvatarDataCell } from '@fondy/tables';
import {
  ControlledTextField,
  getTextFieldPropsFromKey,
  FormControls,
  AutocompleteControlledTextField,
  MaskedControlledTextField,
  MaskedControlledAmountField,
  sortCodeInputMaskPattern,
  createOnlyDigitsTextInputMaskPattern,
  transformInputValuesBeforeValidate,
  AccountSelectOption,
  FormFieldsPreview,
  CurrencySelectField,
  ibanInputMaskPatternAnycase,
  CountryAutocompleteControlledTextField,
} from '@fondy/forms';
import { currency, accountTypes, accountStatuses } from '@fondy/enums';
import { Button, buttonColor, buttonVariant, buttonSize } from '@fondy/buttons';
import { formatSortCodeValue } from '@fondy/utils';
import { TabsHorizontalNavigation } from '@fondy/menu';
import {
  recipientAutocompleteTagsRenderer,
  makePaymentFormTransferTypes,
  recipientAutocompleteHandleEnterKeyDown,
  makePaymentFormTransferTypesLabelsMap,
  newFasterPaymentValidationSchema,
  newPaymentValuesSanitiser,
  omitKnownPayeeDetails,
} from './utils';
import {
  PaymentAmountWithFees,
  TextFieldPreview,
  AccountAutoCompleteDropdownInput,
  PaymentAmountFxRate,
  RadioStyled,
} from '../../atoms';
import {
  walletKeys,
  accountKeys,
  newPaymentKeys,
  partyAssociationsKeys,
  newPaymentKeysLabelsMap,
  paymentCounterpartyKeys,
  GBP_CURRENCY,
  ibanToStringFormatter,
  counterpartyTypes,
  counterpartyTypesLabelsMap,
  counterpartyBankDetailsTypes,
  counterpartyBankDetailsTypesLabelsMap,
} from '../../../utils';
import { stateSelectAccountsData } from '../../../redux';

const getFieldProps = getTextFieldPropsFromKey({
  labelsMap: newPaymentKeysLabelsMap,
});

const getAccountById = (id, accounts) =>
  accounts.find((account) => account[accountKeys.ID] === id);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderTop: theme.border.thin,
    marginBottom: theme.spacing(3),
  },
  groupedInputs: {
    display: 'flex',
    width: '100%',
    '& > *:first-child': {
      maxWidth: 150,
      marginRight: theme.spacing(2),
    },
  },
  groupedNameInputs: {
    display: 'flex',
    width: '100%',
    '& > *:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  groupedAddressInputs: {
    display: 'flex',
    width: '100%',
    '& > *:last-child': {
      maxWidth: 150,
      marginLeft: theme.spacing(2),
    },
  },
  firstInput: {
    marginTop: 0,
  },
  sectionMargin: {
    marginTop: theme.spacing(2),
  },
  horizontalRadioGroup: {
    flexDirection: 'row',
  },
  radioGroupMargin: {
    marginBottom: theme.spacing(1),
  },
  radioTitle: {
    color: theme.palette.text.secondaryDark,
  },
  radioLabel: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  radioLabelText: {
    fontSize: theme.typography.htmlFontSize,
  },
  formSectionHeader: {
    marginTop: theme.spacing(3),
  },
  autocompleteOption: {
    padding: theme.spacing(1),
  },
  additionalOption: {
    padding: 0,
  },
  firstAdditionalItem: {
    '&:not(.first)': {
      marginTop: theme.spacing(1),
      borderTop: theme.border.thin,
    },
  },
  createNewCounterpartyButton: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
  },
  hidden: {
    display: 'none',
  },
  sectionHint: {
    marginBottom: theme.spacing(1.5),
  },
  formControls: {
    borderTop: theme.border.thin,
    paddingTop: theme.spacing(3),
  },
  transferTypeTabs: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

const counterpartyAutocompleteAdditionalOptions = [{ type: 'new' }];

const omitPayeeId = omit([newPaymentKeys.PAYEE_ID]);

const mapCounterpartyToPaymentKeys = (value) => ({
  [newPaymentKeys.PAYEE_NAME]: value[paymentCounterpartyKeys.NAME],
  [newPaymentKeys.PAYEE_FIRST_NAME]:
    value[paymentCounterpartyKeys.FIRST_NAME] ?? '',
  [newPaymentKeys.PAYEE_LAST_NAME]:
    value[paymentCounterpartyKeys.LAST_NAME] ?? '',
  [newPaymentKeys.PAYEE_COMPANY_NAME]:
    value[paymentCounterpartyKeys.COMPANY_NAME] ?? '',
  [newPaymentKeys.PAYEE_ACCOUNT_NUMBER]:
    value[paymentCounterpartyKeys.ACCOUNT_NUMBER],
  [newPaymentKeys.PAYEE_SORT_CODE]: value[paymentCounterpartyKeys.SORT_CODE],
  [newPaymentKeys.PAYEE_ID]: value[paymentCounterpartyKeys.ID],
  [newPaymentKeys.PAYEE_CURRENCY]:
    value[paymentCounterpartyKeys.CURRENCY] || GBP_CURRENCY,
  [newPaymentKeys.PAYEE_IBAN]: value[paymentCounterpartyKeys.IBAN] || '',
  [newPaymentKeys.PAYEE_TYPE]:
    value[paymentCounterpartyKeys.TYPE] ?? counterpartyTypes.PERSONAL,
  [newPaymentKeys.PAYEE_BANK_DETAILS_TYPE]: value[paymentCounterpartyKeys.IBAN]
    ? counterpartyBankDetailsTypes.IBAN
    : counterpartyBankDetailsTypes.ACCOUNT_NUMBER,
  ...(value[paymentCounterpartyKeys.ADDRESS]
    ? value[paymentCounterpartyKeys.ADDRESS]
    : {}),
});

const parseRawFormValues = ({
  rawValues,
  userPartyId,
  isNewRecipient,
} = {}) => {
  const extraValues = {};

  if (isNewRecipient && rawValues[newPaymentKeys.PAYEE_IBAN]) {
    extraValues[newPaymentKeys.PAYEE_IBAN] = rawValues[
      newPaymentKeys.PAYEE_IBAN
    ].replaceAll(' ', '');
    extraValues[newPaymentKeys.PAYEE_ADDRESS] = {
      [newPaymentKeys.PAYEE_COUNTRY]: rawValues[newPaymentKeys.PAYEE_COUNTRY],
      [newPaymentKeys.PAYEE_CITY]: rawValues[newPaymentKeys.PAYEE_CITY],
      [newPaymentKeys.PAYEE_STREET]: rawValues[newPaymentKeys.PAYEE_STREET],
      [newPaymentKeys.PAYEE_BUILDING_NAME]:
        rawValues[newPaymentKeys.PAYEE_BUILDING_NAME],
      [newPaymentKeys.PAYEE_BUILDING_NUMBER]:
        rawValues[newPaymentKeys.PAYEE_BUILDING_NUMBER],
      [newPaymentKeys.PAYEE_POSTAL_CODE]:
        rawValues[newPaymentKeys.PAYEE_POSTAL_CODE],
    };
  }

  const sanitizedValues = {
    ...newPaymentValuesSanitiser(rawValues),
    ...extraValues,
    [newPaymentKeys.PARTY_ID]: userPartyId,
  };

  if (isNewRecipient && sanitizedValues[newPaymentKeys.PAYEE_IBAN]) {
    if (
      Object.hasOwnProperty.call(
        sanitizedValues,
        newPaymentKeys.PAYEE_SORT_CODE,
      )
    ) {
      delete sanitizedValues[newPaymentKeys.PAYEE_SORT_CODE];
    }
    if (
      Object.hasOwnProperty.call(
        sanitizedValues,
        newPaymentKeys.PAYEE_ACCOUNT_NUMBER,
      )
    ) {
      delete sanitizedValues[newPaymentKeys.PAYEE_ACCOUNT_NUMBER];
    }
  }

  const isTransferBetweenOwnAccounts =
    sanitizedValues[newPaymentKeys.TRANSFER_TYPE] ===
    makePaymentFormTransferTypes.BETWEEN_ACCOUNTS;

  return {
    values: pickBy(
      (value) => !isEmpty(value),
      isNewRecipient || isTransferBetweenOwnAccounts
        ? omitPayeeId(sanitizedValues)
        : omitKnownPayeeDetails(sanitizedValues),
    ),
    isTransferBetweenOwnAccounts,
  };
};

export default function MakePaymentForm({
  disabled,
  closeHandler,
  onReset,
  onValidationComplete,
  className,
  formReview,
  updatePaymentFxRate,
  paymentFxRate,
  paymentFee,
  submitText,
}) {
  const payeeNameRef = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const { accountId } = useParams();
  const userPartyId = useSelector(
    (state) => head(state.parties.data)[partyAssociationsKeys.PARTY_ID],
  );
  const { allAccounts } = useSelector(stateSelectAccountsData());
  const recipientFromHistoryState = history?.location?.state?.counterparty;
  const hasAccountIdFromUrl = !!accountId;

  const [previewEditableFields, setPreviewEditableFields] = useState({
    [newPaymentKeys.ACCOUNT_ID]: false,
    [newPaymentKeys.PAYMENT_CURRENCY]: false,
    [newPaymentKeys.AMOUNT]: false,
    [newPaymentKeys.DESCRIPTION]: false,
    [newPaymentKeys.TRANSFER_TYPE]: false,
    [newPaymentKeys.PAYEE_NAME]: false,
    [newPaymentKeys.DESTINATION_ACCOUNT_ID]: false,
    [newPaymentKeys.PAYEE_SORT_CODE]: false,
    [newPaymentKeys.PAYEE_ACCOUNT_NUMBER]: false,
  });
  const [sourceAccount, setSourceAccount] = useState(() =>
    hasAccountIdFromUrl ? getAccountById(accountId, allAccounts) : null,
  );
  const [internalTargetAccount, setInternalTargetAccount] = useState(null);
  const [recipient, setRecipient] = useState(recipientFromHistoryState || null);
  const [paymentCurrencyIso, setPaymentCurrencyIso] = useState(GBP_CURRENCY);

  const isNewRecipient = useMemo(
    () =>
      !!recipient &&
      !recipientFromHistoryState &&
      !!recipient[newPaymentKeys.PAYEE_NAME] &&
      !recipient[newPaymentKeys.PAYEE_ID],
    [recipient, recipientFromHistoryState],
  );

  const isKnownRecipient = useMemo(
    () => !!recipient && !!recipient[newPaymentKeys.PAYEE_ID],
    [recipient],
  );

  const isKnownRecipientWithFilledTypeFields = useMemo(
    () =>
      !!recipient &&
      !!recipient[newPaymentKeys.PAYEE_ID] &&
      !!recipient[newPaymentKeys.PAYEE_TYPE] &&
      (recipient[newPaymentKeys.PAYEE_TYPE] === counterpartyTypes.PERSONAL
        ? !!recipient[newPaymentKeys.PAYEE_FIRST_NAME] &&
          !!recipient[newPaymentKeys.PAYEE_LAST_NAME]
        : !!recipient[newPaymentKeys.PAYEE_COMPANY_NAME]),
    [recipient],
  );

  const isPaymentCurrencyGBP = useMemo(
    () => paymentCurrencyIso === GBP_CURRENCY,
    [paymentCurrencyIso],
  );

  const initialValues = useMemo(
    () => ({
      ...(recipient || { [newPaymentKeys.PAYEE_NAME]: '' }),
      [newPaymentKeys.PAYEE_TYPE]:
        recipient?.[newPaymentKeys.PAYEE_TYPE] ?? counterpartyTypes.PERSONAL,
      [newPaymentKeys.PAYEE_BANK_DETAILS_TYPE]:
        recipient?.[newPaymentKeys.PAYEE_BANK_DETAILS_TYPE] ??
        counterpartyBankDetailsTypes.ACCOUNT_NUMBER,
      [newPaymentKeys.PAYEE_CURRENCY]:
        recipient?.[paymentCounterpartyKeys.CURRENCY] ||
        recipient?.[newPaymentKeys.PAYEE_CURRENCY] ||
        GBP_CURRENCY,
      [newPaymentKeys.PAYMENT_CURRENCY]:
        sourceAccount?.[accountKeys.CURRENCY] || GBP_CURRENCY,
      [newPaymentKeys.TRANSFER_TYPE]: makePaymentFormTransferTypes.RECIPIENT,
      ...(hasAccountIdFromUrl
        ? { [newPaymentKeys.ACCOUNT_ID]: accountId }
        : {}),
    }),
    [accountId, hasAccountIdFromUrl, recipient, sourceAccount],
  );

  const onResetHandler = useCallback(
    async (rawValues, formikHelpers) => {
      if (onReset) {
        setTimeout(
          () =>
            onReset({
              values: {
                ...newPaymentValuesSanitiser(rawValues),
                [newPaymentKeys.PARTY_ID]: userPartyId,
              },
              ...formikHelpers,
            }),
          0,
        );
      }
    },
    [onReset, userPartyId],
  );

  const onSubmitHandler = useCallback(
    async (rawValues, { setSubmitting, setValues, ...restFormikHelpers }) => {
      setSubmitting(true);

      const { isTransferBetweenOwnAccounts, values } = parseRawFormValues({
        rawValues,
        userPartyId,
        isNewRecipient,
      });
      const shouldUpdateCounterpartyTypeFields =
        !isTransferBetweenOwnAccounts &&
        isKnownRecipient &&
        !isKnownRecipientWithFilledTypeFields;

      await onValidationComplete({
        values,
        rawValues,
        recipient,
        setRecipient,
        isNewRecipient,
        isPaymentCurrencyGBP,
        shouldUpdateCounterpartyTypeFields,
        setSubmitting,
        mapCounterpartyToPaymentKeys,
        setValues,
        ...restFormikHelpers,
      });

      return setSubmitting(false);
    },
    [
      isKnownRecipient,
      isKnownRecipientWithFilledTypeFields,
      isNewRecipient,
      isPaymentCurrencyGBP,
      onValidationComplete,
      recipient,
      userPartyId,
    ],
  );

  const formState = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    onReset: onResetHandler,
    onSubmit: onSubmitHandler,
    validate: (values) => {
      return transformInputValuesBeforeValidate({
        validationSchema: newFasterPaymentValidationSchema({
          maxAvailableAmount: sourceAccount
            ? sourceAccount[accountKeys.CURRENT_BALANCE]
            : null,
          isPaymentCurrencyGBP,
          useSchemeForIBAN:
            !isPaymentCurrencyGBP ||
            values?.[newPaymentKeys.PAYEE_BANK_DETAILS_TYPE] ===
              counterpartyBankDetailsTypes.IBAN,
          paymentCurrencyIso,
          paymentFxRate,
        }),
        valuesSanitiser: newPaymentValuesSanitiser,
      })(values);
    },
  });

  const {
    handleSubmit,
    handleReset,
    isSubmitting,
    isValidating,
    setValues,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    setSubmitting,
    values,
    handleChange,
  } = formState;

  const onSourceAccountChangeHandler = useCallback(
    async ({ option, options }) => {
      const choosenAccount =
        getAccountById(option[accountKeys.ID], options) || option;
      if (!choosenAccount) return;
      await setSourceAccount(choosenAccount);
      if (
        values[newPaymentKeys.TRANSFER_TYPE] ===
          makePaymentFormTransferTypes.BETWEEN_ACCOUNTS &&
        values[newPaymentKeys.DESTINATION_ACCOUNT_ID]?.length > 0
      ) {
        await setInternalTargetAccount(null);
        await setFieldValue(newPaymentKeys.DESTINATION_ACCOUNT_ID, undefined);
      }
    },
    [setFieldValue, values],
  );

  const recipientFieldsPreviewToggleHandler = useCallback(
    () =>
      setPreviewEditableFields((prev) => ({
        ...prev,
        [newPaymentKeys.TRANSFER_TYPE]: !prev[newPaymentKeys.TRANSFER_TYPE],
        [newPaymentKeys.PAYEE_NAME]: !prev[newPaymentKeys.PAYEE_NAME],
        [newPaymentKeys.DESTINATION_ACCOUNT_ID]: !prev[
          newPaymentKeys.DESTINATION_ACCOUNT_ID
        ],
        [newPaymentKeys.PAYEE_SORT_CODE]: !prev[newPaymentKeys.PAYEE_SORT_CODE],
        [newPaymentKeys.PAYEE_ACCOUNT_NUMBER]: !prev[
          newPaymentKeys.PAYEE_ACCOUNT_NUMBER
        ],
      })),
    [],
  );

  const transferTypeChangeHandler = useCallback(
    async (_, value, shouldTouchField = true) => {
      const type = parseInt(value, 10);

      await setValues((prevValues) => ({
        ...prevValues,
        [newPaymentKeys.PAYEE_NAME]: '',
        [newPaymentKeys.PAYEE_FIRST_NAME]: '',
        [newPaymentKeys.PAYEE_LAST_NAME]: '',
        [newPaymentKeys.PAYEE_COMPANY_NAME]: '',
        [newPaymentKeys.PAYEE_ACCOUNT_NUMBER]: '',
        [newPaymentKeys.PAYEE_SORT_CODE]: '',
        [newPaymentKeys.PAYEE_ID]: '',
        [newPaymentKeys.PAYEE_COUNTRY]: '',
        [newPaymentKeys.PAYEE_IBAN]: '',
        [newPaymentKeys.PAYEE_CITY]: '',
        [newPaymentKeys.PAYEE_STREET]: '',
        [newPaymentKeys.PAYEE_BUILDING_NAME]: '',
        [newPaymentKeys.PAYEE_BUILDING_NUMBER]: '',
        [newPaymentKeys.PAYEE_POSTAL_CODE]: '',
        [newPaymentKeys.PAYEE_TYPE]: counterpartyTypes.PERSONAL,
        [newPaymentKeys.PAYEE_BANK_DETAILS_TYPE]:
          counterpartyBankDetailsTypes.ACCOUNT_NUMBER,
        [newPaymentKeys.TRANSFER_TYPE]: type,
      }));
      if (shouldTouchField) {
        await setFieldTouched(
          type === makePaymentFormTransferTypes.RECIPIENT
            ? newPaymentKeys.PAYEE_NAME
            : newPaymentKeys.DESTINATION_ACCOUNT_ID,
        );
      }
      await handleChange({
        target: {
          value: type,
          name: newPaymentKeys.TRANSFER_TYPE,
        },
      });
    },
    [handleChange, setFieldTouched, setValues],
  );

  const counterpartyTypeChangeHandler = useCallback(
    async (_, value) => {
      await setValues((prevValues) => ({
        ...prevValues,
        ...(value === counterpartyTypes.PERSONAL
          ? {
              [newPaymentKeys.PAYEE_COMPANY_NAME]: '',
            }
          : {
              [newPaymentKeys.PAYEE_FIRST_NAME]: '',
              [newPaymentKeys.PAYEE_LAST_NAME]: '',
            }),
        [newPaymentKeys.PAYEE_TYPE]: value,
      }));
      await handleChange({
        target: {
          value,
          name: newPaymentKeys.PAYEE_TYPE,
        },
      });
    },
    [handleChange, setValues],
  );

  const counterpartyBankDetailsTypeChangeHandler = useCallback(
    async (_, value) => {
      await setValues((prevValues) => ({
        ...prevValues,
        ...(value === counterpartyBankDetailsTypes.IBAN
          ? {
              [newPaymentKeys.PAYEE_ACCOUNT_NUMBER]: '',
              [newPaymentKeys.PAYEE_SORT_CODE]: '',
            }
          : {
              [newPaymentKeys.PAYEE_IBAN]: '',
              [newPaymentKeys.PAYEE_COUNTRY]: '',
              [newPaymentKeys.PAYEE_CITY]: '',
              [newPaymentKeys.PAYEE_STREET]: '',
              [newPaymentKeys.PAYEE_BUILDING_NAME]: '',
              [newPaymentKeys.PAYEE_BUILDING_NUMBER]: '',
              [newPaymentKeys.PAYEE_POSTAL_CODE]: '',
            }),
        [newPaymentKeys.PAYEE_BANK_DETAILS_TYPE]: value,
      }));
      await handleChange({
        target: {
          value,
          name: newPaymentKeys.PAYEE_BANK_DETAILS_TYPE,
        },
      });
    },
    [handleChange, setValues],
  );

  const recipientAutocompleteOnSelectHandler = useCallback(
    async (_, value, reason) => {
      switch (reason) {
        case 'clear':
          await setValues((prevValues) => ({
            ...prevValues,
            [newPaymentKeys.PAYEE_NAME]: '',
            [newPaymentKeys.PAYEE_FIRST_NAME]: '',
            [newPaymentKeys.PAYEE_LAST_NAME]: '',
            [newPaymentKeys.PAYEE_COMPANY_NAME]: '',
            [newPaymentKeys.PAYEE_ACCOUNT_NUMBER]: '',
            [newPaymentKeys.PAYEE_SORT_CODE]: '',
            [newPaymentKeys.PAYEE_IBAN]: '',
            [newPaymentKeys.PAYEE_ID]: '',
            [newPaymentKeys.PAYEE_TYPE]: counterpartyTypes.PERSONAL,
            [newPaymentKeys.PAYEE_BANK_DETAILS_TYPE]:
              counterpartyBankDetailsTypes.ACCOUNT_NUMBER,
          }));
          await setRecipient(null);
          setTimeout(() => {
            const input = payeeNameRef.current?.querySelector('input');
            if (input) input.focus();
          }, 0);
          break;
        case 'select-option':
          // eslint-disable-next-line no-case-declarations
          const selectedRecipient = mapCounterpartyToPaymentKeys(value);

          await setRecipient({ ...selectedRecipient });
          await setValues((prevValues) => ({
            ...prevValues,
            ...selectedRecipient,
            [newPaymentKeys.PAYEE_NAME]: { ...selectedRecipient },
          }));
          await setInternalTargetAccount(null);
          break;
        default:
          break;
      }
    },
    [setValues],
  );

  const onDestinationAccountChangeHandler = useCallback(
    async ({ option }) => {
      if (!option) return;
      await setInternalTargetAccount(option);
      await setValues((prevValues) => ({
        ...prevValues,
        [newPaymentKeys.PAYEE_NAME]: option[accountKeys.HOLDER_NAME],
        [newPaymentKeys.PAYEE_ACCOUNT_NUMBER]: option[accountKeys.NUMBER],
        [newPaymentKeys.PAYEE_SORT_CODE]: option[accountKeys.SORT_CODE],
        [newPaymentKeys.PAYEE_CURRENCY]: option[accountKeys.CURRENCY],
      }));
      await setRecipient(null);
    },
    [setValues],
  );

  const recipientFieldsPreviewOnCancelHandler = useCallback(
    async ({ previousValues }) => {
      const previousTransferType = previousValues[newPaymentKeys.TRANSFER_TYPE];

      if (
        previousTransferType === makePaymentFormTransferTypes.BETWEEN_ACCOUNTS
      ) {
        await onDestinationAccountChangeHandler({
          option: getAccountById(
            previousValues[newPaymentKeys.DESTINATION_ACCOUNT_ID],
            allAccounts,
          ),
        });
      }

      if (
        previousTransferType === makePaymentFormTransferTypes.RECIPIENT &&
        previousValues?.previousValuesToCache?.recipient &&
        !equals(recipient, previousValues.previousValuesToCache.recipient)
      ) {
        await setRecipient(previousValues.previousValuesToCache.recipient);
      }
    },
    [allAccounts, onDestinationAccountChangeHandler, recipient],
  );

  const recipientAutocompleteGetOptionSelected = useCallback(
    (option, value) =>
      option[paymentCounterpartyKeys.ID] === value[paymentCounterpartyKeys.ID],
    [],
  );

  const recipientAutocompleteFilterOptions = useCallback(
    (options) =>
      options.filter((option) => {
        if (option?.type === 'new') {
          return true;
        }

        return (
          option?.[paymentCounterpartyKeys.CURRENCY] === paymentCurrencyIso
        );
      }),
    [paymentCurrencyIso],
  );

  const expandNewCounterpartyFormFragment = useCallback(
    (inputValue) => (event) => {
      event.persist();
      const name = inputValue ?? event?.target?.value ?? '';

      setRecipient({
        [newPaymentKeys.PAYEE_NAME]: name,
      });
      setValues((prevValues) => ({
        ...prevValues,
        [newPaymentKeys.PAYEE_NAME]: name,
        [newPaymentKeys.PAYEE_CURRENCY]: paymentCurrencyIso,
        [newPaymentKeys.PAYEE_TYPE]: counterpartyTypes.PERSONAL,
        [newPaymentKeys.PAYEE_BANK_DETAILS_TYPE]:
          counterpartyBankDetailsTypes.ACCOUNT_NUMBER,
        [newPaymentKeys.PAYEE_FIRST_NAME]: '',
        [newPaymentKeys.PAYEE_LAST_NAME]: '',
        [newPaymentKeys.PAYEE_COMPANY_NAME]: '',
        [newPaymentKeys.PAYEE_ACCOUNT_NUMBER]: '',
        [newPaymentKeys.PAYEE_COUNTRY]: '',
        [newPaymentKeys.PAYEE_SORT_CODE]: '',
        [newPaymentKeys.PAYEE_IBAN]: '',
        [newPaymentKeys.PAYEE_CITY]: '',
        [newPaymentKeys.PAYEE_STREET]: '',
        [newPaymentKeys.PAYEE_BUILDING_NAME]: '',
        [newPaymentKeys.PAYEE_BUILDING_NUMBER]: '',
        [newPaymentKeys.PAYEE_POSTAL_CODE]: '',
        [newPaymentKeys.PAYEE_ID]: '',
      }));
      setTimeout(() => {
        const input = payeeNameRef.current?.querySelector('input');
        if (input) input.blur();
      }, 0);
    },
    [setValues, paymentCurrencyIso],
  );

  const recipientAutocompleteOptionsRenderer = useCallback(
    (option, { selected, inputValue }) => {
      if (!option) return null;
      if (option.type === 'new') {
        return (
          <Button
            fullWidth
            variant={buttonVariant.TEXT}
            color={buttonColor.PRIMARY}
            startIcon={<AddRounded />}
            className={classes.createNewCounterpartyButton}
            onClick={expandNewCounterpartyFormFragment(inputValue)}
          >
            Add a new counterparty
          </Button>
        );
      }
      return (
        <AvatarDataCell
          title={option[paymentCounterpartyKeys.NAME]}
          titleSuffix={option[paymentCounterpartyKeys.CURRENCY]}
          subtitle={
            option[paymentCounterpartyKeys.IBAN]
              ? ibanToStringFormatter(option[paymentCounterpartyKeys.IBAN])
              : `${
                  option[paymentCounterpartyKeys.ACCOUNT_NUMBER]
                }\t${formatSortCodeValue(
                  option[paymentCounterpartyKeys.SORT_CODE],
                )}`
          }
          selected={selected}
        />
      );
    },
    [classes.createNewCounterpartyButton, expandNewCounterpartyFormFragment],
  );

  const handleSourceAccountEditCancel = useCallback(
    ({ previousValues }) =>
      onSourceAccountChangeHandler({
        options: allAccounts,
        option: getAccountById(
          previousValues[newPaymentKeys.ACCOUNT_ID],
          allAccounts,
        ),
      }),
    [allAccounts, onSourceAccountChangeHandler],
  );

  const editModeInReview = useMemo(
    () =>
      formReview &&
      (Object.values(previewEditableFields).some(
        (fieldName) => fieldName === true,
      ) ||
        !formState.isValid),
    [formReview, formState.isValid, previewEditableFields],
  );

  const paymentBeneficiaryPreviewComponent = useMemo(
    () =>
      !!internalTargetAccount && !recipient ? (
        <AccountSelectOption
          account={internalTargetAccount}
          accountKeys={accountKeys}
          walletKeys={walletKeys}
          data-aio-id="targetAccountPreview"
          selected
        />
      ) : (
        !!recipient && (
          <AvatarDataCell
            title={recipient[newPaymentKeys.PAYEE_NAME]}
            titleProps={{
              variant: 'button',
            }}
            titleSuffix={
              recipient[newPaymentKeys.PAYEE_CURRENCY] ||
              formState.values[newPaymentKeys.PAYEE_CURRENCY]
            }
            subtitle={
              recipient[newPaymentKeys.PAYEE_IBAN] ||
              formState.values[newPaymentKeys.PAYEE_IBAN] ||
              `${
                recipient[newPaymentKeys.PAYEE_ACCOUNT_NUMBER] ||
                formState.values[newPaymentKeys.PAYEE_ACCOUNT_NUMBER]
              }\t${formatSortCodeValue(
                recipient[newPaymentKeys.PAYEE_SORT_CODE] ||
                  formState.values[newPaymentKeys.PAYEE_SORT_CODE],
              )}`
            }
            data-aio-id="targetCounterpartyPreview"
            selected
          />
        )
      ),
    [formState.values, internalTargetAccount, recipient],
  );

  const handleFormFieldPreviewEditSaveAction = useCallback(
    async ({ hasErrors }) => {
      if (hasErrors) return;

      const {
        isTransferBetweenOwnAccounts,
        values: parsedValues,
      } = parseRawFormValues({
        rawValues: values,
        userPartyId,
        isNewRecipient,
      });
      const shouldUpdateCounterpartyTypeFields =
        !isTransferBetweenOwnAccounts &&
        isKnownRecipient &&
        !isKnownRecipientWithFilledTypeFields;

      await onValidationComplete({
        values: parsedValues,
        rawValues: values,
        recipient,
        setRecipient,
        isNewRecipient,
        isPaymentCurrencyGBP,
        shouldUpdateCounterpartyTypeFields,
        postEditCheck: true,
        setFieldError,
        setSubmitting,
        mapCounterpartyToPaymentKeys,
        setValues,
      });
    },
    [
      isKnownRecipient,
      isKnownRecipientWithFilledTypeFields,
      isNewRecipient,
      isPaymentCurrencyGBP,
      onValidationComplete,
      recipient,
      setFieldError,
      setSubmitting,
      setValues,
      userPartyId,
      values,
    ],
  );

  useEffect(() => {
    if (formState.dirty) {
      formState.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentCurrencyIso, paymentFxRate]);

  useEffect(() => {
    if (!isPaymentCurrencyGBP) {
      transferTypeChangeHandler(
        null,
        makePaymentFormTransferTypes.RECIPIENT,
        false,
      );
      setRecipient(null);
      updatePaymentFxRate({
        quoteCurrency: GBP_CURRENCY,
        baseCurrency: paymentCurrencyIso,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentCurrencyGBP]);

  return (
    <form
      onSubmit={handleSubmit}
      onReset={handleReset}
      className={clsx(classes.wrapper, className)}
      id="newPaymentForm"
      key="newPaymentForm"
      noValidate
    >
      <Grid container alignItems="flex-start">
        <Grid item xs={12} className={classes.formSectionHeader}>
          <Typography variant="h3" gutterBottom>
            Sending from
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormFieldsPreview
            fieldsNames={[newPaymentKeys.ACCOUNT_ID]}
            formState={formState}
            className={classes.firstInput}
            preview={formReview}
            onToggle={() =>
              setPreviewEditableFields((prev) => ({
                ...prev,
                [newPaymentKeys.ACCOUNT_ID]: !prev[newPaymentKeys.ACCOUNT_ID],
              }))
            }
            onSave={handleFormFieldPreviewEditSaveAction}
            onCancel={handleSourceAccountEditCancel}
            previewComponent={
              sourceAccount && (
                <AccountSelectOption
                  account={sourceAccount}
                  accountKeys={accountKeys}
                  walletKeys={walletKeys}
                  data-aio-id="sourceAccountPreview"
                />
              )
            }
          >
            <AccountAutoCompleteDropdownInput
              {...getFieldProps(newPaymentKeys.ACCOUNT_ID)}
              formState={formState}
              onChange={onSourceAccountChangeHandler}
              label="Choose account"
              noOptionsText="You don't have any active accounts with any funds"
              defaultValue={hasAccountIdFromUrl ? accountId : undefined}
              getOptionDisabledFn={(option) =>
                option[accountKeys.TYPE] === accountTypes.MASTER ||
                option[accountKeys.STATUS] !== accountStatuses.ACTIVE ||
                option[accountKeys.CURRENT_BALANCE] <= 0
              }
              required
              autoFocus
            />
          </FormFieldsPreview>
        </Grid>

        <Grid item xs={12} className={classes.formSectionHeader}>
          <Typography variant="h3" gutterBottom>
            Payment details
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormFieldsPreview
            fieldsNames={[
              newPaymentKeys.PAYMENT_CURRENCY,
              newPaymentKeys.AMOUNT,
            ]}
            formState={formState}
            preview={formReview}
            label={newPaymentKeysLabelsMap.get(newPaymentKeys.AMOUNT)}
            onToggle={() =>
              setPreviewEditableFields((prev) => ({
                ...prev,
                [newPaymentKeys.AMOUNT]: !prev[newPaymentKeys.AMOUNT],
              }))
            }
            onSave={handleFormFieldPreviewEditSaveAction}
            previewComponent={
              formReview &&
              sourceAccount && (
                <PaymentAmountWithFees
                  value={parseFloat(formState.values[newPaymentKeys.AMOUNT])}
                  feeValue={paymentFee}
                  currencyIso={paymentCurrencyIso}
                  sourceCurrencyIso={sourceAccount[accountKeys.CURRENCY]}
                  data-aio-id="paymentAmountPreview"
                />
              )
            }
          >
            <div className={classes.groupedInputs}>
              <CurrencySelectField
                {...getFieldProps(newPaymentKeys.PAYMENT_CURRENCY)}
                formState={formState}
                currencies={[currency.GBP, currency.EUR, currency.USD]}
                onChange={(event) => setPaymentCurrencyIso(event.target.value)}
                required
                disabled={previewEditableFields[newPaymentKeys.AMOUNT]}
              />
              <MaskedControlledAmountField
                {...getFieldProps(newPaymentKeys.AMOUNT)}
                formState={formState}
                currencyIsoCode={
                  formState.values[newPaymentKeys.PAYMENT_CURRENCY]
                }
                required
              />
            </div>
          </FormFieldsPreview>
        </Grid>

        {!isPaymentCurrencyGBP &&
        paymentFxRate &&
        formState.values[newPaymentKeys.AMOUNT] &&
        !formState?.errors?.[newPaymentKeys.AMOUNT] ? (
          <Grid item xs={12}>
            <PaymentAmountFxRate
              sourceCurrencyIso={sourceAccount[accountKeys.CURRENCY]}
              currencyIso={formState.values[newPaymentKeys.PAYMENT_CURRENCY]}
              amount={formState.values[newPaymentKeys.AMOUNT]}
              feeValue={
                previewEditableFields[newPaymentKeys.AMOUNT] ? 0 : paymentFee
              }
              fxRate={paymentFxRate}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <FormFieldsPreview
            dense
            fieldsNames={[newPaymentKeys.DESCRIPTION]}
            formState={formState}
            preview={formReview}
            label={newPaymentKeysLabelsMap.get(newPaymentKeys.DESCRIPTION)}
            onToggle={() =>
              setPreviewEditableFields((prev) => ({
                ...prev,
                [newPaymentKeys.DESCRIPTION]: !prev[newPaymentKeys.DESCRIPTION],
              }))
            }
            onSave={handleFormFieldPreviewEditSaveAction}
            previewComponent={
              formReview && (
                <TextFieldPreview>
                  {formState.values[newPaymentKeys.DESCRIPTION]}
                </TextFieldPreview>
              )
            }
          >
            <ControlledTextField
              {...getFieldProps(newPaymentKeys.DESCRIPTION)}
              formState={formState}
              required
            />
          </FormFieldsPreview>
        </Grid>

        <Grid item xs={12} className={classes.formSectionHeader}>
          <Typography variant="h3" gutterBottom>
            Sending to
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormFieldsPreview
            fieldsNames={[
              newPaymentKeys.TRANSFER_TYPE,
              newPaymentKeys.PAYEE_NAME,
              newPaymentKeys.DESTINATION_ACCOUNT_ID,
              newPaymentKeys.PAYEE_SORT_CODE,
              newPaymentKeys.PAYEE_ACCOUNT_NUMBER,
            ]}
            formState={formState}
            preview={formReview}
            previousValuesToCache={{
              recipient,
              isKnownRecipient,
              isNewRecipient,
            }}
            onToggle={recipientFieldsPreviewToggleHandler}
            onCancel={recipientFieldsPreviewOnCancelHandler}
            onSave={handleFormFieldPreviewEditSaveAction}
            previewComponent={formReview && paymentBeneficiaryPreviewComponent}
          >
            <>
              <TabsHorizontalNavigation
                activeTab={formState.values[newPaymentKeys.TRANSFER_TYPE]}
                tabs={[
                  {
                    label: makePaymentFormTransferTypesLabelsMap.get(
                      makePaymentFormTransferTypes.RECIPIENT,
                    ),
                    value: makePaymentFormTransferTypes.RECIPIENT,
                  },
                  {
                    label: makePaymentFormTransferTypesLabelsMap.get(
                      makePaymentFormTransferTypes.BETWEEN_ACCOUNTS,
                    ),
                    value: makePaymentFormTransferTypes.BETWEEN_ACCOUNTS,
                    disabled: !isPaymentCurrencyGBP,
                  },
                ]}
                onChange={transferTypeChangeHandler}
                className={classes.transferTypeTabs}
              />

              {formState.values[newPaymentKeys.TRANSFER_TYPE] ===
                makePaymentFormTransferTypes.RECIPIENT && (
                <AutocompleteControlledTextField
                  {...getFieldProps(newPaymentKeys.PAYEE_NAME)}
                  label="Choose counterparty"
                  noOptionsText="Start typing to choose or add a new counterparty..."
                  required={
                    formState.values[newPaymentKeys.TRANSFER_TYPE] ===
                    makePaymentFormTransferTypes.RECIPIENT
                  }
                  multiple
                  value={[formState.values[newPaymentKeys.PAYEE_NAME]]}
                  ref={payeeNameRef}
                  limitTags={1}
                  optionIdKey={paymentCounterpartyKeys.ID}
                  getLimitTagsText={() => null}
                  url={`/api/core-banking/parties/${userPartyId}/payees?currency=${paymentCurrencyIso}`}
                  formState={formState}
                  queryKey="filter"
                  className={classes.firstInput}
                  ListboxProps={{
                    classes: {
                      firstItem: 'first',
                      item: classes.autocompleteOption,
                      additionalItem: classes.additionalOption,
                      firstAdditionalItem: classes.firstAdditionalItem,
                    },
                  }}
                  additionalOptions={
                    isKnownRecipient
                      ? null
                      : counterpartyAutocompleteAdditionalOptions
                  }
                  onSelect={recipientAutocompleteOnSelectHandler}
                  getOptionSelected={recipientAutocompleteGetOptionSelected}
                  filterOptions={recipientAutocompleteFilterOptions}
                  renderTags={recipientAutocompleteTagsRenderer}
                  renderOption={recipientAutocompleteOptionsRenderer}
                  onEnterKeyDown={recipientAutocompleteHandleEnterKeyDown(
                    expandNewCounterpartyFormFragment(null),
                  )}
                />
              )}

              {formState.values[newPaymentKeys.TRANSFER_TYPE] ===
                makePaymentFormTransferTypes.BETWEEN_ACCOUNTS && (
                <AccountAutoCompleteDropdownInput
                  {...getFieldProps(newPaymentKeys.DESTINATION_ACCOUNT_ID)}
                  formState={formState}
                  className={classes.firstInput}
                  onChange={onDestinationAccountChangeHandler}
                  label="Choose account"
                  noOptionsText="Please select account you're paying from first"
                  disabled={!sourceAccount}
                  required={
                    formState.values[newPaymentKeys.TRANSFER_TYPE] ===
                    makePaymentFormTransferTypes.TRANSFER_TYPE
                  }
                  shiftMastersHeaderBy={
                    !isNil(formState.values?.[newPaymentKeys.ACCOUNT_ID])
                      ? 1
                      : 0
                  }
                  filterOptions={(option) =>
                    formState.values?.[newPaymentKeys.ACCOUNT_ID] !==
                    option[accountKeys.ID]
                  }
                />
              )}

              <div
                className={clsx({
                  [classes.hidden]:
                    formState.values[newPaymentKeys.TRANSFER_TYPE] ===
                      makePaymentFormTransferTypes.BETWEEN_ACCOUNTS ||
                    isKnownRecipientWithFilledTypeFields ||
                    !recipient,
                })}
              >
                <Grid item xs={12} className={classes.sectionMargin}>
                  <Typography variant="body2" className={classes.radioTitle}>
                    {newPaymentKeysLabelsMap.get(newPaymentKeys.PAYEE_TYPE)}
                  </Typography>
                </Grid>

                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label={newPaymentKeysLabelsMap.get(
                      newPaymentKeys.PAYEE_TYPE,
                    )}
                    {...getFieldProps(newPaymentKeys.PAYEE_TYPE)}
                    value={formState.values[newPaymentKeys.PAYEE_TYPE]}
                    onBlur={formState.handleBlur}
                    onChange={counterpartyTypeChangeHandler}
                    className={classes.horizontalRadioGroup}
                  >
                    <FormControlLabel
                      classes={{
                        root: classes.radioLabel,
                        label: classes.radioLabelText,
                      }}
                      value={counterpartyTypes.PERSONAL}
                      control={<RadioStyled />}
                      label={counterpartyTypesLabelsMap.get(
                        counterpartyTypes.PERSONAL,
                      )}
                    />
                    <FormControlLabel
                      classes={{
                        root: classes.radioLabel,
                        label: classes.radioLabelText,
                      }}
                      value={counterpartyTypes.BUSINESS}
                      control={<RadioStyled />}
                      label={counterpartyTypesLabelsMap.get(
                        counterpartyTypes.BUSINESS,
                      )}
                    />
                  </RadioGroup>
                </FormControl>

                {formState.values[newPaymentKeys.PAYEE_TYPE] ===
                counterpartyTypes.BUSINESS ? (
                  <ControlledTextField
                    {...getFieldProps(newPaymentKeys.PAYEE_COMPANY_NAME)}
                    formState={formState}
                    required
                  />
                ) : (
                  <div className={classes.groupedNameInputs}>
                    <ControlledTextField
                      {...getFieldProps(newPaymentKeys.PAYEE_FIRST_NAME)}
                      formState={formState}
                      required
                    />
                    <ControlledTextField
                      {...getFieldProps(newPaymentKeys.PAYEE_LAST_NAME)}
                      formState={formState}
                      required
                    />
                  </div>
                )}
              </div>

              <div
                className={clsx({
                  [classes.hidden]:
                    formState.values[newPaymentKeys.TRANSFER_TYPE] ===
                      makePaymentFormTransferTypes.BETWEEN_ACCOUNTS ||
                    isKnownRecipient ||
                    !recipient,
                })}
              >
                {isPaymentCurrencyGBP ? (
                  <>
                    <Grid item xs={12} className={classes.sectionMargin}>
                      <Typography
                        variant="body2"
                        className={classes.radioTitle}
                      >
                        {newPaymentKeysLabelsMap.get(
                          newPaymentKeys.PAYEE_BANK_DETAILS_TYPE,
                        )}
                      </Typography>
                    </Grid>

                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label={newPaymentKeysLabelsMap.get(
                          newPaymentKeys.PAYEE_BANK_DETAILS_TYPE,
                        )}
                        {...getFieldProps(
                          newPaymentKeys.PAYEE_BANK_DETAILS_TYPE,
                        )}
                        value={
                          formState.values[
                            newPaymentKeys.PAYEE_BANK_DETAILS_TYPE
                          ]
                        }
                        onBlur={formState.handleBlur}
                        onChange={counterpartyBankDetailsTypeChangeHandler}
                        className={classes.horizontalRadioGroup}
                      >
                        <FormControlLabel
                          classes={{
                            root: classes.radioLabel,
                            label: classes.radioLabelText,
                          }}
                          value={counterpartyBankDetailsTypes.ACCOUNT_NUMBER}
                          control={<RadioStyled />}
                          label={counterpartyBankDetailsTypesLabelsMap.get(
                            counterpartyBankDetailsTypes.ACCOUNT_NUMBER,
                          )}
                        />
                        <FormControlLabel
                          classes={{
                            root: classes.radioLabel,
                            label: classes.radioLabelText,
                          }}
                          value={counterpartyBankDetailsTypes.IBAN}
                          control={<RadioStyled />}
                          label={counterpartyBankDetailsTypesLabelsMap.get(
                            counterpartyBankDetailsTypes.IBAN,
                          )}
                        />
                      </RadioGroup>
                    </FormControl>
                  </>
                ) : null}

                {!isPaymentCurrencyGBP ||
                formState.values[newPaymentKeys.PAYEE_BANK_DETAILS_TYPE] ===
                  counterpartyBankDetailsTypes.IBAN ? (
                  <div>
                    <MaskedControlledTextField
                      {...getFieldProps(newPaymentKeys.PAYEE_IBAN)}
                      formState={formState}
                      disabled={isKnownRecipient}
                      mask={ibanInputMaskPatternAnycase}
                      maskOptions={{
                        pipe: (conformedValue) => conformedValue.toUpperCase(),
                      }}
                      required={isNewRecipient || isKnownRecipient}
                    />

                    <CountryAutocompleteControlledTextField
                      {...getFieldProps(newPaymentKeys.PAYEE_COUNTRY)}
                      formState={formState}
                      required
                    />

                    <div className={classes.groupedAddressInputs}>
                      <ControlledTextField
                        {...getFieldProps(newPaymentKeys.PAYEE_CITY)}
                        formState={formState}
                        required
                      />
                      <ControlledTextField
                        {...getFieldProps(newPaymentKeys.PAYEE_POSTAL_CODE)}
                        formState={formState}
                        required
                      />
                    </div>
                    <ControlledTextField
                      {...getFieldProps(newPaymentKeys.PAYEE_STREET)}
                      formState={formState}
                      required={
                        !formState.values?.[newPaymentKeys.PAYEE_BUILDING_NAME]
                      }
                    />
                    <div className={classes.groupedAddressInputs}>
                      <ControlledTextField
                        {...getFieldProps(newPaymentKeys.PAYEE_BUILDING_NAME)}
                        formState={formState}
                        required={
                          !formState.values?.[newPaymentKeys.PAYEE_STREET]
                        }
                      />
                      <ControlledTextField
                        {...getFieldProps(newPaymentKeys.PAYEE_BUILDING_NUMBER)}
                        formState={formState}
                        required={
                          !formState.values?.[
                            newPaymentKeys.PAYEE_BUILDING_NAME
                          ]
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className={classes.groupedInputs}>
                    <MaskedControlledTextField
                      {...getFieldProps(newPaymentKeys.PAYEE_SORT_CODE)}
                      formState={formState}
                      disabled={isKnownRecipient}
                      mask={sortCodeInputMaskPattern}
                      required={isNewRecipient || isKnownRecipient}
                    />
                    <MaskedControlledTextField
                      {...getFieldProps(newPaymentKeys.PAYEE_ACCOUNT_NUMBER)}
                      formState={formState}
                      disabled={isKnownRecipient}
                      mask={createOnlyDigitsTextInputMaskPattern(8)}
                      required={isNewRecipient || isKnownRecipient}
                    />
                  </div>
                )}
              </div>
            </>
          </FormFieldsPreview>
        </Grid>
      </Grid>

      <FormControls
        className={classes.formControls}
        formState={formState}
        disabled={isSubmitting || isValidating || editModeInReview || disabled}
        submitText={submitText}
        onCancel={closeHandler}
        align="center"
        column={false}
        buttonsProps={{
          form: 'newPaymentForm',
          size: buttonSize.LARGE,
        }}
        inverseButtons
        submitButtonProps={{
          'data-aio-id': 'makePaymentContinueButton',
        }}
        resetButtonProps={{
          'data-aio-id': 'makePaymentCancelButton',
        }}
      />
    </form>
  );
}

MakePaymentForm.propTypes = {
  disabled: bool,
  onValidationComplete: func.isRequired,
  onReset: func,
  closeHandler: func,
  className: string,
  formReview: bool,
  paymentFee: number,
  updatePaymentFxRate: func.isRequired,
  paymentFxRate: number,
  submitText: string,
};

MakePaymentForm.defaultProps = {
  disabled: false,
  onReset: null,
  closeHandler: undefined,
  className: '',
  formReview: false,
  paymentFee: 0,
  paymentFxRate: 0,
  submitText: 'Proceed',
};
