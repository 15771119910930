/* eslint-disable react/prop-types */
import React from 'react';
import { isNil } from 'rambda';
import { Typography } from '@material-ui/core';
import {
  AvatarDataCell,
  AmountTableCell,
  AccountAliasTableCell,
  SearchHighlighterTableCell,
} from '@fondy/tables';
import { accountTypesLabelsMap } from '@fondy/enums';
import { formatCurrencyValue, formatTimeZonedDate } from '@fondy/utils';
import FormattedDateTime from '../FormattedDateTime';
import {
  transactionKeys,
  isTransactionBetweenOwnAccounts,
  transactionKeysLabelsMap,
} from '../../../utils';
import { AccountByIdTableCell } from '../tableCells';

const getAmountValuePrefix = (transaction) =>
  transaction[transactionKeys.ACCOUNT_OPENING_BALANCE] >
  transaction[transactionKeys.ACCOUNT_CLOSING_BALANCE]
    ? '- '
    : '+ ';

const renderPdfTransactionNameAndReference = ({ row }) => {
  const renderAsAccount = isTransactionBetweenOwnAccounts(row);

  if (renderAsAccount) {
    return `${
      row[transactionKeys.COUNTERPARTY_ACCOUNT_ALIAS]
    } (${accountTypesLabelsMap.get(
      row[transactionKeys.COUNTERPARTY_ACCOUNT_TYPE],
    )})\n${row[transactionKeys.TRANSACTION_REFERENCE]}`;
  }

  return `${row[transactionKeys.COUNTERPARTY_NAME]}\n${
    row[transactionKeys.TRANSACTION_REFERENCE]
  }`;
};

const renderAccountOrCounterparty = ({ row, searchQuery }) => {
  const renderAsAccount = isTransactionBetweenOwnAccounts(row);
  if (renderAsAccount) {
    return (
      <AccountAliasTableCell
        small
        searchQueryHighlight={searchQuery}
        currencyIso={row[transactionKeys.COUNTERPARTY_ACCOUNT_CURRENCY]}
        accountAlias={row[transactionKeys.COUNTERPARTY_ACCOUNT_ALIAS]}
        accountType={row[transactionKeys.COUNTERPARTY_ACCOUNT_TYPE]}
      />
    );
  }

  return (
    <AvatarDataCell
      searchQueryHighlight={searchQuery}
      title={row[transactionKeys.COUNTERPARTY_NAME]}
      data-aio-id="transactionNameAndReferenceCell"
      avatarSize="small"
    />
  );
};

const transactionsTableColumns = [
  {
    id: transactionKeys.CREATED_AT,
    numeric: false,
    disablePadding: false,
    label: transactionKeysLabelsMap.get(transactionKeys.CREATED_AT),
    style: { width: 100 },
    render: ({ value }) => (
      <>
        <Typography variant="body2">
          <FormattedDateTime utcIsoDate={value} />
        </Typography>
        <Typography variant="subtitle2">
          <FormattedDateTime utcIsoDate={value} dateFormat="HH:mm" />
        </Typography>
      </>
    ),
  },
  {
    id: transactionKeys.ACCOUNT_ID,
    numeric: false,
    disablePadding: false,
    label: 'Paid from',
    style: { width: '25%' },
    render: ({ row, searchQuery }) => {
      const isDebit =
        row[transactionKeys.ACCOUNT_OPENING_BALANCE] >
        row[transactionKeys.ACCOUNT_CLOSING_BALANCE];

      if (isDebit) {
        return (
          <AccountByIdTableCell
            id={row[transactionKeys.ACCOUNT_ID]}
            searchQueryHighlight={searchQuery}
            data-aio-id="accountAliasDateCell"
          />
        );
      }

      return renderAccountOrCounterparty({ row, searchQuery });
    },
  },
  {
    id: transactionKeys.COUNTERPARTY_NAME,
    numeric: false,
    disablePadding: false,
    label: 'Paid to',
    style: { width: '25%' },
    render: ({ row, searchQuery }) => {
      const isDebit =
        row[transactionKeys.ACCOUNT_OPENING_BALANCE] >
        row[transactionKeys.ACCOUNT_CLOSING_BALANCE];

      if (isDebit) {
        return renderAccountOrCounterparty({ row, searchQuery });
      }

      return (
        <AccountByIdTableCell
          id={row[transactionKeys.ACCOUNT_ID]}
          searchQueryHighlight={searchQuery}
          data-aio-id="accountAliasDateCell"
        />
      );
    },
    renderPdf: renderPdfTransactionNameAndReference,
  },
  {
    id: transactionKeys.TRANSACTION_REFERENCE,
    numeric: false,
    disablePadding: false,
    label: transactionKeysLabelsMap.get(transactionKeys.TRANSACTION_REFERENCE),
    style: { width: '25%' },
    render: ({ value, searchQuery }) => (
      <SearchHighlighterTableCell value={value} search={searchQuery} />
    ),
  },
  {
    id: transactionKeys.TRANSACTION_AMOUNT,
    numeric: true,
    disablePadding: false,
    label: transactionKeysLabelsMap.get(transactionKeys.TRANSACTION_AMOUNT),
    align: 'right',
    style: { width: 120 },
    render: ({ value, row }) => (
      <>
        {row[transactionKeys.TRANSACTION_ORIGINAL_CURRENCY] ? (
          <AmountTableCell
            value={row[transactionKeys.TRANSACTION_ORIGINAL_AMOUNT]}
            currencyIso={row[transactionKeys.TRANSACTION_ORIGINAL_CURRENCY]}
            variant="subtitle2"
          />
        ) : null}
        <AmountTableCell
          value={value}
          valuePrefix={getAmountValuePrefix(row)}
          currencyIso={row[transactionKeys.TRANSACTION_CURRENCY]}
          justifyContent="flex-end"
          data-aio-id="transactionAmountCell"
          variant="body2"
        />
      </>
    ),
  },
];

export const mobileTransactionsTableColumns = [
  {
    id: transactionKeys.COUNTERPARTY_NAME,
    numeric: false,
    disablePadding: false,
    padding: 'none',
    label: 'Name & Description',
    render: ({ row, searchQuery }) => (
      <>
        <div>{renderAccountOrCounterparty({ row, searchQuery })}</div>
        <Typography variant="body2">
          <SearchHighlighterTableCell
            value={row[transactionKeys.TRANSACTION_REFERENCE]}
            search={searchQuery}
          />
        </Typography>
        <Typography variant="subtitle2">
          <FormattedDateTime
            utcIsoDate={row[transactionKeys.CREATED_AT]}
            dateFormat="dd MMM yyyy HH:mm"
          />
        </Typography>
      </>
    ),
    renderPdf: renderPdfTransactionNameAndReference,
  },
  {
    id: transactionKeys.TRANSACTION_AMOUNT,
    numeric: true,
    disablePadding: false,
    label: transactionKeysLabelsMap.get(transactionKeys.TRANSACTION_AMOUNT),
    align: 'right',
    padding: 'none',
    render: ({ value, row }) => (
      <>
        {row[transactionKeys.TRANSACTION_ORIGINAL_CURRENCY] ? (
          <AmountTableCell
            value={row[transactionKeys.TRANSACTION_ORIGINAL_AMOUNT]}
            currencyIso={row[transactionKeys.TRANSACTION_ORIGINAL_CURRENCY]}
            variant="subtitle2"
          />
        ) : null}
        <AmountTableCell
          value={value}
          valuePrefix={getAmountValuePrefix(row)}
          currencyIso={row[transactionKeys.TRANSACTION_CURRENCY]}
          justifyContent="flex-end"
          variant="body2"
          valueWrapperFn={(formattedValue) => (
            <>
              <span>{formattedValue}</span>
              <div>
                <small>
                  {formatCurrencyValue(
                    row[transactionKeys.ACCOUNT_CLOSING_BALANCE],
                    {
                      currencyIso: row[transactionKeys.TRANSACTION_CURRENCY],
                    },
                  )}
                </small>
              </div>
            </>
          )}
        />
      </>
    ),
  },
];

export const singleAccountTransactionsTableColumnsBase = ({
  injectSplitAccountTransactionType = null,
  injectTransactionReference = true,
} = {}) => [
  {
    id: transactionKeys.COUNTERPARTY_NAME,
    numeric: false,
    disablePadding: false,
    label: 'Paid to/from',
    style: { width: '30%' },
    render: ({ row, searchQuery }) =>
      renderAccountOrCounterparty({ row, searchQuery }),
    renderPdf: renderPdfTransactionNameAndReference,
  },
  ...(injectTransactionReference
    ? [
        {
          id: transactionKeys.TRANSACTION_REFERENCE,
          numeric: false,
          disablePadding: false,
          label: transactionKeysLabelsMap.get(
            transactionKeys.TRANSACTION_REFERENCE,
          ),
          style: { width: '30%' },
          render: ({ value, searchQuery }) => (
            <SearchHighlighterTableCell value={value} search={searchQuery} />
          ),
        },
      ]
    : []),
  {
    id: transactionKeys.TRANSACTION_AMOUNT,
    numeric: true,
    disablePadding: false,
    label: transactionKeysLabelsMap.get(transactionKeys.TRANSACTION_AMOUNT),
    align: 'right',
    style: { width: 120 },
    render: ({ value, row }) => (
      <>
        {row[transactionKeys.TRANSACTION_ORIGINAL_CURRENCY] ? (
          <AmountTableCell
            value={row[transactionKeys.TRANSACTION_ORIGINAL_AMOUNT]}
            currencyIso={row[transactionKeys.TRANSACTION_ORIGINAL_CURRENCY]}
            variant="subtitle2"
          />
        ) : null}
        <AmountTableCell
          value={value}
          valuePrefix={getAmountValuePrefix(row)}
          currencyIso={row[transactionKeys.TRANSACTION_CURRENCY]}
          justifyContent="flex-end"
          variant="body2"
        />
      </>
    ),
    renderPdf: ({ value, row }) =>
      `${getAmountValuePrefix(row)} ${formatCurrencyValue(value, {
        currencyIso: row[transactionKeys.TRANSACTION_CURRENCY],
      })}`,
  },
  ...(!!injectSplitAccountTransactionType &&
  !isNil(injectSplitAccountTransactionType)
    ? [injectSplitAccountTransactionType]
    : []),
  {
    id: transactionKeys.ACCOUNT_CLOSING_BALANCE,
    numeric: true,
    disablePadding: false,
    label: 'Balance',
    align: 'right',
    style: { width: 120 },
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[transactionKeys.TRANSACTION_CURRENCY]}
        justifyContent="flex-end"
        variant="body2"
      />
    ),
    renderPdf: ({ value, row }) =>
      formatCurrencyValue(value, {
        currencyIso: row[transactionKeys.TRANSACTION_CURRENCY],
      }),
  },
];

export const singleAccountTransactionsTableColumns = [
  {
    id: transactionKeys.CREATED_AT,
    numeric: false,
    disablePadding: false,
    label: transactionKeysLabelsMap.get(transactionKeys.CREATED_AT),
    style: { width: 110 },
    render: ({ value }) => (
      <>
        <Typography variant="body2">
          <FormattedDateTime utcIsoDate={value} />
        </Typography>
        <Typography variant="subtitle2">
          <FormattedDateTime utcIsoDate={value} dateFormat="HH:mm" />
        </Typography>
      </>
    ),
  },
  ...singleAccountTransactionsTableColumnsBase(),
];

export const singleAccountTransactionsPdfTableColumns = (
  legalEntityTimezone,
) => [
  {
    id: transactionKeys.CREATED_AT,
    numeric: false,
    disablePadding: false,
    label: 'Date',
    render: ({ value }) => <FormattedDateTime utcIsoDate={value} />,
    renderPdf: ({ value }) => formatTimeZonedDate(value, legalEntityTimezone),
  },
  ...singleAccountTransactionsTableColumnsBase({
    injectTransactionReference: false,
  }),
];

export default transactionsTableColumns;
