import React, { useMemo } from 'react';
import { arrayOf, objectOf, any, func, node, bool, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  AmountTableCell,
  CurrencyTableCell,
  StatusTableCell,
  SearchHighlighterTableCell,
} from '@fondy/tables';
import {
  accountTypes,
  accountTypesLabelsMap,
  accountStatusesLabels,
} from '@fondy/enums';
import MasterAccountWallets from './MasterAccountWallets';
import { accountKeys } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  accountAlias: {
    marginTop: theme.spacing(3),
  },
  cardFullHeight: {
    height: '100%',
  },
  emptyGrid: {
    ...theme.typography.button,
    justifyContent: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
}));

const AccountsGrid = ({
  accounts,
  onAccountClick,
  walletsSelectorFn,
  emptyComponent,
  isFilterActive,
  searchQuery,
}) => {
  const classes = useStyles();

  const isEmpty = useMemo(() => accounts.length === 0, [accounts]);

  return (
    <Grid
      container
      spacing={2}
      className={clsx({
        [classes.emptyGrid]: isEmpty,
      })}
    >
      {isEmpty
        ? emptyComponent
        : accounts.map((account) => (
            <Grid
              item
              key={account[accountKeys.ID]}
              xs={12}
              sm={6}
              lg={4}
              xl={3}
            >
              <Card variant="outlined" className={classes.cardFullHeight}>
                <CardActionArea
                  onClick={(event) => {
                    const isMasterWalletsClick = event.target?.closest(
                      '[role="tooltip"]',
                    );

                    if (!isMasterWalletsClick) {
                      onAccountClick(event, account);
                    }
                  }}
                  className={classes.cardFullHeight}
                >
                  <CardContent className={classes.cardFullHeight}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <CurrencyTableCell
                          value={account[accountKeys.CURRENCY]}
                          hideLabel
                        />
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <StatusTableCell
                              status={account[accountKeys.TYPE]}
                              labelsMap={accountTypesLabelsMap}
                            />
                          </Grid>
                          <Grid item>
                            <StatusTableCell
                              status={account[accountKeys.STATUS]}
                              labelsMap={accountStatusesLabels}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Typography className={classes.accountAlias} noWrap>
                      <SearchHighlighterTableCell
                        value={account[accountKeys.ALIAS]}
                        search={searchQuery}
                      />
                    </Typography>
                    <AmountTableCell
                      value={account[accountKeys.CURRENT_BALANCE]}
                      currencyIso={account[accountKeys.CURRENCY]}
                      variant="h2"
                    />

                    {account[accountKeys.TYPE] === accountTypes.MASTER ? (
                      <MasterAccountWallets
                        walletsSelectorFn={walletsSelectorFn(
                          account[accountKeys.ID],
                        )}
                        totalWalletsCount={account?.wallets.length ?? 0}
                        onAccountClick={onAccountClick}
                        isFilterActive={isFilterActive}
                        searchQuery={searchQuery}
                      />
                    ) : null}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
    </Grid>
  );
};

AccountsGrid.propTypes = {
  // eslint-disable-next-line react/require-default-props
  accounts: arrayOf(objectOf(any)).isRequired,
  onAccountClick: func.isRequired,
  walletsSelectorFn: func.isRequired,
  emptyComponent: node.isRequired,
  isFilterActive: bool.isRequired,
  searchQuery: string,
};

AccountsGrid.defaultProps = {
  searchQuery: null,
};

export default AccountsGrid;
