export const splitAccountsIdsKeys = Object.freeze({
  ACCESSOR_CUSTOMER_ID: 'accessorPartyId',
  SPLIT_ACCOUNT_ID: 'splitAccountRef',
  DEFAULT_WALLET_REF: 'defaultWalletRef',
  STATUS: 'status',
});

export const splitAccountsIdsKeysLabelsMap = new Map([
  [splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID, 'Customer ID'],
  [splitAccountsIdsKeys.SPLIT_ACCOUNT_ID, 'Split Account ID'],
  [splitAccountsIdsKeys.DEFAULT_WALLET_REF, 'Default Wallet ID'],
  [splitAccountsIdsKeys.STATUS, 'Status'],
]);
