export const counterpartiesPageModalStates = Object.freeze({
  HIDDEN: 1,
  EDIT: 2,
  ADD: 3,
  DELETE: 4,
  ERROR: 5,
  DUPLICATE: 6,
});

export const counterpartiesPageModalStateToVisibilityMap = new Map([
  [counterpartiesPageModalStates.HIDDEN, false],
  [counterpartiesPageModalStates.EDIT, true],
  [counterpartiesPageModalStates.ADD, true],
  [counterpartiesPageModalStates.DELETE, true],
  [counterpartiesPageModalStates.ERROR, true],
  [counterpartiesPageModalStates.DUPLICATE, true],
]);

export const counterpartiesPageModalTitlesMap = new Map([
  [counterpartiesPageModalStates.EDIT, 'Counterparty details'],
  [counterpartiesPageModalStates.ADD, 'Create a new counterparty'],
  [counterpartiesPageModalStates.DELETE, 'Delete counterparty?'],
]);
