import { MaterialDesignContent } from 'notistack';
import { styled } from '@material-ui/core/styles';
import { defaultThemeColors } from '@fondy/themes';

const NotistackStyled = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent': {
    boxShadow: '0px 10px 40px 0px rgba(57, 5, 139, 0.10)',
    fontSize: 16,
    padding: theme.spacing(1, 4),
    borderRadius: theme.spacing(1.5),
    '& svg': {
      marginInlineEnd: theme.spacing(1.5),
    },
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: defaultThemeColors.PRIMARY_LIGHTEST,
    color: defaultThemeColors.PRIMARY_DARKEST,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: defaultThemeColors.WARNING,
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: defaultThemeColors.SECONDARY,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: defaultThemeColors.ACCENT,
  },
}));

export default NotistackStyled;
