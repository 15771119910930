export const accountsPageTabs = Object.freeze({
  BALANCES: 0,
  TRANSACTIONS: 1,
  WALLETS: 2,
});

export const accountsPageTabsLabelsMap = new Map([
  [accountsPageTabs.BALANCES, 'Balances'],
  [accountsPageTabs.TRANSACTIONS, 'Transactions'],
  [accountsPageTabs.WALLETS, 'Wallets'],
]);

export const accountsPageTabsRoutingMap = new Map([
  [accountsPageTabs.BALANCES, '/accounts'],
  [accountsPageTabs.TRANSACTIONS, '/accounts/transactions'],
]);
