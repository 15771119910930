import { currency } from '@fondy/enums';
import { formatCurrencyValue } from '@fondy/utils';

export const tableAmountFilter = Object.freeze({
  '0-1000': { min: 0, max: 1000 },
  '1000-10000': { min: 1000, max: 10000 },
  '10000-100000': { min: 10000, max: 100000 },
  '100000-1000000': { min: 100000, max: 1000000 },
});

export const tableAmountFilterLabelGetter = ({ value: key }) =>
  `${formatCurrencyValue(tableAmountFilter[key].min, {
    currencyId: currency.GBP,
    showSymbol: false,
  })} - ${formatCurrencyValue(tableAmountFilter[key].max, {
    currencyId: currency.GBP,
    showSymbol: false,
  })}`;
