import { signupKeysLabelsMap } from '@fondy/enums';
import {
  partyDetailsKeysLabelsMap,
  partyRegistrationKeysLabelsMap,
} from '../../../../utils';

const combinedSoleTraderLabelsMap = new Map([
  ...signupKeysLabelsMap,
  ...partyRegistrationKeysLabelsMap,
  ...partyDetailsKeysLabelsMap,
]);

export default combinedSoleTraderLabelsMap;
