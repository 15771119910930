import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { LoginLayout } from '../../layouts';

function SplashScreenPage() {
  return (
    <LoginLayout centered transparent>
      <CircularProgress />
    </LoginLayout>
  );
}

export default SplashScreenPage;
