import { defaultPageMargins } from '@fondy/pdf';

const pdfFooterCreator = async (
  pdfDocument,
  {
    margin = defaultPageMargins,
    theme,
    text = 'Fondy Ltd, a company registered in England with company number 10063984 is authorized and regulated by the Financial Conduct Authority as an Electronic Money Institution. Firm Reference Number: 900705.',
  },
) => {
  if (!pdfDocument) return;
  const pageWidth = await pdfDocument.getPageWidth();
  const pageHeight = await pdfDocument.getPageHeight();
  const { fontName } = await pdfDocument.getFont();
  await pdfDocument.setFont(fontName, 'italic');
  await pdfDocument.setFontSize(10);
  if (theme) await pdfDocument.setTextColor(theme.palette.text.secondary);
  await pdfDocument.text(text, margin.left, pageHeight - margin.bottom, {
    maxWidth: pageWidth - (margin.left + margin.right + 50),
  });
};

export default pdfFooterCreator;
