import React, { useCallback, useMemo, useState } from 'react';
import { Grid, SvgIcon, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { stringify } from 'query-string';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { GridWidget } from '@fondy/data-display';
import { ConnectedFilteredTable } from '@fondy/tables';
import { Button, buttonColor, buttonSize, buttonVariant } from '@fondy/buttons';
import { orderTypes } from '@fondy/enums';
import { ChevronRightIcon } from '@fondy/icons';
import {
  splitAccountWalletKeys,
  splitAccountsIdsKeys,
  widgetsTimeRangesHistoric,
  widgetsTimeRanges,
  getUrlParamsFromTimeRange,
} from '../../../../utils';
import { splitAccountOverviewWalletsTableColumns } from '../../../atoms/tableColumns';
import { stateSelectSplitAccountsIds } from '../../../../redux';
import { BlurredComponent, WidgetOptionsDropdown } from '../../../atoms';
import { walletsMockedData } from '../../../pages/SplitAccountDashboardPage/mock';

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    minHeight: 70,
    width: 'inherit',
    [theme.breakpoints.up('sm')]: {
      minHeight: 190,
    },
  },
  accountsTableHeader: {
    [theme.breakpoints.down('xs')]: {
      borderTop: theme.border.thin,
    },
  },
  accountsTableTitle: {
    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
    },
  },
}));

const SplitAccountsSummaryWidget = () => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const history = useHistory();
  const { splitAccountsIds } = useSelector(stateSelectSplitAccountsIds);

  const [hasWallets, setHasWallets] = useState(true);
  const [lastVisitedDate, setLastVisitedDate] = useState(new Date());
  const [widgetState, setWidgetState] = useState(widgetsTimeRanges.LAST_WEEK);

  const accessorId =
    splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID];
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });

  const handleCellClick = useCallback(
    (_, row) =>
      history.push(
        `/split-account/wallets/${row[splitAccountWalletKeys.ID]}/transactions`,
      ),
    [history],
  );

  const handleWidgetChange = useCallback(
    ({ item }) => setWidgetState(item),
    [],
  );

  const widgetUrlParams = useMemo(
    () => ({
      startDate: getUrlParamsFromTimeRange(widgetState, lastVisitedDate),
      endDate: lastVisitedDate.toISOString(),
    }),
    [lastVisitedDate, widgetState],
  );

  const walletsDataMapper = (data) => {
    const isEmptyResponse = data.length === 0;

    setTimeout(() => {
      setHasWallets(() => !isEmptyResponse);
    }, 0);

    return isEmptyResponse ? walletsMockedData.wallets : data;
  };

  const spacing = 0;

  return (
    <GridWidget
      title="Top Wallets"
      spacing={spacing}
      grid={{ sm: 12, md: 6 }}
      classes={{
        header: classes.accountsTableHeader,
        title: classes.accountsTableTitle,
      }}
      headerActions={
        hasWallets ? (
          <WidgetOptionsDropdown
            options={widgetsTimeRangesHistoric}
            onChange={handleWidgetChange}
          />
        ) : null
      }
      footer={
        !hasWallets ? null : (
          <Button
            fullWidth={isMobile}
            to="/split-account/wallets"
            color={buttonColor.PRIMARY}
            variant={buttonVariant.TEXT}
            size={buttonSize.MEDIUM}
            endIcon={
              <SvgIcon
                component={ChevronRightIcon}
                style={{ fontSize: 16 }}
                color="primary"
              />
            }
          >
            View all wallets
          </Button>
        )
      }
    >
      <Grid item className={classes.tableWrapper}>
        <BlurredComponent
          blur={!hasWallets}
          message="Here will be displayed the statistics for the wallets"
        >
          <ConnectedFilteredTable
            disableHeader
            disableEmptyComponentPadding
            maxRows={5}
            defaultOrderBy={splitAccountWalletKeys.AVAILABLE_AMOUNT}
            defaultOrderDirection={orderTypes.DESCENDING}
            rowId={splitAccountWalletKeys.ID}
            url={`/api/core-banking/splitaccount/${accessorId}/topwallets?${stringify(
              widgetUrlParams,
            )}`}
            dependencies={[accessorId, widgetUrlParams]}
            loadingRows={5}
            columns={splitAccountOverviewWalletsTableColumns}
            onCellClick={handleCellClick}
            dataMapper={walletsDataMapper}
            data-aio-id="walletsTable"
            pagination={{
              enabled: false,
            }}
          />
        </BlurredComponent>
      </Grid>
    </GridWidget>
  );
};

SplitAccountsSummaryWidget.propTypes = {};

SplitAccountsSummaryWidget.defaultProps = {};

export default SplitAccountsSummaryWidget;
