import { counterpartyTypes, paymentCounterpartyKeys } from './enums';

const isCounterpartyTypeFieldsFilled = (party) =>
  !!party &&
  !!party[paymentCounterpartyKeys.TYPE] &&
  (party[paymentCounterpartyKeys.TYPE] === counterpartyTypes.PERSONAL
    ? !!party[paymentCounterpartyKeys.FIRST_NAME] &&
      !!party[paymentCounterpartyKeys.LAST_NAME]
    : !!party[paymentCounterpartyKeys.COMPANY_NAME]);

export default isCounterpartyTypeFieldsFilled;
