import React from 'react';
import { bool, node } from 'prop-types';
import { Box, Typography } from '@material-ui/core';

const TextFieldPreview = ({
  children,
  inputAdornment,
  isEditable,
  ...restProps
}) => {
  if (!children) return null;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{ minHeight: 30 }}
    >
      <Typography variant="body1" {...restProps}>
        {children}
      </Typography>
      {isEditable ? inputAdornment : null}
    </Box>
  );
};

TextFieldPreview.propTypes = {
  children: node,
  inputAdornment: node,
  isEditable: bool,
};
TextFieldPreview.defaultProps = {
  inputAdornment: null,
  children: null,
  isEditable: true,
};

export default TextFieldPreview;
