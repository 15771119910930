export const paymentCounterpartyKeys = Object.freeze({
  ID: 'id',
  NAME: 'name',
  ACCOUNT_NUMBER: 'accountNumber',
  SORT_CODE: 'sortCode',
  CURRENCY: 'currency',
  IBAN: 'iban',
  BIC: 'bic',
  STATUS: 'status',
  VERIFIED: 'verified',

  ADDRESS: 'address',
  COUNTRY: 'countryCode',
  CITY: 'city',
  STREET: 'street',
  BUILDING_NAME: 'buildingName',
  BUILDING_NUMBER: 'buildingNumber',
  POSTAL_CODE: 'postalCode',

  TYPE: 'type',
  BANK_DETAILS_TYPE: 'bankDetailsType',

  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',

  COMPANY_NAME: 'companyName',
});

export const paymentCounterpartyKeysLabelsMap = new Map([
  [paymentCounterpartyKeys.ID, 'Counterparty Id'],
  [paymentCounterpartyKeys.NAME, 'Counterparty name'],
  [paymentCounterpartyKeys.ACCOUNT_NUMBER, 'Counterparty account number'],
  [paymentCounterpartyKeys.SORT_CODE, 'Counterparty Sort Code'],
  [paymentCounterpartyKeys.CURRENCY, 'Counterparty currency'],
  [paymentCounterpartyKeys.IBAN, 'Counterparty IBAN'],
  [paymentCounterpartyKeys.BIC, 'Counterparty BIC'],
  [paymentCounterpartyKeys.STATUS, 'Counterparty status'],
  [paymentCounterpartyKeys.VERIFIED, 'Counterparty verified'],
  [paymentCounterpartyKeys.COUNTRY, 'Counterparty country'],
  [paymentCounterpartyKeys.TYPE, 'Counterparty type'],
  [
    paymentCounterpartyKeys.BANK_DETAILS_TYPE,
    'Choose which bank details to use',
  ],
]);

export const paymentCounterpartyKeysLabelsMapClean = new Map([
  [paymentCounterpartyKeys.ID, 'Id'],
  [paymentCounterpartyKeys.NAME, 'Name'],
  [paymentCounterpartyKeys.ACCOUNT_NUMBER, 'Account Number'],
  [paymentCounterpartyKeys.SORT_CODE, 'Sort Code'],
  [paymentCounterpartyKeys.CURRENCY, 'Currency'],
  [paymentCounterpartyKeys.IBAN, 'IBAN'],
  [paymentCounterpartyKeys.BIC, 'BIC'],
  [paymentCounterpartyKeys.STATUS, 'Status'],
  [paymentCounterpartyKeys.VERIFIED, 'Verified'],
  [paymentCounterpartyKeys.COUNTRY, 'Country'],

  [paymentCounterpartyKeys.CITY, 'City'],
  [paymentCounterpartyKeys.STREET, 'Street'],
  [paymentCounterpartyKeys.BUILDING_NAME, 'Building name'],
  [paymentCounterpartyKeys.BUILDING_NUMBER, 'Building №'],
  [paymentCounterpartyKeys.POSTAL_CODE, 'Postal code'],

  [paymentCounterpartyKeys.FIRST_NAME, 'First name'],
  [paymentCounterpartyKeys.LAST_NAME, 'Last name'],

  [paymentCounterpartyKeys.COMPANY_NAME, 'Company name'],
]);

export const paymentCounterpartyKeysDescriptionsMap = new Map([
  [paymentCounterpartyKeys.ID, 'UUID autogenerated by the system'],
  [paymentCounterpartyKeys.NAME, 'Name of the Counterparty'],
  [
    paymentCounterpartyKeys.ACCOUNT_NUMBER,
    'Account Number of the Counterparty',
  ],
  [
    paymentCounterpartyKeys.SORT_CODE,
    'Sort Code of the Counterparty (only applicable for UK and Irish payments)',
  ],
  [
    paymentCounterpartyKeys.CURRENCY,
    'Currency to which this Counterparty is associated to',
  ],
  [paymentCounterpartyKeys.IBAN, 'IBAN of the Counterparty'],
  [
    paymentCounterpartyKeys.BIC,
    'BIC Swift Code that identifies the Bank of the Counterparty',
  ],
  [paymentCounterpartyKeys.STATUS, 'Status of the Counterparty'],
  [paymentCounterpartyKeys.VERIFIED, null],
  [paymentCounterpartyKeys.COUNTRY, 'Country of the Counterparty'],
]);
