import React, { useCallback, useState, useMemo } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { groupBy, omit } from 'rambda';
import { ExpandMoreRounded } from '@material-ui/icons';
import {
  Menu,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { bool } from 'prop-types';
import { AccountAliasTableCell } from '@fondy/tables';
import Link from '@fondy/link';
import { accountTypes, accountTypesLabelsMap } from '@fondy/enums';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonVariant,
  buttonSize,
} from '@fondy/buttons';
import { stateSelectAccountsData } from '../../../redux';
import { accountKeys, walletKeys } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  },
  list: {
    padding: theme.spacing(0, 1, 0, 1),
  },
  listSubheader: {
    padding: theme.spacing(0, 2, 0, 1),
    backgroundColor: theme.palette.common.white,
    minHeight: theme.spacing(6),
    display: 'flex',
  },
  listSubheaderMaster: {
    margin: theme.spacing(0.5, 0, 0.5, 0),
    paddingLeft: 0,
    paddingRight: 0,
    top: theme.spacing(6),
  },
  listSubheaderMasterHeader: {
    zIndex: 10,
    paddingLeft: theme.spacing(2),
  },
  listItem: {
    margin: 0,
    padding: 0,
  },
  itemText: {
    margin: 0,
    color: theme.palette.text.primary,
  },
  itemLink: {
    width: '100%',
    padding: theme.spacing(1.5, 1),
    '&:hover': {
      textDecoration: 'none',
    },
  },
  itemInset: {
    paddingLeft: theme.spacing(3),
  },
  aliasTextContainer: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  alias: {
    maxWidth: 250,
    overflow: 'hidden',
    fontWeight: 'normal',
    lineHeight: 1.3,
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  dropdownIconOpen: {
    transform: 'rotate(180deg)',
  },
  standardAccountsList: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const omitAccountTypesForRender = omit([
  accountTypes.MASTER,
  accountTypes.STANDARD,
]);

const PageHeaderAccountsSelect = ({ isLoading, ...rest }) => {
  const classes = useStyles();
  const { accountId } = useParams();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });

  const { selectedAccount, allNotClosedAccounts } = useSelector(
    stateSelectAccountsData(accountId),
  );

  const [anrchorElement, setAnchorElement] = useState(null);

  const groups = useMemo(
    () =>
      groupBy(
        (account) =>
          account[accountKeys.TYPE] === accountTypes.WALLET
            ? account[walletKeys.PAYOUT_ACCOUNT_ID]
            : account[accountKeys.TYPE],
        allNotClosedAccounts,
      ),
    [allNotClosedAccounts],
  );

  const handleOpen = useCallback(
    (event) => setAnchorElement(event.currentTarget),
    [],
  );

  const handleClose = useCallback(() => setAnchorElement(null), []);

  const renderElement = useCallback(
    (account) => {
      const isSelected =
        selectedAccount[accountKeys.ID] === account[accountKeys.ID];
      const isWallet = account[accountKeys.TYPE] === accountTypes.WALLET;
      const isStandard = account[accountKeys.TYPE] === accountTypes.STANDARD;
      const isMaster = account[accountKeys.TYPE] === accountTypes.MASTER;
      return (
        <ListItem
          key={account[accountKeys.ID]}
          className={classes.listItem}
          selected={isSelected}
          onClick={handleClose}
          button
        >
          <Link
            className={classes.itemLink}
            to={`/accounts/${isMaster ? '' : 'transactions/'}${
              account[accountKeys.ID]
            }${isMaster ? '/wallets' : ''}`}
          >
            <ListItemText
              classes={{
                root: classes.itemText,
                inset: clsx({
                  [classes.itemInset]: isWallet || isStandard,
                }),
              }}
              inset={isWallet || isStandard}
            >
              <AccountAliasTableCell
                medium
                fontSize={16}
                currencyIso={account[accountKeys.CURRENCY]}
                accountAlias={account[accountKeys.ALIAS]}
                accountType={account[accountKeys.TYPE]}
                classes={{
                  alias: classes.alias,
                  textContainer: classes.aliasTextContainer,
                }}
              />
            </ListItemText>
          </Link>
        </ListItem>
      );
    },
    [
      classes.alias,
      classes.aliasTextContainer,
      classes.itemInset,
      classes.itemLink,
      classes.itemText,
      classes.listItem,
      handleClose,
      selectedAccount,
    ],
  );

  const renderTag = useCallback(
    (account) => (
      <AccountAliasTableCell
        medium
        fontSize={16}
        key={account[accountKeys.ID]}
        currencyIso={account[accountKeys.CURRENCY]}
        accountAlias={account[accountKeys.ALIAS]}
        accountType={account[accountKeys.TYPE]}
        classes={{
          alias: classes.alias,
          textContainer: classes.aliasTextContainer,
        }}
      />
    ),
    [classes.alias, classes.aliasTextContainer],
  );

  const renderGroup = useCallback(
    ([masterAccountId, wallets]) => {
      const masterAccount = groups[accountTypes.MASTER].find(
        (i) => i[accountKeys.ID] === masterAccountId,
      );
      return (
        <span key={masterAccountId}>
          <List
            component="nav"
            aria-labelledby={`${masterAccountId}-nested-list-subheader`}
            subheader={
              <ListSubheader
                id={`${masterAccountId}-nested-list-subheader`}
                className={clsx(
                  classes.listSubheader,
                  classes.listSubheaderMaster,
                )}
              >
                {renderElement(masterAccount)}
              </ListSubheader>
            }
            className={classes.list}
          >
            {wallets.map(renderElement)}
          </List>
        </span>
      );
    },
    [
      classes.list,
      classes.listSubheader,
      classes.listSubheaderMaster,
      groups,
      renderElement,
    ],
  );

  if (!accountId) return null;

  if (isLoading) return <Skeleton width={100} />;

  return (
    <>
      <Button
        {...rest}
        onClick={handleOpen}
        aria-controls="accounts-drop-menu"
        aria-haspopup="true"
        color={buttonColor.PRIMARY}
        variant={buttonVariant.TEXT}
        shape={buttonShape.ROUNDED}
        size={buttonSize.LARGE}
        className={clsx(classes.actionButton, rest.className)}
        data-aio-id="accountSelectDropdown"
        endIcon={
          <ExpandMoreRounded
            color="inherit"
            className={clsx({
              [classes.dropdownIconOpen]: Boolean(anrchorElement),
            })}
          />
        }
      >
        {renderTag(selectedAccount)}
      </Button>
      <Menu
        keepMounted
        id="accounts-drop-menu"
        anchorEl={anrchorElement}
        open={Boolean(anrchorElement)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            maxHeight: isMobile ? '80%' : '50%',
          },
        }}
      >
        {groups[accountTypes.STANDARD]?.length > 0 && (
          <span>
            <List
              component="nav"
              aria-labelledby={`${accountTypes.STANDARD}-nested-list-subheader`}
              subheader={
                <ListSubheader
                  component="div"
                  id={`${accountTypes.STANDARD}-nested-list-subheader`}
                  className={classes.listSubheader}
                >
                  {`${accountTypesLabelsMap.get(accountTypes.STANDARD)}s`}
                </ListSubheader>
              }
              className={clsx(classes.list, classes.standardAccountsList)}
            >
              {groups[accountTypes.STANDARD].map(renderElement)}
            </List>
          </span>
        )}

        {groups[accountTypes.MASTER]?.length > 0 && (
          <List
            component="nav"
            aria-labelledby={`${accountTypes.MASTER}-nested-list-subheader`}
            subheader={
              <ListSubheader
                component="div"
                id={`${accountTypes.MASTER}-nested-list-subheader`}
                className={clsx(
                  classes.listSubheader,
                  classes.listSubheaderMasterHeader,
                )}
              >
                {`${accountTypesLabelsMap.get(accountTypes.MASTER)}s`}
              </ListSubheader>
            }
            className={classes.list}
          >
            {Object.entries(omitAccountTypesForRender(groups)).map(renderGroup)}
          </List>
        )}
      </Menu>
    </>
  );
};

PageHeaderAccountsSelect.propTypes = {
  isLoading: bool,
};

PageHeaderAccountsSelect.defaultProps = {
  isLoading: false,
};

export default PageHeaderAccountsSelect;
