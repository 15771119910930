const partyAssociationsKeys = Object.freeze({
  COMPLIANCE_STATUS: 'complianceStatus',
  PARTY_ID: 'partyId',
  PARTY_NAME: 'partyName',
  PARTY_STATUS: 'partyStatus',
  ROLE: 'role',
  TIMEZONE: 'timezone',
});

export default partyAssociationsKeys;
