import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

const loadOfflinePage = () => {
  window.location.href = `/offline.html?back=${encodeURIComponent(
    window.location.href,
  )}`;
};

// Detect online/offline state
window.addEventListener('load', () => {
  if (!navigator.onLine) return loadOfflinePage();

  window.addEventListener('offline', () => {
    loadOfflinePage();
  });
  return undefined;
});
