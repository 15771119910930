/* eslint-disable react/forbid-prop-types */
import React from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import {
  bool,
  func,
  node,
  objectOf,
  any,
  string,
  oneOf,
  object,
  oneOfType,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import {
  getTextFieldPropsFromKey,
  FormControls,
  ControlledPinInputField,
} from '@fondy/forms';
import { httpMethods } from '@fondy/enums';
import { fetchErrorHandler } from '@fondy/utils';
import { useApplicationContext } from '@fondy/application-context';
import { twoFactorAuthenticationActivationSchema } from '../TwoFactorAuthenticationActivationForm';
import { tfaKeys } from '../../../utils';

const getFieldProps = getTextFieldPropsFromKey();

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  illustration: {
    marginBottom: theme.spacing(3),
  },
}));

const TwoFactorAuthenticationForm = ({
  onSubmit,
  onError,
  onClose,
  title,
  description,
  submitText,
  resetButtonComponent,
  sendRequest,
  formControlsProps,
  method,
  autoSubmit,
  illustration: Illustration,
  className,
  ...restProps
}) => {
  const classes = useStyles();
  const { fetch, keycloak } = useApplicationContext();

  const formState = useFormik({
    initialValues: {},
    onSubmit: async (
      values,
      { setSubmitting, setFieldError, ...restFormikHelpers },
    ) => {
      try {
        if (sendRequest) {
          await setSubmitting(true);
          const response = await fetch('/api/tfa/totp', {
            method,
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
            body: await JSON.stringify(values),
          });

          if (!response.ok) {
            await fetchErrorHandler({
              response,
              keycloak,
              throwError: true,
            });
          }

          await setSubmitting(false);
        }

        if (onSubmit) {
          const maybeError = await onSubmit({
            setSubmitting,
            setFieldError,
            ...restFormikHelpers,
            ...values,
          });

          if (maybeError instanceof Error) {
            throw maybeError;
          }
        }
      } catch (error) {
        await setFieldError(
          tfaKeys.CODE,
          error.message || 'Default error message',
        );
        if (onError) {
          await setSubmitting(false);
          await onError({
            error,
            setSubmitting,
            setFieldError,
            ...restFormikHelpers,
          });
        }
      }
    },
    validationSchema: twoFactorAuthenticationActivationSchema,
  });
  const { handleSubmit, handleReset } = formState;

  return (
    <Grid {...restProps} className={clsx(classes.root, className)} container>
      {Illustration ? (
        <Grid item xs={12} align="center">
          <Illustration
            width={120}
            height={120}
            className={classes.illustration}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom align="center">
          {title}
        </Typography>
        <Typography
          variant="body2"
          className={classes.description}
          align="center"
        >
          {description}
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <form onSubmit={handleSubmit} onReset={handleReset} noValidate>
          <ControlledPinInputField
            {...getFieldProps(tfaKeys.CODE)}
            description="a new code is generated every 30 seconds"
            formState={formState}
            autoSubmit={autoSubmit}
            required
          />
          <FormControls
            submitText={submitText}
            align="center"
            {...formControlsProps}
            resetButtonComponent={resetButtonComponent}
            formState={formState}
            onCancel={onClose}
            submitButtonProps={{
              'data-aio-id': 'submit2faCode',
            }}
          />
        </form>
      </Grid>
    </Grid>
  );
};

TwoFactorAuthenticationForm.propTypes = {
  onSubmit: func,
  onError: func,
  onClose: func,
  title: string.isRequired,
  description: node.isRequired,
  submitText: string,
  resetButtonComponent: node,
  sendRequest: bool,
  autoSubmit: bool,
  formControlsProps: objectOf(any),
  method: oneOf([httpMethods.POST, httpMethods.DELETE]),
  illustration: oneOfType([node, object]),
  className: string,
};

TwoFactorAuthenticationForm.defaultProps = {
  onSubmit: undefined,
  onError: undefined,
  onClose: undefined,
  submitText: 'Confirm',
  resetButtonComponent: null,
  sendRequest: true,
  autoSubmit: false,
  formControlsProps: {},
  method: httpMethods.POST,
  illustration: null,
  className: null,
};

export default TwoFactorAuthenticationForm;
