const createAccountModalStages = Object.freeze({
  ACCOUNT_TYPE_SELECT: 0,
  CREATE_STANDARD_ACCOUNT: 1,
  CREATE_MASTER_ACCOUNT: 2,
  CREATE_WALLET_ACCOUNT: 3,
  ERROR: 4,
  REQUEST_SUBMITTED: 5,
});

export default createAccountModalStages;
