import { partyTypesLabelsMap, partyTypes } from '@fondy/enums';

export const complianceProfileTypes = Object.freeze({
  SOLE_TRADER: 'SOLETRADER',
  EXTERNALLY_MANAGED: 'EX_MANAGED',
  COMPANY: 'COMPANY',
  FREELANCER: 'FREELANCER',
  OTHER: 'OTHER',
});

export const complianceProfileTypesLabelsMap = new Map([
  [
    complianceProfileTypes.SOLE_TRADER,
    partyTypesLabelsMap.get(partyTypes.SOLE_TRADER),
  ],
  [complianceProfileTypes.EXTERNALLY_MANAGED, 'Externally managed'],
  [complianceProfileTypes.COMPANY, 'Company'],
  [complianceProfileTypes.FREELANCER, 'Freelancer'],
  [complianceProfileTypes.OTHER, 'Other'],
]);
