import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import { PrivateRoute } from '@fondy/keycloak';
import { NotFoundPage } from '@fondy/layouts';
import {
  AccountsPage,
  TransactionsPage,
  WalletsPage,
} from '../../components/pages';
import { privateRoutesRequiredPartyProps } from '../utils';

export default function AccountsRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        {...privateRoutesRequiredPartyProps}
        path={path}
        exact
        enableOffline
      >
        <AccountsPage />
      </PrivateRoute>
      <PrivateRoute
        {...privateRoutesRequiredPartyProps}
        path={`${path}/transactions/:accountId`}
        exact
        enableOffline
      >
        <TransactionsPage />
      </PrivateRoute>
      <PrivateRoute
        {...privateRoutesRequiredPartyProps}
        path={`${path}/:accountId/wallets`}
        exact
        enableOffline
      >
        <WalletsPage />
      </PrivateRoute>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
