import React from 'react';
import { last, isEmpty } from 'rambda';
import { formatSortCodeValue } from '@fondy/utils';
import { AvatarDataCell } from '@fondy/tables';
import {
  GBP_CURRENCY,
  newPaymentKeys,
  ibanToStringFormatter,
} from '../../../../utils';

const renderSubtitle = (valueToRender) => {
  if (typeof valueToRender === 'string') {
    return null;
  }

  if (valueToRender[newPaymentKeys.PAYEE_IBAN]) {
    return ibanToStringFormatter(valueToRender[newPaymentKeys.PAYEE_IBAN]);
  }

  return `${
    valueToRender[newPaymentKeys.PAYEE_ACCOUNT_NUMBER]
  }\t${formatSortCodeValue(valueToRender[newPaymentKeys.PAYEE_SORT_CODE])}`;
};

const recipientAutocompleteTagsRenderer = (value) => {
  const valueToRender = last(value);
  if (valueToRender?.type) return null;
  if (isEmpty(valueToRender)) return '';
  return (
    <AvatarDataCell
      title={valueToRender[newPaymentKeys.PAYEE_NAME] || valueToRender}
      titleSuffix={valueToRender[newPaymentKeys.PAYEE_CURRENCY]}
      subtitle={renderSubtitle(valueToRender)}
      titleProps={{
        variant: 'body1',
      }}
      subtitleProps={{
        style: {
          fontSize: 14,
        },
      }}
      titleSuffixProps={{
        style: {
          fontSize: '1rem',
        },
      }}
    />
  );
};

export default recipientAutocompleteTagsRenderer;
