const makePaymentModalStates = Object.freeze({
  FORM: 1,
  REVIEW: 2,
  AUTHENTICATOR: 3,
  AUTHENTICATOR_SETUP: 4,
  CONFIRMATION: 5,
  ERROR: 6,
  COUNTERPARTY_DUPLICATE: 7,
  COUNTERPARTY_CONFIRMATION: 8,
});

export default makePaymentModalStates;
