import React, { useCallback, useState } from 'react';
import { omit } from 'rambda';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { endOfDay, startOfDay, subDays, subYears } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { orderTypes } from '@fondy/enums';
import { PageHeader } from '@fondy/layouts';
import { formatTimeZonedDate, useApi } from '@fondy/utils';
import { ErrorMessage } from '@fondy/alerts';
import { GridWidget } from '@fondy/data-display';
import { ConnectedFilteredTable, filterFieldTypes } from '@fondy/tables';
import {
  splitAccountWalletPageTabs,
  splitAccountWalletPageTabsLabelsMap,
  splitAccountWalletPageTabsRoutingMap,
} from './utils';
import {
  TotalBalance,
  createTransactionsPdfStatementHeader,
  createTransactionsPdfStatementFooter,
  createTransactionsPdfStatementTableFooter,
} from '../../atoms';
import {
  userCustomerSelector,
  stateTimeZoneSelector,
  stateSelectSplitAccountsIds,
} from '../../../redux';
import { AppLayout } from '../../layouts';
import {
  PageTabsNavigation,
  PageHeaderSplitAccountWalletSelect,
  TransactionDetailsModal,
} from '../../organisms';
import {
  splitAccountWalletTransactionsTableColumns,
  splitAccountWalletTransactionsPdfTableColumns,
} from '../../atoms/tableColumns';
import {
  splitAccountTransactionKeys,
  splitAccountsIdsKeys,
  splitAccountWalletKeys,
  transactionKeys,
  tableEmptyMessageResolver,
  splitAccountTransactionKeysLabelsMap,
  csvValueAsStringFormatter,
  splitAccountTransactionTypes,
  splitAccountTransactionTypesLabelsMap,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    '& > .MuiTypography-subtitle1': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const omitWalletId = omit([transactionKeys.ACCOUNT_ID]);

const SplitAccountWalletTransactionsListPage = () => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const { splitAccountWalletId } = useParams();
  const { customer } = useSelector(userCustomerSelector);
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);
  const { splitAccountsIds } = useSelector(stateSelectSplitAccountsIds);
  const [transactionDetails, setTransactionDetails] = useState(null);

  const {
    apiData: splitAccountWallet,
    isLoading: isSplitAccountWalletLoading,
    isError: isSplitAccountWalletError,
    apiError: splitAccountWalletError,
    retry: retrySplitAccountWalletRequest,
  } = useApi(
    `/api/core-banking/splitaccount/${
      splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID]
    }/wallet/${splitAccountWalletId}`,
    {
      dependencies: [
        splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID],
        splitAccountWalletId,
      ],
    },
  );

  const {
    apiData: userPartyData,
    isLoading: isPartyLoading,
    isError: hasPartyFetchError,
  } = useApi(
    `/api/parties/${
      splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID]
    }`,
    {
      dependencies: [
        splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID],
      ],
    },
  );

  const handleTransactionRecordClick = useCallback(
    async (_, row) => setTransactionDetails(row),
    [],
  );

  const handleTransactionDetailsModalClose = useCallback(
    () => setTransactionDetails(null),
    [],
  );

  const currentDate = new Date();

  return (
    <AppLayout
      backButtonLabel={!!splitAccountWalletId && 'Back to wallets'}
      backButtonUrl={!!splitAccountWalletId && '/split-account/wallets'}
    >
      <PageHeader
        preTitle={
          <PageHeaderSplitAccountWalletSelect
            isLoading={isSplitAccountWalletLoading}
          />
        }
        title={
          <TotalBalance
            renderCurrency
            currencyIso={
              splitAccountWallet?.[
                splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY
              ]
            }
            totalBalance={
              splitAccountWallet?.[splitAccountWalletKeys.CURRENT_AMOUNT] || 0
            }
          />
        }
        subtitle="Available balance"
        classes={{
          header: classes.pageHeader,
        }}
      />

      <PageTabsNavigation
        tabs={splitAccountWalletPageTabs}
        tabsLabelsMap={splitAccountWalletPageTabsLabelsMap}
        tabsRoutingMap={splitAccountWalletPageTabsRoutingMap}
      />

      <GridWidget container noBottomPadding spacing={0}>
        {isSplitAccountWalletError && (
          <ErrorMessage
            error={splitAccountWalletError}
            isLoading={isSplitAccountWalletLoading}
            retryFn={retrySplitAccountWalletRequest}
          />
        )}

        {!isSplitAccountWalletError && (
          <ConnectedFilteredTable
            defaultOrderBy={splitAccountTransactionKeys.BOOKING_DATE}
            defaultOrderDirection={orderTypes.DESCENDING}
            rowId={splitAccountTransactionKeys.ID}
            url={`/api/core-banking/transactions/splitaccount?accountId=${splitAccountWalletId}`}
            dependencies={[splitAccountWalletId]}
            emptyComponent={tableEmptyMessageResolver('transactions')}
            columns={splitAccountWalletTransactionsTableColumns}
            pagination={{
              size: 10,
            }}
            onCellClick={handleTransactionRecordClick}
            data-aio-id="transactionsTable"
            dateFilters={{
              timeZone: legalEntityTimezone,
              dependencies: [splitAccountWalletId],
              minDate: startOfDay(subYears(currentDate, 1)),
              maxDate: endOfDay(currentDate),
              twoFactorIsActive: false,
              dateFrom: {
                label: 'Start date',
                defaultValue: startOfDay(subDays(currentDate, 30)),
              },
              dateTo: {
                label: 'End date',
                defaultValue: endOfDay(currentDate),
              },
            }}
            filters={{
              [splitAccountTransactionKeys.TYPE]: {
                label: splitAccountTransactionKeysLabelsMap.get(
                  splitAccountTransactionKeys.TYPE,
                ),
                values: splitAccountTransactionTypes,
                valuesLabelsMap: splitAccountTransactionTypesLabelsMap,
                type: filterFieldTypes.SELECT,
              },
            }}
            fileExport={{
              allowExportAll: true,
              enabled: true,
              buttonLabel: 'Export statement',
              disabledButton: isPartyLoading || hasPartyFetchError,
              pdfColumns: splitAccountWalletTransactionsPdfTableColumns(
                legalEntityTimezone,
              ),
              csvColumns: [
                splitAccountTransactionKeys.BOOKING_DATE,
                splitAccountTransactionKeys.TRANSACTION_REFERENCE,
                splitAccountTransactionKeys.ID,
                splitAccountTransactionKeys.COUNTERPARTY_NAME,
                splitAccountTransactionKeys.COUNTERPARTY_SORT_CODE,
                splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_NUMBER,
                splitAccountTransactionKeys.DEBIT_CREDIT_CODE,
                splitAccountTransactionKeys.TYPE,
                splitAccountTransactionKeys.TRANSACTION_CURRENCY,
                splitAccountTransactionKeys.TRANSACTION_AMOUNT,
                splitAccountTransactionKeys.ACCOUNT_CLOSING_BALANCE,
              ],
              csvColumnsNamesMap: splitAccountTransactionKeysLabelsMap,
              csvDataMapper: {
                [splitAccountTransactionKeys.TYPE]: (value) =>
                  splitAccountTransactionTypesLabelsMap.get(value) || value,
                [splitAccountTransactionKeys.BOOKING_DATE]: (value) =>
                  formatTimeZonedDate(value, legalEntityTimezone, {
                    dateFormat: 'dd/MM/yyyy',
                  }),
                [splitAccountTransactionKeys.TRANSACTION_REFERENCE]: csvValueAsStringFormatter,
                [splitAccountTransactionKeys.COUNTERPARTY_NAME]: csvValueAsStringFormatter,
              },
              filename: () =>
                `Wallet - ${
                  splitAccountWallet[splitAccountWalletKeys.ALIAS]
                } - statement`,
              headerRenderer: (props) =>
                createTransactionsPdfStatementHeader({
                  ...props,
                  account: splitAccountWallet,
                  party: userPartyData,
                }),
              footerRenderer: createTransactionsPdfStatementFooter,
              tableFooterRenderer: (props) =>
                createTransactionsPdfStatementTableFooter({
                  ...props,
                  currencyIso:
                    splitAccountWallet[
                      splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY
                    ],
                }),
            }}
          />
        )}
      </GridWidget>

      {transactionDetails && (
        <TransactionDetailsModal
          open={!!transactionDetails}
          transaction={transactionDetails}
          onClose={handleTransactionDetailsModalClose}
          isSplitAccountWallet
        />
      )}
    </AppLayout>
  );
};

export default SplitAccountWalletTransactionsListPage;
