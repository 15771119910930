export const complianceProfileComplianceTypes = Object.freeze({
  EXTERNAL_FULL: 'EXTERNAL_FULL',
  FONDY: 'FONDY',
  OTHER: 'OTHER',
});

export const complianceProfileComplianceTypesLabelsMap = new Map([
  [complianceProfileComplianceTypes.EXTERNAL_FULL, 'External Full'],
  [complianceProfileComplianceTypes.FONDY, 'Fondy'],
  [complianceProfileComplianceTypes.OTHER, 'Other'],
]);
