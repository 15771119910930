/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useState } from 'react';
import {
  Cell,
  PieChart,
  Pie,
  Legend,
  Sector,
  ResponsiveContainer,
} from 'recharts';
import clsx from 'clsx';
import { stringify } from 'query-string';
import { identity, isEmpty, isNil } from 'rambda';
import { CircularProgress, Box } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  bool,
  number,
  shape,
  string,
  func,
  arrayOf,
  objectOf,
  any,
  oneOfType,
  instanceOf,
  node,
} from 'prop-types';
import { useApi, getQueryDelimiterFromUrl } from '@fondy/utils';
import { ErrorMessage, BasicAlert, alertTypes } from '@fondy/alerts';
import { userPrefersReducedMotion } from '@fondy/charts/src/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .recharts-legend-item': {
      '&:not(:last-of-type)': {
        paddingRight: theme.spacing(3),
      },
      '& > *': {
        verticalAlign: 'middle',
      },
    },
  },
  legend: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    listStyleType: 'none',
  },
  legendItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  clickableLegendItem: {
    '&:hover': {
      cursor: 'pointer',
      fontWeight: 500,
      letterSpacing: -0.05,
      '& > span:last-of-type': {
        color: 'inherit',
      },
    },
  },
  legendName: {
    color: '#333333',
    fontWeight: theme.typography.fontWeightMedium,
    '&:before': {
      content: '',
    },
  },
  legendValue: {
    display: 'inline-block',
    marginLeft: 'auto',
    fontWeight: theme.typography.fontWeightSemibold,
  },
  legendItemPointer: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    width: 10,
    height: 10,
  },
  error: {
    marginTop: theme.spacing(2),
  },
}));

const renderActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
}) => {
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 4}
        outerRadius={outerRadius + 8}
        fill={fill}
      />
    </g>
  );
};

const ConnectedPieBgChart = ({
  url,
  urlParams,
  dataMapper,
  height,
  margin,
  colorsMap,
  showLegend,
  legendProps,
  className,
  onSuccess,
  onError,
  onClick,
  emptyMessage,
  emptyHeading,
  pieProps,
  emptyComponent,
  ...restProps
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const disableAnimations = userPrefersReducedMotion();
  const [activeIndex, setActiveIndex] = useState(null);
  const { apiData, apiError, isLoading, isError, retry } = useApi(
    `${url}${getQueryDelimiterFromUrl(url)}${stringify(
      {
        page: 0,
        size: -1,
        ...urlParams,
      },
      { arrayFormat: 'comma', skipNull: true, skipEmptyString: true },
    )}`,
    {
      dataMapper,
      lazy: isNil(url) || isEmpty(url),
      dependencies: [url, urlParams],
      onSuccess,
      onError,
    },
  );

  const renderLegend = useCallback(
    ({ onClick: onLegendItemClick, onMouseEnter, onMouseLeave, payload }) => (
      <ul className={classes.legend}>
        {payload.slice(1).map(({ payload: entryPayload, value }, index) => {
          const color =
            colorsMap.get(entryPayload.key) || theme.palette.accent.main;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`item-${index}`}>
              <Box
                className={clsx(classes.legendItem, {
                  [classes.clickableLegendItem]: !!onLegendItemClick,
                })}
                onMouseEnter={
                  onMouseEnter ? (event) => onMouseEnter(event, index) : null
                }
                onMouseLeave={onMouseLeave}
                onClick={
                  onLegendItemClick
                    ? () => onLegendItemClick(entryPayload)
                    : null
                }
              >
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 32 32"
                  version="1.1"
                  className={clsx(
                    'recharts-surface',
                    classes.legendItemPointer,
                  )}
                >
                  <path
                    fill={color}
                    cx="16"
                    cy="16"
                    type="circle"
                    className="recharts-symbols"
                    transform="translate(16, 16)"
                    d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
                  />
                </svg>
                <span className={classes.legendName}>{value}</span>
                <span className={classes.legendValue}>
                  {entryPayload.value}
                </span>
              </Box>
            </li>
          );
        })}
      </ul>
    ),
    [
      theme.palette.accent.main,
      classes.clickableLegendItem,
      classes.legend,
      classes.legendItem,
      classes.legendItemPointer,
      classes.legendName,
      classes.legendValue,
      colorsMap,
    ],
  );

  const onPieEnterHandler = useCallback(
    (_, index) => setActiveIndex(index),
    [],
  );

  const onPieExitHandler = useCallback(() => setActiveIndex(null), []);

  const cellsMapper = useCallback(
    (entry) => {
      const color = colorsMap.get(entry.key) || theme.palette.accent.main;

      return <Cell key={entry.name} fill={color} strokeWidth={0} />;
    },
    [colorsMap, theme.palette.accent.main],
  );

  if (isLoading && !isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={height}
        width="100%"
      >
        <CircularProgress data-test-id="progress" color="primary" />
      </Box>
    );
  }

  if (isError) {
    return (
      <ErrorMessage
        error={apiError}
        retryFn={retry}
        data-test-id="error"
        className={classes.error}
      />
    );
  }

  if (!apiData || apiData.length === 0) {
    return (
      emptyComponent || (
        <BasicAlert
          type={alertTypes.INFO}
          heading={emptyHeading}
          message={emptyMessage}
          data-test-id="emptyMessage"
        />
      )
    );
  }

  const heightWithLegend =
    (apiData || []).length >= 6 ? height + (apiData || []).length * 15 : height;

  return (
    <ResponsiveContainer
      width="100%"
      height={showLegend ? heightWithLegend : height}
    >
      <PieChart
        {...restProps}
        width="100%"
        height="100%"
        margin={margin}
        className={clsx(classes.root, className)}
      >
        <Pie
          dataKey="value"
          nameKey="name"
          data={[{ name: 'bg', value: 1 }]}
          innerRadius={34}
          outerRadius={79}
          paddingAngle={0}
          legendType="none"
          labelLine={false}
          label={false}
          isAnimationActive={false}
          onMouseEnter={onPieExitHandler}
          tooltipType="none"
          activeIndex={-1}
        >
          <Cell fill={theme.palette.secondary.lightest} strokeWidth={0} />
        </Pie>
        <Pie
          dataKey="value"
          nameKey="name"
          data={apiData || []}
          innerRadius={49}
          outerRadius={64}
          paddingAngle={0}
          startAngle={90}
          endAngle={-270}
          legendType="circle"
          labelLine={false}
          label={false}
          isAnimationActive={!disableAnimations}
          onClick={onClick}
          onMouseEnter={onPieEnterHandler}
          onMouseLeave={onPieExitHandler}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          animationDuration={800}
          {...pieProps}
        >
          {(apiData || []).map(cellsMapper)}
        </Pie>
        {showLegend && (
          <Legend
            verticalAlign="middle"
            align="right"
            iconType="circle"
            iconSize={10}
            height={36}
            margin={margin}
            layout="vertical"
            wrapperStyle={{
              height: 'auto',
              width: '50%',
            }}
            onClick={onClick}
            onMouseEnter={onPieEnterHandler}
            onMouseLeave={onPieExitHandler}
            content={renderLegend}
            {...legendProps}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

ConnectedPieBgChart.propTypes = {
  url: string.isRequired,
  urlParams: shape({
    sort: arrayOf(string),
    page: number,
    size: number,
  }),
  height: number,
  margin: shape({
    top: number,
    bottom: number,
    left: number,
    right: number,
  }),
  dataMapper: func,
  colorsMap: instanceOf(Map).isRequired,
  showLegend: bool,
  pieProps: objectOf(any),
  legendProps: objectOf(any),
  className: string,
  onSuccess: func,
  onError: func,
  onClick: func,
  emptyMessage: oneOfType([string, bool]),
  emptyHeading: string,
  emptyComponent: node,
};

ConnectedPieBgChart.defaultProps = {
  height: 220,
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  urlParams: {},
  dataMapper: identity,
  showLegend: true,
  pieProps: {},
  legendProps: {},
  className: '',
  onSuccess: null,
  onError: null,
  onClick: null,
  emptyMessage: "You don't have anything to plot yet",
  emptyHeading: 'No data',
  emptyComponent: null,
};

export default ConnectedPieBgChart;
