import React from 'react';
import { SnackbarProvider } from 'notistack';
import { isNil, isEmpty } from 'rambda';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SvgIcon } from '@material-ui/core';
import { defaultTheme } from '@fondy/themes';
import { ErrorPage } from '@fondy/layouts';
import { AuthProvider } from '@fondy/keycloak';
import {
  InfoStatusIcon,
  ErrorStatusIcon,
  SuccessStatusIcon,
  WarningStatusIcon,
} from '@fondy/icons';
import NotistackStyled from './components/atoms/NotistackStyled';
import { SplashScreenPage } from './components/pages';
import { store, fetchUserProfileAction, fetchUserPartyAction } from './redux';
import routes from './routes';

const handleReduxActionsBeforeResolveStateSelector = ({
  profile,
  parties,
}) => ({
  profile: profile.data,
  parties: parties.data,
  apiError: profile.error || parties.error,
  isFetching: profile.isFetching || parties.isFetching,
});

const handleReduxActionsBeforeResolveStateValidator = ({
  state: { profile, parties, apiError, isFetching },
  isAuthLoading,
  isLoggedIn,
}) => {
  const hasProfileData = !isNil(profile) && !isEmpty(profile);
  const hasPartiesData = !isNil(parties) && !isEmpty(parties);

  if (apiError) return apiError;
  if (isAuthLoading || isFetching) return false;
  if (!isLoggedIn && !isFetching && !hasProfileData && !hasPartiesData) {
    return true;
  }
  if (isLoggedIn && !isFetching && hasProfileData && !hasPartiesData) {
    return true;
  }
  return isLoggedIn && !isFetching && hasProfileData && hasPartiesData;
};

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <SnackbarProvider
        preventDuplicate
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          warning: <SvgIcon component={WarningStatusIcon} />,
          info: <SvgIcon component={InfoStatusIcon} />,
          error: <SvgIcon component={ErrorStatusIcon} />,
          success: <SvgIcon component={SuccessStatusIcon} />,
        }}
        Components={{
          default: NotistackStyled,
          success: NotistackStyled,
          error: NotistackStyled,
          warning: NotistackStyled,
          info: NotistackStyled,
        }}
      >
        <Router>
          <AuthProvider
            store={store}
            fetch={fetch}
            reduxActionsBeforeResolve={[
              fetchUserProfileAction,
              fetchUserPartyAction,
            ]}
            reduxActionsBeforeResolveStateSelector={
              handleReduxActionsBeforeResolveStateSelector
            }
            reduxActionsBeforeResolveStateValidator={
              handleReduxActionsBeforeResolveStateValidator
            }
            splashScreen={SplashScreenPage}
            apiErrorPage={ErrorPage}
            apiErrorPageProps={{
              email: 'support@fondy.io',
            }}
          >
            {routes}
          </AuthProvider>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
