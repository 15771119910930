import { isValid } from 'date-fns';

const tableEmptyMessageResolver = (name = 'records') => ({
  isOnEmptyPage,
  dateFiltersState,
  filtersState,
}) => {
  const activeFiltersCount = Object.values(filtersState || {}).reduce(
    (sum, filter) => (filter.length > 0 ? sum + 1 : sum),
    0,
  );

  if (!isOnEmptyPage && activeFiltersCount > 0) {
    return `You do not have any ${name} matching the selected filter criteria`;
  }

  if (
    !isOnEmptyPage &&
    (isValid(dateFiltersState.dateFrom) || isValid(dateFiltersState.dateTo))
  ) {
    return `You don't have any ${name} within the selected date range`;
  }

  return `You don't have any ${name} yet`;
};

export default tableEmptyMessageResolver;
