import React from 'react';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import { string, node, number, bool, func, oneOfType } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: 14,
  },
  dash: {
    height: 4,
    width: 64,
    background: 'linear-gradient(90deg, #6605FF 0%, #E8005E 100%)',
  },
  gutterTop: {
    marginTop: theme.spacing(2),
  },
}));

const FeatureCard = ({ icon: Icon, title, subtitle, spacing, gutterTop }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={spacing}
      className={clsx({
        [classes.gutterTop]: gutterTop,
      })}
    >
      <Grid item xs={12}>
        {Icon ? <Icon /> : <div className={classes.dash} />}
      </Grid>
      {title && (
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
        </Grid>
      )}
      {subtitle && (
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.subtitle}>
            {subtitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

FeatureCard.propTypes = {
  title: string,
  subtitle: string,
  icon: oneOfType([node, func]),
  spacing: number,
  gutterTop: bool,
};

FeatureCard.defaultProps = {
  title: null,
  icon: null,
  spacing: 1,
  subtitle: null,
  gutterTop: false,
};

export default FeatureCard;
