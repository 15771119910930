import { createPdfDocument, defaultPageMargins } from '@fondy/pdf';
import { formatSortCodeValue, formatTimeZonedDate } from '@fondy/utils';
import {
  conformToMask,
  ibanInputMaskPattern,
  bicSwiftInputMaskPattern,
} from '@fondy/forms';
import {
  accountTypes,
  accountTypesLabelsMap,
  accountStatusesLabels,
} from '@fondy/enums';
import pdfHeaderCreator from '../pdfHeaderCreator';
import pdfFooterCreator from '../pdfFooterCreator';
import {
  accountKeys,
  walletKeys,
  accountKeysLabelsMap,
  walletKeysLabelsMap,
} from '../../../../utils';

const createAccountDetailsPdf = async ({
  account,
  masterAccount,
  loadingStateSetter,
  onError,
  theme,
  timeZone,
  margin = defaultPageMargins,
  filename = 'account-details.pdf',
}) => {
  try {
    if (loadingStateSetter) await loadingStateSetter(true);

    const date = new Date();
    const { pdfDocument } = await createPdfDocument();
    const { fontName } = await pdfDocument.getFont();
    const isWallet = account?.[accountKeys.TYPE] === accountTypes.WALLET;

    await pdfHeaderCreator(pdfDocument, { theme, title: 'Account Details' });

    // account details labels
    await pdfDocument.text(
      [
        `Account name`,
        `${accountKeysLabelsMap.get(accountKeys.HOLDER_NAME)}:`,
        `${accountKeysLabelsMap.get(accountKeys.TYPE)}:`,
        ...(isWallet
          ? [
              `${walletKeysLabelsMap.get(walletKeys.PAYOUT_ACCOUNT_ID)}:`,
              `${walletKeysLabelsMap.get(walletKeys.OWNER_ID)}:`,
              `${walletKeysLabelsMap.get(walletKeys.OWNER_NAME)}:`,
              `${walletKeysLabelsMap.get(walletKeys.BUSINESS_REFERENCE)}:`,
            ]
          : []),
        `${accountKeysLabelsMap.get(accountKeys.CURRENCY)}:`,
        `${accountKeysLabelsMap.get(accountKeys.SORT_CODE)}:`,
        `${accountKeysLabelsMap.get(accountKeys.NUMBER)}:`,
        `${accountKeysLabelsMap.get(accountKeys.IBAN)}:`,
        `${accountKeysLabelsMap.get(accountKeys.BIC_SWIFT)}:`,
        `${accountKeysLabelsMap.get(accountKeys.STATUS)}:`,
        `Document generation date:`,
      ],
      margin.left,
      160,
      { lineHeightFactor: 2 },
    );

    await pdfDocument.setFont(fontName, 'normal');

    // account details values
    await pdfDocument.text(
      [
        `${account[accountKeys.ALIAS]}`,
        `${account[accountKeys.HOLDER_NAME]}`,
        `${accountTypesLabelsMap.get(account[accountKeys.TYPE])}`,
        ...(isWallet
          ? [
              `${
                masterAccount?.[accountKeys.ALIAS] ||
                account[walletKeys.PAYOUT_ACCOUNT_ID]
              }`,
              `${account[walletKeys.OWNER_ID]}`,
              `${account[walletKeys.OWNER_NAME]}`,
              `${account[walletKeys.BUSINESS_REFERENCE]}`,
            ]
          : []),
        `${account[accountKeys.CURRENCY] || 'n/a'}`,
        `${formatSortCodeValue(account[accountKeys.SORT_CODE])}`,
        `${account[accountKeys.NUMBER] || 'n/a'}`,
        `${
          conformToMask(account[accountKeys.IBAN], ibanInputMaskPattern, {
            guide: false,
          }).conformedValue || 'n/a'
        }`,
        `${
          conformToMask(
            account[accountKeys.BIC_SWIFT],
            bicSwiftInputMaskPattern,
            {
              guide: false,
            },
          ).conformedValue || 'n/a'
        }`,
        `${accountStatusesLabels.get(account[accountKeys.STATUS]) || 'n/a'}`,
        `${formatTimeZonedDate(date.toISOString(), timeZone)}`,
      ],
      margin.left + 150,
      160,
      { lineHeightFactor: 2 },
    );

    await pdfFooterCreator(pdfDocument, { theme });

    await pdfDocument.save(
      typeof filename === 'function'
        ? await filename({ date, account })
        : filename,
    );
  } catch (error) {
    if (onError) await onError(error);
  } finally {
    if (loadingStateSetter) await loadingStateSetter(false);
  }
};

export default createAccountDetailsPdf;
