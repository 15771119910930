import { string, object, number, lazy } from 'yup';
import { isValidIBAN } from 'ibantools';
import {
  getRequiredErrorMessage,
  getLengthErrorMessage,
  getDigitsLengthError,
  formatCurrencyValue,
} from '@fondy/utils';
import { makePaymentFormTransferTypes } from './makePaymentFormTransferTypes';
import {
  counterpartyTypes,
  newPaymentKeys,
  newPaymentKeysLabelsMap,
} from '../../../../utils';

export const maxPaymentGlobalLimit = 250000;
const errorMessageRequired = getRequiredErrorMessage();
export const onlyDigitsRegex = /^[\d]+$/;
export const chapsStringValidationRegex = /^[a-zA-Z0-9/\-?:().,'+ ]+$/;
export const fasterPaymentsStringValidationRegex = /^[a-zA-Z0-9/\-?:().,'+ #=!“%&*<>;{@\r]+$/;

export const commonNewPaymentValidationSchema = ({
  maxAvailableAmount = maxPaymentGlobalLimit,
  isPaymentCurrencyGBP,
  useSchemeForIBAN,
  paymentCurrencyIso,
  paymentFxRate,
}) => {
  const validationSchema = object().shape({
    [newPaymentKeys.TRANSFER_TYPE]: number(),
    [newPaymentKeys.DESTINATION_ACCOUNT_ID]: lazy((_, { parent }) => {
      const transferType = parent[newPaymentKeys.TRANSFER_TYPE];
      const uuidType = string().uuid().nullable();
      if (!transferType) return uuidType;
      return transferType === makePaymentFormTransferTypes.BETWEEN_ACCOUNTS
        ? uuidType.required(errorMessageRequired)
        : uuidType;
    }),
    [newPaymentKeys.ACCOUNT_ID]: string()
      .uuid()
      .nullable()
      .required(errorMessageRequired),
    [newPaymentKeys.AMOUNT]: lazy(() => {
      let maxAllowedPaymentAmount = Math.min(
        maxAvailableAmount,
        maxPaymentGlobalLimit,
      );

      if (!isPaymentCurrencyGBP && paymentFxRate) {
        maxAllowedPaymentAmount /= paymentFxRate;
        maxAllowedPaymentAmount = parseFloat(
          maxAllowedPaymentAmount.toFixed(2),
        );
      }

      return number()
        .positive()
        .max(
          maxAllowedPaymentAmount,
          `The max allowed amount to transfer from selected account is ${formatCurrencyValue(
            maxAllowedPaymentAmount,
            {
              currencyIso: paymentCurrencyIso,
            },
          )}`,
        )
        .required(errorMessageRequired);
    }),

    [newPaymentKeys.PAYEE_FIRST_NAME]: string()
      .nullable()
      .max(...getLengthErrorMessage(100))
      .matches(chapsStringValidationRegex, {
        message: 'You have entered an invalid character',
        excludeEmptyString: true,
      })
      .when(newPaymentKeys.TRANSFER_TYPE, {
        is: makePaymentFormTransferTypes.RECIPIENT,
        then: (schema) =>
          schema.when(newPaymentKeys.PAYEE_TYPE, {
            is: counterpartyTypes.PERSONAL,
            then: (s) => s.required(errorMessageRequired),
          }),
      }),
    [newPaymentKeys.PAYEE_LAST_NAME]: string()
      .nullable()
      .max(...getLengthErrorMessage(100))
      .matches(chapsStringValidationRegex, {
        message: 'You have entered an invalid character',
        excludeEmptyString: true,
      })
      .when(newPaymentKeys.TRANSFER_TYPE, {
        is: makePaymentFormTransferTypes.RECIPIENT,
        then: (schema) =>
          schema.when(newPaymentKeys.PAYEE_TYPE, {
            is: counterpartyTypes.PERSONAL,
            then: (s) => s.required(errorMessageRequired),
          }),
      }),
    [newPaymentKeys.PAYEE_COMPANY_NAME]: string()
      .nullable()
      .max(...getLengthErrorMessage(100))
      .matches(chapsStringValidationRegex, {
        message: 'You have entered an invalid character',
        excludeEmptyString: true,
      })
      .when(newPaymentKeys.TRANSFER_TYPE, {
        is: makePaymentFormTransferTypes.RECIPIENT,
        then: (schema) =>
          schema.when(newPaymentKeys.PAYEE_TYPE, {
            is: counterpartyTypes.BUSINESS,
            then: (s) => s.required(errorMessageRequired),
          }),
      }),
  });

  if (useSchemeForIBAN) {
    return validationSchema
      .shape({
        [newPaymentKeys.PAYEE_IBAN]: string()
          .ensure()
          .required(errorMessageRequired)
          .test({
            name: 'iban',
            exclusive: true,
            message: 'Please provide valid IBAN',
            test: (value) => isValidIBAN(value.replaceAll(' ', '')),
          }),
        [newPaymentKeys.PAYEE_COUNTRY]: string()
          .ensure()
          .required(errorMessageRequired),
        [newPaymentKeys.PAYEE_CITY]: string()
          .ensure()
          .trim()
          .required(errorMessageRequired)
          .max(...getLengthErrorMessage(22))
          .matches(
            chapsStringValidationRegex,
            `You have entered an invalid character for ${newPaymentKeysLabelsMap.get(
              newPaymentKeys.PAYEE_CITY,
            )}`,
          ),
        [newPaymentKeys.PAYEE_POSTAL_CODE]: string()
          .ensure()
          .trim()
          .required(errorMessageRequired)
          .min(...getLengthErrorMessage(3, false))
          .max(...getLengthErrorMessage(10))
          .matches(
            chapsStringValidationRegex,
            `You have entered an invalid character for ${newPaymentKeysLabelsMap.get(
              newPaymentKeys.PAYEE_POSTAL_CODE,
            )}`,
          ),
      })
      .shape(
        {
          [newPaymentKeys.PAYEE_STREET]: string()
            .ensure()
            .trim()
            .max(...getLengthErrorMessage(35))
            .matches(chapsStringValidationRegex, {
              message: `You have entered an invalid character for ${newPaymentKeysLabelsMap.get(
                newPaymentKeys.PAYEE_STREET,
              )}`,
              excludeEmptyString: true,
            })
            .when(newPaymentKeys.PAYEE_BUILDING_NAME, {
              is: '',
              then: (schema) => schema.required(errorMessageRequired),
            }),
          [newPaymentKeys.PAYEE_BUILDING_NAME]: string()
            .ensure()
            .trim()
            .max(...getLengthErrorMessage(27))
            .matches(chapsStringValidationRegex, {
              message: `You have entered an invalid character for ${newPaymentKeysLabelsMap.get(
                newPaymentKeys.PAYEE_BUILDING_NAME,
              )}`,
              excludeEmptyString: true,
            })
            .when(newPaymentKeys.PAYEE_STREET, {
              is: '',
              then: (schema) => schema.required(errorMessageRequired),
            }),
          [newPaymentKeys.PAYEE_BUILDING_NUMBER]: string()
            .ensure()
            .trim()
            .max(...getLengthErrorMessage(8))
            .matches(chapsStringValidationRegex, {
              message: `You have entered an invalid character for ${newPaymentKeysLabelsMap.get(
                newPaymentKeys.PAYEE_BUILDING_NUMBER,
              )}`,
              excludeEmptyString: true,
            })
            .when(newPaymentKeys.PAYEE_BUILDING_NAME, {
              is: '',
              then: (schema) => schema.required(errorMessageRequired),
            }),
        },
        [newPaymentKeys.PAYEE_STREET, newPaymentKeys.PAYEE_BUILDING_NAME],
      );
  }

  return validationSchema.shape({
    [newPaymentKeys.PAYEE_ACCOUNT_NUMBER]: string()
      .ensure()
      .required(errorMessageRequired)
      .length(
        ...getDigitsLengthError(
          8,
          newPaymentKeysLabelsMap.get(newPaymentKeys.PAYEE_ACCOUNT_NUMBER),
        ),
      )
      .matches(
        onlyDigitsRegex,
        getDigitsLengthError(
          8,
          newPaymentKeysLabelsMap.get(newPaymentKeys.PAYEE_ACCOUNT_NUMBER),
        )[1],
      ),
    [newPaymentKeys.PAYEE_SORT_CODE]: string()
      .ensure()
      .trim()
      .required(errorMessageRequired)
      .length(
        ...getDigitsLengthError(
          6,
          newPaymentKeysLabelsMap.get(newPaymentKeys.PAYEE_SORT_CODE),
        ),
      )
      .matches(
        onlyDigitsRegex,
        getDigitsLengthError(
          6,
          newPaymentKeysLabelsMap.get(newPaymentKeys.PAYEE_SORT_CODE),
        )[1],
      ),
  });
};

export const newFasterPaymentValidationSchema = (validationProps) =>
  commonNewPaymentValidationSchema(validationProps).shape({
    [newPaymentKeys.PAYEE_NAME]: string()
      .required(errorMessageRequired)
      .max(...getLengthErrorMessage(35))
      .matches(
        chapsStringValidationRegex,
        `You have entered an invalid character for ${newPaymentKeysLabelsMap.get(
          newPaymentKeys.PAYEE_NAME,
        )}`,
      ),
    [newPaymentKeys.DESCRIPTION]: string()
      .required(errorMessageRequired)
      .max(...getLengthErrorMessage(18))
      .matches(
        chapsStringValidationRegex,
        `You have entered an invalid character for ${newPaymentKeysLabelsMap.get(
          newPaymentKeys.DESCRIPTION,
        )}`,
      ),
  });
