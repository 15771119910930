import { accountKeys, accountKeysLabelsMap } from './accountKeys';

export const walletKeys = Object.freeze({
  ...accountKeys,
  PAYOUT_ACCOUNT_ID: 'payoutAccountId',
  OWNER_ID: 'ownerId',
  OWNER_NAME: accountKeys.ALIAS,
  BUSINESS_REFERENCE: 'businessReference',
  IS_DEFAULT: 'isDefault',
});

export const walletKeysLabelsMap = new Map([
  ...accountKeysLabelsMap,
  [walletKeys.PAYOUT_ACCOUNT_ID, 'Wallet Master Account'],
  [walletKeys.OWNER_ID, 'Wallet Owner Id'],
  [accountKeys.OWNER_NAME, 'Wallet Owner Name'],
  [accountKeys.ALIAS, 'Wallet Owner Name'],
  ['ownerName', 'Wallet Owner Name'],
  [walletKeys.BUSINESS_REFERENCE, 'Wallet Business Reference'],
  [walletKeys.IS_DEFAULT, 'Default unmatched wallet'],
]);
