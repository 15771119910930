import { defaultPageMargins } from '@fondy/pdf';
import fondyLogotype from './fondyLogotype.png';

const pdfHeaderCreator = async (
  pdfDocument,
  { theme, margin = defaultPageMargins, title },
) => {
  const pageWidth = await pdfDocument.getPageWidth(0);
  if (theme) {
    await pdfDocument.setDrawColor(theme.palette.text.secondaryLight);
    await pdfDocument.setTextColor(theme.palette.text.secondary);
  }

  await pdfDocument.setFontSize(9);
  await pdfDocument.line(
    margin.left,
    margin.top,
    pageWidth - margin.right,
    margin.top,
    'FD',
  );

  await pdfDocument.addImage(
    fondyLogotype,
    'PNG',
    margin.left,
    53,
    100,
    26,
    'sygnet',
    'FAST',
  );

  await pdfDocument.text(
    ['Contact tel: +44 (0) 20 3807 4651', 'www.fondy.io'],
    pageWidth - margin.right,
    60,
    { align: 'right', lineHeightFactor: 1.5 },
  );

  await pdfDocument.line(
    margin.left,
    margin.top + 50,
    pageWidth - margin.right,
    margin.top + 50,
    'FD',
  );

  await pdfDocument.setTextColor(theme.colors.BLACK);

  if (title) {
    const { fontName } = await pdfDocument.getFont();
    await pdfDocument.setFontSize(13);
    await pdfDocument.setFont(fontName, 'bold');
    await pdfDocument.text(title, margin.left, 120, {
      lineHeightFactor: 2,
    });
    await pdfDocument.setFontSize(10);
  }
};

export default pdfHeaderCreator;
