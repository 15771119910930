/* eslint-disable react/prop-types */
import React from 'react';
import { Grid } from '@material-ui/core';
import {
  StatusTableCell,
  AmountTableCell,
  AccountAliasTableCell,
} from '@fondy/tables';
import {
  accountTypes,
  accountTypesLabelsMap,
  accountStatusesLabels,
} from '@fondy/enums';
import { accountKeys } from '../../../utils';
import BadgeTableCell from '../BadgeTableCell';

const renderBadgeCell = (row) =>
  row[accountKeys.TYPE] === accountTypes.MASTER &&
  row?.filteredWalletsCount !== undefined &&
  row?.isFilterActive === true ? (
    <BadgeTableCell badgeContent={row?.filteredWalletsCount} />
  ) : null;

export const mobileAccountsTableColumns = [
  {
    id: accountKeys.ALIAS,
    label: 'Account name',
    render: ({ value, row, searchQuery }) => (
      <Grid container alignItems="center">
        <AccountAliasTableCell
          small
          showCurrencyIso
          accountAlias={value}
          searchQueryHighlight={searchQuery}
          currencyIso={row[accountKeys.CURRENCY]}
          accountType={row[accountKeys.TYPE]}
          style={{ width: 'unset', marginRight: 8 }}
        />
        {renderBadgeCell(row)}
      </Grid>
    ),
  },
  {
    id: accountKeys.CURRENT_BALANCE,
    align: 'right',
    label: 'Balance',
    style: { width: 100 },
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[accountKeys.CURRENCY]}
        justifyContent="flex-end"
        valueWrapperFn={(formattedValue) => (
          <>
            <div>{formattedValue}</div>
            <StatusTableCell
              small
              status={row[accountKeys.STATUS]}
              labelsMap={accountStatusesLabels}
            />
          </>
        )}
      />
    ),
  },
];

const accountsTableColumns = [
  {
    id: accountKeys.ALIAS,
    style: { width: '40%' },
    label: 'Account name',
    render: ({ value, row, searchQuery }) => (
      <Grid container alignItems="center">
        <AccountAliasTableCell
          small
          accountAlias={value}
          searchQueryHighlight={searchQuery}
          currencyIso={row[accountKeys.CURRENCY]}
          alignTop={row[accountKeys.TYPE] === accountTypes.WALLET}
          style={{ width: 'unset', marginRight: 8 }}
        />
        {renderBadgeCell(row)}
      </Grid>
    ),
  },
  {
    id: accountKeys.TYPE,
    numeric: false,
    label: 'Type',
    render: ({ value }) => (
      <StatusTableCell status={value} labelsMap={accountTypesLabelsMap} />
    ),
    style: {
      textOverflow: 'unset',
      width: '15%',
    },
  },
  {
    id: accountKeys.STATUS,
    numeric: false,
    label: 'Status',
    render: ({ value }) => (
      <StatusTableCell status={value} labelsMap={accountStatusesLabels} />
    ),
    style: {
      textOverflow: 'unset',
      width: '15%',
    },
  },
  {
    id: accountKeys.CURRENCY,
    numeric: false,
    label: 'Currency',
    style: { width: '10%' },
  },
  {
    id: accountKeys.CURRENT_BALANCE,
    align: 'right',
    label: 'Balance',
    style: { width: '20%' },
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[accountKeys.CURRENCY]}
        justifyContent="flex-end"
        variant="body2"
        valueWrapperFn={(formattedValue) =>
          row[accountKeys.TYPE] !== accountTypes.WALLET ? (
            <span style={{ fontWeight: 600 }}>{formattedValue}</span>
          ) : (
            formattedValue
          )
        }
      />
    ),
  },
];

export default accountsTableColumns;
