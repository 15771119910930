import exists from './exists';
import { paymentsKeys } from './enums';

const isPaymentBetweenOwnAccounts = (payment) => {
  if (!exists(payment)) return false;

  const hasCounterpartyAccountId = exists(
    payment[paymentsKeys.COUNTERPARTY_ACCOUNT_ID],
  );

  const hasCounterpartyAccountAlias = exists(
    payment[paymentsKeys.COUNTERPARTY_ACCOUNT_ALIAS],
  );

  const hasCounterpartyAccountCurrency = exists(
    payment[paymentsKeys.COUNTERPARTY_ACCOUNT_CURRENCY],
  );

  return (
    hasCounterpartyAccountId &&
    hasCounterpartyAccountAlias &&
    hasCounterpartyAccountCurrency
  );
};

export default isPaymentBetweenOwnAccounts;
