import React, { useCallback } from 'react';
import { head, last, isNil, isEmpty } from 'rambda';
import { makeStyles } from '@material-ui/core/styles';
import { func, number, string, arrayOf, shape, bool, node } from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { formatCurrencyValue } from '@fondy/utils';
import { currency, currencyDecimalSeparator } from '@fondy/enums';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonSize,
  buttonVariant,
} from '@fondy/buttons';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'block',
    lineHeight: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
      marginLeft: theme.spacing(2),
    },
  },
  action: {
    textAlign: 'left',
    fontSize: 12,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const TotalBalance = ({
  totalBalance,
  currencyIso,
  actions,
  renderCurrency,
}) => {
  const classes = useStyles();

  const actionsRenderer = useCallback(
    ({ label, onClick, icon: Icon, ...rest }) => (
      <Button
        {...rest}
        key={label}
        onClick={onClick}
        color={buttonColor.PRIMARY}
        variant={buttonVariant.OUTLINED}
        shape={buttonShape.ROUNDED}
        size={buttonSize.MEDIUM}
        startIcon={Icon && <Icon />}
        className={classes.action}
        link
      >
        {label}
      </Button>
    ),
    [classes.action],
  );

  if (isEmpty(totalBalance) || isNil(totalBalance) || !currencyIso) {
    return <Skeleton />;
  }

  const currencyId = currency[currencyIso];
  const decimalSeparator = currencyDecimalSeparator.get(currencyId);
  const formattedTotalBalance = formatCurrencyValue(totalBalance, {
    currencyIso,
  });
  const splittedTotalBalance = formattedTotalBalance.split(decimalSeparator);

  return (
    <Box data-aio-id="totalBalance">
      <span>{head(splittedTotalBalance)}</span>
      <Typography variant="h3" variantMapping={{ h3: 'span' }}>
        {`${decimalSeparator}${last(splittedTotalBalance)}${
          renderCurrency ? ` ${currencyIso}` : ''
        }`}
      </Typography>
      {actions && (
        <div className={classes.actions}>{actions.map(actionsRenderer)}</div>
      )}
    </Box>
  );
};

TotalBalance.propTypes = {
  totalBalance: number,
  currencyIso: string,
  renderCurrency: bool,
  actions: arrayOf(
    shape({
      label: node.isRequired,
      onClick: func,
      icon: func,
    }),
  ),
};

TotalBalance.defaultProps = {
  totalBalance: null,
  currencyIso: null,
  actions: null,
  renderCurrency: false,
};

export default TotalBalance;
