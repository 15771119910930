import React, { useState, useCallback } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, CircularProgress, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { endOfDay, startOfDay, subYears } from 'date-fns';
import { GridWidget } from '@fondy/data-display';
import {
  orderTypes,
  currencyIsoCodes,
  currency,
  paymentStatuses,
  paymentStatusesLabelsMap,
} from '@fondy/enums';
import { StatusMessage } from '@fondy/alerts';
import { PageHeader } from '@fondy/layouts';
import { ConnectedFilteredTable, filterFieldTypes } from '@fondy/tables';
import { ErrorIllustration } from '@fondy/icons';
import AppLayout from '../../layouts/AppLayout';
import { paymentsTableColumns, mobilePaymentsTableColumns } from '../../atoms';
import { MakePaymentButton, PaymentDetailsModal } from '../../organisms';
import {
  stateSelectAccountsAliases,
  stateTimeZoneSelector,
} from '../../../redux';
import {
  paymentsKeys,
  paymentsKeysLabelsMap,
  tableAmountFilter,
  tableCurrencyFilter,
  tableAmountFilterLabelGetter,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  paymentsTableCell: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
    },
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
  },
  statusError: {
    margin: theme.spacing(2, 0),
    '& svg > path:first-child': {
      fill: theme.palette.secondary.lightest,
    },
  },
}));

function PaymentsPage() {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const currentDate = new Date();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
  const { accountsAliases, isLoading, isError, apiError } = useSelector(
    stateSelectAccountsAliases,
  );
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [paymentsTableDependecy, refreshPayments] = useState(false);

  const handlePaymentRecordClick = useCallback(
    async (_, row) => setPaymentDetails(row),
    [],
  );

  const handlePaymentDetailsModalClose = useCallback(
    () => setPaymentDetails(null),
    [],
  );

  return (
    <AppLayout>
      <PageHeader
        title="Payments"
        actions={[
          <MakePaymentButton
            key="new-payment"
            onSuccess={() => refreshPayments((prevState) => !prevState)}
          />,
        ]}
      />

      <GridWidget container noBottomPadding spacing={0}>
        {!isLoading && isError && (
          <StatusMessage
            error={apiError}
            illustration={ErrorIllustration}
            className={classes.statusError}
          />
        )}
        {isLoading && !isError && (
          <Grid item xs={12} className={classes.spinnerWrapper}>
            <CircularProgress />
          </Grid>
        )}
        {!isLoading && !isError && (
          <ConnectedFilteredTable
            disableHeader={isMobile}
            defaultOrderBy={paymentsKeys.CREATED_AT}
            defaultOrderDirection={orderTypes.DESCENDING}
            rowId={paymentsKeys.ID}
            url="/api/core-banking/payments"
            emptyComponent="You don't have any payments yet"
            columns={
              isMobile ? mobilePaymentsTableColumns : paymentsTableColumns
            }
            pagination={{
              size: 10,
            }}
            dependencies={[paymentsTableDependecy, accountsAliases]}
            onCellClick={handlePaymentRecordClick}
            dateFilters={{
              timeZone: legalEntityTimezone,
              minDate: startOfDay(subYears(currentDate, 1)),
              maxDate: endOfDay(currentDate),
              twoFactorIsActive: false,
              dateFrom: {
                label: 'Start date',
              },
              dateTo: {
                label: 'End date',
              },
            }}
            // searchableParamName="filter"
            filters={{
              // [paymentsKeys.TYPE]: {
              //   label: 'Payment type',
              //   values: ['COUNTERPARTY', 'BETWEEN_ACCOUNTS'],
              //   type: filterFieldTypes.CHECKBOX,
              //   selectItemLabelGetter: ({ value }) =>
              //     value === 'COUNTERPARTY'
              //       ? 'Counterparty'
              //       : 'Between accounts',
              // },
              // [paymentsKeys.CURRENCY]: {
              //   label: 'Currency',
              //   values: tableCurrencyFilter,
              //   type: filterFieldTypes.CHECKBOX,
              // },
              // [paymentsKeys.AMOUNT]: {
              //   label: 'Amount',
              //   values: Object.keys(tableAmountFilter),
              //   selectItemLabelGetter: tableAmountFilterLabelGetter,
              //   type: filterFieldTypes.CHECKBOX,
              // },
              [paymentsKeys.STATUS]: {
                label: paymentsKeysLabelsMap.get(paymentsKeys.STATUS),
                values: paymentStatuses,
                valuesLabelsMap: paymentStatusesLabelsMap,
                type: filterFieldTypes.CHECKBOX,
              },
            }}
            classes={{
              cell: classes.paymentsTableCell,
            }}
          />
        )}
      </GridWidget>

      <PaymentDetailsModal
        open={!!paymentDetails}
        payment={paymentDetails}
        onClose={handlePaymentDetailsModalClose}
      />
    </AppLayout>
  );
}

export default PaymentsPage;
