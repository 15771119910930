/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@material-ui/core';
import { AvatarDataCell, StatusTableCell } from '@fondy/tables';
import FormattedDateTime from '../FormattedDateTime';
import {
  splitAccountComplianceProfileKeys,
  splitAccountComplianceProfileKeysLabelsMap,
  complianceProfileStatusesLabelsMap,
  complianceProfileTypesLabelsMap,
  complianceProfileStatuses,
} from '../../../utils';

const splitAccountProfilesTableColumns = [
  {
    id: splitAccountComplianceProfileKeys.FULL_NAME,
    numeric: false,
    disablePadding: false,
    label: splitAccountComplianceProfileKeysLabelsMap.get(
      splitAccountComplianceProfileKeys.FULL_NAME,
    ),
    render: ({ value }) => <AvatarDataCell title={value} />,
  },
  {
    id: splitAccountComplianceProfileKeys.ID,
    numeric: false,
    disablePadding: false,
    label: splitAccountComplianceProfileKeysLabelsMap.get(
      splitAccountComplianceProfileKeys.ID,
    ),
  },
  {
    id: splitAccountComplianceProfileKeys.STATUS,
    numeric: false,
    disablePadding: false,
    label: splitAccountComplianceProfileKeysLabelsMap.get(
      splitAccountComplianceProfileKeys.STATUS,
    ),
    style: {
      textOverflow: 'unset',
    },
    render: ({ value }) => {
      const isAllowedValue = Object.values(complianceProfileStatuses).includes(
        value,
      );

      return (
        <StatusTableCell
          status={isAllowedValue ? value : complianceProfileStatuses.PENDING}
          labelsMap={complianceProfileStatusesLabelsMap}
        />
      );
    },
  },
  {
    id: splitAccountComplianceProfileKeys.PROFILE_TYPE,
    numeric: false,
    disablePadding: false,
    label: splitAccountComplianceProfileKeysLabelsMap.get(
      splitAccountComplianceProfileKeys.PROFILE_TYPE,
    ),
    style: {
      textOverflow: 'unset',
    },
    render: ({ value }) => (
      <StatusTableCell
        status={value}
        labelsMap={complianceProfileTypesLabelsMap}
        style={{
          backgroundColor: 'transparent',
        }}
      />
    ),
  },
  {
    id: splitAccountComplianceProfileKeys.DATE_CREATED,
    numeric: false,
    disablePadding: false,
    label: splitAccountComplianceProfileKeysLabelsMap.get(
      splitAccountComplianceProfileKeys.DATE_CREATED,
    ),
    render: ({ value }) =>
      value ? (
        <FormattedDateTime utcIsoDate={value} />
      ) : (
        <Typography variant="subtitle2" color="textSecondary">
          n/a
        </Typography>
      ),
  },
];

export default splitAccountProfilesTableColumns;
