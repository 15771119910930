export const splitAccountTransactionTypes = Object.freeze({
  PAYIN: 'PAYIN',
  WALLET: 'WALLET',
  REVERSAL: 'REVERSAL',
  PAYOUT: 'PAYOUT',
  PROFIT: 'PROFIT',
  FEE: 'FEE',
});

export const splitAccountTransactionTypesLabelsMap = new Map([
  [splitAccountTransactionTypes.PAYIN, 'Payin'],
  [splitAccountTransactionTypes.WALLET, 'Wallet'],
  [splitAccountTransactionTypes.REVERSAL, 'Reversal'],
  [splitAccountTransactionTypes.PAYOUT, 'Payout'],
  [splitAccountTransactionTypes.PROFIT, 'Profit'],
  [splitAccountTransactionTypes.FEE, 'Fee'],
]);
