const accountsActionTypes = Object.freeze({
  ADD_ACCOUNT: 'ADD_ACCOUNT',
  ADD_ACCOUNT_ERROR: 'ADD_ACCOUNT_ERROR',
  ADD_ACCOUNT_IS_FETCHING: 'ADD_ACCOUNT_IS_FETCHING',
  GET_ACCOUNTS: 'GET_ACCOUNTS',
  GET_ACCOUNTS_IS_FETCHING: 'GET_ACCOUNTS_IS_FETCHING',
  GET_ACCOUNTS_ERROR: 'GET_ACCOUNTS_ERROR',
});

export default accountsActionTypes;
