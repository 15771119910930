import React, { useState, useCallback, useMemo } from 'react';
import { head, pick } from 'rambda';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { ConnectedFilteredTable, filterFieldTypes } from '@fondy/tables';
import { Dialog, GridWidget } from '@fondy/data-display';
import { StatusMessage } from '@fondy/alerts';
import { httpMethods } from '@fondy/enums';
import {
  CounterpartyPossibleMatchIllustration,
  ErrorAccountCreationIllustration,
} from '@fondy/icons';
import { PageHeader } from '@fondy/layouts';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonSize,
  buttonVariant,
} from '@fondy/buttons';
import { currency, currencyIsoCodes } from '@fondy/enums/src/currency';
import { FormControls } from '@fondy/forms';
import {
  counterpartiesPageModalStates,
  counterpartiesPageModalStateToVisibilityMap,
  counterpartiesPageModalTitlesMap,
} from './utils';
import AppLayout from '../../layouts/AppLayout';
import { CounterpartyEditForm } from '../../organisms';
import {
  counterpartiesTableColumns,
  mobileCounterpartiesTableColumns,
} from '../../atoms';
import {
  paymentCounterpartyKeys,
  partyAssociationsKeys,
  paymentCounterpartyKeysLabelsMapClean,
  counterpartyTypes,
  counterpartyBankDetailsTypes,
} from '../../../utils';

const defaultDuplicateMatchState = {
  match: null,
  values: null,
  handleRequest: null,
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 640,
    },
  },
  dialogHeader: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),

    '& h6': {
      fontSize: 12,
      color: theme.palette.text.secondaryDark,
    },
  },
  containerChild: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(2, 4),
  },
  nav: {
    '& button:not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
  statusMessage: {
    fontSize: 14,
    color: theme.palette.text.secondaryDark,
    '& > b': {
      fontWeight: 600,
    },
  },
  statusMessageContainer: {
    flex: 1,
  },
  statusMessageButtons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

const getSuccessfulMessageByModalStatus = (modalStatus) => {
  switch (modalStatus) {
    case counterpartiesPageModalStates.ADD:
      return 'New counterparty successfully created';
    case counterpartiesPageModalStates.EDIT:
      return 'Counterparty successfully saved';
    case counterpartiesPageModalStates.DELETE:
      return 'Counterparty successfully deleted';
    default:
      return null;
  }
};

function CounterpartiesPage() {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
  const userPartyId = useSelector(
    (state) => head(state.parties.data)[partyAssociationsKeys.PARTY_ID],
  );
  const [modalSelectedRecipient, setModalSelectedRecipient] = useState(
    undefined,
  );
  const [formError, setFormError] = useState(null);
  const [duplicateMatch, setDuplicateMatch] = useState(
    defaultDuplicateMatchState,
  );
  const [modalStatus, setModalStatus] = useState(null);
  const [refetchDependency, toggleRefetchDependency] = useState(false);
  const [isDuplicateSubmitting, setIsDuplicateSubmitting] = useState(false);

  const handleCloseModal = useCallback(() => {
    setDuplicateMatch(defaultDuplicateMatchState);
    setFormError(null);
    setModalStatus(counterpartiesPageModalStates.HIDDEN);
    setModalSelectedRecipient(undefined);
  }, []);

  const handleMenuOptionClick = useCallback(
    (nextModalState) => (_, row) => {
      if (row)
        setModalSelectedRecipient({
          ...row,
          ...(row[paymentCounterpartyKeys.ADDRESS]
            ? row[paymentCounterpartyKeys.ADDRESS]
            : {}),
          [paymentCounterpartyKeys.TYPE]:
            row[paymentCounterpartyKeys.TYPE] ?? counterpartyTypes.PERSONAL,
          [paymentCounterpartyKeys.BANK_DETAILS_TYPE]: row[
            paymentCounterpartyKeys.IBAN
          ]
            ? counterpartyBankDetailsTypes.IBAN
            : counterpartyBankDetailsTypes.ACCOUNT_NUMBER,
        });
      setModalStatus(nextModalState);
    },
    [],
  );

  const handleSubmitModal = useCallback(() => {
    const message = getSuccessfulMessageByModalStatus(modalStatus);
    if (message) {
      enqueueSnackbar({
        message,
        variant: 'success',
      });
    }

    handleCloseModal();
    toggleRefetchDependency((prev) => !prev);
  }, [enqueueSnackbar, handleCloseModal, modalStatus]);

  const handleModalFormError = useCallback((error) => {
    setFormError(error);
    setModalStatus(counterpartiesPageModalStates.ERROR);
  }, []);

  const handleDuplicate = useCallback(({ values, match, handleRequest }) => {
    setIsDuplicateSubmitting(() => false);

    setDuplicateMatch({ match, values, handleRequest });
    setModalStatus(counterpartiesPageModalStates.DUPLICATE);
  }, []);

  const columnProps = useMemo(
    () => ({
      onEdit: handleMenuOptionClick(counterpartiesPageModalStates.EDIT),
      onDelete: handleMenuOptionClick(counterpartiesPageModalStates.DELETE),
    }),
    [handleMenuOptionClick],
  );

  const columns = useMemo(
    () =>
      isMobile
        ? mobileCounterpartiesTableColumns(columnProps)
        : counterpartiesTableColumns(columnProps),
    [columnProps, isMobile],
  );

  return (
    <AppLayout>
      <PageHeader
        title="Counterparties"
        actions={[
          <Button
            color={buttonColor.PRIMARY}
            variant={buttonVariant.CONTAINED}
            shape={buttonShape.ROUNDED}
            size={buttonSize.LARGE}
            startIcon={<AddRounded style={{ fontSize: 16 }} />}
            onClick={handleMenuOptionClick(counterpartiesPageModalStates.ADD)}
            data-aio-id="addCounterpartyButton"
            key="create-new"
          >
            Create new counterparty
          </Button>,
        ]}
      />

      <GridWidget container noBottomPadding spacing={0}>
        <ConnectedFilteredTable
          disableRowHover
          disableHeader={isMobile}
          defaultOrderBy={paymentCounterpartyKeys.NAME}
          searchableParamName="filter"
          rowId={paymentCounterpartyKeys.ID}
          url={`/api/core-banking/parties/${userPartyId}/payees`}
          emptyComponent="You don't have any counterparties yet"
          columns={columns}
          dependencies={[refetchDependency]}
          filters={{
            [paymentCounterpartyKeys.CURRENCY]: {
              label: paymentCounterpartyKeysLabelsMapClean.get(
                paymentCounterpartyKeys.CURRENCY,
              ),
              values: [
                currencyIsoCodes.get(currency.GBP),
                currencyIsoCodes.get(currency.EUR),
                currencyIsoCodes.get(currency.USD),
              ],
              type: filterFieldTypes.SELECT,
            },
          }}
          pagination={{
            size: 10,
          }}
        />
      </GridWidget>

      <Dialog
        dense
        fullWidth
        maxWidth={false}
        open={counterpartiesPageModalStateToVisibilityMap.get(modalStatus)}
        onClose={handleCloseModal}
        header={counterpartiesPageModalTitlesMap.get(modalStatus)}
        className={classes.dialog}
        subtitle={
          [
            counterpartiesPageModalStates.EDIT,
            counterpartiesPageModalStates.ADD,
          ].includes(modalStatus)
            ? 'Your counterparty should have a UK or EU bank account'
            : undefined
        }
        headerProps={{
          className: classes.dialogHeader,
        }}
      >
        <Grid container>
          <Grid item xs={12} className={classes.containerChild}>
            {formError && (
              <StatusMessage
                title="Counterparty error"
                message={
                  <>
                    <Typography variant="body2" gutterBottom>
                      There was a problem with the counterparty.
                    </Typography>
                    <Typography variant="body2">Please try again</Typography>
                  </>
                }
                onConfirm={handleCloseModal}
                illustration={ErrorAccountCreationIllustration}
              />
            )}
            {duplicateMatch.match && (
              <>
                <StatusMessage
                  className={classes.statusMessageContainer}
                  title="Possible match"
                  message={
                    <Typography
                      variant="body1"
                      className={classes.statusMessage}
                    >
                      We’ve found an existing counterparty with the name
                      <b>{` ${
                        duplicateMatch.match[paymentCounterpartyKeys.NAME]
                      }`}</b>
                      .
                      <br />
                      Do you wish to continue?
                    </Typography>
                  }
                  illustration={CounterpartyPossibleMatchIllustration}
                  renderConfirmButton={false}
                />

                <FormControls
                  className={classes.statusMessageButtons}
                  formState={{
                    isSubmitting: isDuplicateSubmitting,
                  }}
                  submitText="Yes"
                  cancelText="No"
                  onCancel={handleCloseModal}
                  onSubmit={() => {
                    setIsDuplicateSubmitting(() => true);

                    return duplicateMatch.handleRequest(duplicateMatch.values);
                  }}
                  disabled={isDuplicateSubmitting}
                  column={isMobile}
                  inverseButtons={isMobile}
                  align="center"
                  buttonsProps={{
                    size: buttonSize.LARGE,
                    fullWidth: isMobile,
                  }}
                  submitButtonProps={{
                    variant: buttonVariant.CONTAINED,
                    'data-aio-id': 'createDuplicatedCounterpartyButton',
                  }}
                  resetButtonProps={{
                    variant: buttonVariant.OUTLINED,
                    'data-aio-id': 'cancelDuplicatedCounterpartyButton',
                  }}
                />
              </>
            )}
            {!formError &&
              modalStatus === counterpartiesPageModalStates.ADD && (
                <CounterpartyEditForm
                  submitText="Create"
                  method={httpMethods.POST}
                  url={`/api/core-banking/parties/${userPartyId}/payees`}
                  onCancel={handleCloseModal}
                  onSubmit={handleSubmitModal}
                  onError={handleModalFormError}
                  onDuplicate={handleDuplicate}
                />
              )}
            {!formError &&
              modalStatus === counterpartiesPageModalStates.EDIT && (
                <CounterpartyEditForm
                  submitText="Save changes"
                  method={httpMethods.PUT}
                  url={`/api/core-banking/parties/${userPartyId}/payees/${
                    modalSelectedRecipient[paymentCounterpartyKeys.ID]
                  }`}
                  initialValues={modalSelectedRecipient}
                  onCancel={handleCloseModal}
                  onSubmit={handleSubmitModal}
                  onError={handleModalFormError}
                  disabledFields={[
                    paymentCounterpartyKeys.ACCOUNT_NUMBER,
                    paymentCounterpartyKeys.SORT_CODE,
                    paymentCounterpartyKeys.CURRENCY,
                    paymentCounterpartyKeys.IBAN,
                    paymentCounterpartyKeys.BANK_DETAILS_TYPE,
                  ]}
                />
              )}
            {!formError &&
              modalStatus === counterpartiesPageModalStates.DELETE && (
                <CounterpartyEditForm
                  submitText="Yes"
                  cancelText="No"
                  method={httpMethods.DELETE}
                  url={`/api/core-banking/parties/${userPartyId}/payees/${
                    modalSelectedRecipient[paymentCounterpartyKeys.ID]
                  }`}
                  initialValues={modalSelectedRecipient}
                  onCancel={handleCloseModal}
                  onSubmit={handleSubmitModal}
                  onError={handleModalFormError}
                  disabledFields={[
                    paymentCounterpartyKeys.NAME,
                    paymentCounterpartyKeys.TYPE,
                    paymentCounterpartyKeys.BANK_DETAILS_TYPE,
                    paymentCounterpartyKeys.FIRST_NAME,
                    paymentCounterpartyKeys.LAST_NAME,
                    paymentCounterpartyKeys.COMPANY_NAME,
                    paymentCounterpartyKeys.ACCOUNT_NUMBER,
                    paymentCounterpartyKeys.SORT_CODE,
                    paymentCounterpartyKeys.CURRENCY,
                    paymentCounterpartyKeys.IBAN,
                    paymentCounterpartyKeys.ADDRESS,
                  ]}
                />
              )}
          </Grid>
        </Grid>
      </Dialog>
    </AppLayout>
  );
}

export default CounterpartiesPage;
