import { CallSplitRounded } from '@material-ui/icons';
import {
  AccountsIcon,
  HelpIcon,
  HomeIcon,
  MerchantsIcon,
  PaymentsIcon,
  SettingsIcon,
  CounterpartiesIcon,
} from '@fondy/icons';
import { getAppVersion } from '../../../utils';

export const sidebarNavigation = [
  {
    label: 'Home',
    icon: HomeIcon,
    to: '/',
    exact: true,
    aioTestId: 'homeMenuLink',
  },
  {
    label: 'Accounts',
    icon: AccountsIcon,
    to: '/accounts',
    aioTestId: 'accountsMenuLink',
  },
  {
    label: 'Payments',
    icon: PaymentsIcon,
    to: '/payments',
    aioTestId: 'paymentsMenuLink',
  },
  {
    label: 'Counterparties',
    icon: CounterpartiesIcon,
    to: '/counterparties',
    aioTestId: 'counterpartiesMenuLink',
  },
  {
    label: 'Merchants',
    icon: MerchantsIcon,
    to: '/merchants',
    aioTestId: 'merchantsMenuLink',
  },
  {
    divider: true,
  },
  {
    label: 'Split Account',
    icon: CallSplitRounded,
    to: '/split-account',
    aioTestId: 'splitAccountMenuLink',
  },
];

export const bottomSidebarNavigation = [
  {
    label: 'Settings',
    icon: SettingsIcon,
    to: '/settings',
    aioTestId: 'settingsMenuLink',
  },
  {
    divider: true,
  },
  {
    label: 'Help',
    to: '/help',
    icon: HelpIcon,
    disabled: true,
    aioTestId: 'helpMenuLink',
  },
];

export const footerNavigation = [
  // {
  //   label: 'Cookie settings',
  //   to: '/settings/cookies',
  // },
  // {
  //   label: 'Terms & conditions',
  //   to: '/help/terms-and-conditions',
  // },
  // {
  //   label: 'Privacy policy',
  //   to: '/help/privacy-policy',
  // },
  // {
  //   label: 'Give feedback',
  //   to: '/help/feedback',
  //   icon: HelpIcon,
  //   primaryColor: true,
  // },
  {
    key: 'version',
    label: getAppVersion(),
    disabled: true,
    aioTestId: 'versionMenuLink',
  },
];
