export const accountKeys = Object.freeze({
  ID: 'id',
  PARTY_ID: 'partyId',
  PRODUCT_ID: 'productId',
  PRODUCT_CODE: 'productCode',
  NAME: 'name',
  ALIAS: 'alias',
  HOLDER_NAME: 'holderName',
  CURRENCY: 'currency',
  CURRENT_BALANCE: 'currentBalance',
  DATE_CREATED: 'createdAt',
  DATE_OPENED: 'openedAt',
  STATUS: 'status',
  SORT_CODE: 'sortCode',
  NUMBER: 'number',
  BIC_SWIFT: 'bicSwift',
  IBAN: 'iban',
  TYPE: 'accountType',
});

export const accountKeysLabelsMap = new Map([
  [accountKeys.ID, 'ID'],
  [accountKeys.PARTY_ID, 'Party ID'],
  [accountKeys.PRODUCT_ID, 'Product ID'],
  [accountKeys.PRODUCT_CODE, 'Product code'],
  [accountKeys.NAME, 'Account name'],
  [accountKeys.ALIAS, 'Account alias'],
  [accountKeys.HOLDER_NAME, 'Account holder name'],
  [accountKeys.CURRENCY, 'Account currency'],
  [accountKeys.CURRENT_BALANCE, 'Current balance'],
  [accountKeys.DATE_CREATED, 'Date created'],
  [accountKeys.DATE_OPENED, 'Date opened'],
  [accountKeys.STATUS, 'Status'],
  [accountKeys.SORT_CODE, 'Sort code'],
  [accountKeys.NUMBER, 'Account number'],
  [accountKeys.BIC_SWIFT, 'BIC/SWIFT'],
  [accountKeys.IBAN, 'IBAN'],
  [accountKeys.TYPE, 'Account type'],
]);
