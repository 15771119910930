/* eslint-disable react/prop-types */
import React from 'react';
import {
  AvatarDataCell,
  AmountTableCell,
  AccountAliasTableCell,
} from '@fondy/tables';
import { formatTimeZonedDate } from '@fondy/utils';
import { singleAccountTransactionsTableColumnsBase } from './transactionsTableColumns';
import FormattedDateTime from '../FormattedDateTime';
import {
  splitAccountTransactionKeys,
  splitAccountTransactionKeysLabelsMap,
  isTransactionBetweenOwnAccounts,
  splitAccountTransactionTypesLabelsMap,
} from '../../../utils';

const getAmountValuePrefix = (transaction) =>
  transaction[splitAccountTransactionKeys.ACCOUNT_OPENING_BALANCE] >
  transaction[splitAccountTransactionKeys.ACCOUNT_CLOSING_BALANCE]
    ? '- '
    : '+ ';

const commonSplitAccountWalletTransactionsTableColumns = [
  {
    id: splitAccountTransactionKeys.BOOKING_DATE,
    numeric: false,
    disablePadding: false,
    label: splitAccountTransactionKeysLabelsMap.get(
      splitAccountTransactionKeys.BOOKING_DATE,
    ),
    render: ({ value }) => <FormattedDateTime utcIsoDate={value} />,
  },
  {
    id: splitAccountTransactionKeys.COUNTERPARTY_NAME,
    numeric: false,
    disablePadding: false,
    label: 'Name & reference',
    render: ({ row }) => {
      const renderAsAccount = isTransactionBetweenOwnAccounts(row);
      if (renderAsAccount) {
        return (
          <AccountAliasTableCell
            small
            currencyIso={
              row[splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_CURRENCY]
            }
            accountAlias={
              row[splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_ALIAS]
            }
            accountType={
              row[splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_TYPE]
            }
            subtitle={row[splitAccountTransactionKeys.TRANSACTION_REFERENCE]}
            data-aio-id="transactionNameAndReferenceCell"
          />
        );
      }

      return (
        <AvatarDataCell
          title={row[splitAccountTransactionKeys.COUNTERPARTY_NAME]}
          subtitle={row[splitAccountTransactionKeys.TRANSACTION_REFERENCE]}
          data-aio-id="transactionNameAndReferenceCell"
        />
      );
    },
  },
];

const balanceColumn = {
  id: splitAccountTransactionKeys.AMOUNT,
  numeric: true,
  disablePadding: false,
  label: splitAccountTransactionKeysLabelsMap.get(
    splitAccountTransactionKeys.AMOUNT,
  ),
  align: 'right',
  render: ({ value, row }) => (
    <>
      {row[splitAccountTransactionKeys.TRANSACTION_ORIGINAL_CURRENCY] ? (
        <AmountTableCell
          value={row[splitAccountTransactionKeys.ORIGINAL_AMOUNT]}
          currencyIso={
            row[splitAccountTransactionKeys.TRANSACTION_ORIGINAL_CURRENCY]
          }
          variant="subtitle2"
        />
      ) : null}
      <AmountTableCell
        value={value}
        valuePrefix={getAmountValuePrefix(row)}
        currencyIso={row[splitAccountTransactionKeys.TRANSACTION_CURRENCY]}
        subtitleCurrencyIso={
          row[splitAccountTransactionKeys.TRANSACTION_CURRENCY]
        }
        subtitleValue={row[splitAccountTransactionKeys.ACCOUNT_CLOSING_BALANCE]}
        justifyContent="flex-end"
        data-test-id="transactionAmountCell"
        data-aio-id="transactionAmountCell"
      />
    </>
  ),
};

const splitAccountTransactionTypeColumn = {
  id: splitAccountTransactionKeys.TYPE,
  numeric: false,
  disablePadding: false,
  label: splitAccountTransactionKeysLabelsMap.get(
    splitAccountTransactionKeys.TYPE,
  ),
  render: ({ value }) => splitAccountTransactionTypesLabelsMap.get(value),
  style: {
    textOverflow: 'unset',
  },
  renderPdf: ({ value }) =>
    splitAccountTransactionTypesLabelsMap.get(value) || value,
};

export const splitAccountWalletTransactionsTableColumns = [
  ...commonSplitAccountWalletTransactionsTableColumns,
  splitAccountTransactionTypeColumn,
  balanceColumn,
];

export const splitAccountTransactionsTableColumns = [
  ...commonSplitAccountWalletTransactionsTableColumns,
  splitAccountTransactionTypeColumn,
  balanceColumn,
];

export const splitAccountTransactionsPdfTableColumns = (
  legalEntityTimezone,
) => [
  {
    id: splitAccountTransactionKeys.BOOKING_DATE,
    numeric: false,
    disablePadding: false,
    label: 'Date',
    render: ({ value }) => <FormattedDateTime utcIsoDate={value} />,
    renderPdf: ({ value }) => formatTimeZonedDate(value, legalEntityTimezone),
  },
  ...singleAccountTransactionsTableColumnsBase({
    injectSplitAccountTransactionType: splitAccountTransactionTypeColumn,
    injectTransactionReference: false,
  }),
];

export const splitAccountWalletTransactionsPdfTableColumns = (
  legalEntityTimezone,
) => [
  {
    id: splitAccountTransactionKeys.BOOKING_DATE,
    numeric: false,
    disablePadding: false,
    label: 'Date',
    render: ({ value }) => <FormattedDateTime utcIsoDate={value} />,
    renderPdf: ({ value }) => formatTimeZonedDate(value, legalEntityTimezone),
  },
  ...singleAccountTransactionsTableColumnsBase({
    injectSplitAccountTransactionType: splitAccountTransactionTypeColumn,
    injectTransactionReference: false,
  }),
];
