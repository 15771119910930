/* eslint-disable react/prop-types */
import React from 'react';
import { AmountTableCell, AccountAliasTableCell } from '@fondy/tables';
import { accountKeys, accountKeysLabelsMap } from '../../../utils';

const getLabel = (id) => accountKeysLabelsMap.get(id).replace('Account ', '');
const getAccountsSummaryTableColumns = (isMobile = false) => [
  {
    id: accountKeys.ALIAS,
    padding: 'none',
    label: getLabel(accountKeys.NAME),
    render: ({ value, row }) => (
      <AccountAliasTableCell
        fontSize={16}
        fontWeight={500}
        accountAlias={value}
        currencyIso={row[accountKeys.CURRENCY]}
        showCurrencyIso={isMobile}
        accountType={row[accountKeys.TYPE]}
      />
    ),
  },
  ...(isMobile
    ? []
    : [
        {
          id: accountKeys.CURRENCY,
          style: { width: 50 },
          align: 'center',
          label: getLabel(accountKeys.CURRENCY),
        },
      ]),
  {
    id: accountKeys.CURRENT_BALANCE,
    padding: 'none',
    align: 'right',
    style: { width: isMobile ? '35%' : '25%' },
    label: getLabel(accountKeys.CURRENT_BALANCE),
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[accountKeys.CURRENCY]}
        justifyContent="flex-end"
      />
    ),
  },
];

export default getAccountsSummaryTableColumns;
