import { head } from 'rambda';

const recipientAutocompleteHandleEnterKeyDown = (onEnter) => ({
  event,
  options,
  closeHandler,
}) => {
  if (options.length === 1 && head(options).type === 'new') {
    event.preventDefault();
    event.stopPropagation();
    if (!!onEnter && !!closeHandler) {
      onEnter(event);
      closeHandler();
    }
  }
};

export default recipientAutocompleteHandleEnterKeyDown;
