import { ErrorIcon, WarningIcon, AllSetIcon } from '@fondy/icons';

export const copKeys = Object.freeze({
  ID: 'id',
  NEED_CONFIRMATION: 'needConfirmation',
  ACCOUNT_STATUS: 'accountStatus',
  NAME_CHECK_STATUS: 'nameCheckStatus',
  CODES: 'codes',
  RESPONDED_NAME: 'respondedName',
  STATUS: 'status',
});

export const copStatus = Object.freeze({
  FULL_MATCH: 'FULL_MATCH',
  PARTIAL_MATCH: 'PARTIAL_MATCH',
  NO_MATCH: 'NO_MATCH',
});

export const copNameCheckStatus = Object.freeze({
  FULL_MATCH: 'FULL_MATCH',
  NO_MATCH: 'NO_MATCH',
  PARTIAL_MATCH: 'PARTIAL_MATCH',
  UNABLE_TO_MATCH: 'UNABLE_TO_MATCH',
});

export const copAccountStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CLOSED: 'CLOSED',
  FORBIDDEN: 'FORBIDDEN',
  NOT_FOUND: 'NOT_FOUND',
  UNABLE_TO_MATCH: 'UNABLE_TO_MATCH',
});

export const copKeysLabelsMap = new Map([
  [copKeys.NAME_CHECK_STATUS, 'Payee name check status'],
  [copKeys.ACCOUNT_STATUS, 'Payee account status'],
  [copKeys.RESPONDED_NAME, 'Responded payee name'],
]);

export const copStatusIconMap = new Map([
  [copStatus.FULL_MATCH, AllSetIcon],
  [copStatus.PARTIAL_MATCH, WarningIcon],
  [copStatus.NO_MATCH, ErrorIcon],
]);

export const copStatusTitleMap = new Map([
  [copStatus.FULL_MATCH, 'All set!'],
  [copStatus.PARTIAL_MATCH, 'Warning'],
  [copStatus.NO_MATCH, 'Error'],
]);

export const copStatusDescriptionLine1Map = new Map([
  [copStatus.FULL_MATCH, 'The payee details are verified and correct.'],
  [
    copStatus.PARTIAL_MATCH,
    'The details you entered don’t match the bank’s records.',
  ],
  [
    copStatus.NO_MATCH,
    'We can’t process the payment due to incorrect payee details.',
  ],
]);

export const copStatusDescriptionLine2Map = new Map([
  [copStatus.FULL_MATCH, 'You’re good to go!'],
  [
    copStatus.PARTIAL_MATCH,
    'Please double-check them, as payments sent to the wrong account can’t be recovered.',
  ],
  [copStatus.NO_MATCH, 'Please check the account information and try again.'],
]);

export const copNameCheckStatusMap = new Map([
  [copNameCheckStatus.FULL_MATCH, 'Full match'],
  [copNameCheckStatus.NO_MATCH, 'No match'],
  [copNameCheckStatus.PARTIAL_MATCH, 'Partial match'],
  [copNameCheckStatus.UNABLE_TO_MATCH, 'Unable to match'],
]);

export const copAccountStatusMap = new Map([
  [copAccountStatus.ACTIVE, 'Active'],
  [copAccountStatus.INACTIVE, 'Inactive'],
  [copAccountStatus.CLOSED, 'Closed'],
  [copAccountStatus.FORBIDDEN, 'Forbidden'],
  [copAccountStatus.NOT_FOUND, 'Not found'],
  [copAccountStatus.UNABLE_TO_MATCH, 'Unable to match'],
]);

export const copCodesMap = new Map([
  ['ANNM', 'Account Name does Not Match'],
  ['MBAM', 'There may be a match on the Account Name'],
  ['BANM', 'Business account, name matches'],
  ['PANM', 'Personal account, name matches'],
  ['BAMM', 'Business account, name may be a match'],
  ['PAMM', 'Personal account, name may be a match'],
  ['AC01', 'Incorrect Account Number'],
  ['IVCR', 'Invalid Customer Reference'],
  ['ACNS', 'Account type Not Supported for CoP'],
  ['OPTO', 'Opted out of CoP Service'],
  ['CASS', 'Account has been switched'],
  ['SCNS', 'Sort code not supported at endpoint'],
]);
