import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { pick, isNil, omit } from 'rambda';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import { Grid, CircularProgress, Tooltip, SvgIcon } from '@material-ui/core';
import { bool, func, string, shape, oneOf, arrayOf } from 'prop-types';
import { DownloadIcon } from '@fondy/icons';
import { StatusTableCell, AvatarDataCell } from '@fondy/tables';
import { createPdfDocument, defaultPageMargins } from '@fondy/pdf';
import { Dialog, ObjectDataDisplay } from '@fondy/data-display';
import {
  complianceProfileTypes,
  complianceProfileStatuses,
  complianceProfileRiskScores,
  splitAccountComplianceProfileKeys,
  splitAccountComplianceProfileKeysLabelsMap,
  complianceProfileComplianceTypes,
  complianceProfileRiskScoresLabelsMap,
  complianceProfileTypesLabelsMap,
  complianceProfileStatusesLabelsMap,
  complianceProfileStatusesTooltipsMap,
} from '../../../utils';
import { pdfHeaderCreator, pdfFooterCreator } from '../../atoms/pdfSections';

const mergedSplitAccountComplianceProfileKeysLabelsMap = new Map([
  ...splitAccountComplianceProfileKeysLabelsMap,
  [splitAccountComplianceProfileKeys.OVERALL_RISK_SCORE, 'Risk Score'],
  [
    splitAccountComplianceProfileKeys.STATUS,
    splitAccountComplianceProfileKeysLabelsMap.get(
      splitAccountComplianceProfileKeys.STATUS,
    ),
  ],
]);

const getSplitAccountComplianceProfileDetails = pick([
  splitAccountComplianceProfileKeys.ID,
  splitAccountComplianceProfileKeys.EXTERNAL_ID,
  splitAccountComplianceProfileKeys.FULL_NAME,
  splitAccountComplianceProfileKeys.EMAIL,
  splitAccountComplianceProfileKeys.PHONE,
  splitAccountComplianceProfileKeys.PROFILE_TYPE,
  splitAccountComplianceProfileKeys.STATUS,
  splitAccountComplianceProfileKeys.OVERALL_RISK_SCORE,
  splitAccountComplianceProfileKeys.EXTERNAL_URL_VALIDATION,
]);

const dataFieldsCopyableValues = Object.freeze({
  [splitAccountComplianceProfileKeys.ID]: true,
  [splitAccountComplianceProfileKeys.EXTERNAL_ID]: true,
  [splitAccountComplianceProfileKeys.FULL_NAME]: true,
  [splitAccountComplianceProfileKeys.EMAIL]: true,
  [splitAccountComplianceProfileKeys.PHONE]: true,
  [splitAccountComplianceProfileKeys.EXTERNAL_URL_VALIDATION]: true,
});

const omitExternalUrlValidation = omit([
  splitAccountComplianceProfileKeys.EXTERNAL_URL_VALIDATION,
]);

const dataFieldsMapper = {
  [splitAccountComplianceProfileKeys.STATUS]: (value) => {
    const isAllowedValue = Object.values(complianceProfileStatuses).includes(
      value,
    );
    return (
      <Grid container spacing={1}>
        <Grid item>
          <StatusTableCell
            status={isAllowedValue ? value : complianceProfileStatuses.PENDING}
            labelsMap={complianceProfileStatusesLabelsMap}
          />
        </Grid>
        <Grid item style={{ display: 'flex' }}>
          <Tooltip
            arrow
            title={complianceProfileStatusesTooltipsMap.get(
              isAllowedValue ? value : complianceProfileStatuses.PENDING,
            )}
            style={{
              alignSelf: 'center',
            }}
          >
            <InfoOutlined fontSize="small" color="disabled" />
          </Tooltip>
        </Grid>
      </Grid>
    );
  },
  [splitAccountComplianceProfileKeys.OVERALL_RISK_SCORE]: (value) => (
    <StatusTableCell
      status={value}
      labelsMap={complianceProfileRiskScoresLabelsMap}
    />
  ),
  [splitAccountComplianceProfileKeys.PROFILE_TYPE]: (value) =>
    complianceProfileTypesLabelsMap?.get(value) || value,
};

const pdfDataFieldsMapper = {
  [splitAccountComplianceProfileKeys.OVERALL_RISK_SCORE]: (value) =>
    complianceProfileRiskScoresLabelsMap.get(value) || value,
  [splitAccountComplianceProfileKeys.PROFILE_TYPE]: (value) =>
    complianceProfileTypesLabelsMap?.get(value) || value,
  [splitAccountComplianceProfileKeys.STATUS]: (value) =>
    complianceProfileStatusesLabelsMap.get(value) ||
    complianceProfileStatusesLabelsMap.get(complianceProfileStatuses.PENDING),
};

const useStyles = makeStyles((theme) => ({
  dataDisplay: {
    margin: 0,
    '&:first-of-type': {
      marginTop: theme.spacing(1),
    },
  },
  collapse: {
    padding: 0,
  },
  row: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  value: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      justifyContent: 'space-between',
    },
  },
}));

const SplitAccountComplianceProfileDetailsModal = ({
  profile,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(() => (!isNil(open) ? open : !!profile));
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const objectDataDisplayClasses = useMemo(
    () => ({
      item: classes.row,
      value: classes.value,
      collapse: classes.collapse,
    }),
    [classes.collapse, classes.row, classes.value],
  );

  const closeHandler = useCallback(() => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handlePdfDownload = useCallback(async () => {
    try {
      await setIsGeneratingPdf(true);

      const { pdfDocument } = await createPdfDocument();
      const { fontName } = await pdfDocument.getFont();
      const pageWidth = await pdfDocument.getPageWidth();

      await pdfHeaderCreator(pdfDocument, { theme, title: 'Profile Details' });

      const profileData = getSplitAccountComplianceProfileDetails(profile);
      const startingY = 160;
      const labelsMaxWidth = 150;

      // Profile details labels
      await pdfDocument.text(
        Object.keys(profileData).map(
          (i) => mergedSplitAccountComplianceProfileKeysLabelsMap.get(i) || i,
        ),
        defaultPageMargins.left,
        startingY,
        {
          lineHeightFactor: 2,
        },
      );

      await pdfDocument.setFont(fontName, 'normal');

      // Split EXTERNAL_URL_VALIDATION into multiline array (needs to be on the last pos)
      const externalUrlValidationString =
        profileData[splitAccountComplianceProfileKeys.EXTERNAL_URL_VALIDATION];
      const externalUrlValidationArray = pdfDocument.splitTextToSize(
        externalUrlValidationString,
        pageWidth -
          (defaultPageMargins.left + defaultPageMargins.right + labelsMaxWidth),
      );

      // Profile details values
      await pdfDocument.text(
        [
          ...Object.entries(omitExternalUrlValidation(profileData)).map(
            ([key, value]) =>
              pdfDataFieldsMapper?.[key]?.(value) || value || 'n/a',
          ),
          ...externalUrlValidationArray,
        ],
        defaultPageMargins.left + labelsMaxWidth,
        startingY,
        {
          lineHeightFactor: 2,
        },
      );

      await pdfFooterCreator(pdfDocument, { theme });

      await pdfDocument.save(
        `${
          profileData[splitAccountComplianceProfileKeys.FULL_NAME]
        } - profile details`,
      );
    } catch (error) {
      console.warn(error);
    } finally {
      await setIsGeneratingPdf(false);
    }
  }, [profile, theme]);

  useEffect(() => {
    setIsOpen(!isNil(profile));
    return () => {
      setIsOpen(false);
    };
  }, [profile]);

  if (!profile) return null;

  return (
    <Dialog
      dense
      disableHeaderPadding
      open={isOpen}
      onClose={closeHandler}
      centeredHeader={false}
      contentProps={{
        dividers: true,
      }}
      header={
        <AvatarDataCell
          avatarSize="large"
          titleProps={{
            variant: 'h3',
            gutterBottom: true,
          }}
          subtitleProps={{
            variant: 'subtitle1',
          }}
          title={profile?.[splitAccountComplianceProfileKeys.FULL_NAME]}
          subtitle={profile?.[splitAccountComplianceProfileKeys.EMAIL]}
        />
      }
      footerActions={[
        {
          label: 'Download profile details',
          color: 'secondary',
          size: 'large',
          onClick: handlePdfDownload,
          startIcon: isGeneratingPdf ? (
            <CircularProgress size={14} className={classes.progress} />
          ) : (
            <SvgIcon style={{ fontSize: 20 }} component={DownloadIcon} />
          ),
          disabled: isGeneratingPdf,
          'data-aio-id': 'downloadTransactionStatementButton',
        },
      ]}
    >
      <Grid container spacing={0}>
        <Grid item grid={{ xs: 12 }}>
          <ObjectDataDisplay
            classes={objectDataDisplayClasses}
            className={classes.dataDisplay}
            data={getSplitAccountComplianceProfileDetails(profile)}
            dataFieldsLabelsMap={
              mergedSplitAccountComplianceProfileKeysLabelsMap
            }
            dataFieldsCopyableValues={dataFieldsCopyableValues}
            dataFieldsMapper={dataFieldsMapper}
            grid={{ xs: 12 }}
            itemSpacing={1}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

SplitAccountComplianceProfileDetailsModal.propTypes = {
  open: bool,
  onClose: func,
  profile: shape({
    [splitAccountComplianceProfileKeys.ID]: string,
    [splitAccountComplianceProfileKeys.CUSTOMER_ID]: string,
    [splitAccountComplianceProfileKeys.STATUS]: oneOf(
      Object.values(complianceProfileStatuses),
    ),
    [splitAccountComplianceProfileKeys.DATE_CREATED]: string,
    [splitAccountComplianceProfileKeys.EMAIL]: string,
    [splitAccountComplianceProfileKeys.EXTERNAL_ID]: string,
    [splitAccountComplianceProfileKeys.EXTERNAL_URL_VALIDATION]: string,
    [splitAccountComplianceProfileKeys.FULL_NAME]: string,
    [splitAccountComplianceProfileKeys.IDENTITY_VERSIONS]: arrayOf(string),
    [splitAccountComplianceProfileKeys.LATEST_IDENTITY]: string,
    [splitAccountComplianceProfileKeys.OVERALL_RISK_SCORE]: oneOf(
      Object.values(complianceProfileRiskScores),
    ),
    [splitAccountComplianceProfileKeys.PHONE]: string,
    [splitAccountComplianceProfileKeys.PROFILE_TYPE]: oneOf(
      Object.values(complianceProfileTypes),
    ),
    [splitAccountComplianceProfileKeys.TYPE_OF_COMPLIANCE]: oneOf(
      Object.values(complianceProfileComplianceTypes),
    ),
  }),
};

SplitAccountComplianceProfileDetailsModal.defaultProps = {
  open: null,
  profile: null,
  onClose: null,
};

export default SplitAccountComplianceProfileDetailsModal;
