/* eslint-disable no-unused-vars */
import { createPdfDocument, defaultPageMargins } from '@fondy/pdf';
import { paymentStatusesLabelsMap, paymentStatuses } from '@fondy/enums';
import { conformToMask, ibanInputMaskPattern } from '@fondy/forms';
import {
  formatSortCodeValue,
  formatTimeZonedDate,
  formatCurrencyValue,
} from '@fondy/utils';
import pdfHeaderCreator from '../pdfHeaderCreator';
import pdfFooterCreator from '../pdfFooterCreator';
import {
  paymentsKeys,
  accountKeys,
  accountKeysLabelsMap,
  paymentsKeysLabelsMap,
  PAYMENT_SUBTYPE_MCPO,
  ibanToStringFormatter,
  paymentsKeysDetailsLabelsMap,
} from '../../../../utils';

const createPaymentDetailsPdf = async ({
  payment,
  sourceAccount,
  targetAccount,
  loadingStateSetter,
  onError,
  theme,
  timeZone,
  rejectionMapping,
  isSettlement = false,
  margin = defaultPageMargins,
  filename = 'payment-details.pdf',
}) => {
  try {
    if (loadingStateSetter) await loadingStateSetter(true);

    const date = new Date();
    const { pdfDocument } = await createPdfDocument();
    const { fontName } = await pdfDocument.getFont();
    const pageWidth = await pdfDocument.getPageWidth();
    const rightColumnX = pageWidth / 2;
    const rightColumnMaxWidth = pageWidth / 2 - (margin.right + 5);
    const documentTitleY = 230;
    const isRejected =
      payment[paymentsKeys.STATUS] === paymentStatuses.REJECTED;
    const rejectionCode = isRejected
      ? rejectionMapping(payment[paymentsKeys.REJECTION_CODE])
      : '';
    const rejectionCodeLinesRatio =
      (await pdfDocument.getTextWidth(rejectionCode)) / rightColumnMaxWidth;
    const rejectionCodeLines =
      rejectionCodeLinesRatio > 1.35 ? Math.ceil(rejectionCodeLinesRatio) : 1;
    let extraOffset = rejectionCodeLines * 25;

    if (payment[paymentsKeys.PAYMENT_SUBTYPE] === PAYMENT_SUBTYPE_MCPO) {
      extraOffset += 60;
    }

    // draw top header with logotype
    await pdfHeaderCreator(pdfDocument, {
      theme,
    });

    // account details labels
    await pdfDocument.setFontSize(10);
    await pdfDocument.setFont(fontName, 'bold');
    await pdfDocument.text(
      [
        `${accountKeysLabelsMap.get(accountKeys.NAME)}:`,
        `${accountKeysLabelsMap.get(accountKeys.IBAN)}:`,
        `${accountKeysLabelsMap.get(accountKeys.SORT_CODE)}:`,
        `${accountKeysLabelsMap.get(accountKeys.NUMBER)}:`,
      ],
      pageWidth - (margin.right + 160),
      120,
      { lineHeightFactor: 1.8, align: 'right' },
    );

    // account details
    await pdfDocument.setFont(fontName, 'normal');
    await pdfDocument.text(
      [
        `${sourceAccount[accountKeys.ALIAS]}`,
        `${
          conformToMask(sourceAccount[accountKeys.IBAN], ibanInputMaskPattern, {
            guide: false,
          }).conformedValue || 'n/a'
        }`,
        `${formatSortCodeValue(sourceAccount[accountKeys.SORT_CODE]) || 'n/a'}`,
        `${sourceAccount[accountKeys.NUMBER] || 'n/a'}`,
      ],
      pageWidth - (margin.right + 155),
      120,
      { lineHeightFactor: 1.8, align: 'left' },
    );

    // document title background rectangle
    await pdfDocument.setFillColor(theme.palette.secondary.lightest);
    await pdfDocument.context2d.fillRect(
      margin.left,
      documentTitleY,
      pageWidth - (margin.left + margin.right),
      30,
    );

    // document title
    await pdfDocument.setFontSize(12);
    await pdfDocument.text(
      `${isSettlement ? 'Settlement' : 'Payment'} Confirmation`,
      margin.left + 10,
      documentTitleY + 20,
    );
    await pdfDocument.text(
      `Document generation date: ${formatTimeZonedDate(
        date.toISOString(),
        timeZone,
      )}`,
      rightColumnX,
      documentTitleY + 20,
    );

    // payment details labels
    await pdfDocument.setFont(fontName, 'normal');
    await pdfDocument.text(
      [
        `${paymentsKeysLabelsMap.get(paymentsKeys.CREATED_AT)}:`,
        ...(payment[paymentsKeys.PAYMENT_SUBTYPE] === PAYMENT_SUBTYPE_MCPO
          ? [
              `${paymentsKeysLabelsMap.get(paymentsKeys.ORIGINAL_AMOUNT)}:`,
              `${paymentsKeysLabelsMap.get(paymentsKeys.FX_RATE)}:`,
            ]
          : []),
        `${paymentsKeysLabelsMap.get(paymentsKeys.AMOUNT)}:`,
        `${paymentsKeysLabelsMap.get(paymentsKeys.STATUS)}:`,
        ...(isRejected
          ? [
              paymentsKeysLabelsMap.get(paymentsKeys.REJECTION_CODE),
              ...Array.from({ length: rejectionCodeLines - 1 }, () => ''),
            ]
          : []),
        `${paymentsKeysLabelsMap.get(paymentsKeys.DESCRIPTION)}:`,
        `${paymentsKeysLabelsMap.get(paymentsKeys.END_2_END_ID)}:`,
        `${paymentsKeysLabelsMap.get(paymentsKeys.ID)}:`,
      ],
      margin.left,
      300,
      { lineHeightFactor: 1.8 },
    );

    // payment details values
    await pdfDocument.setFont(fontName, 'normal');
    await pdfDocument.text(
      [
        `${formatTimeZonedDate(payment[paymentsKeys.CREATED_AT], timeZone, {
          dateFormat: 'dd.MM.yyyy HH:mm',
        })}`,
        ...(payment[paymentsKeys.PAYMENT_SUBTYPE] === PAYMENT_SUBTYPE_MCPO
          ? [
              `${formatCurrencyValue(payment[paymentsKeys.ORIGINAL_AMOUNT], {
                currencyIso: payment[paymentsKeys.ORIGINAL_CURRENCY],
              })}`,
              `${payment[paymentsKeys.FX_RATE]}`,
            ]
          : []),
        `${formatCurrencyValue(payment[paymentsKeys.AMOUNT], {
          currencyIso: payment[paymentsKeys.CURRENCY],
        })}`,
        `${paymentStatusesLabelsMap.get(payment[paymentsKeys.STATUS])}`,
        ...(isRejected ? [rejectionCode] : []),
        `${payment[paymentsKeys.DESCRIPTION]}`,
        `${payment[paymentsKeys.END_2_END_ID]}`,
        `${payment[paymentsKeys.ID]}`,
      ],
      rightColumnX,
      300,
      { lineHeightFactor: 1.8, maxWidth: rightColumnMaxWidth },
    );

    // From labels
    await pdfDocument.setFont(fontName, 'bold');
    await pdfDocument.text('From', margin.left, 440 + extraOffset, {
      lineHeightFactor: 1.8,
    });
    await pdfDocument.setFont(fontName, 'normal');
    await pdfDocument.text(
      [
        `Account Holder Name:`,
        ...(sourceAccount ? ['Account Name:'] : []),
        `${accountKeysLabelsMap.get(accountKeys.SORT_CODE)}:`,
        `${accountKeysLabelsMap.get(accountKeys.NUMBER)}:`,
      ],
      margin.left,
      460 + extraOffset,
      { lineHeightFactor: 1.8 },
    );

    // From values
    await pdfDocument.text(
      [
        `${
          isSettlement
            ? payment[paymentsKeys.ACCOUNT_ID]
            : sourceAccount[accountKeys.HOLDER_NAME]
        }`,
        ...(sourceAccount ? [sourceAccount[accountKeys.ALIAS]] : []),
        `${
          formatSortCodeValue(
            isSettlement
              ? payment[paymentsKeys.COUNTERPARTY_SORT_CODE]
              : sourceAccount[accountKeys.SORT_CODE],
          ) || 'n/a'
        }`,
        `${
          (isSettlement
            ? payment[paymentsKeys.COUNTERPARTY_ACCOUNT_NUMBER]
            : sourceAccount[accountKeys.NUMBER]) || 'n/a'
        }`,
      ],
      rightColumnX,
      460 + extraOffset,
      { lineHeightFactor: 1.8 },
    );

    const toY = sourceAccount ? 580 : 560;

    // To labels
    await pdfDocument.setFont(fontName, 'bold');
    await pdfDocument.text('To', margin.left, toY - 20 + extraOffset, {
      lineHeightFactor: 1.8,
    });
    await pdfDocument.setFont(fontName, 'normal');
    await pdfDocument.text(
      [
        `Account Holder Name:`,
        ...(targetAccount ? ['Account Name:'] : []),
        ...(payment[paymentsKeys.COUNTERPARTY_IBAN]
          ? [
              `${paymentsKeysDetailsLabelsMap.get(
                paymentsKeys.COUNTERPARTY_IBAN,
              )}:`,
            ]
          : [
              `${accountKeysLabelsMap.get(accountKeys.SORT_CODE)}:`,
              `${accountKeysLabelsMap.get(accountKeys.NUMBER)}:`,
            ]),
      ],
      margin.left,
      toY + extraOffset,
      { lineHeightFactor: 1.8 },
    );

    // To values
    await pdfDocument.text(
      [
        `${
          isSettlement
            ? sourceAccount[accountKeys.HOLDER_NAME]
            : payment[paymentsKeys.COUNTERPARTY_NAME]
        }`,
        ...(targetAccount ? [targetAccount[accountKeys.ALIAS]] : []),
        ...(payment[paymentsKeys.COUNTERPARTY_IBAN]
          ? [
              `${ibanToStringFormatter(
                payment[paymentsKeys.COUNTERPARTY_IBAN],
              )}`,
            ]
          : [
              `${formatSortCodeValue(
                isSettlement
                  ? sourceAccount[accountKeys.SORT_CODE]
                  : payment[paymentsKeys.COUNTERPARTY_SORT_CODE],
              )}`,
              `${
                isSettlement
                  ? sourceAccount[accountKeys.NUMBER]
                  : payment[paymentsKeys.COUNTERPARTY_ACCOUNT_NUMBER]
              }`,
            ]),
      ],
      rightColumnX,
      toY + extraOffset,
      { lineHeightFactor: 1.8 },
    );

    await pdfFooterCreator(pdfDocument, { theme });

    await pdfDocument.save(
      typeof filename === 'function'
        ? await filename({ date, payment })
        : filename,
    );
  } catch (error) {
    if (onError) await onError(error);
    console.warn(error);
  } finally {
    if (loadingStateSetter) await loadingStateSetter(false);
  }
};

export default createPaymentDetailsPdf;
