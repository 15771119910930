import { partyActionTypes } from '../constants';

const partyReducer = (state = {}, action) => {
  switch (action.type) {
    case partyActionTypes.UPDATE_PARTY_FETCHING:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case partyActionTypes.UPDATE_PARTY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        lastUpdate: new Date(),
      };
    case partyActionTypes.UPDATE_PARTY:
      return {
        ...state,
        isFetching: false,
        error: null,
        data: action.userDetails,
        lastUpdate: new Date(),
      };
    default:
      return state;
  }
};

export default partyReducer;
