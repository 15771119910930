import { currency, currencyIsoCodes } from '@fondy/enums/src/currency';

export const GBP_CURRENCY = currencyIsoCodes.get(currency.GBP);

export const PAYMENT_SUBTYPE_MCPO = 'MCPO';

export const paymentsKeys = Object.freeze({
  ID: 'id',
  CREATED_AT: 'createdAt',
  ACCOUNT_ALIAS: 'accountAlias',
  DESCRIPTION: 'description',
  STATUS: 'paymentStatus',
  AMOUNT: 'amount',
  ORIGINAL_AMOUNT: 'originalAmount',
  FX_RATE: 'fxRate',
  DIRECTION: 'direction',
  CURRENCY: 'currencyCode',
  ORIGINAL_CURRENCY: 'originalCurrencyCode',
  TYPE: 'type',
  ACCOUNT_ID: 'accountId',
  COUNTERPARTY_NAME: 'counterpartyName',
  COUNTERPARTY_ACCOUNT_NUMBER: 'counterpartyAccountNumber',
  COUNTERPARTY_ACCOUNT_ALIAS: 'counterPartyAccountAlias',
  COUNTERPARTY_ACCOUNT_CURRENCY: 'counterPartyAccountCurrency',
  COUNTERPARTY_ACCOUNT_ID: 'counterPartyAccountId',
  COUNTERPARTY_SORT_CODE: 'counterpartySortCode',
  COUNTERPARTY_IBAN: 'counterpartyIban',
  END_2_END_ID: 'endToEndIdentification',
  REJECTION_CODE: 'rejectionCode',
  ASSESSMENT_FAILED: 'assessmentFailed',
  CREDITOR_ADDRESS_L1: 'creditorAddressL1',
  CREDITOR_ADDRESS_L2: 'creditorAddressL2',
  CREDITOR_ADDRESS_L3: 'creditorAddressL3',
  PAYMENT_SUBTYPE: 'paymentSubType',
});

export const paymentsKeysLabelsMap = new Map([
  [paymentsKeys.ID, 'Payment ID'],
  [paymentsKeys.CREATED_AT, 'Date & time'],
  [paymentsKeys.ACCOUNT_ALIAS, 'Account alias'],
  [paymentsKeys.ACCOUNT_ID, 'Account ID'],
  [paymentsKeys.DESCRIPTION, 'Description'],
  [paymentsKeys.STATUS, 'Status'],
  [paymentsKeys.AMOUNT, 'Amount'],
  [paymentsKeys.ORIGINAL_AMOUNT, 'Original amount'],
  [paymentsKeys.FX_RATE, 'FX rate'],
  [paymentsKeys.CURRENCY, 'Currency'],
  [paymentsKeys.ORIGINAL_CURRENCY, 'Original Currency Code'],
  [paymentsKeys.DIRECTION, 'Direction'],
  [paymentsKeys.TYPE, 'Type'],
  [paymentsKeys.COUNTERPARTY_NAME, 'Counterparty name'],
  [paymentsKeys.COUNTERPARTY_ACCOUNT_ID, 'Counterparty account Id'],
  [paymentsKeys.COUNTERPARTY_ACCOUNT_ALIAS, 'Counterparty account alias'],
  [paymentsKeys.COUNTERPARTY_ACCOUNT_CURRENCY, 'Counterparty account currency'],
  [paymentsKeys.COUNTERPARTY_ACCOUNT_NUMBER, 'Counterparty account number'],
  [paymentsKeys.COUNTERPARTY_SORT_CODE, 'Counterparty sort code'],
  [paymentsKeys.END_2_END_ID, 'Unique End-to-End Payment Identification'],
  [paymentsKeys.REJECTION_CODE, 'Rejection reason'],
  [paymentsKeys.PAYMENT_SUBTYPE, 'Payment Subtype'],
  [paymentsKeys.CREDITOR_ADDRESS_L1, 'Creditor Address Line 1'],
  [paymentsKeys.CREDITOR_ADDRESS_L2, 'Creditor Address Line 2'],
  [paymentsKeys.CREDITOR_ADDRESS_L3, 'Creditor Address Line 3'],
]);

export const paymentsKeysDetailsLabelsMap = new Map([
  [paymentsKeys.ACCOUNT_ID, 'Paid from'],
  [paymentsKeys.COUNTERPARTY_NAME, 'Paid to'],
  [paymentsKeys.COUNTERPARTY_ACCOUNT_NUMBER, 'Account number'],
  [paymentsKeys.COUNTERPARTY_SORT_CODE, 'Sort code'],
  [paymentsKeys.COUNTERPARTY_IBAN, 'IBAN'],
]);

export const paymentsRejectionsMap = new Map([
  ['1100', 'Payment rejected and reason has not been specified.'],
  ['1114', 'Counterparty account is invalid'],
  ['1160', 'Creditor account closed'],
  ['1161', "Payment has been rejected by the creditor's bank"],
  [
    '1162',
    "Payment has been rejected by the counterparty's bank because the counterparty account name does not match the counterparty account number",
  ],
  [
    '1163',
    "Payment has been rejected by the counterparty's bank  because the receiving account cannot be identified without a Counterparty Reference",
  ],
  [
    '1164',
    "Payment has been rejected by the counterparty's bank due to reference information field being incorrect",
  ],
  [
    '1165',
    "Payment has been rejected by the counterparty's bank  because the counterparty account is not in the currency quoted",
  ],
  [
    '1166',
    "Payment has been rejected by the counterparty's bank because the counterparty account is no longer valid",
  ],
  [
    '1167',
    "Payment rejected by the counterparty's bank and reason has not been specified",
  ],
  [
    '1168',
    "Payment rejected by the counterparty's bank and reason has not been specified",
  ],
  [
    '1169',
    "Payment rejected by the counterparty's bank and reason has not been specified",
  ],
  [
    '1170',
    "Payment has been rejected by the counterparty's bank as the Terms and Conditions of the counterparty account do not permit crediting of these funds.",
  ],
  [
    '1171',
    "Payment has been rejected by the counterparty's bank as the counterparty name is missing",
  ],
  [
    '1176',
    'Payment has been rejected due to an unknown sort code/account number',
  ],
  [
    '1177',
    'Payment has been rejected because the counterparty account has been closed.',
  ],
  ['1178', 'Payment rejected and reason has not been specified.'],
  ['1180', 'Counterparty account is no longer valid'],
  ['00000001', 'Invalid sort code/account number combination'],
  ['00000002', 'Creditor account closed'],
  ['00000003', 'Reason has not been specified'],
  [
    'CB_AccountHoldersBankrupt',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_AccountNameInvalid',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_AccountSwitched',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_AuthenticationFailure',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_CancelledByBoEController',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_CancelledByChapsMember',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_CancelledCreditAccountDisabled',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_CancelledDebitAccountDisabled',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_CancelledQueuedCloseOfBusiness',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_DupSttlmntOriginalIsQueued',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_DupSttlmntOriginalWasRejected',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_FailedModulusCheck',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_InitiatingPartyActionRequired',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_InitiatingPartyRequested',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_InstructionCancelled',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_InstructionCancelledByCustomer',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_InstructionHasExpired',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_InvalidProcessorState',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_NewAccountUpdatedWithDDI',
    'An error occurred during payment processing, please try again.',
  ],
  [
    'CB_SenderNotInTertiaryMode',
    'An error occurred during payment processing, please try again.',
  ],
  ['CB_PaymentReferenceMissing', 'Payment reference is missing'],
  ['CB_PaymentReferenceIncorrect', 'Payment reference is invalid'],
  ['CB_PaymentBeneficiaryNameMissing', "Counterparty's name is missing"],
  [
    'CB_AccountNameAndNumberMismatch',
    'Counterparty name is not consistent with the associated account number',
  ],
  ['AC01', 'Creditor account is invalid'],
  ['AC02', 'Creditor account is invalid'],
  ['AC05', 'Creditor account is invalid'],
  ['AM01', 'Amount specified is equal to zero'],
  ['AM02', 'Amount specified is greater than the allowed maximum'],
  [
    'AM03',
    'Specified amount is a non-processable currency outside of existing agreement',
  ],
  ['AM05', 'Payment is a possible duplication'],
  ['AM06', 'Amount specified was less than agreed minimum'],
  ['AM07', 'An error occurred during payment processing, please try again.'],
  ['AM08', 'Charges amount is not as agreed between sender and receiver'],
  [
    'BE01',
    'Beneficiary Name was not consistent with the associated account number',
  ],
  [
    'BE02',
    'Beneficiary specified is not known at associated sort code (wrong bank)',
  ],
  ['BE03', 'Beneficiary specified no longer exists in the bank'],
  ['BE04', 'Beneficiary address was missing or incorrect '],
  [
    'BE05',
    'Party who initiated the transaction/message was not recognised by the Beneficiary ',
  ],
  ['DT01', 'Invalid date (e.g. wrong or missing settlement date)'],
  ['MS01', 'Reason has not been specified'],
  ['PY01', 'Unknown Account with Institution'],
  ['RF01', 'Transaction reference is not unique within the message'],
  ['RC01', 'Routing code specified  has an incorrect format'],
  ['RC02', 'Routing code specified is not numeric'],
  ['RC03', 'Routing code specified is not valid for local clearing'],
  ['RC04', 'Routing code specified refers to a closed branch'],
  [
    'RR01',
    "Ordering customer's account or unique identification specification is insufficient or missing",
  ],
  [
    'RR02',
    "Ordering customer's name and/or address specification is insufficient or missing",
  ],
  [
    'RR03',
    "Creditor's name and/or address specification is insufficient or missing.",
  ],
  [
    'TM01',
    'Associated transaction/message was received after agreed processing cut-off time',
  ],
  ['AC03', 'Creditor account is invalid or missing'],
  ['AC04', 'Creditor account closed'],
  ['AC07', 'Creditor account closed'],
  ['AC09', 'An error occurred during payment processing, please try again.'],
  ['AC12', 'An error occurred during payment processing, please try again.'],
  [
    'AG01',
    'No agreement was on file at the receiver for affecting associated transaction/message',
  ],
  [
    'AG02',
    'Bank Operation code specified in the transaction/message is not valid for receiver',
  ],
  ['AG03', 'Transaction is not supported'],
  ['AM09', 'Amount received is not the amount agreed or expected'],
  ['BE08', 'Debtor name is missing'],
  ['BE09', 'Country code is missing or Invalid'],
  ['OTHR', 'An error occurred during payment processing, please try again.'],
  ['CURR', 'Currency is incorrect'],
  ['CUTO', 'Payment has passed the cut-off time, please try again'],
  ['REFE', 'An error occurred during payment processing, please try again.'],
  ['AM04', 'Insufficient Funds. Please fund your account to repeat payment'],
  ['AC06', 'Creditor account is invalid'],
  ['ACIN', 'Creditor account status invalid'],
  ['IPTY', 'Creditor is invalid'],
  ['NPTY', 'Creditor is invalid'],
  [
    'FRAD',
    'Payment has been rejected, please contact support for further information',
  ],
  ['NACK', 'Payment declined, please contact support'],
  ['TOUT', 'Payment declined, please contact support'],
]);
