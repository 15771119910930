export const productTypeKeys = Object.freeze({
  FAMILY: 'productTypeFamily',
  CODE: 'productTypeCode',
  NAME: 'productTypeName',
  STATUS: 'productTypeStatus',
  DESCRIPTION: 'productTypeDescription',
  IS_INTERNAL: 'productTypeInternal',
});

export const productTypeKeysLabelsMap = new Map([
  [productTypeKeys.FAMILY, 'Product Family'],
  [productTypeKeys.CODE, 'Product Type Code'],
  [productTypeKeys.NAME, 'Product Type Name'],
  [productTypeKeys.STATUS, 'Status'],
  [productTypeKeys.DESCRIPTION, 'Product Type Description'],
  [productTypeKeys.IS_INTERNAL, 'Internal Use'],
]);

export const productTypeKeysDescriptionsMap = new Map([
  [
    productTypeKeys.FAMILY,
    'Product Family under which the user is creating the Product Type',
  ],
  [productTypeKeys.CODE, 'Code given to the Product Type'],
  [productTypeKeys.NAME, 'Name for which the Product type is known'],
  [productTypeKeys.STATUS, 'Status of the Product Type'],
  [
    productTypeKeys.DESCRIPTION,
    'Field that can be used by the user to provide more information about this Product Type',
  ],
  [
    productTypeKeys.IS_INTERNAL,
    'Field that identifies if the Product type that is being created is for internal use or not.',
  ],
]);
