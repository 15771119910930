import { isEmpty } from 'rambda';
import { textMaskRegexRemover, parseInputValueToFloat } from '@fondy/forms';
import { newPaymentKeys } from '../../../../utils';

export const newPaymentSortCodeSanitiser = textMaskRegexRemover(/[^\d]/g);
export const newPaymentValuesSanitiser = (values) => ({
  ...values,
  [newPaymentKeys.PAYEE_SORT_CODE]: newPaymentSortCodeSanitiser(
    values[newPaymentKeys.PAYEE_SORT_CODE],
  ),
  [newPaymentKeys.AMOUNT]: parseInputValueToFloat(
    values[newPaymentKeys.AMOUNT],
  ),
  [newPaymentKeys.PAYEE_NAME]:
    !isEmpty(values[newPaymentKeys.PAYEE_NAME]) &&
    typeof values[newPaymentKeys.PAYEE_NAME] === 'string'
      ? values[newPaymentKeys.PAYEE_NAME]
      : values[newPaymentKeys.PAYEE_NAME][newPaymentKeys.PAYEE_NAME],
});
