import { generateApiErrorFromResponse } from '@fondy/utils';
import { userProfileActionTypes } from '../constants';

export const updateUserProfileAction = (userDetails) => ({
  type: userProfileActionTypes.UPDATE_USER_PROFILE,
  userDetails,
});

export const fetchUserProfileAction = (fetch, forceFetch = false) => async (
  dispatch,
  getState,
) => {
  const { profile } = await getState();

  if (!profile.data || forceFetch) {
    dispatch({
      type: userProfileActionTypes.UPDATE_USER_PROFILE_FETCHING,
    });

    try {
      const response = await fetch('/api/users/self', {
        cache: 'no-cache',
        credentials: 'same-origin',
        mode: 'same-origin',
        headers: {
          'Content-type': 'application/json',
        },
      });

      if (!response.ok) {
        throw await generateApiErrorFromResponse(response);
      }

      await dispatch({
        type: userProfileActionTypes.UPDATE_USER_PROFILE,
        userDetails: await response.json(),
      });
    } catch (error) {
      await dispatch({
        type: userProfileActionTypes.UPDATE_USER_PROFILE_ERROR,
        error,
      });
    }
  }
};
