import React, { useMemo, useState, useCallback } from 'react';
import { VisibilityRounded } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { orderTypes } from '@fondy/enums';
import { GridWidget } from '@fondy/data-display';
import { ConnectedFilteredTable } from '@fondy/tables';
import {
  userCustomerSelector,
  stateTimeZoneSelector,
  stateSelectSplitAccountsIds,
} from '../../../redux';
import { AppLayout } from '../../layouts';
import {
  PageTabsNavigation,
  SplitAccountComplianceProfileDetailsModal,
  SplitAccountPageHeader,
} from '../../organisms';
import { splitAccountProfilesTableColumns } from '../../atoms/tableColumns';
import {
  splitAccountPagesTabs,
  splitAccountPagesTabsLabelsMap,
  splitAccountPagesTabsRoutingMap,
  splitAccountPagesTabsDisabledFieldsMap,
  splitAccountsIdsKeys,
  walletKeys,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  itemActions: {
    textOverflow: 'unset',
    transition: theme.transitions.create('all', {
      easing: 'ease-in-out',
    }),
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.color.fade(theme.palette.primary.main, 0.05),
    },
  },
}));

const SplitAccountProfilesListPage = () => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
  const { customer } = useSelector(userCustomerSelector);
  const { splitAccountsIds } = useSelector(stateSelectSplitAccountsIds);
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const accessorId =
    splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID];

  const itemActionsColumn = useMemo(
    () => ({
      id: 'more',
      numeric: false,
      disablePadding: true,
      label: '',
      style: {
        textOverflow: 'unset',
      },
      render: ({ row }) => (
        <IconButton
          color="secondary"
          aria-label="preview details"
          component="span"
          className={classes.itemActions}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelectedProfile(row);
            return false;
          }}
        >
          <VisibilityRounded />
        </IconButton>
      ),
    }),
    [classes.itemActions],
  );

  const modalCloseHandler = useCallback(() => {
    setSelectedProfile(null);
  }, []);

  const handleRowClick = useCallback((event, row) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedProfile(row);
    return false;
  }, []);

  return (
    <AppLayout>
      <SplitAccountPageHeader />

      <PageTabsNavigation
        tabs={splitAccountPagesTabs}
        tabsLabelsMap={splitAccountPagesTabsLabelsMap}
        tabsRoutingMap={splitAccountPagesTabsRoutingMap}
        disabledTabsMap={splitAccountPagesTabsDisabledFieldsMap}
      />

      <GridWidget container noBottomPadding spacing={0}>
        <ConnectedFilteredTable
          defaultOrderBy={walletKeys.DATE_CREATED}
          defaultOrderDirection={orderTypes.DESCENDING}
          rowId={walletKeys.ID}
          url={`/api/core-banking/${accessorId}/onboarding/compliance/profile`}
          dependencies={[accessorId]}
          emptyComponent="You don't have any profiles yet"
          columns={[...splitAccountProfilesTableColumns, itemActionsColumn]}
          pagination={{
            size: 10,
          }}
          onCellClick={handleRowClick}
          data-aio-id="profilesTable"
        />
      </GridWidget>

      {!!selectedProfile && (
        <SplitAccountComplianceProfileDetailsModal
          profile={selectedProfile}
          onClose={modalCloseHandler}
        />
      )}
    </AppLayout>
  );
};

export default SplitAccountProfilesListPage;
