import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute } from '@fondy/keycloak';
import { NotFoundPage } from '@fondy/layouts';
import {
  privateRoutesRequiredPartyProps,
  privateSplitAccountsRoutesRequiredProps,
} from './utils';
import AccountsRoutes from './accounts';
import SettingsRoutes from './settings';
import SplitAccountRoutes from './splitAccount';
import {
  DashboardPage,
  MerchantsPage,
  PaymentsPage,
  CounterpartiesPage,
} from '../components/pages';

const routes = (
  <Switch>
    <PrivateRoute
      {...privateRoutesRequiredPartyProps}
      exact
      path="/"
      enableOffline
    >
      <DashboardPage />
    </PrivateRoute>

    <PrivateRoute
      path="/payments"
      {...privateRoutesRequiredPartyProps}
      enableOffline
    >
      <PaymentsPage />
    </PrivateRoute>

    <PrivateRoute
      path="/counterparties"
      {...privateRoutesRequiredPartyProps}
      enableOffline
    >
      <CounterpartiesPage />
    </PrivateRoute>

    <PrivateRoute
      path="/accounts"
      {...privateRoutesRequiredPartyProps}
      enableOffline
    >
      <AccountsRoutes />
    </PrivateRoute>

    <PrivateRoute
      path="/merchants"
      {...privateRoutesRequiredPartyProps}
      exact
      enableOffline
    >
      <MerchantsPage />
    </PrivateRoute>

    <PrivateRoute
      path="/split-account"
      {...privateSplitAccountsRoutesRequiredProps}
      enableOffline
    >
      <SplitAccountRoutes />
    </PrivateRoute>

    <PrivateRoute
      path="/settings"
      {...privateRoutesRequiredPartyProps}
      exact
      enableOffline
    >
      <SettingsRoutes />
    </PrivateRoute>

    <Route path="*">
      <NotFoundPage />
    </Route>
  </Switch>
);

export default routes;
