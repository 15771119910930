export const complianceProfileRiskScores = Object.freeze({
  LOW_RISK: 'Low Risk',
  UNKNOWN: 'unknown',
  BAD_PERSON: 'BADPERSON',
});

export const complianceProfileRiskScoresLabelsMap = new Map([
  [complianceProfileRiskScores.LOW_RISK, 'Low Risk'],
  [complianceProfileRiskScores.BAD_PERSON, 'Bad Person'],
  [complianceProfileRiskScores.UNKNOWN, 'Unknown'],
]);
