import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { arrayOf, objectOf, any, func, bool, string, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Grid, SvgIcon } from '@material-ui/core';
import { isEmpty, omit } from 'rambda';
import {
  clearAllTableFilters,
  FilteredTable,
  filterFieldTypes,
  TableFilters,
} from '@fondy/tables';
import { ErrorMessage } from '@fondy/alerts';
import {
  accountStatuses,
  accountTypes,
  accountTypesLabelsMap,
  orderTypes,
  accountStatusesLabels,
  currency,
  currencyIsoCodes,
} from '@fondy/enums';
import { Button, buttonVariant } from '@fondy/buttons';
import { GridViewIcon, ListViewIcon } from '@fondy/icons';
import { Pagination } from '@fondy/data-display';
import {
  ControlledSearchField,
  conformToMask,
  ibanInputMaskPattern,
} from '@fondy/forms';
import { formatSortCodeValue } from '@fondy/utils';
import {
  accountKeys,
  walletKeys,
  tableAmountFilter as balanceFilter,
  tableAmountFilterLabelGetter as tableBalanceFilterLabelGetter,
  tableCurrencyFilter,
} from '../../../utils';
import { AccountsGrid } from '../../atoms';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  filtersToolbar: {
    display: 'flex',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  filtersToolbarFiltersGrid: {
    width: 'unset',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  toggleViewButton: {
    minWidth: 'unset',
    boxSizing: 'content-box',
    padding: theme.spacing(0.875),
    marginLeft: theme.spacing(1),
  },
  searchForm: {
    width: '100%',
    flex: 1,
    marginRight: theme.spacing(3),
  },
}));

const searchableParamName = 'search';
const availablePageSizes = [6, 12, 30, 60, 90];
const paginationConfigInit = Object.freeze({
  page: 0,
  size: availablePageSizes[0],
});
const allowedSearchAttributes = [
  accountKeys.ALIAS,
  accountKeys.NUMBER,
  accountKeys.SORT_CODE,
  accountKeys.IBAN,
  accountKeys.HOLDER_NAME,
  walletKeys.BUSINESS_REFERENCE,
];
const filters = Object.freeze({
  [accountKeys.TYPE]: {
    label: 'Type',
    values: [accountTypes.MASTER, accountTypes.WALLET, accountTypes.STANDARD],
    valuesLabelsMap: accountTypesLabelsMap,
    type: filterFieldTypes.CHECKBOX,
  },
  [accountKeys.STATUS]: {
    label: 'Status',
    values: [
      accountStatuses.PENDING,
      accountStatuses.ACTIVE,
      accountStatuses.CLOSED,
    ],
    valuesLabelsMap: accountStatusesLabels,
    type: filterFieldTypes.CHECKBOX,
  },
  [accountKeys.CURRENCY]: {
    label: 'Currency',
    values: tableCurrencyFilter,
    type: filterFieldTypes.CHECKBOX,
  },
  [accountKeys.CURRENT_BALANCE]: {
    label: 'Balance',
    values: Object.keys(balanceFilter),
    selectItemLabelGetter: tableBalanceFilterLabelGetter,
    type: filterFieldTypes.CHECKBOX,
  },
});
const masterWalletsFiltersOmit = [accountKeys.CURRENCY, accountKeys.TYPE];
const masterWalletsFilters = Object.freeze(
  omit(masterWalletsFiltersOmit, filters),
);
const defaultFiltersConfigState = clearAllTableFilters(filters);

const AccountsTable = ({
  columns,
  onRetryFetchClick,
  disableRowHover,
  className,
  max,
  renderCollapsible,
  accountsSelectorFn,
  renderRowsOnly,
  isNested,
  searchQuery,
  isMasterAccountWalletsMode,
  ...restProps
}) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    getError: accountsFetchError,
    data: accounts,
    isFetching: isAccountsFetching,
  } = useSelector(accountsSelectorFn);

  const [renderGridView, setRenderGridView] = useState(false);

  const [paginationConfig, setPaginationConfig] = useState(
    paginationConfigInit,
  );
  const [paginationState, setPaginationState] = useState(null);
  const [searchInputState, setSearchInputState] = useState(searchQuery);
  const [accountsState, setAccountsState] = useState([]);
  const [filtersState, setFiltersState] = useState(defaultFiltersConfigState);

  const isFilterActive = useMemo(
    () =>
      Boolean(searchInputState) ||
      Object.values(filtersState).some(
        (filterValues) => filterValues.length !== 0,
      ),
    [searchInputState, filtersState],
  );

  const emptyComponent = useMemo(() => {
    if (isNested || isMasterAccountWalletsMode) {
      return "You don't have any wallets matching the selected criteria";
    }

    return isFilterActive
      ? "You don't have any accounts matching the selected criteria"
      : "You don't have any accounts yet";
  }, [isNested, isMasterAccountWalletsMode, isFilterActive]);

  const handleAccountClick = useCallback(
    (_, row) =>
      history.push(
        row[accountKeys.TYPE] === accountTypes.MASTER
          ? `/accounts/${row[accountKeys.ID]}/wallets`
          : `/accounts/transactions/${row[accountKeys.ID]}`,
      ),
    [history],
  );

  const handlePaginationChange = useCallback(
    (_, nextPageNumber) =>
      setPaginationConfig((prevConfig) => ({
        ...prevConfig,
        page: nextPageNumber,
      })),
    [],
  );

  const handlePaginationSizeChange = useCallback(
    (_, nextPageSize) =>
      setPaginationConfig(() => ({
        page: 0,
        size: nextPageSize,
      })),
    [],
  );

  const handleSearchInputChange = useCallback((_, searchInput) => {
    setSearchInputState(() => searchInput);
    setPaginationConfig((prevConfig) => ({
      ...prevConfig,
      page: 0,
    }));
  }, []);

  const searchFilterFn = useCallback(
    (account) => {
      if (!isFilterActive) {
        return true;
      }

      let hasMatchingWallet = false;
      if (account?.wallets && !isEmpty(account.wallets)) {
        hasMatchingWallet = !isEmpty(account.wallets.filter(searchFilterFn));
      }

      return (
        hasMatchingWallet ||
        (Object.keys(filtersState).every((key) => {
          if (filtersState[key].length === 0) {
            return true;
          }

          const accountValue = account[key] ?? '';

          if (key === accountKeys.CURRENT_BALANCE) {
            return filtersState[key].some(
              (balanceKey) =>
                accountValue >= balanceFilter[balanceKey].min &&
                accountValue <= balanceFilter[balanceKey].max,
            );
          }

          return filtersState[key].includes(accountValue);
        }) &&
          Object.values(allowedSearchAttributes).some((key) => {
            if (!searchInputState) {
              return true;
            }

            let accountValue = account[key] ?? '';

            if (
              key === accountKeys.SORT_CODE &&
              searchInputState.includes('-')
            ) {
              accountValue = formatSortCodeValue(accountValue);
            } else if (
              key === accountKeys.IBAN &&
              searchInputState.includes(' ')
            ) {
              accountValue = conformToMask(
                searchInputState,
                ibanInputMaskPattern,
                {
                  guide: false,
                },
              ).conformedValue;
            }

            return accountValue
              .toString()
              .toLowerCase()
              .includes(searchInputState.toLowerCase());
          }))
      );
    },
    [isFilterActive, searchInputState, filtersState],
  );

  const onFiltersChange = useCallback((newFilters) => {
    setFiltersState((prevValue) => ({
      ...prevValue,
      ...newFilters,
    }));
    setPaginationConfig((prevConfig) => ({
      ...prevConfig,
      page: 0,
    }));
  }, []);

  const onFiltersClear = useCallback(
    async () => setFiltersState(defaultFiltersConfigState),
    [],
  );

  const walletsSelectorFn = useCallback(
    (masterAccountId) => (state) => ({
      ...state.accounts,
      data: state.accounts.data
        .filter(
          (account) =>
            account[walletKeys.PAYOUT_ACCOUNT_ID] === masterAccountId &&
            account[walletKeys.TYPE] === accountTypes.WALLET,
        )
        .filter(searchFilterFn),
    }),
    [searchFilterFn],
  );

  const collapsibleRendererFn = useCallback(
    ({ row }) => (
      <AccountsTable
        {...{
          ...restProps,
          onRetryFetchClick,
          columns,
          searchQuery: searchInputState,
          className: classes.walletsTableWrapper,
          accountsSelectorFn: walletsSelectorFn(row[accountKeys.ID]),
          defaultOrderBy: walletKeys.CURRENT_BALANCE,
          defaultOrderType: orderTypes.DESCENDING,
          isNested: true,
          renderCollapsible: true,
          renderRowsOnly: true,
        }}
      />
    ),
    [
      restProps,
      onRetryFetchClick,
      columns,
      searchInputState,
      classes.walletsTableWrapper,
      walletsSelectorFn,
    ],
  );

  const gridViewRendererFn = useCallback(
    (dataToRender) => {
      return (
        <AccountsGrid
          accounts={dataToRender}
          onAccountClick={handleAccountClick}
          walletsSelectorFn={walletsSelectorFn}
          emptyComponent={emptyComponent}
          isFilterActive={isFilterActive}
          searchQuery={searchInputState}
        />
      );
    },
    [
      emptyComponent,
      handleAccountClick,
      isFilterActive,
      searchInputState,
      walletsSelectorFn,
    ],
  );

  useEffect(() => {
    if (isNested || renderRowsOnly) return;

    const totalPages = Math.ceil(accountsState.length / paginationConfig.size);

    setPaginationState(() => ({
      empty: accountsState.length === 0,
      first: paginationConfig.page === 0,
      last: paginationConfig.page === totalPages - 1,
      number: paginationConfig.page,
      size: paginationConfig.size,
      totalPages,
    }));
  }, [accountsState, isNested, paginationConfig, renderRowsOnly]);

  useEffect(() => {
    if (isNested || renderRowsOnly) return;

    setAccountsState(() => {
      const masterAccounts = [...accounts].map((account) => {
        let filteredWalletsCount = 0;
        if (isFilterActive && account?.wallets && !isEmpty(account.wallets)) {
          filteredWalletsCount = account.wallets.filter(searchFilterFn).length;
        }

        return {
          ...account,
          isFilterActive,
          filteredWalletsCount,
        };
      });

      return !isFilterActive
        ? masterAccounts
        : masterAccounts.filter(searchFilterFn);
    });
  }, [
    accounts,
    searchInputState,
    searchFilterFn,
    isNested,
    renderRowsOnly,
    isFilterActive,
    filtersState,
  ]);

  return (
    <div
      className={classes.wrapper}
      data-aio-id={`${isNested ? 'wallets' : 'accounts'}Table`}
    >
      {!accountsFetchError && (
        <FilteredTable
          emptyComponent={emptyComponent}
          header={
            renderRowsOnly ? null : (
              <Box className={classes.filtersToolbar}>
                <ControlledSearchField
                  isLoading={isAccountsFetching}
                  onChange={handleSearchInputChange}
                  onSubmit={(_, formikHelpers) => {
                    formikHelpers.setSubmitting(false);
                  }}
                  name={searchableParamName}
                  formClassName={classes.searchForm}
                />
                <Grid
                  container
                  className={classes.filtersToolbarFiltersGrid}
                  wrap="nowrap"
                >
                  <TableFilters
                    filters={
                      isMasterAccountWalletsMode
                        ? masterWalletsFilters
                        : filters
                    }
                    filtersState={filtersState}
                    emptyFiltersState={defaultFiltersConfigState}
                    onChange={onFiltersChange}
                    onClear={onFiltersClear}
                    isLoading={isAccountsFetching}
                  />
                  {isMasterAccountWalletsMode ? null : (
                    <Button
                      variant={buttonVariant.OUTLINED}
                      disabled={isAccountsFetching}
                      className={classes.toggleViewButton}
                      onClick={() => setRenderGridView((value) => !value)}
                    >
                      <SvgIcon
                        component={renderGridView ? ListViewIcon : GridViewIcon}
                      />
                    </Button>
                  )}
                </Grid>
              </Box>
            )
          }
          footer={
            renderRowsOnly || !paginationState ? null : (
              <Pagination
                isLoading={isAccountsFetching}
                paginationState={paginationState}
                onPageChange={handlePaginationChange}
                onPageSizeChange={handlePaginationSizeChange}
                availablePageSizes={availablePageSizes}
              />
            )
          }
          {...restProps}
          maxRows={max}
          className={className}
          rowId={accountKeys.ID}
          data={renderRowsOnly ? accounts : accountsState}
          paginationConfig={renderRowsOnly ? null : paginationConfig}
          columns={columns}
          isLoading={isAccountsFetching}
          onCellClick={handleAccountClick}
          loadingRows={3}
          disableRowHover={disableRowHover}
          defaultOrderBy={accountKeys.CURRENT_BALANCE}
          defaultOrderType={orderTypes.DESCENDING}
          isCollapsible={
            renderCollapsible
              ? ({ row }) => row[accountKeys.TYPE] === accountTypes.MASTER
              : undefined
          }
          renderCollapsible={renderCollapsible ? collapsibleRendererFn : null}
          disableHeader={renderRowsOnly}
          headerCellsClassName={classes.headerCell}
          useCustomRenderer={renderGridView}
          customRenderer={gridViewRendererFn}
          searchQuery={searchInputState}
        />
      )}
      <ErrorMessage
        error={accountsFetchError}
        isLoading={isAccountsFetching}
        retryFn={onRetryFetchClick}
      />
    </div>
  );
};

AccountsTable.propTypes = {
  className: string,
  // eslint-disable-next-line react/require-default-props
  columns: arrayOf(objectOf(any)).isRequired,
  onRetryFetchClick: func,
  disableRowHover: bool,
  max: number,
  renderCollapsible: bool,
  accountsSelectorFn: func,
  renderRowsOnly: bool,
  isNested: bool,
  searchQuery: string,
  isMasterAccountWalletsMode: bool,
};

AccountsTable.defaultProps = {
  disableRowHover: false,
  onRetryFetchClick: null,
  className: '',
  max: -1,
  renderCollapsible: false,
  renderRowsOnly: false,
  isNested: false,
  accountsSelectorFn: (state) => ({
    ...state.accounts,
    data: state.accounts.data.filter(
      (account) =>
        ![accountStatuses.CLOSED, accountStatuses.SUSPENDED].includes(
          account[accountKeys.STATUS],
        ),
    ),
  }),
  searchQuery: null,
  isMasterAccountWalletsMode: false,
};

export default AccountsTable;
