export const productFamilyKeys = Object.freeze({
  CODE: 'productFamilyCode',
  NAME: 'productFamilyName',
  DESCRIPTION: 'productFamilyDescription',
  STATUS: 'productFamilyStatus',
});

export const productFamilyKeysLabelsMap = new Map([
  [productFamilyKeys.CODE, 'Product Family Code'],
  [productFamilyKeys.NAME, 'Product Family Name'],
  [productFamilyKeys.DESCRIPTION, 'Product Family Description'],
  [productFamilyKeys.STATUS, 'Status'],
]);

export const productFamilyKeysDescriptionsMap = new Map([
  [productFamilyKeys.CODE, 'Code given to the Product Family'],
  [productFamilyKeys.NAME, 'Name for which the Product Family is known'],
  [
    productFamilyKeys.DESCRIPTION,
    'Field that can be used by the user to provide more information about this Product Family',
  ],
  [productFamilyKeys.STATUS, 'Status of the Product Family'],
]);
