import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { isNil } from 'rambda';
import { Skeleton } from '@material-ui/lab';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { ExitToAppRounded } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { endOfDay, startOfDay, subDays, subYears } from 'date-fns';
import { useSelector } from 'react-redux';
import { orderTypes, currencySymbols, currency } from '@fondy/enums';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonVariant,
  buttonSize,
} from '@fondy/buttons';
import { formatCurrencyValue } from '@fondy/utils';
import { ConnectedMultiAreaChart } from '@fondy/charts';
import { PageHeader } from '@fondy/layouts';
import { ConnectedFilteredTable } from '@fondy/tables';
import { GridWidget } from '@fondy/data-display';
import AppLayout from '../../layouts/AppLayout';
import {
  merchantsSettlementsTableColumns,
  mobileMerchantsSettlementsTableColumns,
} from '../../atoms';
import {
  PaymentDetailsModal,
  TwoFactorAuthenticationForm,
  TwoFactorAuthenticationActivationForm,
} from '../../organisms';
import { stateSelectUserProfile, stateTimeZoneSelector } from '../../../redux';
import { paymentsKeys } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  title: {
    justifyContent: 'center',
    minHeight: 'unset',
  },
  statistic: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  mobileSettlementsWidgets: {
    flex: '1 1 100%',
    margin: -1 * theme.spacing(1),
  },
  statisticNoPadding: {
    paddingTop: 0,
  },
}));

const chartDataMapper = (data) => ({
  charts: ['sum'],
  data: data.content,
  total: data.count,
  sum: data.total,
});

function MerchantsPage() {
  const classes = useStyles();
  const currentDate = new Date();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);
  const { totpEnabled } = useSelector(stateSelectUserProfile);
  const [settlementDetails, setSettlementDetails] = useState(null);
  const [settlementsChartStats, setSettlementsChartStats] = useState({});

  const handleSettlementRecordClick = useCallback(
    async (_, row) => setSettlementDetails(row),
    [],
  );

  const handleSettlementDetailsModalClose = useCallback(
    () => setSettlementDetails(null),
    [],
  );

  const chartValueFormatter = useCallback(
    (value, name) => [
      formatCurrencyValue(value, {
        currencyId: currency.GBP,
      }),
      name,
    ],
    [],
  );

  const chartValueTickFormatter = useCallback(
    (value) =>
      formatCurrencyValue(value, {
        currencyId: currency.GBP,
        abbreviate: true,
      }),
    [],
  );

  const handleChartSuccess = useCallback(
    (apiState) =>
      setSettlementsChartStats({
        totalSettlementsCount: isNil(apiState.apiData.total)
          ? 0
          : apiState.apiData.total,
        totalSettlementsAmount: apiState.apiData.sum || 0,
      }),
    [],
  );

  return (
    <AppLayout>
      <PageHeader
        title="Merchants"
        actions={[
          <Button
            fullWidth={isMobile}
            color={buttonColor.PRIMARY}
            variant={buttonVariant.CONTAINED}
            shape={buttonShape.ROUNDED}
            size={buttonSize.MEDIUM}
            startIcon={<ExitToAppRounded />}
            to="https://portal.fondy.eu"
            key="go-to-the-portal"
            target="_blank"
          >
            Go to the Gateway portal
          </Button>,
        ]}
      />

      <GridWidget
        container
        title="Merchant settlements"
        headerActions="Last 6 months"
        classes={{
          title: classes.title,
        }}
      >
        <GridWidget
          transparent
          noPadding
          grid={{ xs: 12, sm: 4, md: 3 }}
          spacing={0}
          container
          className={clsx({ [classes.mobileSettlementsWidgets]: isMobile })}
          classes={{
            content: clsx({ [classes.statisticNoPadding]: isMobile }),
          }}
        >
          <Grid item xs={12} className={classes.statistic}>
            <Typography variant="caption" gutterBottom>
              Total amount settled
            </Typography>
            <Typography variant="h2">
              {!isNil(settlementsChartStats.totalSettlementsAmount) ? (
                formatCurrencyValue(
                  settlementsChartStats.totalSettlementsAmount || 0,
                  { currencyId: currency.GBP },
                )
              ) : (
                <Skeleton />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.statistic}>
            <Typography variant="caption" gutterBottom>
              Number of settlements
            </Typography>
            <Typography variant="h2">
              {!isNil(settlementsChartStats.totalSettlementsCount) ? (
                settlementsChartStats.totalSettlementsCount
              ) : (
                <Skeleton />
              )}
            </Typography>
          </Grid>
        </GridWidget>
        <GridWidget
          transparent
          noPadding
          grid={{ xs: 12, sm: 8, md: 9 }}
          spacing={0}
          className={clsx({ [classes.mobileSettlementsWidgets]: isMobile })}
        >
          <Grid item xs={12}>
            <ConnectedMultiAreaChart
              height={250}
              showLegend={false}
              emptyMessage={false}
              emptyHeading="No settlements yet"
              url="/api/core-banking/charts/payments/settlements"
              unit={currencySymbols.get(currency.GBP)}
              dataMapper={chartDataMapper}
              xAxisProps={{
                dataKey: 'date',
              }}
              yAxisProps={{
                tickFormatter: chartValueTickFormatter,
              }}
              tooltipProps={{
                formatter: chartValueFormatter,
              }}
              onSuccess={handleChartSuccess}
            />
          </Grid>
        </GridWidget>
      </GridWidget>

      <GridWidget container noBottomPadding spacing={0}>
        <ConnectedFilteredTable
          defaultOrderBy={paymentsKeys.CREATED_AT}
          defaultOrderDirection={orderTypes.DESCENDING}
          rowId={paymentsKeys.ID}
          url="/api/core-banking/payments/settlements"
          emptyComponent="You don't have any settlements yet"
          disableHeader={isMobile}
          columns={
            isMobile
              ? mobileMerchantsSettlementsTableColumns
              : merchantsSettlementsTableColumns
          }
          pagination={{
            size: 10,
          }}
          onCellClick={handleSettlementRecordClick}
          dateFilters={{
            timeZone: legalEntityTimezone,
            minDate: startOfDay(subYears(currentDate, 1)),
            maxDate: endOfDay(currentDate),
            twoFactorIsActive: totpEnabled,
            requireTwoFactorBeyondDate: startOfDay(subDays(currentDate, 90)),
            twoFactorForm: TwoFactorAuthenticationForm,
            twoFactorActivationForm: TwoFactorAuthenticationActivationForm,
            twoFactorFormProps: {
              title: 'Authenticate this request',
              description:
                'Use six digit code generated from Google Authenticator app',
              formControlsProps: {
                column: false,
                inverseButtons: true,
                align: 'right',
              },
            },
            twoFactorActivationFormProps: {
              fullWidth: true,
              title: 'Enable 2-Step Verification',
              description:
                'In order to make that request, you must have this enabled',
            },
            dateFrom: {
              label: 'Start date',
            },
            dateTo: {
              label: 'End date',
            },
          }}
        />
      </GridWidget>

      <PaymentDetailsModal
        isSettlement
        open={!!settlementDetails}
        payment={settlementDetails}
        onClose={handleSettlementDetailsModalClose}
      />
    </AppLayout>
  );
}

export default MerchantsPage;
