import createAccountModalStages from './createAccountModalStages';

const createAccountModalHeaderSubtitles = new Map([
  [
    createAccountModalStages.ACCOUNT_TYPE_SELECT,
    'Please select which account you would like to create',
  ],
  [
    createAccountModalStages.CREATE_STANDARD_ACCOUNT,
    'Please enter a Standard account name and choose a currency.',
  ],
  [
    createAccountModalStages.CREATE_MASTER_ACCOUNT,
    'Please enter a Master account name and choose a currency.',
  ],
  [
    createAccountModalStages.CREATE_WALLET_ACCOUNT,
    'Please enter a Wallet account Owner ID and Wallet Owner Name.',
  ],
  [createAccountModalStages.ERROR, null],
  [
    createAccountModalStages.REQUEST_SUBMITTED,
    `Thanks. We've got your account request and we'll be in touch soon.`,
  ],
]);

export default createAccountModalHeaderSubtitles;
