import React from 'react';
import { Route } from 'react-router-dom';
import { activeStatuses } from '@fondy/enums';
import userPartiesStateSelector from './userPartiesStateSelector';
import { partyDetailsKeys } from '../../utils';

const isPartyAllInactive = (state) =>
  state.data.every(
    (party) => party[partyDetailsKeys.STATUS] === activeStatuses.INACTIVE,
  );

const privateRoutesRequiredPartyProps = {
  reduxSelectorFn: userPartiesStateSelector,
  reduxRedirectorFn: ({ state, keycloak }) => {
    if (
      !state ||
      !state.data ||
      state.data.length === 0 ||
      (Array.isArray(state?.data) && isPartyAllInactive(state))
    ) {
      const logoutUrl = keycloak.createLogoutUrl();

      return () => (
        <Route
          component={() => {
            window.location.href = logoutUrl;
            return null;
          }}
        />
      );
    }

    return null;
  },
};

export default privateRoutesRequiredPartyProps;
