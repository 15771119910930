import { createPdfDocument, defaultPageMargins } from '@fondy/pdf';
import {
  formatSortCodeValue,
  formatTimeZonedDate,
  formatCurrencyValue,
} from '@fondy/utils';
import pdfHeaderCreator from '../pdfHeaderCreator';
import pdfFooterCreator from '../pdfFooterCreator';
import {
  transactionKeys,
  accountKeys,
  transactionKeysLabelsMap,
  transactionKeysDetailsLabelsMap,
  splitAccountTransactionKeys,
  splitAccountTransactionTypesLabelsMap,
  splitAccountTransactionKeysDetailsLabelsMap,
  ibanToStringFormatter,
} from '../../../../utils';

const createTransactionDetailsPdf = async ({
  transaction,
  selectedAccount,
  loadingStateSetter,
  toOwnAccount,
  isSplitAccount,
  isSplitAccountWallet,
  onError,
  theme,
  timeZone,
  datetimeFormat,
  margin = defaultPageMargins,
  filename = 'transaction-details.pdf',
}) => {
  try {
    if (loadingStateSetter) await loadingStateSetter(true);

    const date = new Date();
    const { pdfDocument } = await createPdfDocument();
    const { fontName } = await pdfDocument.getFont();
    const isDebit =
      transaction[transactionKeys.ACCOUNT_OPENING_BALANCE] >
      transaction[transactionKeys.ACCOUNT_CLOSING_BALANCE];
    const transactionDirectionSymbol = isDebit ? '-' : '+';

    await pdfHeaderCreator(pdfDocument, {
      theme,
      title: 'Transaction Details',
    });

    const receiverLabels = transaction[transactionKeys.COUNTERPARTY_IBAN]
      ? [
          `${transactionKeysDetailsLabelsMap.get(
            transactionKeys.COUNTERPARTY_IBAN,
          )}:`,
        ]
      : [
          `${transactionKeysDetailsLabelsMap.get(
            transactionKeys.COUNTERPARTY_SORT_CODE,
          )}:`,
          `${transactionKeysDetailsLabelsMap.get(
            transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER,
          )}:`,
        ];
    const receiverValues = transaction[transactionKeys.COUNTERPARTY_IBAN]
      ? [
          `${ibanToStringFormatter(
            transaction[transactionKeys.COUNTERPARTY_IBAN],
          )}`,
        ]
      : [
          `${formatSortCodeValue(
            transaction[transactionKeys.COUNTERPARTY_SORT_CODE],
          )}`,
          `${transaction[transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER]}`,
        ];

    const accountDetailsLabels = [
      ...(transaction[transactionKeys.TRANSACTION_ORIGINAL_CURRENCY]
        ? [
            `${transactionKeysLabelsMap.get(
              transactionKeys.TRANSACTION_ORIGINAL_AMOUNT,
            )}:`,
            `${transactionKeysLabelsMap.get(transactionKeys.FX_RATE)}:`,
          ]
        : []),
      `${transactionKeysLabelsMap.get(transactionKeys.TRANSACTION_AMOUNT)}:`,
      `${transactionKeysLabelsMap.get(transactionKeys.TRANSACTION_REFERENCE)}:`,
      `${transactionKeysLabelsMap.get(transactionKeys.ID)}:`,
      `Paid ${isDebit ? 'from' : 'to'}`,
      `Paid ${isDebit ? 'to' : 'from'}`,
      ...(toOwnAccount ? [] : receiverLabels),
      'Status:',
      ...(isSplitAccount || isSplitAccountWallet
        ? [
            `${splitAccountTransactionKeysDetailsLabelsMap.get(
              splitAccountTransactionKeys.TYPE,
            )}:`,
          ]
        : []),
      `${transactionKeysDetailsLabelsMap.get(transactionKeys.BOOKING_DATE)}:`,
      `Document generation date:`,
    ];

    // account details labels
    await pdfDocument.text(accountDetailsLabels, margin.left, 160, {
      lineHeightFactor: 2,
    });

    await pdfDocument.setFont(fontName, 'normal');

    const accountDetailsValues = [
      ...(transaction[transactionKeys.TRANSACTION_ORIGINAL_CURRENCY]
        ? [
            `${formatCurrencyValue(
              transaction[transactionKeys.TRANSACTION_ORIGINAL_AMOUNT],
              {
                currencyIso:
                  transaction[transactionKeys.TRANSACTION_ORIGINAL_CURRENCY],
              },
            )}`,
            `${transaction[transactionKeys.FX_RATE]}`,
          ]
        : []),
      `${transactionDirectionSymbol}${formatCurrencyValue(
        transaction[transactionKeys.TRANSACTION_AMOUNT],
        {
          currencyIso: transaction[transactionKeys.TRANSACTION_CURRENCY],
        },
      )}`,
      `${transaction[transactionKeys.TRANSACTION_REFERENCE]}`,
      `${transaction[transactionKeys.ID]}`,
      `${selectedAccount[accountKeys.ALIAS]}`,
      `${
        toOwnAccount
          ? transaction[transactionKeys.COUNTERPARTY_ACCOUNT_ALIAS]
          : transaction[transactionKeys.COUNTERPARTY_NAME]
      }`,
      ...(toOwnAccount ? [] : receiverValues),
      'Completed',
      ...(isSplitAccount || isSplitAccountWallet
        ? [
            splitAccountTransactionTypesLabelsMap.get(
              transaction?.[splitAccountTransactionKeys.TYPE],
            ) || 'n/a',
          ]
        : []),
      `${formatTimeZonedDate(
        transaction[transactionKeys.BOOKING_DATE],
        timeZone,
        { dateFormat: datetimeFormat },
      )}`,
      `${formatTimeZonedDate(date.toISOString(), timeZone, {
        dateFormat: datetimeFormat,
      })}`,
    ];

    // account details values
    await pdfDocument.text(accountDetailsValues, margin.left + 150, 160, {
      lineHeightFactor: 2,
    });

    await pdfFooterCreator(pdfDocument, { theme });

    await pdfDocument.save(
      typeof filename === 'function'
        ? await filename({ date, transaction })
        : filename,
    );
  } catch (error) {
    console.warn(error);
    if (onError) await onError(error);
  } finally {
    if (loadingStateSetter) await loadingStateSetter(false);
  }
};

export default createTransactionDetailsPdf;
