export const splitAccountProfileKeys = Object.freeze({
  ID: 'id',
  NAME: 'name',
  STATUS: 'status',
  TYPE: 'type',
  COUNTRY: 'country',
  CURRENCY: 'transactionCurrency',
  JOIN_DATE: 'joinData',
});

export const splitAccountProfileKeysLabelsMap = new Map([
  [splitAccountProfileKeys.ID, 'Id'],
  [splitAccountProfileKeys.NAME, 'Name'],
  [splitAccountProfileKeys.STATUS, 'Status'],
  [splitAccountProfileKeys.TYPE, 'Type'],
  [splitAccountProfileKeys.COUNTRY, 'Country'],
  [splitAccountProfileKeys.CURRENCY, 'Currency'],
  [splitAccountProfileKeys.JOIN_DATE, 'Join Date'],
]);
