/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { bool, func, number, shape, string } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import {
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';
import { useApplicationContext } from '@fondy/application-context';
import {
  ControlledPinInputField,
  getTextFieldPropsFromKey,
  SubmitButton,
} from '@fondy/forms';
import { Button, buttonColor, buttonVariant } from '@fondy/buttons';
import { httpMethods } from '@fondy/enums';
import {
  useApi,
  fetchErrorHandler,
  transparentPixelBase64,
} from '@fondy/utils';
import twoFactorAuthenticationActivationSchema from './twoFactorAuthenticationActivationSchema';
import { tfaKeys } from '../../../utils';

const getFieldProps = getTextFieldPropsFromKey();

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    justifyContent: 'center',
  },
  formContainer: {
    justifyContent: 'center',
  },
  qrCode: {
    display: 'flex',
    width: 130,
    height: 130,
    margin: '0 auto',
    userSelect: 'none',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  list: {
    paddingInlineStart: theme.spacing(2),
    '& > li:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  setupKeyBtn: {
    display: 'block',
    margin: '0 auto',
    marginTop: theme.spacing(1),
  },
  manualSetupCode: {
    ...theme.typography.button,
    fontSize: 14,
    display: 'block',
    margin: theme.spacing(1, 'auto'),
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.lightest,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: '1px solid',
    borderColor: theme.palette.secondary.light,
  },
  qrCodeContainer: {
    position: 'relative',
  },
  circularProgressWrapper: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 8,
    left: 'calc(50% - 65px)',
    width: 130,
    height: 130,
    zIndex: 0,
    backgroundColor: theme.palette.secondary.lightest,
    borderRadius: theme.shape.borderRadius,
    pointerEvents: 'none',
    userSelect: 'none',
    transition: theme.transitions.create('backgroundColor', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  circularProgress: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  visuallyHidden: {
    opacity: 0,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
}));

export default function TwoFactorAuthenticationActivationForm({
  onSubmit,
  onError,
  className,
  fullWidth,
  title,
  description,
  autoSubmit,
  qrGrid,
  formGrid,
  classes: parentClasses,
  ...restProps
}) {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const { fetch, keycloak } = useApplicationContext();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
  const [qrCodeState, setQrCodeState] = useState({
    renderImage: true,
    loading: true,
    error: false,
  });

  const { apiData, isLoading } = useApi('/api/tfa/totp');

  const formState = useFormik({
    initialValues: {},
    validateOnMount: false,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      await setSubmitting(true);
      try {
        const response = await fetch('/api/tfa/totp', {
          method: httpMethods.POST,
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
          body: await JSON.stringify(values),
        });

        if (!response.ok) {
          await fetchErrorHandler({
            response,
            keycloak,
            throwError: true,
          });
        }

        await setSubmitting(false);
        if (onSubmit) await onSubmit(values);
      } catch (error) {
        await setFieldError(
          tfaKeys.CODE,
          error.message ||
            'Something went wrong. Please contact Fondy support!',
        );
        await setSubmitting(false);
        if (onError) await onError(error);
      }
    },
    validationSchema: twoFactorAuthenticationActivationSchema,
  });

  const handleQrCodeImageLoadError = useCallback(() => {
    setQrCodeState((prevState) => ({
      ...prevState,
      loading: false,
      renderImage: false,
      error: true,
    }));
  }, []);
  const handleQrCodeImageLoadSuccess = useCallback(() => {
    setQrCodeState((prevState) => ({
      ...prevState,
      loading: false,
      error: false,
    }));
  }, []);
  const toggleManualCodeActivation = useCallback(
    () =>
      setQrCodeState((prevState) => ({
        ...prevState,
        renderImage: !prevState.renderImage,
      })),
    [],
  );

  const { handleSubmit, handleReset } = formState;
  const qrCodeImageIsLoading = isLoading || qrCodeState.isLoading;
  const renderManualActivationCode =
    !qrCodeImageIsLoading && !qrCodeState.renderImage;

  return (
    <Grid
      {...restProps}
      item
      container
      className={clsx(classes.container, className, parentClasses.container)}
      {...(fullWidth
        ? { xs: 12 }
        : {
            xs: 12,
            md: 10,
          })}
    >
      {(!!title || !!description) && (
        <Grid
          item
          xs={12}
          className={clsx(classes.header, parentClasses.header)}
        >
          {title && (
            <Typography variant="h2" gutterBottom align="center">
              {title}
            </Typography>
          )}
          {description && (
            <Typography variant="body2" gutterBottom align="center">
              {description}
            </Typography>
          )}
        </Grid>
      )}
      <Grid
        container
        item
        spacing={2}
        xs={12}
        className={clsx(classes.formContainer, parentClasses.formContainer)}
      >
        <Grid
          item
          className={clsx(
            classes.qrCodeContainer,
            parentClasses.qrCodeContainer,
          )}
          {...(fullWidth
            ? { xs: 4 }
            : {
                xs: 12,
                sm: 3,
              })}
          {...qrGrid}
        >
          {!qrCodeState.error ? (
            <div
              className={clsx(
                classes.qrCode,
                parentClasses.qrCode,
                classes.circularProgressWrapper,
                {
                  [classes.transparent]:
                    qrCodeState.renderImage && !qrCodeState.loading,
                },
              )}
            >
              <CircularProgress
                className={clsx(classes.circularProgress, {
                  [classes.visuallyHidden]:
                    qrCodeState.renderImage && !qrCodeState.loading,
                })}
              />
            </div>
          ) : null}
          {!qrCodeState.error ? (
            <img
              className={clsx(classes.qrCode, parentClasses.qrCode)}
              src={isLoading ? transparentPixelBase64 : apiData?.qr || ''}
              alt="QR Code"
              onError={!isLoading ? handleQrCodeImageLoadError : undefined}
              onLoad={!isLoading ? handleQrCodeImageLoadSuccess : undefined}
            />
          ) : null}
          {!qrCodeState.error ? (
            <Button
              link
              className={clsx(classes.setupKeyBtn, parentClasses.setupKeyBtn)}
              color={buttonColor.PRIMARY}
              variant={buttonVariant.TEXT}
              onClick={toggleManualCodeActivation}
            >
              {renderManualActivationCode ? 'Hide setup key' : null}
              {!renderManualActivationCode ? (
                <>
                  <div>Can&apos;t scan the code</div>
                  <div>view setup key</div>
                </>
              ) : null}
            </Button>
          ) : null}
          {qrCodeState.error ? 'Setup key' : null}
          {renderManualActivationCode ? (
            <span className={classes.manualSetupCode}>
              {apiData?.secret || ''}
            </span>
          ) : null}
        </Grid>
        <Grid
          item
          {...(fullWidth
            ? { xs: 8 }
            : {
                xs: 12,
                sm: 7,
                md: 5,
              })}
          {...formGrid}
        >
          <Typography variant="h4">
            How to enable 2-Step verification
          </Typography>
          <ol className={classes.list}>
            <li>
              Download the Google Authenticator app onto your mobile device.
            </li>
            <li>
              {qrCodeState.error
                ? 'Enter the setup key to Google Authenticator app'
                : null}
              {!qrCodeState.error && qrCodeState.renderImage
                ? 'Scan this QR code, using the app.'
                : null}
              {!qrCodeState.error && !qrCodeState.renderImage
                ? 'Scan this QR code, using the app or enter the setup key manually into app'
                : null}
            </li>
            <li>Enter the 6-digit code generated by the app</li>
          </ol>
          <form
            onSubmit={handleSubmit}
            onReset={handleReset}
            noValidate
            id="twoFactorSetup"
            key="twoFactorSetup"
          >
            <ControlledPinInputField
              {...getFieldProps(tfaKeys.CODE)}
              description="a new code is generated every 30 seconds"
              formState={formState}
              autoSubmit={autoSubmit}
              autoFocus={!isMobile}
              required
            />
            <SubmitButton
              formState={formState}
              className={clsx(classes.submit, parentClasses.submit)}
              disabled={isLoading}
              form="twoFactorSetup"
            >
              Continue
            </SubmitButton>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
}

TwoFactorAuthenticationActivationForm.propTypes = {
  onSubmit: func,
  onError: func,
  className: string,
  fullWidth: bool,
  autoSubmit: bool,
  title: string,
  description: string,
  qrGrid: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
  }),
  formGrid: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
  }),
  classes: shape({
    container: string,
    header: string,
    formContainer: string,
    qrCodeContainer: string,
    qrCode: string,
    setupKeyBtn: string,
    submit: string,
  }),
};

TwoFactorAuthenticationActivationForm.defaultProps = {
  onSubmit: undefined,
  onError: undefined,
  className: '',
  fullWidth: false,
  autoSubmit: false,
  title: null,
  description: null,
  qrGrid: {},
  formGrid: {},
  classes: {},
};
