// eslint-disable-next-line import/prefer-default-export

export const widgetsTimeRangesKeys = Object.freeze({
  NOW: 'NOW',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
  LAST_QUARTER: 'LAST_QUARTER',
  LAST_YEAR: 'LAST_YEAR',
});

export const widgetsTimeRangeUnits = Object.freeze({
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
});

export const widgetsTimeRangesNow = Object.freeze({
  [widgetsTimeRangesKeys.NOW]: Object.freeze({
    label: 'For now',
    unit: widgetsTimeRangeUnits.DAYS,
    value: 0,
  }),
});

export const widgetsTimeRangesHistoric = Object.freeze({
  [widgetsTimeRangesKeys.LAST_WEEK]: Object.freeze({
    label: 'Last week',
    unit: widgetsTimeRangeUnits.DAYS,
    value: 7,
  }),
  [widgetsTimeRangesKeys.LAST_MONTH]: Object.freeze({
    label: 'Last 30 days',
    unit: widgetsTimeRangeUnits.DAYS,
    value: 30,
  }),
  [widgetsTimeRangesKeys.LAST_QUARTER]: Object.freeze({
    label: 'Last 3 months',
    unit: widgetsTimeRangeUnits.MONTHS,
    value: 3,
  }),
  [widgetsTimeRangesKeys.LAST_YEAR]: Object.freeze({
    label: 'Last 6 months',
    unit: widgetsTimeRangeUnits.MONTHS,
    value: 6,
  }),
});

export const widgetsTimeRanges = Object.freeze({
  ...widgetsTimeRangesNow,
  ...widgetsTimeRangesHistoric,
});
