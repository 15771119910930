export const transactionKeys = Object.freeze({
  ID: 'id',
  ACCOUNT_ID: 'accountId',
  PAYMENT_INSTRUCTION_ID: 'paymentInstructionId',
  END_TO_END_INSTRUCTION_ID: 'endToEndInstructionId',
  TRANSACTION_CODE: 'transactionCode',
  VALUE_DATE: 'valueDate',
  BOOKING_DATE: 'bookingDate',
  CREATED_AT: 'createdAt',
  POSTING_REFERENCE: 'postingReference',
  TRANSACTION_CURRENCY: 'transactionCurrency',
  TRANSACTION_ORIGINAL_CURRENCY: 'originalCurrency',
  TRANSACTION_AMOUNT: 'transactionAmount',
  TRANSACTION_ORIGINAL_AMOUNT: 'originalAmount',
  ACCOUNT_ALIAS: 'accountAlias',
  ACCOUNT_OPENING_BALANCE: 'accountOpeningBalance',
  ACCOUNT_CLOSING_BALANCE: 'accountClosingBalance',
  FEE_TRANSCATION_ID: 'feeTransactionId',
  IS_FEE: 'isFee',
  PAYMENT_CHANNEL_CODE: 'paymentChannelCode',
  DEBIT_CREDIT_CODE: 'debitCreditCode',
  COUNTERPARTY_NAME: 'counterpartyName',
  COUNTERPARTY_IBAN: 'counterpartyIban',
  COUNTERPARTY_ACCOUNT_ID: 'counterpartyAccountId',
  COUNTERPARTY_ACCOUNT_TYPE: 'counterpartyAccountType',
  COUNTERPARTY_ACCOUNT_ALIAS: 'counterpartyAccountAlias',
  COUNTERPARTY_ACCOUNT_NUMBER: 'counterpartyAccountNumber',
  COUNTERPARTY_ACCOUNT_CURRENCY: 'counterpartyAccountCurrency',
  COUNTERPARTY_SORT_CODE: 'counterpartySortCode',
  COUNTERPARTY_ADDRESS_L1: 'counterpartyAddressL1',
  COUNTERPARTY_ADDRESS_L2: 'counterpartyAddressL2',
  COUNTERPARTY_ADDRESS_L3: 'counterpartyAddressL3',
  TRANSACTION_LABEL: 'transactionLabel',
  TRANSACTION_NOTES: 'transactionNotes',
  FX_RATE: 'fxRate',
  TRANSACTION_REFERENCE: 'transactionReference',
});

export const transactionKeysLabelsMap = new Map([
  [transactionKeys.ID, 'Transaction ID'],
  [transactionKeys.ACCOUNT_ID, 'Account ID'],
  [transactionKeys.ACCOUNT_ALIAS, 'Account alias'],
  [transactionKeys.PAYMENT_INSTRUCTION_ID, 'Payment instruction ID'],
  [transactionKeys.END_TO_END_INSTRUCTION_ID, 'E2E Instruction ID'],
  [transactionKeys.TRANSACTION_CODE, 'Transaction code'],
  [transactionKeys.VALUE_DATE, 'Value date'],
  [transactionKeys.BOOKING_DATE, 'Booking date'],
  [transactionKeys.POSTING_REFERENCE, 'Posting reference'],
  [transactionKeys.TRANSACTION_CURRENCY, 'Transaction currency'],
  [transactionKeys.TRANSACTION_ORIGINAL_CURRENCY, 'Original currency'],
  [transactionKeys.TRANSACTION_AMOUNT, 'Amount'],
  [transactionKeys.TRANSACTION_ORIGINAL_AMOUNT, 'Original amount'],
  [transactionKeys.ACCOUNT_OPENING_BALANCE, 'Account opening balance'],
  [transactionKeys.ACCOUNT_CLOSING_BALANCE, 'Account closing balance'],
  [transactionKeys.FEE_TRANSCATION_ID, 'Fee transaction ID'],
  [transactionKeys.IS_FEE, 'Has fee'],
  [transactionKeys.PAYMENT_CHANNEL_CODE, 'Payment channel code'],
  [transactionKeys.DEBIT_CREDIT_CODE, 'Debit credit code'],
  [transactionKeys.COUNTERPARTY_NAME, 'Counterparty name'],
  [transactionKeys.COUNTERPARTY_IBAN, 'Counterparty IBAN'],
  [transactionKeys.COUNTERPARTY_ACCOUNT_ID, 'Counterparty account Id'],
  [transactionKeys.COUNTERPARTY_ACCOUNT_TYPE, 'Counterparty account type'],
  [transactionKeys.COUNTERPARTY_ACCOUNT_ALIAS, 'Counterparty account alias'],
  [transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER, 'Counterparty account number'],
  [
    transactionKeys.COUNTERPARTY_ACCOUNT_CURRENCY,
    'Counterparty account currency',
  ],
  [transactionKeys.COUNTERPARTY_SORT_CODE, 'Counterparty sort code'],
  [transactionKeys.COUNTERPARTY_ADDRESS_L1, 'Counterparty Address Line 1'],
  [transactionKeys.COUNTERPARTY_ADDRESS_L2, 'Counterparty Address Line 2'],
  [transactionKeys.COUNTERPARTY_ADDRESS_L3, 'Counterparty Address Line 3'],
  [transactionKeys.TRANSACTION_LABEL, 'Transaction label'],
  [transactionKeys.TRANSACTION_NOTES, 'Transaction notes'],
  [transactionKeys.TRANSACTION_REFERENCE, 'Description'],
  [transactionKeys.CREATED_AT, 'Date & time'],
  [transactionKeys.FX_RATE, 'FX rate'],
]);

export const transactionKeysCsvLabelsMap = new Map([
  ...transactionKeysLabelsMap,
  [transactionKeys.BOOKING_DATE, 'Transaction date'],
  [transactionKeys.ACCOUNT_CLOSING_BALANCE, 'Account balance'],
  [transactionKeys.DEBIT_CREDIT_CODE, 'Debit/Credit'],
]);

export const transactionKeysDetailsLabelsMap = new Map([
  [transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER, 'Account number'],
  [transactionKeys.COUNTERPARTY_SORT_CODE, 'Sort code'],
  [transactionKeys.COUNTERPARTY_IBAN, 'IBAN'],
  [transactionKeys.BOOKING_DATE, 'Date'],
]);
