import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bool, func, string, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Popper, SvgIcon, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { WalletsIcon } from '@fondy/icons';
import {
  AccountAliasTableCell,
  AmountTableCell,
  FilteredTable,
  StatusTableCell,
} from '@fondy/tables';
import { orderTypes, accountStatusesLabels } from '@fondy/enums';
import { Paper } from '@fondy/layouts';
import { accountKeys } from '../../../../utils';
import BadgeTableCell from '../../BadgeTableCell';

const columns = [
  {
    id: accountKeys.ALIAS,
    style: { width: '50%' },
    label: 'Account name',
    render: ({ value, row, searchQuery }) => (
      <AccountAliasTableCell
        small
        accountAlias={value}
        searchQueryHighlight={searchQuery}
        currencyIso={row[accountKeys.CURRENCY]}
        alignTop
      />
    ),
  },
  {
    id: accountKeys.STATUS,
    numeric: false,
    label: 'Status',
    render: ({ value }) => (
      <StatusTableCell
        status={value}
        labelsMap={accountStatusesLabels}
        medium
      />
    ),
    style: {
      textOverflow: 'unset',
      width: '20%',
    },
  },
  {
    id: accountKeys.CURRENT_BALANCE,
    align: 'right',
    label: 'Balance',
    style: { width: '30%' },
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[accountKeys.CURRENCY]}
        justifyContent="flex-end"
        variant="body2"
      />
    ),
  },
];
const emptyComponent =
  "You don't have any wallets matching the selected criteria";

const useStyles = makeStyles((theme) => {
  const boxShadow =
    '0px 3px 5px 0px rgba(34, 9, 66, 0.20), 0px 0px 1px 0px rgba(34, 9, 66, 0.30)';

  return {
    container: {
      marginTop: theme.spacing(1.25),
      width: 'fit-content',
      minHeight: 20,
    },
    walletsText: {
      color: theme.palette.background.greyDark,
      padding: theme.spacing(0, 0.75),
    },
    popper: {
      zIndex: 1200,
      '&[x-placement*="bottom"]': {
        marginTop: 20,
      },
      '&[x-placement*="top"]': {
        marginBottom: 20,
      },
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-1.38em',
        '&::before': {
          transformOrigin: '0 100%',
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-1.38em',
        '&::before': {
          transformOrigin: '100% 0',
        },
      },
    },
    popperContainer: {
      width: 372,
      maxHeight: 224,
      padding: theme.spacing(0.5),
      overflowY: 'auto',
      backgroundColor: theme.palette.background.paper,
      boxShadow,
    },
    arrow: {
      overflow: 'hidden',
      position: 'absolute',
      width: '2em',
      height: '1.41em',
      boxSizing: 'content-box',
      color: theme.palette.background.paper,
      padding: theme.spacing(0, 1),
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        transform: 'rotate(45deg)',
        backgroundColor: 'currentColor',
        borderTopLeftRadius: 2,
        borderBottomRightRadius: 2,
        boxShadow,
      },
    },
  };
});

const MasterAccountWallets = ({
  walletsSelectorFn,
  onAccountClick,
  totalWalletsCount,
  isFilterActive,
  searchQuery,
}) => {
  const classes = useStyles();
  const { data: wallets } = useSelector(walletsSelectorFn);

  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setOpen(true);

    setTimeoutId((prevTimeoutId) => {
      clearTimeout(prevTimeoutId);

      return null;
    });
  }, []);

  const handleMouseLeave = useCallback(() => {
    const id = setTimeout(() => {
      setOpen(false);
    }, 1000);

    setTimeoutId((prevTimeoutId) => {
      clearTimeout(prevTimeoutId);

      return id;
    });
  }, []);

  const popper = useMemo(
    () => (
      <Popper
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        placement="bottom"
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <Paper className={classes.popperContainer}>
          <span className={classes.arrow} ref={setArrowRef} />

          <FilteredTable
            maxRows={-1}
            rowId={accountKeys.ID}
            data={wallets}
            columns={columns}
            onCellClick={onAccountClick}
            defaultOrderBy={accountKeys.CURRENT_BALANCE}
            defaultOrderType={orderTypes.DESCENDING}
            emptyComponent={emptyComponent}
            searchQuery={searchQuery}
            disableHeader
          />
        </Paper>
      </Popper>
    ),
    [
      open,
      anchorEl,
      classes.popper,
      classes.popperContainer,
      classes.arrow,
      arrowRef,
      wallets,
      onAccountClick,
      searchQuery,
    ],
  );

  useEffect(
    () => () => {
      clearTimeout(timeoutId);
    },
    [timeoutId],
  );

  return (
    <Grid
      container
      className={classes.container}
      alignItems="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <SvgIcon
        component={WalletsIcon}
        color="secondary"
        style={{ fontSize: 16 }}
        ref={isFilterActive ? null : setAnchorEl}
      />
      <Typography variant="body2" className={classes.walletsText}>
        Wallets: {totalWalletsCount}
      </Typography>
      {isFilterActive ? (
        <BadgeTableCell badgeContent={wallets.length} ref={setAnchorEl} />
      ) : null}

      {popper}
    </Grid>
  );
};

MasterAccountWallets.propTypes = {
  walletsSelectorFn: func.isRequired,
  onAccountClick: func.isRequired,
  totalWalletsCount: number.isRequired,
  isFilterActive: bool.isRequired,
  searchQuery: string,
};

MasterAccountWallets.defaultProps = {
  searchQuery: null,
};

export default MasterAccountWallets;
