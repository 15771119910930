/* eslint-disable import/prefer-default-export */
import { getAppVersion } from '../../../utils';

export const footerNavigation = [
  {
    label: 'Terms & conditions',
    to: '/help/terms-and-conditions',
  },
  {
    label: 'Privacy policy',
    to: '/help/privacy-policy',
  },
  {
    label: 'FAQ',
    to: '/help/faq',
  },
  {
    label: 'Contact support',
    to: '/contact/support',
  },
  {
    key: 'version',
    label: getAppVersion(),
    disabled: true,
  },
];
