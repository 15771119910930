import React, { useCallback, useMemo } from 'react';
import { head, omit } from 'rambda';
import { stringify } from 'query-string';
import { shape, string, func, arrayOf, oneOf } from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { useApplicationContext } from '@fondy/application-context';
import {
  FormControls,
  ControlledTextField,
  getTextFieldPropsFromKey,
  textMaskRegexRemover,
  MaskedControlledTextField,
  sortCodeInputMaskPattern,
  transformInputValuesBeforeValidate,
  flagIconStyles,
  CurrencySelectField,
  CountryAutocompleteControlledTextField,
} from '@fondy/forms';
import { fetchErrorHandler } from '@fondy/utils';
import { useKeycloak } from '@fondy/keycloak';
import { httpMethods, currency, currencyIsoCodes } from '@fondy/enums';
import {
  createOnlyDigitsTextInputMaskPattern,
  ibanInputMaskPatternAnycase,
} from '@fondy/forms/src/utils/inputMasksPatterns';
import {
  accountNumberRecipientValidationSchema,
  ibanRecipientValidationSchema,
} from './recipientValidationSchema';
import {
  counterpartyTypes,
  counterpartyTypesLabelsMap,
  GBP_CURRENCY,
  partyAssociationsKeys,
  paymentCounterpartyKeys,
  paymentCounterpartyKeysLabelsMap,
  paymentCounterpartyKeysLabelsMapClean,
  isCounterpartyTypeFieldsFilled,
  counterpartyBankDetailsTypes,
  counterpartyBankDetailsTypesLabelsMap,
} from '../../../utils';
import { RadioStyled } from '../../atoms';

const getFieldProps = getTextFieldPropsFromKey({
  labelsMap: new Map([
    ...paymentCounterpartyKeysLabelsMapClean,
    [paymentCounterpartyKeys.NAME, 'Counterparty name'],
  ]),
});

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: '100%',
    textAlign: 'left',
    borderTop: theme.border.thin,
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  centered: {
    justifyContent: 'center',
  },
  flag: flagIconStyles['& img'],
  groupedInputs: {
    display: 'flex',
    width: '100%',
    '& > *:first-child': {
      maxWidth: 130,
      marginRight: theme.spacing(2),
    },
  },
  groupedNameInputs: {
    display: 'flex',
    width: '100%',
    '& > *:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  groupedAddressInputs: {
    display: 'flex',
    width: '100%',
    '& > *:last-child': {
      maxWidth: 150,
      marginLeft: theme.spacing(2),
    },
  },
  horizontalRadioGroup: {
    flexDirection: 'row',
  },
  radioTitle: {
    color: theme.palette.text.secondaryDark,
  },
  radioLabel: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  radioLabelText: {
    fontSize: theme.typography.htmlFontSize,
  },
  sectionMargin: {
    marginTop: theme.spacing(1),
  },
  formControls: {
    borderTop: theme.border.thin,
    paddingTop: theme.spacing(3),
  },
}));

const omitCounterpartyDuplicateFields = omit([
  paymentCounterpartyKeys.NAME,
  paymentCounterpartyKeys.TYPE,
  paymentCounterpartyKeys.BANK_DETAILS_TYPE,
  paymentCounterpartyKeys.FIRST_NAME,
  paymentCounterpartyKeys.LAST_NAME,
  paymentCounterpartyKeys.COMPANY_NAME,
  paymentCounterpartyKeys.ADDRESS,
]);
const omitCounterpartyAddressFields = omit([
  paymentCounterpartyKeys.COUNTRY,
  paymentCounterpartyKeys.CITY,
  paymentCounterpartyKeys.BUILDING_NUMBER,
  paymentCounterpartyKeys.BUILDING_NAME,
  paymentCounterpartyKeys.STREET,
  paymentCounterpartyKeys.POSTAL_CODE,
]);
const sortCodeSanitiser = textMaskRegexRemover(/[^\d]/g);
const valuesSanitiser = (values) => ({
  ...values,
  ...(values?.[paymentCounterpartyKeys.SORT_CODE]
    ? {
        [paymentCounterpartyKeys.SORT_CODE]: sortCodeSanitiser(
          values[paymentCounterpartyKeys.SORT_CODE],
        ),
      }
    : {}),
  ...(values?.[paymentCounterpartyKeys.IBAN]
    ? {
        [paymentCounterpartyKeys.IBAN]: values[
          paymentCounterpartyKeys.IBAN
        ].replaceAll(' ', ''),
        [paymentCounterpartyKeys.ADDRESS]: {
          [paymentCounterpartyKeys.COUNTRY]:
            values[paymentCounterpartyKeys.COUNTRY],
          [paymentCounterpartyKeys.CITY]: values[paymentCounterpartyKeys.CITY],
          [paymentCounterpartyKeys.STREET]:
            values[paymentCounterpartyKeys.STREET],
          [paymentCounterpartyKeys.BUILDING_NAME]:
            values[paymentCounterpartyKeys.BUILDING_NAME],
          [paymentCounterpartyKeys.BUILDING_NUMBER]:
            values[paymentCounterpartyKeys.BUILDING_NUMBER],
          [paymentCounterpartyKeys.POSTAL_CODE]:
            values[paymentCounterpartyKeys.POSTAL_CODE],
        },
      }
    : {}),
  ...(values?.[paymentCounterpartyKeys.TYPE] === counterpartyTypes.PERSONAL
    ? {
        [paymentCounterpartyKeys.COMPANY_NAME]: null,
      }
    : {
        [paymentCounterpartyKeys.FIRST_NAME]: null,
        [paymentCounterpartyKeys.LAST_NAME]: null,
      }),
});

export default function CounterpartyEditForm({
  initialValues,
  onCancel,
  onSubmit,
  onError,
  onDuplicate,
  disabledFields,
  submitText,
  method,
  url,
}) {
  const classes = useStyles();
  const { fetch } = useApplicationContext();
  const { keycloak } = useKeycloak();
  const userPartyId = useSelector(
    (state) => head(state.parties.data)[partyAssociationsKeys.PARTY_ID],
  );

  const makeRequest = useCallback(
    async (values) =>
      fetch(url, {
        method,
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
        body:
          method === httpMethods.DELETE
            ? undefined
            : await JSON.stringify(values),
      }),
    [fetch, method, url],
  );

  const handleRequest = useCallback(
    (formHelpers) => async (values) => {
      try {
        const response = await makeRequest(values);

        if (!response.ok) {
          await fetchErrorHandler({
            response,
            keycloak,
            throwError: true,
          });
        }

        const json = await response.json();
        await formHelpers.setSubmitting(false);
        if (onSubmit) await onSubmit({ json });
      } catch (error) {
        await formHelpers.setSubmitting(false);
        if (onError) await onError(error);
      }
    },
    [keycloak, makeRequest, onError, onSubmit],
  );

  const formState = useFormik({
    initialValues,
    onSubmit: async (rawValues, formHelpers) => {
      formHelpers.setSubmitting(true);
      const values = omitCounterpartyAddressFields(valuesSanitiser(rawValues));

      try {
        if (!!onDuplicate && method === httpMethods.POST) {
          const response = await fetch(
            `/api/core-banking/parties/${userPartyId}/payees?${stringify(
              omitCounterpartyDuplicateFields(values),
              {
                arrayFormat: 'comma',
                skipNull: true,
                skipEmptyString: true,
              },
            )}`,
            {
              method: httpMethods.GET,
            },
          );

          const json = await response.json();

          if (json?.content?.length > 0) {
            const match = head(
              json.content.filter((p) => !!p[paymentCounterpartyKeys.TYPE]),
            );
            const isMatchTypeFieldsFilled = isCounterpartyTypeFieldsFilled(
              match,
            );

            if (isMatchTypeFieldsFilled) {
              await onDuplicate({
                values,
                formHelpers,
                match,
                handleRequest: handleRequest(formHelpers),
              });
              return;
            }
          }
        }

        const response = await makeRequest(values);

        if (!response.ok) {
          await fetchErrorHandler({
            response,
            keycloak,
            throwError: true,
          });
        }

        const json = await response.json();
        formHelpers.setSubmitting(false);
        if (onSubmit) onSubmit({ json });
      } catch (error) {
        formHelpers.setSubmitting(false);
        if (onError) onError(error);
      }
    },
    validate:
      method !== httpMethods.DELETE
        ? async (values) => {
            return transformInputValuesBeforeValidate({
              validationSchema:
                values?.[paymentCounterpartyKeys.CURRENCY] !== GBP_CURRENCY ||
                values?.[paymentCounterpartyKeys.BANK_DETAILS_TYPE] ===
                  counterpartyBankDetailsTypes.IBAN
                  ? ibanRecipientValidationSchema
                  : accountNumberRecipientValidationSchema,
              valuesSanitiser,
            })(values);
          }
        : null,
  });
  const { handleSubmit, handleReset, handleChange, setValues } = formState;

  const counterpartyTypeChangeHandler = useCallback(
    async (_, value) => {
      await handleChange({
        target: {
          value,
          name: paymentCounterpartyKeys.TYPE,
        },
      });
    },
    [handleChange],
  );

  const counterpartyBankDetailsTypeChangeHandler = useCallback(
    async (_, value) => {
      await setValues((prevValues) => ({
        ...prevValues,
        ...(value === counterpartyBankDetailsTypes.IBAN
          ? {
              [paymentCounterpartyKeys.ACCOUNT_NUMBER]: null,
              [paymentCounterpartyKeys.SORT_CODE]: null,
            }
          : {
              [paymentCounterpartyKeys.IBAN]: null,
              [paymentCounterpartyKeys.COUNTRY]: null,
              [paymentCounterpartyKeys.CITY]: null,
              [paymentCounterpartyKeys.STREET]: null,
              [paymentCounterpartyKeys.BUILDING_NAME]: null,
              [paymentCounterpartyKeys.BUILDING_NUMBER]: null,
              [paymentCounterpartyKeys.POSTAL_CODE]: null,
            }),
        [paymentCounterpartyKeys.BANK_DETAILS_TYPE]: value,
      }));
      await handleChange({
        target: {
          value,
          name: paymentCounterpartyKeys.BANK_DETAILS_TYPE,
        },
      });
    },
    [handleChange, setValues],
  );

  const isPaymentCurrencyGBP = useMemo(
    () => formState.values?.[paymentCounterpartyKeys.CURRENCY] === GBP_CURRENCY,
    [formState.values],
  );

  return (
    <form
      className={classes.formWrapper}
      onSubmit={handleSubmit}
      onReset={handleReset}
      noValidate
    >
      <ControlledTextField
        {...getFieldProps(paymentCounterpartyKeys.NAME)}
        formState={formState}
        disabled={disabledFields.includes(paymentCounterpartyKeys.NAME)}
        autoFocus
        required
      />

      <Grid item xs={12} className={classes.sectionMargin}>
        <Typography variant="body2" className={classes.radioTitle}>
          {paymentCounterpartyKeysLabelsMap.get(paymentCounterpartyKeys.TYPE)}
        </Typography>
      </Grid>

      <FormControl component="fieldset">
        <RadioGroup
          aria-label={paymentCounterpartyKeysLabelsMap.get(
            paymentCounterpartyKeys.TYPE,
          )}
          {...getFieldProps(paymentCounterpartyKeys.TYPE)}
          value={formState.values[paymentCounterpartyKeys.TYPE]}
          onBlur={formState.handleBlur}
          onChange={counterpartyTypeChangeHandler}
          className={classes.horizontalRadioGroup}
        >
          <FormControlLabel
            classes={{
              root: classes.radioLabel,
              label: classes.radioLabelText,
            }}
            value={counterpartyTypes.PERSONAL}
            control={<RadioStyled />}
            label={counterpartyTypesLabelsMap.get(counterpartyTypes.PERSONAL)}
            disabled={disabledFields.includes(paymentCounterpartyKeys.TYPE)}
          />
          <FormControlLabel
            classes={{
              root: classes.radioLabel,
              label: classes.radioLabelText,
            }}
            value={counterpartyTypes.BUSINESS}
            control={<RadioStyled />}
            label={counterpartyTypesLabelsMap.get(counterpartyTypes.BUSINESS)}
            disabled={disabledFields.includes(paymentCounterpartyKeys.TYPE)}
          />
        </RadioGroup>
      </FormControl>

      {formState.values[paymentCounterpartyKeys.TYPE] ===
      counterpartyTypes.BUSINESS ? (
        <ControlledTextField
          {...getFieldProps(paymentCounterpartyKeys.COMPANY_NAME)}
          formState={formState}
          disabled={disabledFields.includes(
            paymentCounterpartyKeys.COMPANY_NAME,
          )}
          required
        />
      ) : (
        <div className={classes.groupedNameInputs}>
          <ControlledTextField
            {...getFieldProps(paymentCounterpartyKeys.FIRST_NAME)}
            formState={formState}
            disabled={disabledFields.includes(
              paymentCounterpartyKeys.FIRST_NAME,
            )}
            required
          />
          <ControlledTextField
            {...getFieldProps(paymentCounterpartyKeys.LAST_NAME)}
            formState={formState}
            disabled={disabledFields.includes(
              paymentCounterpartyKeys.LAST_NAME,
            )}
            required
          />
        </div>
      )}

      <CurrencySelectField
        {...getFieldProps(paymentCounterpartyKeys.CURRENCY)}
        formState={formState}
        currencies={[currency.GBP, currency.EUR, currency.USD]}
        disabled={disabledFields.includes(paymentCounterpartyKeys.CURRENCY)}
        required
      />

      {isPaymentCurrencyGBP ? (
        <>
          <Grid item xs={12} className={classes.sectionMargin}>
            <Typography variant="body2" className={classes.radioTitle}>
              {paymentCounterpartyKeysLabelsMap.get(
                paymentCounterpartyKeys.BANK_DETAILS_TYPE,
              )}
            </Typography>
          </Grid>

          <FormControl component="fieldset">
            <RadioGroup
              aria-label={paymentCounterpartyKeysLabelsMap.get(
                paymentCounterpartyKeys.BANK_DETAILS_TYPE,
              )}
              {...getFieldProps(paymentCounterpartyKeys.BANK_DETAILS_TYPE)}
              value={
                formState.values[paymentCounterpartyKeys.BANK_DETAILS_TYPE]
              }
              onBlur={formState.handleBlur}
              onChange={counterpartyBankDetailsTypeChangeHandler}
              className={classes.horizontalRadioGroup}
            >
              <FormControlLabel
                classes={{
                  root: classes.radioLabel,
                  label: classes.radioLabelText,
                }}
                value={counterpartyBankDetailsTypes.ACCOUNT_NUMBER}
                control={<RadioStyled />}
                label={counterpartyBankDetailsTypesLabelsMap.get(
                  counterpartyBankDetailsTypes.ACCOUNT_NUMBER,
                )}
                disabled={disabledFields.includes(
                  paymentCounterpartyKeys.BANK_DETAILS_TYPE,
                )}
              />
              <FormControlLabel
                classes={{
                  root: classes.radioLabel,
                  label: classes.radioLabelText,
                }}
                value={counterpartyBankDetailsTypes.IBAN}
                control={<RadioStyled />}
                label={counterpartyBankDetailsTypesLabelsMap.get(
                  counterpartyBankDetailsTypes.IBAN,
                )}
                disabled={disabledFields.includes(
                  paymentCounterpartyKeys.BANK_DETAILS_TYPE,
                )}
              />
            </RadioGroup>
          </FormControl>
        </>
      ) : null}

      {!isPaymentCurrencyGBP ||
      formState.values[paymentCounterpartyKeys.BANK_DETAILS_TYPE] ===
        counterpartyBankDetailsTypes.IBAN ? (
        <>
          <MaskedControlledTextField
            {...getFieldProps(paymentCounterpartyKeys.IBAN)}
            formState={formState}
            mask={ibanInputMaskPatternAnycase}
            maskOptions={{
              pipe: (conformedValue) => conformedValue.toUpperCase(),
            }}
            disabled={disabledFields.includes(paymentCounterpartyKeys.IBAN)}
            required
          />

          <CountryAutocompleteControlledTextField
            {...getFieldProps(paymentCounterpartyKeys.COUNTRY)}
            formState={formState}
            disabled={disabledFields.includes(paymentCounterpartyKeys.ADDRESS)}
            required
          />

          <div className={classes.groupedAddressInputs}>
            <ControlledTextField
              {...getFieldProps(paymentCounterpartyKeys.CITY)}
              formState={formState}
              disabled={disabledFields.includes(
                paymentCounterpartyKeys.ADDRESS,
              )}
              required
            />
            <ControlledTextField
              {...getFieldProps(paymentCounterpartyKeys.POSTAL_CODE)}
              formState={formState}
              disabled={disabledFields.includes(
                paymentCounterpartyKeys.ADDRESS,
              )}
              required
            />
          </div>
          <ControlledTextField
            {...getFieldProps(paymentCounterpartyKeys.STREET)}
            formState={formState}
            disabled={disabledFields.includes(paymentCounterpartyKeys.ADDRESS)}
            required={
              !formState.values?.[paymentCounterpartyKeys.BUILDING_NAME]
            }
          />
          <div className={classes.groupedAddressInputs}>
            <ControlledTextField
              {...getFieldProps(paymentCounterpartyKeys.BUILDING_NAME)}
              formState={formState}
              disabled={disabledFields.includes(
                paymentCounterpartyKeys.ADDRESS,
              )}
              required={!formState.values?.[paymentCounterpartyKeys.STREET]}
            />
            <ControlledTextField
              {...getFieldProps(paymentCounterpartyKeys.BUILDING_NUMBER)}
              formState={formState}
              disabled={disabledFields.includes(
                paymentCounterpartyKeys.ADDRESS,
              )}
              required={
                !formState.values?.[paymentCounterpartyKeys.BUILDING_NAME]
              }
            />
          </div>
        </>
      ) : (
        <div className={classes.groupedInputs}>
          <MaskedControlledTextField
            {...getFieldProps(paymentCounterpartyKeys.SORT_CODE)}
            disabled={disabledFields.includes(
              paymentCounterpartyKeys.SORT_CODE,
            )}
            mask={sortCodeInputMaskPattern}
            formState={formState}
            required
          />

          <MaskedControlledTextField
            {...getFieldProps(paymentCounterpartyKeys.ACCOUNT_NUMBER)}
            disabled={disabledFields.includes(
              paymentCounterpartyKeys.ACCOUNT_NUMBER,
            )}
            mask={createOnlyDigitsTextInputMaskPattern(8)}
            formState={formState}
            required
          />
        </div>
      )}

      <FormControls
        className={classes.formControls}
        submitText={submitText}
        formState={formState}
        onCancel={onCancel}
        align="center"
        column={false}
        inverseButtons
        submitButtonProps={{
          'data-aio-id': 'submitCounterpartyButton',
        }}
        resetButtonProps={{
          'data-aio-id': 'cancelCounterpartyButton',
        }}
      />
    </form>
  );
}

CounterpartyEditForm.propTypes = {
  initialValues: shape({
    name: string,
  }),
  onCancel: func,
  onSubmit: func,
  onError: func,
  onDuplicate: func,
  disabledFields: arrayOf(string),
  submitText: string,
  url: string.isRequired,
  method: oneOf([httpMethods.POST, httpMethods.PUT, httpMethods.DELETE])
    .isRequired,
};

CounterpartyEditForm.defaultProps = {
  initialValues: {
    [paymentCounterpartyKeys.CURRENCY]: currencyIsoCodes.get(currency.GBP),
    [paymentCounterpartyKeys.TYPE]: counterpartyTypes.PERSONAL,
    [paymentCounterpartyKeys.BANK_DETAILS_TYPE]:
      counterpartyBankDetailsTypes.ACCOUNT_NUMBER,
  },
  onCancel: undefined,
  onSubmit: undefined,
  onError: undefined,
  onDuplicate: undefined,
  disabledFields: [],
  submitText: 'Save',
};
