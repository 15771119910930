import React, { useState, useCallback } from 'react';
import { omit, head, isNil, pickBy, isEmpty } from 'rambda';
import { stringify } from 'query-string';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { bool, func } from 'prop-types';
import clsx from 'clsx';
import Link from '@fondy/link';
import { useApplicationContext } from '@fondy/application-context';
import { Dialog } from '@fondy/data-display';
import { httpMethods } from '@fondy/enums';
import { useKeycloak } from '@fondy/keycloak';
import { fetchErrorHandler } from '@fondy/utils';
import { StatusMessage } from '@fondy/alerts';
import {
  PaymentSentIllustration,
  PaymentFailedIllustration,
  CounterpartyPossibleMatchIllustration,
  AuthenticateWith2FAIllustration,
} from '@fondy/icons';
import { buttonSize, buttonVariant } from '@fondy/buttons';
import { FormControls } from '@fondy/forms';
import {
  makePaymentModalStates,
  makePaymentModalTitlesMap,
  newPaymentErrorCodesMap,
} from './utils';
import {
  MakePaymentForm,
  makePaymentFormTransferTypes,
  newPaymentSortCodeSanitiser,
  omitKnownPayeeDetails,
} from '../MakePaymentForm';
import TwoFactorAuthenticationForm from '../TwoFactorAuthenticationForm';
import TwoFactorAuthenticationActivationForm from '../TwoFactorAuthenticationActivationForm';
import {
  stateSelectUserProfile,
  fetchUserProfileAction,
  getAccountsAction,
} from '../../../redux';
import {
  newPaymentKeys,
  tfaKeys,
  partyAssociationsKeys,
  paymentCounterpartyKeys,
  GBP_CURRENCY,
  isCounterpartyTypeFieldsFilled,
  copKeys,
  copNameCheckStatus,
  copAccountStatus,
  copStatus,
  copStatusIconMap,
  copStatusTitleMap,
  copStatusDescriptionLine1Map,
  copStatusDescriptionLine2Map,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 640,
    },
  },
  header: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  containerChild: {
    display: 'flex',
    flexDirection: 'column',
  },
  hidden: {
    display: 'none',
  },
  paymentReference: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.lightest,
    padding: theme.spacing(2, 3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  statusMessage: {
    fontSize: 14,
    color: theme.palette.text.secondaryDark,
    '& > b': {
      fontWeight: 600,
    },
  },
  statusMessageContainer: {
    flex: 1,
  },
  statusMessageButtons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

const defaultDuplicateMatchState = {
  match: null,
  values: null,
  useExistingCounterparty: null,
};

const formAllowedStages = [
  makePaymentModalStates.FORM,
  makePaymentModalStates.REVIEW,
];

const omitFormHelperProps = omit([
  newPaymentKeys.TRANSFER_TYPE,
  newPaymentKeys.PAYEE_STREET,
  newPaymentKeys.PAYEE_BUILDING_NUMBER,
  newPaymentKeys.PAYEE_BUILDING_NAME,
  newPaymentKeys.PAYEE_COUNTRY,
  newPaymentKeys.PAYEE_CITY,
  newPaymentKeys.PAYEE_POSTAL_CODE,
]);

const matchCopStatus = (cop) => {
  if (cop[copKeys.ACCOUNT_STATUS] === copAccountStatus.ACTIVE) {
    if (cop[copKeys.NAME_CHECK_STATUS] === copNameCheckStatus.FULL_MATCH) {
      return copStatus.FULL_MATCH;
    }

    if (cop[copKeys.NAME_CHECK_STATUS] === copNameCheckStatus.PARTIAL_MATCH) {
      return copStatus.PARTIAL_MATCH;
    }
  }

  return copStatus.NO_MATCH;
};

const MakePaymentModal = ({ open, onClose, onSuccess }) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const { fetch } = useApplicationContext();
  const { totpEnabled } = useSelector(stateSelectUserProfile);
  const userPartyId = useSelector(
    (state) => head(state.parties.data)[partyAssociationsKeys.PARTY_ID],
  );

  const [currentModalState, setCurrentModalState] = useState(
    makePaymentModalStates.FORM,
  );
  const [duplicateMatch, setDuplicateMatch] = useState(
    defaultDuplicateMatchState,
  );
  const [copDetails, setCopDetails] = useState(null);
  const [submitData, setSubmitData] = useState(null);
  const [successfulPaymentId, setSuccessfulPaymentId] = useState(null);
  const [paymentFee, setPaymentFee] = useState(0);
  const [paymentFxRate, setPaymentFxRate] = useState(0);
  const [isCopSubmitting, setIsCopSubmitting] = useState(false);
  const [isDuplicateSubmitting, setIsDuplicateSubmitting] = useState(false);

  const forceFetchAccounts = useCallback(
    (force = true) => dispatch(getAccountsAction(fetch, force)),
    [dispatch, fetch],
  );

  const forceRefreshUserProfile = useCallback(
    () => dispatch(fetchUserProfileAction(fetch, true)),
    [dispatch, fetch],
  );

  const makePaymentRequest = useCallback(
    async ({ code, data }) => {
      const response = await fetch('/api/core-banking/payments', {
        method: httpMethods.POST,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          ...omitFormHelperProps(data),
          ...(code ? { [newPaymentKeys.TWO_FACTOR_CODE]: code } : {}),
        }),
      });

      if (!response.ok) {
        await fetchErrorHandler({
          response,
          keycloak,
          throwError: true,
        });
      }

      const { id } = await response.json();
      return id;
    },
    [fetch, keycloak],
  );

  const handleTwoFactorAuthentication = useCallback(
    async ({ code, setSubmitting, setFieldError }) => {
      try {
        await setSubmitting(true);

        if (!submitData) {
          throw new Error('Payment request data is empty!');
        }

        if (!code) {
          throw new Error('You cannot send payment request without 2FA code!');
        }

        const paymentId = await makePaymentRequest({ code, data: submitData });

        if (paymentId) await setSuccessfulPaymentId(paymentId);

        await forceFetchAccounts();
        await setSubmitting(false);
        await setCurrentModalState(makePaymentModalStates.CONFIRMATION);
      } catch (error) {
        await setFieldError(
          tfaKeys.CODE,
          'The code you entered is incorrect, please try again',
        );
        await setSubmitting(false);
      }
    },
    [forceFetchAccounts, makePaymentRequest, submitData],
  );

  const handleTwoFactorAuthenticationError = useCallback((error) => {
    console.warn('2FA Error', error);
  }, []);

  const twoFactorActivationErrorHandler = useCallback((error) => {
    console.warn('2FA Activation Error', error);
  }, []);

  const checkIfAuthenticationIsRequired = useCallback(
    async ({ values, tfaCheck, setSubmitting }) => {
      await setSubmitting(true);
      await setSubmitData(values);

      if (tfaCheck) {
        await setCurrentModalState(
          totpEnabled
            ? makePaymentModalStates.AUTHENTICATOR
            : makePaymentModalStates.AUTHENTICATOR_SETUP,
        );
        return setSubmitting(false);
      }

      const paymentId = await makePaymentRequest({
        data: values,
      });

      if (paymentId) await setSuccessfulPaymentId(paymentId);

      await forceFetchAccounts();
      await setCurrentModalState(makePaymentModalStates.CONFIRMATION);
      return setSubmitting(false);
    },
    [forceFetchAccounts, makePaymentRequest, totpEnabled],
  );

  const verifyCounterparty = useCallback(
    async ({ values, rawValues, tfaCheck, setSubmitting, match }) => {
      try {
        const isCounterpartyPayment =
          rawValues[newPaymentKeys.TRANSFER_TYPE] ===
          makePaymentFormTransferTypes.RECIPIENT;

        if (isCounterpartyPayment) {
          const copVerifyBody = match
            ? {
                ...match,
                [newPaymentKeys.PAYEE_ID]: match[paymentCounterpartyKeys.ID],
              }
            : {
                [newPaymentKeys.PAYEE_ID]: rawValues[newPaymentKeys.PAYEE_ID],

                [paymentCounterpartyKeys.CURRENCY]:
                  rawValues[newPaymentKeys.PAYEE_CURRENCY],
                [paymentCounterpartyKeys.ACCOUNT_NUMBER]:
                  rawValues[newPaymentKeys.PAYEE_ACCOUNT_NUMBER],
                [paymentCounterpartyKeys.SORT_CODE]: newPaymentSortCodeSanitiser(
                  rawValues[newPaymentKeys.PAYEE_SORT_CODE],
                ),
                [paymentCounterpartyKeys.IBAN]:
                  rawValues[newPaymentKeys.PAYEE_IBAN],

                [paymentCounterpartyKeys.TYPE]:
                  rawValues[newPaymentKeys.PAYEE_TYPE],
                [paymentCounterpartyKeys.FIRST_NAME]:
                  rawValues[newPaymentKeys.PAYEE_FIRST_NAME],
                [paymentCounterpartyKeys.LAST_NAME]:
                  rawValues[newPaymentKeys.PAYEE_LAST_NAME],
                [paymentCounterpartyKeys.COMPANY_NAME]:
                  rawValues[newPaymentKeys.PAYEE_COMPANY_NAME],
              };

          const copResponse = await fetch(
            `/api/core-banking/parties/${userPartyId}/cop`,
            {
              method: httpMethods.POST,
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
              body: JSON.stringify(
                pickBy((value) => !isEmpty(value), copVerifyBody),
              ),
            },
          );

          if (!copResponse.ok) {
            await fetchErrorHandler({
              copResponse,
              keycloak,
              throwError: true,
            });
          }

          const copResponseDetails = await copResponse.json();
          if (copResponseDetails[copKeys.NEED_CONFIRMATION]) {
            setIsCopSubmitting(false);

            setCopDetails(() => ({
              ...copResponseDetails,
              [copKeys.STATUS]: matchCopStatus(copResponseDetails),
              onConfirm: async () => {
                try {
                  setIsCopSubmitting(true);

                  const copConfirmResponse = await fetch(
                    `/api/core-banking/parties/${userPartyId}/cop/confirm`,
                    {
                      method: 'PUT',
                      mode: 'cors',
                      cache: 'no-cache',
                      headers: {
                        Accept: 'application/json',
                        'Content-type': 'application/json',
                      },
                      body: JSON.stringify({
                        [copKeys.ID]: copResponseDetails[copKeys.ID],
                      }),
                    },
                  );

                  if (!copConfirmResponse.ok) {
                    await fetchErrorHandler({
                      copConfirmResponse,
                      keycloak,
                      throwError: true,
                    });
                  }

                  return await checkIfAuthenticationIsRequired({
                    values,
                    tfaCheck,
                    setSubmitting,
                  });
                } catch (e) {
                  setIsCopSubmitting(false);

                  return setCurrentModalState(makePaymentModalStates.ERROR);
                }
              },
            }));

            await setCurrentModalState(
              makePaymentModalStates.COUNTERPARTY_CONFIRMATION,
            );

            return setSubmitting(false);
          }
        }

        return await checkIfAuthenticationIsRequired({
          values,
          tfaCheck,
          setSubmitting,
        });
      } catch (e) {
        return setCurrentModalState(makePaymentModalStates.ERROR);
      }
    },
    [checkIfAuthenticationIsRequired, fetch, keycloak, userPartyId],
  );

  const updatePaymentFxRate = useCallback(
    async ({ baseCurrency, quoteCurrency }) => {
      try {
        const fx = await fetch(
          `/api/core-banking/fx/${baseCurrency}/${quoteCurrency}`,
        );
        const response = await fx.json();

        setPaymentFxRate(response?.rate ?? 0);
      } catch (error) {
        setPaymentFxRate(0);
      }
    },
    [fetch],
  );

  const handleValidationComplete = useCallback(
    async ({
      values,
      rawValues,
      recipient,
      setRecipient,
      isNewRecipient,
      isPaymentCurrencyGBP,
      shouldUpdateCounterpartyTypeFields,
      setSubmitting,
      setFieldError,
      postEditCheck = false,
      mapCounterpartyToPaymentKeys,
      setValues,
    } = {}) => {
      await setSubmitting(true);
      try {
        if (shouldUpdateCounterpartyTypeFields) {
          const counterpartyUpdateResponse = await fetch(
            `/api/core-banking/parties/${userPartyId}/payees/${
              rawValues[newPaymentKeys.PAYEE_ID]
            }`,
            {
              method: 'PUT',
              mode: 'cors',
              cache: 'no-cache',
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
              },
              body: JSON.stringify(
                pickBy((value) => !isEmpty(value), {
                  [paymentCounterpartyKeys.COMPANY_NAME]:
                    rawValues[newPaymentKeys.PAYEE_COMPANY_NAME],
                  [paymentCounterpartyKeys.FIRST_NAME]:
                    rawValues[newPaymentKeys.PAYEE_FIRST_NAME],
                  [paymentCounterpartyKeys.LAST_NAME]:
                    rawValues[newPaymentKeys.PAYEE_LAST_NAME],
                  [paymentCounterpartyKeys.TYPE]:
                    rawValues[newPaymentKeys.PAYEE_TYPE],
                  [paymentCounterpartyKeys.NAME]:
                    recipient[newPaymentKeys.PAYEE_NAME],
                }),
              ),
            },
          );

          if (!counterpartyUpdateResponse.ok) {
            await fetchErrorHandler({
              counterpartyUpdateResponse,
              keycloak,
              throwError: true,
            });
          }

          setRecipient((prev) => ({
            ...prev,
            [newPaymentKeys.PAYEE_COMPANY_NAME]:
              rawValues[newPaymentKeys.PAYEE_COMPANY_NAME],
            [newPaymentKeys.PAYEE_FIRST_NAME]:
              rawValues[newPaymentKeys.PAYEE_FIRST_NAME],
            [newPaymentKeys.PAYEE_LAST_NAME]:
              rawValues[newPaymentKeys.PAYEE_LAST_NAME],
            [newPaymentKeys.PAYEE_TYPE]: rawValues[newPaymentKeys.PAYEE_TYPE],
          }));
        }

        const response = await fetch(
          '/api/core-banking/payments/request-for-quote',
          {
            method: httpMethods.POST,
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
            body: await JSON.stringify(omitFormHelperProps(values)),
          },
        );

        if (!response.ok) {
          await fetchErrorHandler({
            response,
            keycloak,
            throwError: true,
          });
        }

        const { fee, tfaCheck } = await response.json();
        if (!isNil(fee) && typeof fee === 'number') {
          await setPaymentFee(fee);
        }

        if (currentModalState === makePaymentModalStates.FORM) {
          if (!isPaymentCurrencyGBP) {
            await updatePaymentFxRate({
              quoteCurrency: GBP_CURRENCY,
              baseCurrency: values[newPaymentKeys.PAYMENT_CURRENCY],
            });
          }

          await setCurrentModalState(makePaymentModalStates.REVIEW);
          return setSubmitting(false);
        }

        if (currentModalState === makePaymentModalStates.REVIEW) {
          if (postEditCheck) return setSubmitting(false);

          if (isNewRecipient) {
            const matchingExistingCounterparties = await fetch(
              `/api/core-banking/parties/${userPartyId}/payees?${stringify(
                {
                  [paymentCounterpartyKeys.CURRENCY]:
                    values[newPaymentKeys.PAYEE_CURRENCY],
                  ...(values[newPaymentKeys.PAYEE_IBAN]
                    ? {
                        [paymentCounterpartyKeys.IBAN]:
                          values[newPaymentKeys.PAYEE_IBAN],
                      }
                    : {
                        [paymentCounterpartyKeys.ACCOUNT_NUMBER]:
                          values[newPaymentKeys.PAYEE_ACCOUNT_NUMBER],
                        [paymentCounterpartyKeys.SORT_CODE]:
                          values[newPaymentKeys.PAYEE_SORT_CODE],
                      }),
                },
                {
                  arrayFormat: 'comma',
                  skipNull: true,
                  skipEmptyString: true,
                },
              )}`,
            );

            const matchingCounterparties = await matchingExistingCounterparties.json();
            if (matchingCounterparties?.content?.length > 0) {
              const match = head(
                matchingCounterparties.content.filter(
                  (p) => !!p[paymentCounterpartyKeys.TYPE],
                ),
              );
              const isMatchTypeFieldsFilled = isCounterpartyTypeFieldsFilled(
                match,
              );

              if (isMatchTypeFieldsFilled) {
                setIsDuplicateSubmitting(false);

                await setDuplicateMatch({
                  match,
                  values,
                  useExistingCounterparty: async () => {
                    setIsDuplicateSubmitting(true);

                    const selectedRecipient = mapCounterpartyToPaymentKeys(
                      match,
                    );

                    await setRecipient({ ...selectedRecipient });
                    await setValues((prevValues) => ({
                      ...prevValues,
                      ...selectedRecipient,
                      [newPaymentKeys.PAYEE_NAME]: { ...selectedRecipient },
                    }));

                    return verifyCounterparty({
                      match,
                      values: omitKnownPayeeDetails({
                        ...values,
                        [newPaymentKeys.PAYEE_ID]:
                          match[paymentCounterpartyKeys.ID],
                      }),
                      rawValues,
                      tfaCheck,
                      setSubmitting,
                    });
                  },
                });

                await setCurrentModalState(
                  makePaymentModalStates.COUNTERPARTY_DUPLICATE,
                );

                return setSubmitting(false);
              }
            }
          }

          return verifyCounterparty({
            values,
            rawValues,
            tfaCheck,
            setSubmitting,
          });
        }
      } catch (error) {
        if (error.affectedField) {
          const errorMessage = error.errorCode
            ? newPaymentErrorCodesMap.get(error.errorCode)
            : error.message;
          await setFieldError(
            error.affectedField,
            errorMessage ||
              'Something went wrong, please contact Fondy Support',
          );
          await setCurrentModalState(makePaymentModalStates.FORM);
          return setSubmitting(false);
        }

        return setCurrentModalState(makePaymentModalStates.ERROR);
      }

      return undefined;
    },
    [
      fetch,
      currentModalState,
      userPartyId,
      keycloak,
      updatePaymentFxRate,
      verifyCounterparty,
    ],
  );

  const twoFactorActivationSuccessHandler = useCallback(async () => {
    await forceRefreshUserProfile();
    await setCurrentModalState(makePaymentModalStates.AUTHENTICATOR);
  }, [forceRefreshUserProfile]);

  const onSuccessfulPaymentConfirm = useCallback(
    async (event) => {
      await setDuplicateMatch(defaultDuplicateMatchState);
      await forceFetchAccounts();
      if (onSuccess) await onSuccess(event);
      if (onClose) return onClose(event);
      return undefined;
    },
    [forceFetchAccounts, onClose, onSuccess],
  );

  return (
    <Dialog
      dense
      fullWidth
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      open={open}
      onClose={onClose}
      centeredFooter={false}
      header={makePaymentModalTitlesMap.get(currentModalState)}
      className={classes.dialog}
      headerProps={{
        className: classes.header,
      }}
      data-aio-id="makePaymentModal"
    >
      <Grid container>
        <Grid item xs={12} className={classes.containerChild}>
          <MakePaymentForm
            onReset={onClose}
            onValidationComplete={handleValidationComplete}
            formReview={currentModalState === makePaymentModalStates.REVIEW}
            className={clsx({
              [classes.hidden]: !formAllowedStages.includes(currentModalState),
            })}
            paymentFee={paymentFee}
            paymentFxRate={paymentFxRate}
            updatePaymentFxRate={updatePaymentFxRate}
            submitText={
              currentModalState === makePaymentModalStates.REVIEW
                ? 'Confirm payment'
                : 'Proceed'
            }
          />

          {currentModalState === makePaymentModalStates.AUTHENTICATOR && (
            <TwoFactorAuthenticationForm
              autoSubmit
              sendRequest={false}
              illustration={AuthenticateWith2FAIllustration}
              onSubmit={handleTwoFactorAuthentication}
              onError={handleTwoFactorAuthenticationError}
              title="Authenticate this payment"
              description={
                <>
                  <span>Use six digit code generated from</span>
                  <br />
                  <b>Google Authenticator app</b>
                </>
              }
              submitText="Authenticate"
              resetButtonComponent={
                <Typography variant="button">
                  Can&apos;t receive code?&nbsp;
                  <Link
                    to={keycloak
                      .createAccountUrl({
                        redirectUri: window.location.origin,
                      })
                      .replace('account', 'account/totp')}
                  >
                    Reset
                  </Link>
                </Typography>
              }
            />
          )}

          {currentModalState === makePaymentModalStates.AUTHENTICATOR_SETUP && (
            <TwoFactorAuthenticationActivationForm
              fullWidth
              autoSubmit
              onSubmit={twoFactorActivationSuccessHandler}
              onError={twoFactorActivationErrorHandler}
            />
          )}

          {currentModalState === makePaymentModalStates.CONFIRMATION && (
            <StatusMessage
              title="Your payment has been sent!"
              // This was disabled by https://fondy.atlassian.net/browse/BANKING-2356 but I decided just to comment
              // out this feature ;)
              // message={
              //   <>
              //     <Typography variant="body2" gutterBottom>
              //       Payment reference number
              //     </Typography>
              //     <Typography
              //       className={classes.paymentReference}
              //       onClick={() => writeToClipboard(successfulPaymentId)}
              //       data-aio-id="newPaymentId"
              //     >
              //       {successfulPaymentId}
              //     </Typography>
              //   </>
              // }
              confirmLabel="Continue"
              onConfirm={onSuccessfulPaymentConfirm}
              className={classes.successMessage}
              illustration={PaymentSentIllustration}
              confirmButtonProps={{
                'data-aio-id': 'confirmSuccessfulPaymentButton',
              }}
            />
          )}

          {currentModalState === makePaymentModalStates.ERROR && (
            <StatusMessage
              title="Payment not send"
              message={
                <>
                  <Typography variant="body2" gutterBottom>
                    There was a problem sending your payment.
                  </Typography>
                  <Typography variant="body2">Please try again</Typography>
                </>
              }
              confirmLabel="Confirm"
              onConfirm={onClose}
              illustration={PaymentFailedIllustration}
              confirmButtonProps={{
                'data-aio-id': 'confirmMakePaymentErrorButton',
              }}
            />
          )}

          {currentModalState ===
            makePaymentModalStates.COUNTERPARTY_CONFIRMATION && (
            <>
              <StatusMessage
                className={classes.statusMessageContainer}
                title={copStatusTitleMap.get(copDetails[copKeys.STATUS])}
                message={
                  <>
                    <Typography
                      variant="body1"
                      className={classes.statusMessage}
                    >
                      {copStatusDescriptionLine1Map.get(
                        copDetails[copKeys.STATUS],
                      )}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.statusMessage}
                    >
                      {copStatusDescriptionLine2Map.get(
                        copDetails[copKeys.STATUS],
                      )}
                    </Typography>
                  </>
                }
                renderConfirmButton={false}
                illustration={copStatusIconMap.get(copDetails[copKeys.STATUS])}
              />

              <FormControls
                className={classes.statusMessageButtons}
                formState={{
                  isSubmitting: isCopSubmitting,
                }}
                submitText={`${
                  copDetails[copKeys.STATUS] === copStatus.FULL_MATCH
                    ? 'Continue'
                    : 'Continue anyway'
                }`}
                cancelText="Check details"
                onCancel={() =>
                  setCurrentModalState(makePaymentModalStates.REVIEW)
                }
                onSubmit={copDetails.onConfirm}
                disabled={isCopSubmitting}
                column={isMobile}
                align="center"
                buttonsProps={{
                  size: buttonSize.LARGE,
                  fullWidth: isMobile,
                }}
                inverseButtons={!isMobile}
                renderSubmitButton={
                  copDetails[copKeys.STATUS] !== copStatus.NO_MATCH
                }
                submitButtonProps={{
                  'data-aio-id': 'copContinueButton',
                }}
                renderResetButton={
                  copDetails[copKeys.STATUS] !== copStatus.FULL_MATCH
                }
                resetButtonProps={{
                  variant:
                    copDetails[copKeys.STATUS] === copStatus.NO_MATCH
                      ? buttonVariant.CONTAINED
                      : buttonVariant.OUTLINED,
                  'data-aio-id': 'copCancelButton',
                }}
              />
            </>
          )}

          {currentModalState ===
            makePaymentModalStates.COUNTERPARTY_DUPLICATE && (
            <>
              <StatusMessage
                className={classes.statusMessageContainer}
                title="Possible match"
                message={
                  <Typography variant="body1" className={classes.statusMessage}>
                    We’ve found an existing payee with the name
                    <b> {duplicateMatch.match[paymentCounterpartyKeys.NAME]}</b>
                    .
                    <br />
                    Would you like to use these details or enter new ones?
                  </Typography>
                }
                illustration={CounterpartyPossibleMatchIllustration}
                renderConfirmButton={false}
              />

              <FormControls
                className={classes.statusMessageButtons}
                formState={{
                  isSubmitting: isDuplicateSubmitting,
                }}
                submitText="Use existing"
                cancelText="Enter new details"
                onCancel={() =>
                  setCurrentModalState(makePaymentModalStates.REVIEW)
                }
                onSubmit={duplicateMatch.useExistingCounterparty}
                disabled={isDuplicateSubmitting}
                column={isMobile}
                inverseButtons={isMobile}
                align="center"
                buttonsProps={{
                  size: buttonSize.LARGE,
                  fullWidth: isMobile,
                }}
                submitButtonProps={{
                  variant: buttonVariant.OUTLINED,
                  'data-aio-id': 'duplicateContinueButton',
                }}
                resetButtonProps={{
                  variant: buttonVariant.CONTAINED,
                  'data-aio-id': 'duplicateCancelButton',
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

MakePaymentModal.propTypes = {
  open: bool,
  onClose: func,
  onSuccess: func,
};

MakePaymentModal.defaultProps = {
  open: false,
  onClose: undefined,
  onSuccess: undefined,
};

export default MakePaymentModal;
