// eslint-disable-next-line no-restricted-imports
import { rgbToHex } from '@material-ui/core/styles/colorManipulator';
import { defaultTheme } from '@fondy/themes';

export const complianceProfileStatuses = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  LIMITED: 'LIMITED',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO',
  CREATED: 'CREATED',
});

export const complianceProfileStatusesLabelsMap = new Map([
  [complianceProfileStatuses.ACTIVE, 'Active'],
  [complianceProfileStatuses.INACTIVE, 'Inactive'],
  [complianceProfileStatuses.PENDING, 'Pending'],
  [complianceProfileStatuses.LIMITED, 'Pending'],
  [complianceProfileStatuses.CREATED, 'Pending'],
  [complianceProfileStatuses.REJECTED, 'Rejected'],
  [complianceProfileStatuses.ADDITIONAL_INFO, 'Additional Info Requested'],
]);

export const complianceProfileStatusesColorsMap = new Map([
  [complianceProfileStatuses.ACTIVE, '#00C2B8'],
  [
    complianceProfileStatuses.LIMITED,
    rgbToHex(defaultTheme.palette.primary.light),
  ],
  [complianceProfileStatuses.INACTIVE, '#B5B7BA'],
  [
    complianceProfileStatuses.PENDING,
    rgbToHex(defaultTheme.palette.secondaryAccent.main),
  ],
  [
    complianceProfileStatuses.UNKNOWN,
    rgbToHex(defaultTheme.palette.secondary.light),
  ],
  [
    complianceProfileStatuses.REJECTED,
    rgbToHex(defaultTheme.palette.accent.main),
  ],
  [
    complianceProfileStatuses.ADDITIONAL_INFO,
    rgbToHex(defaultTheme.palette.primary.light),
  ],
]);

export const complianceProfileStatusesTooltipsMap = new Map([
  [
    complianceProfileStatuses.ACTIVE,
    'Profile is active and approved to process transactions.',
  ],
  [
    complianceProfileStatuses.INACTIVE,
    'Profile is inactive and can not be used any more.',
  ],
  [complianceProfileStatuses.PENDING, 'Profile is under review. '],
  [
    complianceProfileStatuses.REJECTED,
    'Profile was rejected and can not process transactions.',
  ],
]);
