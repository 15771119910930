export const splitAccountTransactionKeys = Object.freeze({
  ID: 'id',
  BOOKING_DATE: 'bookingDate',
  VALUE_DATE: 'valueDate',
  POSTING_REFERENCE: 'postingReference',
  COUNTERPARTY_ACCOUNT_ALIAS: 'counterpartyAccountAlias',
  COUNTERPARTY_ACCOUNT_CURRENCY: 'counterpartyAccountCurrency',
  COUNTERPARTY_ACCOUNT_ID: 'counterpartyAccountId',
  COUNTERPARTY_ACCOUNT_NUMBER: 'counterpartyAccountNumber',
  COUNTERPARTY_ACCOUNT_TYPE: 'counterpartyAccountType',
  COUNTERPARTY_NAME: 'counterpartyName',
  COUNTERPARTY_IBAN: 'counterpartyIban',
  COUNTERPARTY_SORT_CODE: 'counterpartySortCode',
  TYPE: 'transactionType',
  AMOUNT: 'transactionAmount',
  ORIGINAL_AMOUNT: 'originalAmount',
  ACCOUNT_ALIAS: 'accountAlias',
  ACCOUNT_ID: 'accountId',
  ACCOUNT_TYPE: 'accountType',
  ACCOUNT_OPENING_BALANCE: 'accountOpeningBalance',
  ACCOUNT_CLOSING_BALANCE: 'accountClosingBalance',
  TRANSACTION_REFERENCE: 'transactionReference',
  TRANSACTION_CODE: 'transactionCode',
  TRANSACTION_CURRENCY: 'transactionCurrency',
  TRANSACTION_ORIGINAL_CURRENCY: 'originalCurrency',
  TRANSACTION_LABEL: 'transactionLabel',
  TRANSACTION_NOTES: 'transactionNotes',
  CREATED_AT: 'createdAt',
  DEBIT_CREDIT_CODE: 'debitCreditCode',
  END_TO_END_INSTRUCTION_ID: 'endToEndInstructionId',
  FEE_TRANSACTION_ID: 'feeTransactionId',
  IS_FEE: 'isFee',
  PAYMENT_CHANNEL_CODE: 'paymentChannelCode',
  PAYMENT_INSTRUCTION_ID: 'paymentInstructionId',
  FX_RATE: 'fxRate',
});

export const splitAccountTransactionKeysLabelsMap = new Map([
  [splitAccountTransactionKeys.ID, 'Id'],
  [splitAccountTransactionKeys.ACCOUNT_ALIAS, 'Account Alias'],
  [splitAccountTransactionKeys.ACCOUNT_ID, 'Account Id'],
  [splitAccountTransactionKeys.ACCOUNT_TYPE, 'Account Type'],
  [splitAccountTransactionKeys.TRANSACTION_CODE, 'Transaction Code'],
  [splitAccountTransactionKeys.TRANSACTION_LABEL, 'Label'],
  [splitAccountTransactionKeys.TRANSACTION_NOTES, 'Notes'],
  [splitAccountTransactionKeys.CREATED_AT, 'Created At'],
  [splitAccountTransactionKeys.DEBIT_CREDIT_CODE, 'Debit/Credit Code'],
  [splitAccountTransactionKeys.END_TO_END_INSTRUCTION_ID, 'E2E Transaction Id'],
  [splitAccountTransactionKeys.FEE_TRANSACTION_ID, 'Fee Transaction Id'],
  [splitAccountTransactionKeys.IS_FEE, 'Is Fee'],
  [splitAccountTransactionKeys.PAYMENT_CHANNEL_CODE, 'Payment Channel Code'],
  [
    splitAccountTransactionKeys.PAYMENT_INSTRUCTION_ID,
    'Payment Instruction Id',
  ],
  [splitAccountTransactionKeys.VALUE_DATE, 'Value Date'],
  [splitAccountTransactionKeys.BOOKING_DATE, 'Date'],
  [splitAccountTransactionKeys.POSTING_REFERENCE, 'Reference'],
  [splitAccountTransactionKeys.COUNTERPARTY_NAME, 'Name'],
  [
    splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_CURRENCY,
    'Payee Account Currency',
  ],
  [
    splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_ALIAS,
    'Payee Account Alias',
  ],
  [splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_ID, 'Payee Account Id'],
  [
    splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_NUMBER,
    'Payee Account Number',
  ],
  [splitAccountTransactionKeys.COUNTERPARTY_ACCOUNT_TYPE, 'Payee Account Type'],
  [splitAccountTransactionKeys.COUNTERPARTY_SORT_CODE, 'Payee Sort Code'],
  [splitAccountTransactionKeys.TYPE, 'Type'],
  [splitAccountTransactionKeys.AMOUNT, 'Amount'],
  [splitAccountTransactionKeys.ORIGINAL_AMOUNT, 'Original amount'],
  [splitAccountTransactionKeys.TRANSACTION_CURRENCY, 'Currency'],
  [
    splitAccountTransactionKeys.TRANSACTION_ORIGINAL_CURRENCY,
    'Original currency',
  ],
  [splitAccountTransactionKeys.ACCOUNT_CLOSING_BALANCE, 'Closing Balance'],
  [splitAccountTransactionKeys.ACCOUNT_OPENING_BALANCE, 'Opening Balance'],
  [splitAccountTransactionKeys.TRANSACTION_REFERENCE, 'Transaction Reference'],
  [splitAccountTransactionKeys.FX_RATE, 'FX rate'],
]);

export const splitAccountTransactionKeysDetailsLabelsMap = new Map([
  [
    splitAccountTransactionKeys.TYPE,
    splitAccountTransactionKeysLabelsMap.get(splitAccountTransactionKeys.TYPE),
  ],
]);
