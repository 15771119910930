/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { arrayOf, node } from 'prop-types';
import { Grid, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { PageHeader } from '@fondy/layouts';
import { ChevronRightIcon } from '@fondy/icons';
import { TotalBalance } from '../../atoms';
import { stateSelectSplitAccountDefaultWallet } from '../../../redux';
import { splitAccountWalletKeys } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  totalBalanceTitle: {
    paddingTop: theme.spacing(2),
    color: theme.palette.text.secondaryDark,
  },
  pageTitle: {
    paddingRight: theme.spacing(1),
  },
}));

const SplitAccountPageHeader = ({ actions, ...restProps }) => {
  const classes = useStyles();

  const { splitAccountDefaultWallet } = useSelector(
    stateSelectSplitAccountDefaultWallet,
  );

  return (
    <PageHeader
      preTitle={
        <Grid container alignItems="center">
          <Typography
            variant="h3"
            color="primary"
            className={classes.pageTitle}
          >
            Marketplace
          </Typography>
          <SvgIcon component={ChevronRightIcon} color="primary" />
        </Grid>
      }
      title={
        <>
          <Typography variant="body2" className={classes.totalBalanceTitle}>
            Available Balance
          </Typography>
          <TotalBalance
            currencyIso={
              splitAccountDefaultWallet[
                splitAccountWalletKeys.CURRENT_AMOUNT_CURRENCY
              ]
            }
            totalBalance={
              splitAccountDefaultWallet[splitAccountWalletKeys.CURRENT_AMOUNT]
            }
            renderCurrency
          />
        </>
      }
      actions={actions}
      classes={{
        toolbar: classes.toolbar,
      }}
      {...restProps}
    />
  );
};

SplitAccountPageHeader.propTypes = {
  actions: arrayOf(node),
};
SplitAccountPageHeader.defaultProps = {
  actions: null,
};

export default SplitAccountPageHeader;
