import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@fondy/layouts';
import {
  BankColorIcon,
  GbpSymbolCheckedColorIcon,
  GbpSymbolColorIcon,
  PercentageColorIcon,
} from '@fondy/icons';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonVariant,
  buttonSize,
} from '@fondy/buttons';
import bannerHeaderImage from './images/banner.png';
import { AppLayout } from '../../layouts';
import { FeatureCard } from '../../atoms';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 0,
  },
  banner: {
    background: `linear-gradient(284deg, #23005A 8.76%, #0C012D 82.76%)`,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  bannerContainer: {
    padding: theme.spacing(4),
    color: theme.palette.common.white,
  },
  title: {
    fontSize: 32,
    lineHeight: `${34}px`,
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  headerImage: {
    maxWidth: 550,
    minWidth: 200,
    width: '100%',
  },
  content: {
    padding: theme.spacing(4),
    '&:not(:last-of-type)': {
      paddingBottom: 0,
    },
  },
  list: {
    lineHeight: '200%',
    fontSize: 14,
    paddingInlineStart: theme.spacing(3),
    '& li': {
      paddingLeft: theme.spacing(1),
      '&::marker': {
        color: theme.palette.accent.main,
      },
    },
  },
  ctaButton: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 4),
  },
  subtitle: {
    fontSize: 14,
  },
}));

const SplitAccountCtaPage = () => {
  const classes = useStyles();

  return (
    <AppLayout>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Grid container spacing={0} className={classes.banner}>
            <Grid item xs={12} sm={6} className={classes.bannerContainer}>
              <Typography variant="h2" className={classes.title}>
                Discover the ultimate solution for seamless split payments and
                payouts
              </Typography>
              <Typography variant="body1">
                Invigorate your platform or marketplace with our affordable
                split payments, enjoy seamless Split Accounts tailored to
                simplify your payments to partners and suppliers
              </Typography>
              <Button
                to={`${
                  // eslint-disable-next-line no-underscore-dangle
                  window?._RUNTIME_CONFIG_?.SPLIT_ACCOUNT_LANDING_PAGE_CTA_URL
                }`}
                color={buttonColor.PRIMARY}
                variant={buttonVariant.CONTAINED}
                shape={buttonShape.ROUNDED}
                size={buttonSize.LARGE}
                className={classes.ctaButton}
                target="_blank"
              >
                Request Split Account
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                src={bannerHeaderImage}
                alt="banner"
                className={classes.headerImage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.content}>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                How it works
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.content}>
            <Grid item xs={6} sm={3}>
              <FeatureCard
                subtitle="Accept funds into your Split Account"
                icon={GbpSymbolCheckedColorIcon}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <FeatureCard
                subtitle="Easily manage your platform or marketplace commission"
                icon={PercentageColorIcon}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <FeatureCard
                subtitle="Make simple split payments into your partner or suppliers’s wallet"
                icon={GbpSymbolColorIcon}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <FeatureCard
                subtitle="Trigger payouts to your partner or supplier's bank account"
                icon={BankColorIcon}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.content}>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom>
                Unlock limitless benefits
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" className={classes.subtitle}>
                Accept payments from accounts or cards in GBP and seamlessly
                split funds between your platform and
                merchants/sellers/partners. Our Split Account allows you to
                leverage advanced technology and extensive industry experience
                to simplify your payment operations and enjoy multiple benefits
                including:
              </Typography>
            </Grid>

            <Grid container item xs={12} spacing={4}>
              <Grid item xs={12} sm={6}>
                <FeatureCard
                  title="Seamless Integration"
                  subtitle="Easily integrate our payment solution into your existing platform using our user-friendly APIs"
                  spacing={2}
                  gutterTop
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FeatureCard
                  title="Effortless onboarding via API"
                  subtitle="Accelerate partnership and collaboration with quick and easy partner onboarding via our API – simple, secure, and scalable"
                  spacing={2}
                  gutterTop
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FeatureCard
                  title="Affordable Split Payments"
                  subtitle="Save time and money with cost-effective split payments, that let you manage your resources efficiently with automation and customisable commission and fee structures"
                  spacing={2}
                  gutterTop
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FeatureCard
                  title="Multiple-party Payouts in GBP"
                  subtitle="Make simple payouts to your partners and suppliers in GBP (EUR coming soon)"
                  spacing={2}
                  gutterTop
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={1} className={classes.content}>
            <Grid item xs={12}>
              <Typography variant="h2">Get started today</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                className={classes.list}
                paragraph={false}
                component="ol"
              >
                <li>Request your Split Account</li>
                <li>Integrate into our Split Account API</li>
                <li>Onboard your partner or supplier</li>
                <li>Create wallets for your partner or supplier</li>
                <li>
                  Start splitting payments into your partner or supplier wallets
                </li>
                <li>
                  Make easy payouts to your partner or supplier’s bank account
                </li>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </AppLayout>
  );
};

export default SplitAccountCtaPage;
