/* eslint-disable react/prop-types */
import React from 'react';
import {
  StatusTableCell,
  AmountTableCell,
  AccountAliasTableCell,
  AvatarDataCell,
} from '@fondy/tables';
import { accountStatusesLabels } from '@fondy/enums';
import { walletKeysLabelsMap, walletKeys, accountKeys } from '../../../utils';

export const mobileWalletsTableColumns = [
  {
    id: accountKeys.ALIAS,
    label: 'Account name',
    render: ({ value, row, searchQuery }) => (
      <AccountAliasTableCell
        small
        showCurrencyIso
        accountAlias={value}
        searchQueryHighlight={searchQuery}
        currencyIso={row[accountKeys.CURRENCY]}
      />
    ),
  },
  {
    id: accountKeys.CURRENT_BALANCE,
    align: 'right',
    label: 'Balance',
    style: { width: 100 },
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[accountKeys.CURRENCY]}
        justifyContent="flex-end"
        valueWrapperFn={(formattedValue) => (
          <>
            <div>{formattedValue}</div>
            <StatusTableCell
              small
              status={row[accountKeys.STATUS]}
              labelsMap={accountStatusesLabels}
            />
          </>
        )}
      />
    ),
  },
];

const walletsTableColumns = [
  {
    id: walletKeys.ALIAS,
    padding: 'none',
    label: 'Account name',
    render: ({ row, searchQuery }) => (
      <AccountAliasTableCell
        small
        searchQueryHighlight={searchQuery}
        accountAlias={row[walletKeys.ALIAS]}
        currencyIso={row[walletKeys.CURRENCY]}
      />
    ),
  },
  {
    id: walletKeys.HOLDER_NAME,
    numeric: false,
    label: 'Holder name',
    render: ({ row, searchQuery }) => (
      <AvatarDataCell
        title={row[walletKeys.HOLDER_NAME]}
        searchQueryHighlight={searchQuery}
        avatarSize="small"
      />
    ),
  },
  {
    id: walletKeys.STATUS,
    numeric: false,
    label: walletKeysLabelsMap.get(walletKeys.STATUS),
    render: ({ value }) => (
      <StatusTableCell status={value} labelsMap={accountStatusesLabels} />
    ),
    style: {
      width: 125,
      textOverflow: 'unset',
    },
  },
  {
    id: walletKeys.CURRENT_BALANCE,
    style: { width: 125 },
    align: 'right',
    label: 'Balance',
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[walletKeys.CURRENCY]}
        justifyContent="flex-end"
      />
    ),
  },
];

export default walletsTableColumns;
