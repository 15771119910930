/* eslint-disable react/prop-types */
import React from 'react';
import { AmountTableCell, AvatarDataCell } from '@fondy/tables';
import FormattedDateTime from '../FormattedDateTime';
import { paymentsKeys, paymentsKeysLabelsMap } from '../../../utils';

const merchantsSettlementsTableColumns = [
  {
    id: paymentsKeys.CREATED_AT,
    numeric: false,
    disablePadding: false,
    label: paymentsKeysLabelsMap.get(paymentsKeys.CREATED_AT),
    render: ({ value }) => <FormattedDateTime utcIsoDate={value} />,
  },
  {
    id: paymentsKeys.END_2_END_ID,
    numeric: false,
    disablePadding: false,
    style: { width: '35%' },
    label: 'Settlement ID',
  },
  {
    id: paymentsKeys.CURRENCY,
    numeric: false,
    disablePadding: false,
    label: paymentsKeysLabelsMap.get(paymentsKeys.CURRENCY),
  },
  {
    id: paymentsKeys.AMOUNT,
    numeric: true,
    disablePadding: false,
    align: 'right',
    label: paymentsKeysLabelsMap.get(paymentsKeys.AMOUNT),
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[paymentsKeys.CURRENCY]}
        direction={row[paymentsKeys.DIRECTION]}
        justifyContent="flex-end"
      />
    ),
  },
];

export const mobileMerchantsSettlementsTableColumns = [
  {
    id: paymentsKeys.DESCRIPTION,
    numeric: false,
    disablePadding: false,
    label: `Name & ${paymentsKeysLabelsMap
      .get(paymentsKeys.DESCRIPTION)
      .toLocaleLowerCase()}`,
    render: ({ row }) => (
      <AvatarDataCell
        subtitle={
          <>
            <div>{row[paymentsKeys.DESCRIPTION]}</div>
            <div>
              <FormattedDateTime utcIsoDate={row[paymentsKeys.CREATED_AT]} />
            </div>
          </>
        }
      />
    ),
  },
  {
    id: paymentsKeys.AMOUNT,
    numeric: true,
    disablePadding: false,
    align: 'right',
    label: paymentsKeysLabelsMap.get(paymentsKeys.AMOUNT),
    render: ({ value, row }) => (
      <AmountTableCell
        value={value}
        currencyIso={row[paymentsKeys.CURRENCY]}
        direction={row[paymentsKeys.DIRECTION]}
        justifyContent="flex-end"
      />
    ),
  },
];

export default merchantsSettlementsTableColumns;
