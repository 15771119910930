import React from 'react';
import { number, string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AmountTableCell } from '@fondy/tables';
import FormattedDateTime from '../FormattedDateTime';

const useStyles = makeStyles((theme) => ({
  createdAt: {
    fontSize: theme.typography.fontSize,
    marginTop: theme.spacing(1),
  },
  originalAmount: {
    marginBottom: theme.spacing(0.5),
  },
  textColorSecondary: {
    color: theme.palette.text.secondary,
  },
  divider: {
    width: 1,
    borderRadius: 1,
    height: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.colors.GREY_LIGHT,
  },
}));

const PaymentTransactionDetailsModalHeader = ({
  amountValuePrefix,
  amount,
  currency,
  originalAmount,
  originalCurrency,
  fxRate,
  createdAt,
  datetimeFormat,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <Grid container item justifyContent="center" {...restProps}>
      {originalCurrency ? (
        <Grid
          container
          className={classes.originalAmount}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <AmountTableCell
              value={originalAmount}
              currencyIso={originalCurrency}
              className={classes.textColorSecondary}
              variant="body2"
              valuePrefix="Original amount "
            />
          </Grid>
          <div className={classes.divider} />
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              FX rate {fxRate}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <AmountTableCell
        valuePrefix={amountValuePrefix}
        value={amount}
        currencyIso={currency}
        variant="h1"
        data-aio-id="detailsAmount"
      />
      <Grid container justifyContent="center">
        <Grid item>
          <Typography
            variant="body2"
            className={classes.createdAt}
            data-aio-id="detailsCreatedAt"
          >
            <FormattedDateTime
              utcIsoDate={createdAt}
              dateFormat={datetimeFormat}
            />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

PaymentTransactionDetailsModalHeader.propTypes = {
  amount: number.isRequired,
  amountValuePrefix: string,
  currency: string.isRequired,
  originalAmount: number,
  originalCurrency: string,
  createdAt: string.isRequired,
  fxRate: number,
  datetimeFormat: string,
};
PaymentTransactionDetailsModalHeader.defaultProps = {
  amountValuePrefix: null,
  originalAmount: null,
  originalCurrency: null,
  fxRate: null,
  datetimeFormat: 'dd MMM yyyy HH:mm',
};

export default PaymentTransactionDetailsModalHeader;
