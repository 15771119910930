import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { currencyIsoCodes, currency, orderTypes } from '@fondy/enums';
import { PageHeader } from '@fondy/layouts';
import { GridWidget } from '@fondy/data-display';
import { ConnectedFilteredTable } from '@fondy/tables';
import { TotalBalance } from '../../atoms';
import {
  userCustomerSelector,
  stateTimeZoneSelector,
  stateSelectSplitAccountsIds,
} from '../../../redux';
import { AppLayout } from '../../layouts';
import { MakePaymentButton, PageTabsNavigation } from '../../organisms';
import { splitAccountWalletsTableColumns } from '../../atoms/tableColumns';
import {
  splitAccountsIdsKeys,
  splitAccountPagesTabs,
  splitAccountWalletKeys,
  splitAccountPagesTabsLabelsMap,
  partyAssociationsKeys,
  splitAccountPagesTabsRoutingMap,
  splitAccountPagesTabsDisabledFieldsMap,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    '& > .MuiTypography-subtitle1': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  paper: {
    padding: theme.spacing(0, 2),
  },
  widgetContent: {
    paddingTop: theme.spacing(2),
  },
}));

const SplitAccountPayoutsListPage = () => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
  const { customer } = useSelector(userCustomerSelector);
  const { splitAccountsIds } = useSelector(stateSelectSplitAccountsIds);
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);

  const currencyId = currency.GBP;
  const currencyIso = currencyIsoCodes.get(currencyId);

  return (
    <AppLayout>
      <PageHeader
        preTitle={customer[partyAssociationsKeys.PARTY_NAME]}
        title={<TotalBalance currencyIso={currencyIso} totalBalance={0} />}
        actions={[<MakePaymentButton key="new-payout" label="Make a payout" />]}
        classes={{
          header: classes.pageHeader,
        }}
      />

      <PageTabsNavigation
        tabs={splitAccountPagesTabs}
        tabsLabelsMap={splitAccountPagesTabsLabelsMap}
        tabsRoutingMap={splitAccountPagesTabsRoutingMap}
        disabledTabsMap={splitAccountPagesTabsDisabledFieldsMap}
      />

      <GridWidget
        classes={{
          paper: classes.paper,
          content: classes.widgetContent,
        }}
      >
        <ConnectedFilteredTable
          defaultOrderBy={splitAccountWalletKeys.ID}
          defaultOrderDirection={orderTypes.DESCENDING}
          rowId={splitAccountWalletKeys.ID}
          url={`/api/core-banking/splitaccount/${
            splitAccountsIds[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID]
          }/wallet`}
          dependencies={[]}
          emptyComponent="You don't have any wallets yet"
          columns={splitAccountWalletsTableColumns}
          onCellClick={(args) => console.info('Clicked', args)}
          data-aio-id="walletsTable"
        />
      </GridWidget>
    </AppLayout>
  );
};

export default SplitAccountPayoutsListPage;
