import { userProfileActionTypes } from '../constants';

const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case userProfileActionTypes.UPDATE_USER_PROFILE_FETCHING:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case userProfileActionTypes.UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        lastUpdate: new Date(),
      };
    case userProfileActionTypes.UPDATE_USER_PROFILE:
      return {
        ...state,
        isFetching: false,
        error: null,
        data: action.userDetails,
        lastUpdate: new Date(),
      };
    default:
      return state;
  }
};

export default userProfileReducer;
