import { formatCurrencyValue } from '@fondy/utils';
import { transactionKeys } from '../../../../utils';

const createTransactionsPdfStatementTableFooter = async ({
  data,
  currencyIso,
} = {}) => {
  const totalAmount = data.reduce((sum, transaction) => {
    const amount = transaction[transactionKeys.TRANSACTION_AMOUNT];
    return transaction[transactionKeys.ACCOUNT_OPENING_BALANCE] >
      transaction[transactionKeys.ACCOUNT_CLOSING_BALANCE]
      ? sum - amount
      : sum + amount;
  }, 0);
  return [
    [
      {
        content: 'Total',
        colSpan: 2,
        styles: {
          halign: 'right',
        },
      },
      {
        content: `${formatCurrencyValue(totalAmount, {
          currencyIso,
          positiveValueSymbol: '+ ',
        })}`,
        colSpan: 2,
      },
    ],
  ];
};

export default createTransactionsPdfStatementTableFooter;
