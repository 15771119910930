const splitAccountsActionTypes = Object.freeze({
  ADD_SPLIT_ACCOUNT: 'ADD_SPLIT_ACCOUNT',
  ADD_SPLIT_ACCOUNT_ERROR: 'ADD_SPLIT_ACCOUNT_ERROR',
  ADD_SPLIT_ACCOUNT_IS_FETCHING: 'ADD_SPLIT_ACCOUNT_IS_FETCHING',
  GET_SPLIT_ACCOUNTS: 'GET_SPLIT_ACCOUNTS',
  GET_SPLIT_ACCOUNTS_IS_FETCHING: 'GET_SPLIT_ACCOUNTS_IS_FETCHING',
  GET_SPLIT_ACCOUNTS_ERROR: 'GET_SPLIT_ACCOUNTS_ERROR',
});

export default splitAccountsActionTypes;
