import React from 'react';
import clsx from 'clsx';
import { node, bool, shape, number, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { DefaultLayout } from '@fondy/layouts';
import { useAuth } from '@fondy/keycloak';
import { footerNavigation } from './appLoginNavigation';
import { userPartyStateValidationSelector } from '../../../redux';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    minHeight: 450,
    padding: theme.spacing(6),
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  centered: {
    justifyContent: 'center',
  },
  footer: {
    '& ul': {
      justifyContent: 'center',
    },
    '& > div > a': {
      justifyContent: 'center',
    },
  },
}));

function LoginLayout({ children, transparent, centered, grid, className }) {
  const classes = useStyles();
  const { hasActiveParty } = useSelector(userPartyStateValidationSelector);
  return (
    <DefaultLayout
      authHook={useAuth}
      sidebar={{
        disabled: true,
      }}
      toolbar={{
        disabled: transparent,
        fixed: true,
        transparent: false,
        customerLogo: 'flow',
        userToolbarProps: {
          authHook: useAuth,
          disableMobileNavigation: !hasActiveParty,
        },
      }}
      footer={{
        disabled: transparent,
        navigation: footerNavigation,
        className: classes.footer,
      }}
    >
      <Grid className={classes.contentContainer} item container>
        <Grid
          item
          {...grid}
          className={clsx(
            classes.content,
            {
              [classes.transparent]: transparent,
              [classes.centered]: centered,
            },
            className,
          )}
        >
          {children}
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}

LoginLayout.propTypes = {
  children: node.isRequired,
  transparent: bool,
  centered: bool,
  className: string,
  grid: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
  }),
};

LoginLayout.defaultProps = {
  transparent: false,
  centered: false,
  className: '',
  grid: {
    xs: 12,
    sm: 8,
    md: 5,
    lg: 4,
    xl: 4,
  },
};

export default LoginLayout;
