import { head } from 'rambda';

const stateSelectSplitAccountsIds = ({ parties }) => ({
  splitAccountsIds: parties.data.length
    ? head(head(parties.data).splitAccountsIds)
    : {},
  isLoading: parties.isFetching,
  isError: !!parties.error,
  apiError: parties.error,
});

export default stateSelectSplitAccountsIds;
