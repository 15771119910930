export const legalEntityKeys = Object.freeze({
  SYSTEM_ID: 'id',
  ENTITY_ID: 'legalEntityId',
  LEI: 'lei',
  NAME: 'legalEntityName',
  COUNTRY_OF_REGISTRATION: 'countryOfRegistration',
  REGISTRATION_NUMBER: 'registrationNumber',
  BIC_SWIFT: 'bic',
  CURRENCY: 'defaultCurrency',
  DATE_CREATED: 'dateCreated',
  LAST_UPDATED: 'lastUpdated',
  LAST_UPDATED_BY: 'lastUpdatedBy',
  CREATED_BY: 'createdBy',
  HEADQUARTERS: 'headquarters',
  DEFAULT_CURRENCY: 'defaultCurrency',
  DEFAULT_LANGUAGE: 'locale',
  TIMEZONE: 'timezone',
  ADRESS_LINE_1: 'addressLine1',
  ADRESS_LINE_2: 'addressLine2',
  ADRESS_LINE_3: 'addressLine3',
  POSTAL_CODE: 'postalCode',
  TOWN: 'town',
  COUNTY: 'county',
  COUNTRY: 'country',
  DIAL_IN_CODE: 'dialInCode',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
});

export const legalEntityKeysLabelsMap = new Map([
  [legalEntityKeys.SYSTEM_ID, 'Legal Entity System ID'],
  [legalEntityKeys.ENTITY_ID, 'Legal Entity ID'],
  [legalEntityKeys.LEI, 'Legal Entity Identifier (LEI)'],
  [legalEntityKeys.NAME, 'Legal Entity Name'],
  [legalEntityKeys.COUNTRY_OF_REGISTRATION, 'Country of Registration'],
  [legalEntityKeys.REGISTRATION_NUMBER, 'Registration Number'],
  [legalEntityKeys.BIC_SWIFT, 'BIC/SWIFT Code'],
  [legalEntityKeys.HEADQUARTERS, 'Headquarters office'],
  [legalEntityKeys.DEFAULT_CURRENCY, 'Default Currency'],
  [legalEntityKeys.DEFAULT_LANGUAGE, 'Default Language'],
  [legalEntityKeys.ADRESS_LINE_1, 'Adress Line 1'],
  [legalEntityKeys.ADRESS_LINE_2, 'Adress Line 2'],
  [legalEntityKeys.ADRESS_LINE_3, 'Adress Line 3'],
  [legalEntityKeys.POSTAL_CODE, 'Postal Code'],
  [legalEntityKeys.TOWN, 'Town'],
  [legalEntityKeys.COUNTY, 'County/Region'],
  [legalEntityKeys.COUNTRY, 'Country'],
  [legalEntityKeys.DIAL_IN_CODE, 'Dial-in Code'],
  [legalEntityKeys.PHONE_NUMBER, 'Phone Number'],
  [legalEntityKeys.EMAIL, 'Email'],
  [legalEntityKeys.TIMEZONE, 'Default Timezone'],
  [legalEntityKeys.DATE_CREATED, 'Date created'],
  [legalEntityKeys.LAST_UPDATED, 'Last update'],
  [legalEntityKeys.CREATED_BY, 'Created by'],
  [legalEntityKeys.LAST_UPDATED_BY, 'Last updated by'],
]);

export const legalEntityKeysDescriptionsMap = new Map([
  [legalEntityKeys.ENTITY_ID, 'Legal Entity ID'],
  [
    legalEntityKeys.LEI,
    'Code given to the Legal Entity (Legal Entity Identifier - LEI)',
  ],
  [legalEntityKeys.NAME, 'Name for which the Legal Entity is known'],
  [
    legalEntityKeys.COUNTRY_OF_REGISTRATION,
    'Country where this Legal Entity is registered',
  ],
  [legalEntityKeys.REGISTRATION_NUMBER, 'LE Registration Number'],
  [
    legalEntityKeys.BIC_SWIFT,
    'Main BIC/SWIFT Code assigned to this Legal Entity',
  ],
  [
    legalEntityKeys.HEADQUARTERS,
    'Indicates if this Legal Entity is the main entity of the group',
  ],
  [
    legalEntityKeys.DEFAULT_CURRENCY,
    'Default currency for accounting purposes',
  ],
  [legalEntityKeys.DEFAULT_LANGUAGE, 'Default language for the system'],
  [
    legalEntityKeys.ADRESS_LINE_1,
    'Address Line used for the Address details, such as Street Name, Door number, etc.',
  ],
  [legalEntityKeys.ADRESS_LINE_2, null],
  [legalEntityKeys.ADRESS_LINE_3, null],
  [
    legalEntityKeys.POSTAL_CODE,
    'Postal Code of the Legal Entity official address',
  ],
  [legalEntityKeys.TOWN, 'Town/City of the Legal Entity official address'],
  [
    legalEntityKeys.COUNTY,
    'County/Region of the Legal Entity official address',
  ],
  [legalEntityKeys.COUNTRY, 'Country of the Legal Entity official address'],
  [
    legalEntityKeys.DIAL_IN_CODE,
    'Country dial-in code of the Legal Entity phone number',
  ],
  [legalEntityKeys.PHONE_NUMBER, 'Legal Entity phone number'],
  [legalEntityKeys.EMAIL, 'Legal Entity email for future contacts'],
  [legalEntityKeys.TIMEZONE, 'Timezone to be considered for this Legal Entity'],
]);
