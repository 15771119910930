import { head } from 'rambda';
import { generateApiErrorFromResponse } from '@fondy/utils';
import { splitAccountsIdsKeys } from '../../utils';

const fetchSplitAccounts = async ({ fetch, splitAccountsIds } = {}) => {
  const firstSplitAccount = head(splitAccountsIds);
  const response = await fetch(
    `/api/core-banking/splitaccount/${
      firstSplitAccount[splitAccountsIdsKeys.ACCESSOR_CUSTOMER_ID]
    }/wallet/${firstSplitAccount[splitAccountsIdsKeys.DEFAULT_WALLET_REF]}`,
  );

  if (!response.ok) {
    throw await generateApiErrorFromResponse(response);
  }

  return response.json();
};

export default fetchSplitAccounts;
