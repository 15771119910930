/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@material-ui/core';
import {
  StatusTableCell,
  AmountTableCell,
  AvatarDataCell,
  SearchHighlighterTableCell,
} from '@fondy/tables';
import { paymentStatusesLabelsMap } from '@fondy/enums';
import { AccountByIdTableCell } from '../tableCells';
import FormattedDateTime from '../FormattedDateTime';
import {
  paymentsKeys,
  paymentsKeysLabelsMap,
  isPaymentBetweenOwnAccounts,
  paymentsKeysDetailsLabelsMap,
  PAYMENT_SUBTYPE_MCPO,
} from '../../../utils';

const renderCreditor = ({ row, searchQuery }) => {
  const renderAsAccount = isPaymentBetweenOwnAccounts(row);

  if (renderAsAccount) {
    return (
      <AccountByIdTableCell
        small
        id={row[paymentsKeys.COUNTERPARTY_ACCOUNT_ID]}
        searchQueryHighlight={searchQuery}
      />
    );
  }

  if (!row[paymentsKeys.COUNTERPARTY_NAME]) return null;

  return (
    <AvatarDataCell
      title={row[paymentsKeys.COUNTERPARTY_NAME]}
      searchQueryHighlight={searchQuery}
      avatarSize="small"
    />
  );
};

const paymentsTableColumns = [
  {
    id: paymentsKeys.CREATED_AT,
    numeric: false,
    disablePadding: false,
    label: paymentsKeysLabelsMap.get(paymentsKeys.CREATED_AT),
    style: { width: 91 },
    render: ({ value }) => (
      <>
        <Typography variant="body2">
          <FormattedDateTime utcIsoDate={value} />
        </Typography>
        <Typography variant="subtitle2">
          <FormattedDateTime utcIsoDate={value} dateFormat="HH:mm" />
        </Typography>
      </>
    ),
  },
  {
    id: paymentsKeys.ACCOUNT_ID,
    numeric: false,
    disablePadding: false,
    label: paymentsKeysDetailsLabelsMap.get(paymentsKeys.ACCOUNT_ID),
    style: { width: '20%' },
    render: ({ row, searchQuery }) => (
      <AccountByIdTableCell
        small
        id={row[paymentsKeys.ACCOUNT_ID]}
        searchQueryHighlight={searchQuery}
      />
    ),
  },
  {
    id: 'creditorName',
    numeric: false,
    disablePadding: false,
    style: { width: '20%' },
    label: paymentsKeysDetailsLabelsMap.get(paymentsKeys.COUNTERPARTY_NAME),
    render: ({ row, searchQuery }) => renderCreditor({ row, searchQuery }),
  },
  {
    id: 'paymentReference',
    numeric: false,
    disablePadding: false,
    style: { width: '20%' },
    label: paymentsKeysLabelsMap.get(paymentsKeys.DESCRIPTION),
    render: ({ row, searchQuery }) => (
      <SearchHighlighterTableCell
        value={row[paymentsKeys.DESCRIPTION]}
        search={searchQuery}
      />
    ),
  },
  {
    id: paymentsKeys.AMOUNT,
    numeric: true,
    disablePadding: false,
    label: paymentsKeysLabelsMap.get(paymentsKeys.AMOUNT),
    style: { width: 100 },
    render: ({ value, row }) => (
      <>
        {row[paymentsKeys.PAYMENT_SUBTYPE] === PAYMENT_SUBTYPE_MCPO ? (
          <AmountTableCell
            value={row[paymentsKeys.ORIGINAL_AMOUNT]}
            currencyIso={row[paymentsKeys.ORIGINAL_CURRENCY]}
            variant="subtitle2"
          />
        ) : null}
        <AmountTableCell
          value={value}
          currencyIso={row[paymentsKeys.CURRENCY]}
          direction={row[paymentsKeys.DIRECTION]}
          variant="body2"
        />
      </>
    ),
  },
  {
    id: paymentsKeys.STATUS,
    numeric: false,
    disablePadding: false,
    align: 'right',
    label: paymentsKeysLabelsMap.get(paymentsKeys.STATUS),
    render: ({ value }) => (
      <StatusTableCell status={value} labelsMap={paymentStatusesLabelsMap} />
    ),
    style: {
      width: 92,
      textOverflow: 'unset',
    },
  },
];

export const mobilePaymentsTableColumns = [
  {
    id: paymentsKeys.CREATED_AT,
    numeric: false,
    disablePadding: false,
    padding: 'none',
    label: 'Paid to',
    render: ({ row, searchQuery }) => (
      <>
        <div>{renderCreditor({ row, searchQuery })}</div>
        <Typography variant="body2">
          <SearchHighlighterTableCell
            value={row[paymentsKeys.DESCRIPTION]}
            search={searchQuery}
          />
        </Typography>
        <Typography variant="subtitle2">
          <FormattedDateTime
            utcIsoDate={row[paymentsKeys.CREATED_AT]}
            dateFormat="dd MMM yyyy HH:mm"
          />
        </Typography>
      </>
    ),
  },
  {
    id: paymentsKeys.AMOUNT,
    numeric: true,
    disablePadding: false,
    padding: 'none',
    align: 'right',
    label: paymentsKeysLabelsMap.get(paymentsKeys.AMOUNT),
    render: ({ value, row }) => (
      <>
        {row[paymentsKeys.PAYMENT_SUBTYPE] === PAYMENT_SUBTYPE_MCPO ? (
          <AmountTableCell
            value={row[paymentsKeys.ORIGINAL_AMOUNT]}
            currencyIso={row[paymentsKeys.ORIGINAL_CURRENCY]}
            variant="subtitle2"
          />
        ) : null}
        <AmountTableCell
          value={value}
          currencyIso={row[paymentsKeys.CURRENCY]}
          direction={row[paymentsKeys.DIRECTION]}
          variant="body2"
          valueWrapperFn={(formattedValue) => (
            <>
              <div>{formattedValue}</div>
              <StatusTableCell
                status={row[paymentsKeys.STATUS]}
                labelsMap={paymentStatusesLabelsMap}
                small
              />
            </>
          )}
        />
      </>
    ),
  },
];

export default paymentsTableColumns;
