import React, { useState, useCallback } from 'react';
import { sub } from 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { formatDate, formatCurrencyValue, zonedTimeToUtc } from '@fondy/utils';
import { PageHeader } from '@fondy/layouts';
import { GridWidget } from '@fondy/data-display';
import { useApplicationContext } from '@fondy/application-context';
import { ConnectedMultiAreaChart, ConnectedMultiBarChart } from '@fondy/charts';
import { orderTypes, currencySymbols, currency } from '@fondy/enums';
import { stateSelectUserProfile, getAccountsAction } from '../../../redux';
import { AppLayout } from '../../layouts';
import { MakePaymentButton, AccountsSummaryWidget } from '../../organisms';
import { transactionKeys } from '../../../utils';

const transactionInOutChartDataMapper = (data) => ({
  charts: data.labels,
  data: data.content,
});

const accountsBalancesChartDataMapper = (data) => ({
  charts: data.accounts,
  data: data.content,
});

function Dashboard() {
  const { breakpoints } = useTheme();
  const dispatch = useDispatch();
  const { fetch } = useApplicationContext();
  const { firstName } = useSelector(stateSelectUserProfile);
  const lastVisitedDate = new Date();
  const lastUpdate = formatDate(lastVisitedDate, { dateFormat: 'dd/MM/yy' });
  const [balancesChartDays, setBalancesChartDays] = useState(90);
  const [moneyInOutChartMonths, setMoneyInOutChartMonths] = useState(6);
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });

  const fetchAccounts = useCallback(
    (force = true) => dispatch(getAccountsAction(fetch, force)),
    [dispatch, fetch],
  );

  const chartValueFormatter = useCallback(
    (value, name) => [
      formatCurrencyValue(value, {
        currencyId: currency.GBP,
      }),
      name,
    ],
    [],
  );

  const chartValueTickFormatter = useCallback(
    (value) =>
      formatCurrencyValue(value, {
        currencyId: currency.GBP,
        abbreviate: true,
      }),
    [],
  );

  const spacing = isMobile ? 0 : undefined;

  return (
    <AppLayout>
      <PageHeader
        title={`Hello, ${firstName}`}
        subtitle={`Last visited ${lastUpdate} at ${formatDate(lastVisitedDate, {
          dateFormat: 'hh:mm',
        })}`}
        actions={[
          <MakePaymentButton key="new-payment" onSuccess={fetchAccounts} />,
        ]}
      />

      <AccountsSummaryWidget />

      <GridWidget transparent noPadding>
        <GridWidget
          title="Money in/out"
          subtitle={`Last updated ${lastUpdate}`}
          headerActions={`Last ${moneyInOutChartMonths} months`}
          grid={{
            sm: 12,
            md: 5,
          }}
          spacing={spacing}
        >
          <Grid item xs={12} data-aio-id="moneyInOutChart">
            <ConnectedMultiBarChart
              emptyMessage={false}
              emptyHeading="No transactions yet"
              url="/api/core-banking/charts/transactions/inout"
              unit={currencySymbols.get(currency.GBP)}
              urlParams={{
                sort: [transactionKeys.CREATED_AT, orderTypes.ASCENDING],
                dateFrom: zonedTimeToUtc(
                  sub(lastVisitedDate, { months: moneyInOutChartMonths }),
                ).toISOString(),
                dateTo: zonedTimeToUtc(lastVisitedDate).toISOString(),
              }}
              dataMapper={transactionInOutChartDataMapper}
              cartesianGridProps={{
                strokeDasharray: 1,
              }}
              xAxisProps={{
                dataKey: 'date',
              }}
              yAxisProps={{
                tickFormatter: chartValueTickFormatter,
              }}
              tooltipProps={{
                formatter: chartValueFormatter,
              }}
            />
          </Grid>
        </GridWidget>
        <GridWidget
          title="Accounts balances"
          subtitle={`Last updated ${lastUpdate}`}
          headerActions={`Last ${balancesChartDays} days`}
          grid={{
            sm: 12,
            md: 7,
          }}
          spacing={spacing}
        >
          <Grid item xs={12} data-aio-id="accountsBalanceChart">
            <ConnectedMultiAreaChart
              emptyMessage={false}
              emptyHeading="No transactions yet"
              url="/api/core-banking/charts/transactions/balance"
              unit={currencySymbols.get(currency.GBP)}
              urlParams={{
                sort: [transactionKeys.CREATED_AT, orderTypes.ASCENDING],
                dateFrom: sub(lastVisitedDate, {
                  balancesChartDays,
                }).toISOString(),
                dateTo: lastVisitedDate.toISOString(),
              }}
              dataMapper={accountsBalancesChartDataMapper}
              xAxisProps={{
                dataKey: 'date',
              }}
              yAxisProps={{
                tickFormatter: chartValueTickFormatter,
              }}
              tooltipProps={{
                formatter: chartValueFormatter,
              }}
            />
          </Grid>
        </GridWidget>
      </GridWidget>
    </AppLayout>
  );
}

export default Dashboard;
