export const partyRegistrationKeys = Object.freeze({
  TYPE: 'type',
  IDENTIFICATION_DOCUMENT_NUMBER: 'identificationNumber',
  IDENTIFICATION_DOCUMENT_TYPE: 'identificationDocumentType',
  IDENTIFICATION_DOCUMENT_EXPIRY_DATE: 'identificationDocExpiry',
  COUNTRY_OF_BIRTH: 'countryOfBirth',
  NATIONALITY: 'nationality',
  COUNTRY_OF_RESIDENCE: 'countryOfResidence',
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  POSTAL_CODE: 'postalCode',
  TOWN: 'town',
  REGION: 'region',
  COUNTRY: 'country',
  PEP: 'pep',
  PEP_RELATIVE: 'pepRelative',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  TAX_LIABILITY: 'taxLiability',
  TAX_NUMBER: 'taxNumber',
  HOME_ADDRESS: 'homeAddress',
});

export const partyRegistrationKeysLabelsMap = new Map([
  [partyRegistrationKeys.IDENTIFICATION_DOCUMENT_NUMBER, 'Document number'],
  [partyRegistrationKeys.IDENTIFICATION_DOCUMENT_TYPE, 'Document type'],
  [
    partyRegistrationKeys.IDENTIFICATION_DOCUMENT_EXPIRY_DATE,
    'Document expiry date',
  ],
  [partyRegistrationKeys.COUNTRY_OF_BIRTH, 'Country of Birth'],
  [partyRegistrationKeys.NATIONALITY, 'Nationality'],
  [partyRegistrationKeys.COUNTRY_OF_RESIDENCE, 'Country of Residence'],
  [partyRegistrationKeys.ADDRESS_LINE_1, 'Address Line 1'],
  [partyRegistrationKeys.ADDRESS_LINE_2, 'Address Line 2'],
  [partyRegistrationKeys.POSTAL_CODE, 'Postal Code'],
  [partyRegistrationKeys.TOWN, 'Town'],
  [partyRegistrationKeys.REGION, 'Region'],
  [partyRegistrationKeys.COUNTRY, 'Country'],
  [partyRegistrationKeys.TAX_LIABILITY, 'Tax liability'],
  [partyRegistrationKeys.TAX_NUMBER, 'Tax number'],
  [partyRegistrationKeys.HOME_ADDRESS, 'Home address'],
  [
    partyRegistrationKeys.TERMS_AND_CONDITIONS,
    'I agree to Fondy Terms & Conditions',
  ],
]);

export const partyRegistrationPhases = Object.freeze({
  BUSINESS_TYPE_CHOICE: 0,
  REGISTRATION_FORM: 1,
  TERMS_AND_CONDITIONS: 2,
});
