import makePaymentModalStates from './makePaymentModalStates';

const makePaymentModalTitlesMap = new Map([
  [makePaymentModalStates.FORM, 'Make a payment'],
  [makePaymentModalStates.REVIEW, 'Payment confirmation'],
  [makePaymentModalStates.AUTHENTICATOR, null],
  [makePaymentModalStates.AUTHENTICATOR_SETUP, 'Enable 2-Step Verification'],
  [makePaymentModalStates.CONFIRMATION, null],
  [makePaymentModalStates.ERROR, null],
  [makePaymentModalStates.COUNTERPARTY_DUPLICATE, null],
  [makePaymentModalStates.COUNTERPARTY_CONFIRMATION, null],
]);

export default makePaymentModalTitlesMap;
