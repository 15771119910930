import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, SvgIcon, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PageHeader } from '@fondy/layouts';
import { accountTypes, accountStatuses } from '@fondy/enums';
import { GridWidget } from '@fondy/data-display';
import { TabsHorizontalNavigation } from '@fondy/menu';
import { AccountAliasTableCell } from '@fondy/tables';
import { createAccountModalStages } from '../../organisms/CreateAccountModal';
import { AppLayout } from '../../layouts';
import { stateSelectAccountsData } from '../../../redux';
import {
  CreateAccountButton,
  AccountsTable,
  AccountActions,
} from '../../organisms';
import {
  mobileWalletsTableColumns,
  walletsTableColumns,
  TotalBalance,
} from '../../atoms';
import { accountsPageTabs } from '../AccountsPage/utils';
import { accountKeys, walletKeys } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(1),
  },
  pageHeader: {
    marginBottom: theme.spacing(1),
  },
  flagIcon: {
    width: 20,
  },
}));

function WalletsPage() {
  const classes = useStyles();

  const { breakpoints } = useTheme();
  const { accountId } = useParams();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });

  const { totalBalance, selectedAccount, isLoading } = useSelector(
    stateSelectAccountsData(accountId),
  );

  const isAccountSelected = !!selectedAccount;

  const accountsSelectorFn = useCallback(
    (state) => ({
      ...state.accounts,
      data: state.accounts.data.filter(
        (account) =>
          account[accountKeys.TYPE] === accountTypes.WALLET &&
          account[walletKeys.PAYOUT_ACCOUNT_ID] === accountId &&
          ![accountStatuses.SUSPENDED].includes(account[accountKeys.STATUS]),
      ),
    }),
    [accountId],
  );

  return (
    <AppLayout backButtonLabel="Back to accounts" backButtonUrl="/accounts">
      {isAccountSelected ? (
        <PageHeader
          classes={{ root: classes.pageHeader, title: classes.title }}
          preTitle={
            <AccountAliasTableCell
              fontSize={16}
              fontWeight={600}
              isLoading={isLoading}
              currencyIso={selectedAccount[accountKeys.CURRENCY]}
              accountAlias={selectedAccount[accountKeys.ALIAS]}
              accountType={selectedAccount[accountKeys.TYPE]}
              accountStatus={selectedAccount[accountKeys.STATUS]}
              classes={{ avatar: classes.flagIcon }}
            />
          }
          title={
            <TotalBalance
              currencyIso={selectedAccount[accountKeys.CURRENCY]}
              totalBalance={totalBalance}
            />
          }
          actions={[
            <AccountActions key="account-actions" account={selectedAccount} />,
            <CreateAccountButton
              key="create-account"
              buttonLabel="Create new wallet"
              disabled={
                selectedAccount[accountKeys.STATUS] !== accountStatuses.ACTIVE
              }
              modalProps={{
                initialStage: createAccountModalStages.CREATE_WALLET_ACCOUNT,
              }}
            />,
          ]}
        />
      ) : null}

      <TabsHorizontalNavigation
        activeTab={accountsPageTabs.WALLETS}
        tabs={[
          {
            label: 'Wallets',
            value: accountsPageTabs.WALLETS,
          },
          {
            label: 'Transactions',
            value: accountsPageTabs.TRANSACTIONS,
            to: `/accounts/transactions/${accountId}`,
          },
        ]}
      />

      <GridWidget container noBottomPadding spacing={0}>
        <AccountsTable
          columns={isMobile ? mobileWalletsTableColumns : walletsTableColumns}
          accountsSelectorFn={accountsSelectorFn}
          isMasterAccountWalletsMode
        />
      </GridWidget>
    </AppLayout>
  );
}

export default WalletsPage;
