import { string, object } from 'yup';
import { getRequiredErrorMessage, getLengthErrorMessage } from '@fondy/utils';
import { accountTypes, currencyFormValidationRule } from '@fondy/enums';
import { accountKeys, walletKeys } from '../../../../utils';

const errorMessageRequired = getRequiredErrorMessage();

export const standardAccountValidationSchema = object().shape({
  [accountKeys.ALIAS]: string()
    .required(errorMessageRequired)
    .max(...getLengthErrorMessage(35)),
  [accountKeys.CURRENCY]: string()
    .required(errorMessageRequired)
    .oneOf(currencyFormValidationRule),
  [accountKeys.PRODUCT_CODE]: string().required(errorMessageRequired),
  productId: string().required(errorMessageRequired).uuid(),
  [accountKeys.TYPE]: string()
    .required(errorMessageRequired)
    .oneOf(Object.values(accountTypes)),
});

export const masterAccountValidationSchema = standardAccountValidationSchema;

export const walletAccountValidationSchema = object().shape({
  [walletKeys.PAYOUT_ACCOUNT_ID]: string()
    .uuid()
    .nullable()
    .required(errorMessageRequired),
  [walletKeys.OWNER_ID]: string()
    .max(...getLengthErrorMessage(35))
    .required(errorMessageRequired),
  ownerName: string()
    .max(...getLengthErrorMessage(35))
    .required(errorMessageRequired),
  [accountKeys.TYPE]: string()
    .required(errorMessageRequired)
    .oneOf(Object.values(accountTypes)),
});

const createAccountModalValidationSchema = (
  newAccountType,
  { walletAliasKey = 'ownerName' } = {},
) => {
  switch (newAccountType) {
    case accountTypes.STANDARD:
      return standardAccountValidationSchema;
    case accountTypes.MASTER:
      return masterAccountValidationSchema;
    case accountTypes.WALLET:
      return walletAccountValidationSchema.shape({
        [walletAliasKey]: string()
          .max(...getLengthErrorMessage(35))
          .required(errorMessageRequired),
      });
    default:
      break;
  }

  return null;
};

export default createAccountModalValidationSchema;
