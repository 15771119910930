export const splitAccountComplianceProfileKeys = Object.freeze({
  ID: 'id',
  CUSTOMER_ID: 'clientRef',
  EMAIL: 'email',
  PHONE: 'telephone',
  FULL_NAME: 'fullName',
  EXTERNAL_ID: 'externalId',
  TYPE_OF_COMPLIANCE: 'typeOfCompliance',
  EXTERNAL_URL_VALIDATION: 'externalUrlValidation',
  PROFILE_TYPE: 'profileType',
  DATE_CREATED: 'createdDate',
  STATUS: 'status',
  OVERALL_RISK_SCORE: 'overallRiskScore',
  IDENTITY_VERSIONS: 'identityVersions',
  LATEST_IDENTITY: 'latestIdentity',
});

export const splitAccountComplianceProfileKeysLabelsMap = new Map([
  [splitAccountComplianceProfileKeys.ID, 'ID'],
  [splitAccountComplianceProfileKeys.CUSTOMER_ID, 'Customer ID'],
  [splitAccountComplianceProfileKeys.EMAIL, 'Email'],
  [splitAccountComplianceProfileKeys.PHONE, 'Phone'],
  [splitAccountComplianceProfileKeys.FULL_NAME, 'Name'],
  [splitAccountComplianceProfileKeys.EXTERNAL_ID, 'External ID'],
  [splitAccountComplianceProfileKeys.TYPE_OF_COMPLIANCE, 'Type of Compliance'],
  [
    splitAccountComplianceProfileKeys.EXTERNAL_URL_VALIDATION,
    'External URL Validation',
  ],
  [splitAccountComplianceProfileKeys.PROFILE_TYPE, 'Type'],
  [splitAccountComplianceProfileKeys.DATE_CREATED, 'Join Date'],
  [splitAccountComplianceProfileKeys.STATUS, 'Status'],
  [splitAccountComplianceProfileKeys.OVERALL_RISK_SCORE, 'Overall Risk Score'],
  [splitAccountComplianceProfileKeys.IDENTITY_VERSIONS, 'Identity Versions'],
  [splitAccountComplianceProfileKeys.LATEST_IDENTITY, 'Latest Identity'],
]);
