/* eslint-disable react/forbid-prop-types */
import React, {
  Children,
  useCallback,
  useState,
  useMemo,
  forwardRef,
  useRef,
  isValidElement,
  cloneElement,
} from 'react';
import clsx from 'clsx';
import {
  bool,
  func,
  string,
  shape,
  objectOf,
  any,
  number,
  arrayOf,
} from 'prop-types';
import { isEmpty, isNil } from 'rambda';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import {
  TextField,
  List,
  ListSubheader,
  useMediaQuery,
} from '@material-ui/core';
import {
  accountStatuses,
  accountTypes,
  accountTypesLabelsMap,
} from '@fondy/enums';
import {
  AccountSelectOption,
  conformToMask,
  ibanInputMaskPattern,
} from '@fondy/forms';
import { formatSortCodeValue } from '@fondy/utils';
import { stateSelectAccountsData } from '../../../redux';
import { walletKeys, accountKeys } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    lineHeight: 'inherit',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    background: theme.palette.background.white,
    cursor: 'not-allowed',
  },
  defaultLabelMargin: {
    transform: 'translate(14px, 20px) scale(1)',
    whiteSpace: 'nowrap',
  },
  inputRoot: {
    '& > div:not(:empty) + input:empty:not(:focus)': {
      height: 0,
      padding: 0,
      margin: 0,
    },
  },
  selectedTag: {
    display: 'block',
    width: 'inherit',
    padding: theme.spacing(1.5, 0, 1.5, 1),
  },
  accountListItem: {
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.lightest,
    },
  },
  standardAccounts: {
    paddingLeft: theme.spacing(2),
  },
  nestedAccounts: {
    paddingLeft: theme.spacing(6.5),
    '&:first-of-type': {
      marginTop: theme.spacing(0.5),
    },
  },
  paper: {
    boxShadow: theme.shadows[3],
  },
  listbox: {
    paddingTop: 0,
  },
  mastersHeader: {
    top: 40,
  },
}));

const searchAttributes = [
  accountKeys.ALIAS,
  accountKeys.NUMBER,
  accountKeys.SORT_CODE,
  accountKeys.TYPE,
  walletKeys.BUSINESS_REFERENCE,
  accountKeys.IBAN,
];

const getOptionDisabled = (option) =>
  option[accountKeys.TYPE] === accountTypes.MASTER ||
  option[accountKeys.STATUS] !== accountStatuses.ACTIVE;

const getOptionSelected = (option, value) =>
  !isNil(value) && value === option[accountKeys.ID];

const groupBy = (option) =>
  option[accountKeys.TYPE] === accountTypes.WALLET
    ? option[walletKeys.PAYOUT_ACCOUNT_ID]
    : accountTypesLabelsMap.get(option[accountKeys.TYPE]);

const renderElement = (option, { selected }) =>
  option?.[accountKeys.ID] ? (
    <AccountSelectOption
      account={option}
      accountKeys={accountKeys}
      walletKeys={walletKeys}
      selected={selected}
      data-account-id={option?.[accountKeys.ID]}
      data-account-type={option?.[accountKeys.TYPE]}
    />
  ) : null;

const getOptionLabel = () => '';
const filterMasterAccounts = (account) =>
  account[accountKeys.TYPE] !== accountTypes.MASTER;

const sortAccounts = (data) =>
  data.sort((a, b) => {
    const aSortKey =
      a[accountKeys.TYPE] === accountTypes.WALLET
        ? walletKeys.PAYOUT_ACCOUNT_ID
        : accountKeys.TYPE;
    const bSortKey =
      b[accountKeys.TYPE] === accountTypes.WALLET
        ? walletKeys.PAYOUT_ACCOUNT_ID
        : accountKeys.TYPE;
    return (
      b[bSortKey].localeCompare(a[aSortKey]) ||
      b[accountKeys.CURRENT_BALANCE] - a[accountKeys.CURRENT_BALANCE]
    );
  });

const AccountAutoCompleteDropdownInput = forwardRef(
  (
    {
      id,
      disabled,
      name,
      label,
      formState,
      required,
      className,
      noOptionsText,
      onChange,
      defaultValue,
      filterOptions,
      placeholder,
      filterMasterAccountsFn,
      getOptionDisabledFn,
      disableOptionsListSubheaders,
      autoComplete,
      shiftMastersHeaderBy,
      scrollToSelectedOption,
      allowedSearchAttributes,
      ...restProps
    },
    ref,
  ) => {
    const classes = useStyles();
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
    const inputFieldRef = useRef(null);
    const { allNotClosedAccounts, isLoading } = useSelector(
      stateSelectAccountsData(),
    );
    const masterAccounts = useMemo(
      () =>
        allNotClosedAccounts.filter(
          (i) => i[accountKeys.TYPE] === accountTypes.MASTER,
        ),
      [allNotClosedAccounts],
    );
    const standardAccountsCount = useMemo(
      () =>
        allNotClosedAccounts.filter(
          (i) => i[accountKeys.TYPE] === accountTypes.STANDARD,
        ).length,
      [allNotClosedAccounts],
    );
    const [allOptions, setAllOptions] = useState(() =>
      sortAccounts(allNotClosedAccounts),
    );
    const [isOpen, setIsOpen] = useState(false);

    const hasError = !!formState.errors[name];
    const error = hasError && formState.errors[name];

    const filterListOptions = useCallback(
      (options) => {
        const filteredOptions = options.filter(filterMasterAccountsFn);
        return filterOptions
          ? filteredOptions.filter(filterOptions)
          : filteredOptions;
      },
      [filterMasterAccountsFn, filterOptions],
    );

    const handleInputBlur = useCallback(
      (event) => {
        if (!isEmpty(event.target.value)) {
          setAllOptions(allNotClosedAccounts);
        }
        return formState.handleBlur(event);
      },
      [allNotClosedAccounts, formState],
    );

    const renderInput = useCallback(
      (params) => (
        <TextField
          {...params}
          ref={inputFieldRef}
          fullWidth
          data-test-id="AutocompleteTextField"
          placeholder={placeholder}
          label={label}
          name={name}
          id={id || name}
          variant="outlined"
          error={hasError}
          helperText={error}
          margin={isMobile ? 'dense' : 'normal'}
          required={required}
          color="primary"
          className={className}
          onBlur={handleInputBlur}
          InputLabelProps={{
            classes: {
              root: classes.defaultLabelMargin,
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: !isNil(formState.values[name]) ? (
              <div className={classes.selectedTag}>
                {renderElement(
                  allNotClosedAccounts.find(
                    (i) => i[accountKeys.ID] === formState.values[name],
                  ),
                  { selected: true },
                )}
              </div>
            ) : null,
          }}
        />
      ),
      [
        placeholder,
        label,
        name,
        id,
        hasError,
        error,
        isMobile,
        required,
        className,
        handleInputBlur,
        classes.defaultLabelMargin,
        classes.selectedTag,
        formState.values,
        allNotClosedAccounts,
      ],
    );

    const renderGroup = useCallback(
      ({ key, group, children }) => {
        const masterAccountData = masterAccounts.find(
          (i) => i[accountKeys.ID] === group,
        );
        return (
          <List
            key={key}
            component="nav"
            aria-labelledby="nested-accounts-list-subheader"
            subheader={
              !disableOptionsListSubheaders && (
                <>
                  {masterAccountData &&
                    key <= standardAccountsCount - shiftMastersHeaderBy && (
                      <ListSubheader
                        component="div"
                        id="nested-accounts-list-subheader"
                        className={classes.listSubheader}
                      >
                        {`${accountTypesLabelsMap.get(accountTypes.MASTER)}s`}
                      </ListSubheader>
                    )}
                  <ListSubheader
                    component="div"
                    id="nested-accounts-list-subheader"
                    className={clsx(classes.listSubheader, {
                      [classes.mastersHeader]:
                        !!masterAccountData &&
                        key <= standardAccountsCount - shiftMastersHeaderBy,
                    })}
                  >
                    {masterAccountData ? (
                      <AccountSelectOption
                        account={masterAccountData}
                        accountKeys={accountKeys}
                        walletKeys={walletKeys}
                        title="You cannot select the master account."
                      />
                    ) : (
                      `${accountTypesLabelsMap.get(accountTypes.STANDARD)}s`
                    )}
                  </ListSubheader>
                </>
              )
            }
          >
            {Children.map(children, (child) =>
              isValidElement(child)
                ? cloneElement(child, {
                    className: clsx(
                      child.props.className,
                      classes.accountListItem,
                      {
                        [classes.nestedAccounts]:
                          !!masterAccountData && !disableOptionsListSubheaders,
                        [classes.standardAccounts]:
                          !masterAccountData || disableOptionsListSubheaders,
                      },
                    ),
                  })
                : child,
            )}
          </List>
        );
      },
      [
        classes.accountListItem,
        classes.listSubheader,
        classes.mastersHeader,
        classes.nestedAccounts,
        classes.standardAccounts,
        disableOptionsListSubheaders,
        masterAccounts,
        shiftMastersHeaderBy,
        standardAccountsCount,
      ],
    );

    const toggleOpen = useCallback(
      (event) => {
        if (event.type === 'focus') return;
        setIsOpen((prevValue) => !prevValue);
        const selectedOption = formState.values[name];
        if (
          !isOpen &&
          scrollToSelectedOption &&
          !isNil(selectedOption) &&
          !isEmpty(selectedOption)
        ) {
          const observer = new MutationObserver(() => {
            const listContainer = document.querySelector(
              `ul#${name}Autocomplete-popup`,
            );
            if (document.contains(listContainer)) {
              observer.disconnect();
              const selectedOptionElement = listContainer.querySelector(
                `[data-account-id="${selectedOption}"]`,
              );
              if (!selectedOptionElement) return;
              const {
                top,
              } = selectedOptionElement.parentNode.getBoundingClientRect();
              const targetX = top - 75;
              listContainer.scrollTo({
                top: targetX < 0 ? 0 : targetX,
              });
            }
          });

          observer.observe(document, {
            attributes: false,
            childList: true,
            characterData: false,
            subtree: true,
          });
        }
      },
      [formState.values, isOpen, name, scrollToSelectedOption],
    );

    const onChangeHandler = useCallback(
      async (event, value, reason) => {
        event.persist();
        switch (reason) {
          case 'select-option':
            await formState.handleChange({
              ...event,
              target: {
                ...event.target,
                id: name,
                name,
                value: value[accountKeys.ID],
              },
            });
            if (onChange) {
              await onChange({
                event,
                reason,
                options: allOptions,
                ref,
                option: value,
              });
            }
            if (inputFieldRef?.current) {
              setTimeout(() => {
                const input = inputFieldRef?.current.querySelector('input');
                if (input) {
                  input.value = '';
                  input.blur();
                }
              }, 0);
            }
            setAllOptions(allNotClosedAccounts);
            break;
          case 'remove-option':
          default:
            break;
        }
      },
      [allNotClosedAccounts, allOptions, formState, name, onChange, ref],
    );

    const onInputChange = useCallback(
      (_, value, reason) => {
        switch (reason) {
          case 'clear':
            setAllOptions(allNotClosedAccounts);
            formState.setFieldValue(name, null);
            setIsOpen(true);
            break;
          case 'input':
            if (isNil(value) || isEmpty(value)) {
              setAllOptions(allNotClosedAccounts);
            } else {
              const searchResults = allNotClosedAccounts.filter((account) =>
                Object.keys(account).some((key) => {
                  if (!allowedSearchAttributes.includes(key)) return false;
                  let accountValue = account[key] || '';
                  if (key === accountKeys.SORT_CODE && value.includes('-')) {
                    accountValue = formatSortCodeValue(accountValue);
                  } else if (key === accountKeys.IBAN && value.includes(' ')) {
                    accountValue = conformToMask(value, ibanInputMaskPattern, {
                      guide: false,
                    }).conformedValue;
                  }
                  return accountValue
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
                }),
              );

              const walletsByMasterAlias = masterAccounts.reduce(
                (all, current) => {
                  const masterAlias = current[accountKeys.ALIAS] || '';
                  if (
                    masterAlias
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  ) {
                    return [
                      ...all,
                      ...allNotClosedAccounts.filter(
                        (account) =>
                          account[walletKeys.TYPE] === accountTypes.WALLET &&
                          account[walletKeys.PAYOUT_ACCOUNT_ID] ===
                            current[accountKeys.ID],
                      ),
                    ];
                  }

                  return all;
                },
                [],
              );

              const searchOptions = [
                ...new Set([...searchResults, ...walletsByMasterAlias]),
              ];

              setAllOptions(sortAccounts(searchOptions));
            }
            break;
          default:
            break;
        }
      },
      [
        allNotClosedAccounts,
        allowedSearchAttributes,
        formState,
        masterAccounts,
        name,
      ],
    );

    return (
      <Autocomplete
        {...restProps}
        ref={ref}
        defaultValue={defaultValue}
        value={formState.values[name] || null}
        id={`${name}Autocomplete`}
        name={`${name}Autocomplete`}
        options={allOptions}
        loading={isLoading}
        filterOptions={filterListOptions}
        groupBy={groupBy}
        renderOption={renderElement}
        renderTags={renderElement}
        renderGroup={renderGroup}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabledFn}
        getOptionSelected={getOptionSelected}
        renderInput={renderInput}
        onChange={onChangeHandler}
        onInputChange={onInputChange}
        noOptionsText={noOptionsText}
        onOpen={toggleOpen}
        onClose={toggleOpen}
        popupIcon={<KeyboardArrowDownRounded />}
        classes={{
          inputRoot: classes.inputRoot,
          paper: classes.paper,
          listbox: classes.listbox,
        }}
        open={isOpen}
        // open
        // debug
        disabled={disabled}
        autoHighlight
        disableListWrap
        fullWidth
        openOnFocus
        clearOnEscape
        clearOnBlur
        autoComplete={autoComplete}
      />
    );
  },
);

AccountAutoCompleteDropdownInput.propTypes = {
  formState: shape({
    values: objectOf(any).isRequired,
    touched: objectOf(any).isRequired,
    errors: objectOf(any),
    dirty: bool,
    isSubmitting: bool,
    isValidating: bool,
    isValid: bool,
    handleChange: func,
    handleBlur: func,
    handleSubmit: func,
    handleReset: func,
  }).isRequired,
  required: bool,
  className: string,
  name: string.isRequired,
  label: string.isRequired,
  disabled: bool,
  noOptionsText: string,
  placeholder: string,
  id: string,
  onChange: func,
  defaultValue: any,
  filterOptions: func,
  filterMasterAccountsFn: func,
  getOptionDisabledFn: func,
  disableOptionsListSubheaders: bool,
  description: string,
  autoComplete: bool,
  shiftMastersHeaderBy: number,
  scrollToSelectedOption: bool,
  allowedSearchAttributes: arrayOf(string),
};

AccountAutoCompleteDropdownInput.defaultProps = {
  required: false,
  disabled: false,
  className: '',
  noOptionsText: 'No accounts found',
  id: null,
  onChange: null,
  defaultValue: null,
  filterOptions: null,
  placeholder: 'Search for an account...',
  filterMasterAccountsFn: filterMasterAccounts,
  getOptionDisabledFn: getOptionDisabled,
  disableOptionsListSubheaders: false,
  description: null,
  autoComplete: true,
  shiftMastersHeaderBy: 0,
  scrollToSelectedOption: true,
  allowedSearchAttributes: searchAttributes,
};

export default AccountAutoCompleteDropdownInput;
