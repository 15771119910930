import { string, object } from 'yup';
import { isValidIBAN } from 'ibantools';
import {
  getRequiredErrorMessage,
  getLengthErrorMessage,
  getDigitsLengthError,
} from '@fondy/utils';
import {
  chapsStringValidationRegex,
  onlyDigitsRegex,
} from '../MakePaymentForm/utils';
import {
  counterpartyTypes,
  paymentCounterpartyKeys,
  paymentCounterpartyKeysLabelsMap,
  paymentCounterpartyKeysLabelsMapClean,
} from '../../../utils';

const errorMessageRequired = getRequiredErrorMessage();

const commonRecipientValidationSchema = object().shape({
  [paymentCounterpartyKeys.NAME]: string()
    .required(errorMessageRequired)
    .max(...getLengthErrorMessage(35))
    .matches(chapsStringValidationRegex, {
      message: 'You have entered an invalid character',
      excludeEmptyString: true,
    }),
  [paymentCounterpartyKeys.FIRST_NAME]: string()
    .nullable()
    .max(...getLengthErrorMessage(100))
    .matches(chapsStringValidationRegex, {
      message: 'You have entered an invalid character',
      excludeEmptyString: true,
    })
    .when(paymentCounterpartyKeys.TYPE, {
      is: counterpartyTypes.PERSONAL,
      then: (schema) => schema.required(errorMessageRequired),
    }),
  [paymentCounterpartyKeys.LAST_NAME]: string()
    .nullable()
    .max(...getLengthErrorMessage(100))
    .matches(chapsStringValidationRegex, {
      message: 'You have entered an invalid character',
      excludeEmptyString: true,
    })
    .when(paymentCounterpartyKeys.TYPE, {
      is: counterpartyTypes.PERSONAL,
      then: (schema) => schema.required(errorMessageRequired),
    }),
  [paymentCounterpartyKeys.COMPANY_NAME]: string()
    .nullable()
    .max(...getLengthErrorMessage(100))
    .matches(chapsStringValidationRegex, {
      message: 'You have entered an invalid character',
      excludeEmptyString: true,
    })
    .when(paymentCounterpartyKeys.TYPE, {
      is: counterpartyTypes.BUSINESS,
      then: (schema) => schema.required(errorMessageRequired),
    }),
});

export const accountNumberRecipientValidationSchema = commonRecipientValidationSchema.shape(
  {
    [paymentCounterpartyKeys.ACCOUNT_NUMBER]: string()
      .ensure()
      .required(errorMessageRequired)
      .length(
        ...getDigitsLengthError(
          8,
          paymentCounterpartyKeysLabelsMap.get(
            paymentCounterpartyKeys.ACCOUNT_NUMBER,
          ),
        ),
      )
      .matches(
        onlyDigitsRegex,
        getDigitsLengthError(
          8,
          paymentCounterpartyKeysLabelsMap.get(
            paymentCounterpartyKeys.ACCOUNT_NUMBER,
          ),
        )[1],
      ),
    [paymentCounterpartyKeys.SORT_CODE]: string()
      .ensure()
      .trim()
      .required(errorMessageRequired)
      .length(
        ...getDigitsLengthError(
          6,
          paymentCounterpartyKeysLabelsMap.get(
            paymentCounterpartyKeys.SORT_CODE,
          ),
        ),
      )
      .matches(
        onlyDigitsRegex,
        getDigitsLengthError(
          6,
          paymentCounterpartyKeysLabelsMap.get(
            paymentCounterpartyKeys.SORT_CODE,
          ),
        )[1],
      ),
  },
);

export const ibanRecipientValidationSchema = commonRecipientValidationSchema
  .shape({
    [paymentCounterpartyKeys.IBAN]: string()
      .ensure()
      .required(errorMessageRequired)
      .test({
        name: 'iban',
        exclusive: true,
        message: 'Please provide valid IBAN',
        test: (value) => isValidIBAN(value.replaceAll(' ', '')),
      }),
    [paymentCounterpartyKeys.COUNTRY]: string()
      .ensure()
      .required(errorMessageRequired),
    [paymentCounterpartyKeys.CITY]: string()
      .ensure()
      .trim()
      .required(errorMessageRequired)
      .max(...getLengthErrorMessage(22))
      .matches(
        chapsStringValidationRegex,
        `You have entered an invalid character for ${paymentCounterpartyKeysLabelsMapClean.get(
          paymentCounterpartyKeys.CITY,
        )}`,
      ),
    [paymentCounterpartyKeys.POSTAL_CODE]: string()
      .ensure()
      .trim()
      .required(errorMessageRequired)
      .min(...getLengthErrorMessage(3, false))
      .max(...getLengthErrorMessage(10))
      .matches(
        chapsStringValidationRegex,
        `You have entered an invalid character for ${paymentCounterpartyKeysLabelsMapClean.get(
          paymentCounterpartyKeys.POSTAL_CODE,
        )}`,
      ),
  })
  .shape(
    {
      [paymentCounterpartyKeys.STREET]: string()
        .ensure()
        .trim()
        .max(...getLengthErrorMessage(35))
        .matches(chapsStringValidationRegex, {
          message: `You have entered an invalid character for ${paymentCounterpartyKeysLabelsMapClean.get(
            paymentCounterpartyKeys.STREET,
          )}`,
          excludeEmptyString: true,
        })
        .when(paymentCounterpartyKeys.BUILDING_NAME, {
          is: '',
          then: (schema) => schema.required(errorMessageRequired),
        }),
      [paymentCounterpartyKeys.BUILDING_NAME]: string()
        .ensure()
        .trim()
        .max(...getLengthErrorMessage(27))
        .matches(chapsStringValidationRegex, {
          message: `You have entered an invalid character for ${paymentCounterpartyKeysLabelsMapClean.get(
            paymentCounterpartyKeys.BUILDING_NAME,
          )}`,
          excludeEmptyString: true,
        })
        .when(paymentCounterpartyKeys.STREET, {
          is: '',
          then: (schema) => schema.required(errorMessageRequired),
        }),
      [paymentCounterpartyKeys.BUILDING_NUMBER]: string()
        .ensure()
        .trim()
        .max(...getLengthErrorMessage(8))
        .matches(chapsStringValidationRegex, {
          message: `You have entered an invalid character for ${paymentCounterpartyKeysLabelsMapClean.get(
            paymentCounterpartyKeys.BUILDING_NUMBER,
          )}`,
          excludeEmptyString: true,
        })
        .when(paymentCounterpartyKeys.BUILDING_NAME, {
          is: '',
          then: (schema) => schema.required(errorMessageRequired),
        }),
    },
    [paymentCounterpartyKeys.STREET, paymentCounterpartyKeys.BUILDING_NAME],
  );
