export const productKeys = Object.freeze({
  AVAILABLE_TO_CUSTOMERS: 'availableToCustomers',
  CREATED_BY: 'createdBy',
  DATE_CREATED: 'dateCreated',
  DATE_UPDATED: 'dateUpdated',
  ID: 'id',
  LAST_REVIEW_DATE: 'lastReviewDate',
  LEGAL_ENTITY: 'legalEntity',
  BANK_IDENTIFIER: 'productBankIdentifier',
  CODE: 'productCode',
  CURRENCY: 'productCurrency',
  DESCRIPTION: 'productDescription',
  END_DATE: 'productEndDate',
  FAMILY: 'productFamily',
  LAUNCH_DATE: 'productLaunchDate',
  NAME: 'productName',
  TYPE: 'productType',
  STATUS: 'status',
  UPDATED_BY: 'updatedBy',
  GL_ACCOUNT_FOR_DEBIT: 'glDebitAccount',
  GL_ACCOUNT_FOR_CREDIT: 'glCreditAccount',
});

export const productKeysLabelsMap = new Map([
  [productKeys.AVAILABLE_TO_CUSTOMERS, 'Available to Customers'],
  [productKeys.CREATED_BY, 'Creation by'],
  [productKeys.DATE_CREATED, 'Date Created'],
  [productKeys.DATE_UPDATED, 'Last modified'],
  [productKeys.ID, 'id'],
  [productKeys.LAST_REVIEW_DATE, 'Product Last Review Date'],
  [productKeys.LEGAL_ENTITY, 'Legal Entity Name'],
  [productKeys.BANK_IDENTIFIER, 'Product Bank Identifier'],
  [productKeys.CODE, 'Product Code'],
  [productKeys.CURRENCY, 'Currency'],
  [productKeys.DESCRIPTION, 'Product Description'],
  [productKeys.END_DATE, 'Product End Date'],
  [productKeys.FAMILY, 'Product Family'],
  [productKeys.LAUNCH_DATE, 'Product Launch Date'],
  [productKeys.NAME, 'Product Name'],
  [productKeys.TYPE, 'Product Type'],
  [productKeys.STATUS, 'Status'],
  [productKeys.UPDATED_BY, 'Modified by'],
  [productKeys.GL_ACCOUNT_FOR_DEBIT, 'GL Account for Debit'],
  [productKeys.GL_ACCOUNT_FOR_CREDIT, 'GL Account for Credit'],
]);

export const productKeysDescriptionsMap = new Map([
  [
    productKeys.AVAILABLE_TO_CUSTOMERS,
    'Flag that identifies if the Product is to be visible to customer, i.e. in customers platform. In this case, as soon as the product becomes Active, the customer will be able to subscribe them in the platform.',
  ],
  [productKeys.CREATED_BY, 'User responsible for the record creation'],
  [productKeys.DATE_CREATED, 'Date when the record was created'],
  [productKeys.DATE_UPDATED, 'Date of the last update made on the record'],
  [productKeys.ID, null],
  [productKeys.LAST_REVIEW_DATE, 'Product Last Review Date'],
  [productKeys.LEGAL_ENTITY, 'Legal Entity of this product'],
  [
    productKeys.BANK_IDENTIFIER,
    'Bank Identifier that should be considered in this Product to create the account number',
  ],
  [productKeys.CODE, 'Code given to the Account Product'],
  [productKeys.CURRENCY, 'Product Currency'],
  [
    productKeys.DESCRIPTION,
    'Field that can be used by the user to provide more information about this Product Family',
  ],
  [productKeys.END_DATE, 'Product End Date'],
  [
    productKeys.FAMILY,
    'Product Family, only informative and automatically filled after Product Type selection.',
  ],
  [productKeys.LAUNCH_DATE, 'Product Launch Date'],
  [productKeys.NAME, 'Name for which the Account Product is known'],
  [
    productKeys.TYPE,
    'Product Type for which the user is creating the Account Product.',
  ],
  [productKeys.STATUS, 'Status of the Product'],
  [productKeys.UPDATED_BY, 'User responsible for the record last update'],
  [
    productKeys.GL_ACCOUNT_FOR_DEBIT,
    'Account from the General Ledger to post any debit transaction from all the accounts under this product',
  ],
  [
    productKeys.GL_ACCOUNT_FOR_CREDIT,
    'Account from the General Ledger to post any credit transaction from all the accounts under this product',
  ],
]);
