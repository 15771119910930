import React from 'react';
import { number, string } from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrencyValue } from '@fondy/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    borderLeftWidth: '2px',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.colors.PRIMARY,
    padding: '2px 16px',
    margin: '4px 0 18px',
  },
  fxRate: {
    color: theme.colors.GREY,
    lineHeight: '16px',
  },
}));

const PaymentAmountFxRate = ({
  amount,
  feeValue,
  fxRate,
  sourceCurrencyIso,
  currencyIso,
  ...restProps
}) => {
  const classes = useStyles();
  const debitAmount = amount * fxRate + feeValue;

  return (
    <Box {...restProps} display="flex" className={classes.container}>
      <span className={classes.fxRate}>
        You will be debited{' '}
        {formatCurrencyValue(debitAmount, {
          currencyIso: sourceCurrencyIso,
          showSymbol: false,
        })}{' '}
        {sourceCurrencyIso}, current FX rate 1 {currencyIso} ={' '}
        {formatCurrencyValue(fxRate, {
          currencyIso: sourceCurrencyIso,
          showSymbol: false,
          decimalsDigits: 5,
        })}{' '}
        {sourceCurrencyIso}
      </span>
    </Box>
  );
};

PaymentAmountFxRate.propTypes = {
  amount: number.isRequired,
  feeValue: number.isRequired,
  fxRate: number.isRequired,
  sourceCurrencyIso: string.isRequired,
  currencyIso: string.isRequired,
};
PaymentAmountFxRate.defaultProps = {};

export default PaymentAmountFxRate;
