import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AddRounded } from '@material-ui/icons';
import { any, objectOf, string, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonSize,
  buttonVariant,
} from '@fondy/buttons';
import { useApplicationContext } from '@fondy/application-context';
import { getAccountsAction } from '../../../redux';
import CreateAccountModal from '../CreateAccountModal';

const useStyles = makeStyles(() => ({
  createAccountButton: {
    whiteSpace: 'nowrap',
  },
}));

export default function CreateAccountButton({
  buttonLabel,
  modalProps,
  disabled,
  ...restProps
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { fetch } = useApplicationContext();

  const { isPosting: isAccountPosting } = useSelector(
    (state) => state.accounts,
  );

  const [isOpen, setIsOpen] = useState(false);

  const fetchAccounts = useCallback(
    (force = true) => dispatch(getAccountsAction(fetch, force)),
    [dispatch, fetch],
  );

  const toggleModalOpenState = useCallback(async () => {
    await setIsOpen((prevState) => !prevState);
    return fetchAccounts();
  }, [fetchAccounts]);

  return (
    <>
      <Button
        {...restProps}
        onClick={toggleModalOpenState}
        color={buttonColor.PRIMARY}
        variant={buttonVariant.CONTAINED}
        shape={buttonShape.ROUNDED}
        size={buttonSize.LARGE}
        startIcon={<AddRounded style={{ fontSize: 16 }} />}
        data-aio-id="createAccountButton"
        disabled={disabled || isAccountPosting}
        className={classes.createAccountButton}
      >
        {buttonLabel}
      </Button>

      <CreateAccountModal
        {...modalProps}
        open={isOpen}
        onClose={toggleModalOpenState}
      />
    </>
  );
}

CreateAccountButton.propTypes = {
  buttonLabel: string,
  disabled: bool,
  // eslint-disable-next-line react/forbid-prop-types
  modalProps: objectOf(any),
};

CreateAccountButton.defaultProps = {
  buttonLabel: 'Create new account',
  modalProps: {},
  disabled: false,
};
