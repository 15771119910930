import { omit } from 'rambda';
import { newPaymentKeys } from '../../../../utils';

const omitKnownPayeeDetails = omit([
  newPaymentKeys.PAYEE_NAME,
  newPaymentKeys.PAYEE_SORT_CODE,
  newPaymentKeys.PAYEE_ACCOUNT_NUMBER,
  newPaymentKeys.PAYEE_COUNTRY,
  newPaymentKeys.PAYEE_CURRENCY,
  newPaymentKeys.PAYEE_IBAN,
  newPaymentKeys.PAYEE_CITY,
  newPaymentKeys.PAYEE_STREET,
  newPaymentKeys.PAYEE_BUILDING_NAME,
  newPaymentKeys.PAYEE_BUILDING_NUMBER,
  newPaymentKeys.PAYEE_POSTAL_CODE,
  newPaymentKeys.PAYEE_ADDRESS,
  newPaymentKeys.PAYEE_FIRST_NAME,
  newPaymentKeys.PAYEE_LAST_NAME,
  newPaymentKeys.PAYEE_COMPANY_NAME,
  newPaymentKeys.PAYEE_TYPE,
  newPaymentKeys.PAYEE_BANK_DETAILS_TYPE,
]);

export default omitKnownPayeeDetails;
