import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bool, func, string, shape } from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useApplicationContext } from '@fondy/application-context';
import { Drawer } from '@fondy/data-display';
import { accountTypes, httpMethods } from '@fondy/enums';
import {
  ControlledTextField,
  FormFieldsPreview,
  getTextFieldPropsFromKey,
  transformInputValuesBeforeValidate,
} from '@fondy/forms';
import { fetchErrorHandler } from '@fondy/utils';
import { accountKeys, walletKeys } from '../../../utils';
import { createAccountModalValidationSchema } from '../CreateAccountModal/utils';
import { TextFieldPreview } from '../../atoms';
import { getAccountsAction } from '../../../redux';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    padding: theme.spacing(0, 0, 1),
  },
  label: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
}));

const rewrittenAccountKeysLabelsMap = new Map([
  [accountKeys.ALIAS, 'Account name'],
  [accountKeys.HOLDER_NAME, 'Holder name'],
]);

const getFieldProps = getTextFieldPropsFromKey({
  labelsMap: rewrittenAccountKeysLabelsMap,
});

const AccountSettingsModal = ({ open, account, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fetch, keycloak } = useApplicationContext();

  const formState = useFormik({
    initialValues: {
      [accountKeys.ALIAS]: account[accountKeys.ALIAS],
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: transformInputValuesBeforeValidate({
      validationSchema: createAccountModalValidationSchema(
        account[accountKeys.TYPE],
        {
          walletAliasKey:
            account[accountKeys.TYPE] === accountTypes.WALLET
              ? 'alias'
              : undefined,
        },
      ),
      valuesSanitiser: (values) => ({ ...values }),
    }),
  });

  const { setSubmitting, values } = formState;

  const isAliasEditable = useMemo(
    () =>
      account?.[accountKeys.ID] &&
      (account[accountKeys.TYPE] !== accountTypes.WALLET ||
        (account[accountKeys.TYPE] === accountTypes.WALLET &&
          !account[walletKeys.IS_DEFAULT])),
    [account],
  );

  const onSave = useCallback(
    async ({ hasErrors }) => {
      if (hasErrors) return;

      setSubmitting(true);

      try {
        const response = await fetch(
          `/api/core-banking/accounts/${account[accountKeys.ID]}`,
          {
            method: httpMethods.PUT,
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              Accept: 'application/json',
              'Content-type': 'application/json',
            },
            body: JSON.stringify(values),
          },
        );

        if (!response.ok) {
          await fetchErrorHandler({
            response,
            keycloak,
            throwError: true,
          });
        }

        dispatch(getAccountsAction(fetch, true));

        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
      }
    },
    [account, dispatch, fetch, keycloak, setSubmitting, values],
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      contentProps={{
        dividers: true,
      }}
      header="Settings"
    >
      <form className={classes.wrapper} id="accountSettings" noValidate>
        <Grid container alignItems="flex-start">
          <Grid item xs={12}>
            <FormFieldsPreview
              dense
              preview
              fieldsNames={[accountKeys.ALIAS]}
              formState={formState}
              label={rewrittenAccountKeysLabelsMap.get(accountKeys.ALIAS)}
              labelClassName={classes.label}
              onSave={onSave}
              previewComponent={
                <TextFieldPreview
                  isEditable={isAliasEditable}
                  data-aio-id={`${accountKeys.ALIAS}Value`}
                >
                  {formState.values[accountKeys.ALIAS]}
                </TextFieldPreview>
              }
            >
              <ControlledTextField
                {...getFieldProps(accountKeys.ALIAS)}
                margin="normal"
                label={null}
                formState={formState}
                required
              />
            </FormFieldsPreview>
          </Grid>

          <Grid item xs={12}>
            <FormFieldsPreview
              dense
              preview
              fieldsNames={[accountKeys.HOLDER_NAME]}
              formState={formState}
              label={rewrittenAccountKeysLabelsMap.get(accountKeys.HOLDER_NAME)}
              labelClassName={classes.label}
              previewComponent={
                <TextFieldPreview isEditable={false}>
                  {account[accountKeys.HOLDER_NAME]}
                </TextFieldPreview>
              }
            >
              {account[accountKeys.HOLDER_NAME]}
            </FormFieldsPreview>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
};

AccountSettingsModal.propTypes = {
  open: bool,
  account: shape({
    [accountKeys.ALIAS]: string,
    [accountKeys.HOLDER_NAME]: string,
  }).isRequired,
  onClose: func,
};

AccountSettingsModal.defaultProps = {
  open: false,
  onClose: undefined,
};

export default AccountSettingsModal;
