import React, { useCallback, useMemo } from 'react';
import { instanceOf, any, objectOf } from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { TabsHorizontalNavigation } from '@fondy/menu';

const PageTabsNavigation = ({
  tabs,
  tabsLabelsMap,
  tabsRoutingMap,
  disabledTabsMap,
  tabsVisibility,
  ...restProps
}) => {
  const { path: currentPath } = useRouteMatch();

  const activeTab = useMemo(
    () =>
      Array.from(tabsRoutingMap.entries()).reduce(
        (activeRoute, [routeId, routeUrl]) =>
          routeUrl === currentPath ? routeId : activeRoute,
        null,
      ),
    [currentPath, tabsRoutingMap],
  );

  const tabsRenderer = useCallback(
    (tabId) => ({
      key: tabId,
      value: tabId,
      label: tabsLabelsMap?.get(tabId),
      to: tabsRoutingMap?.get(tabId),
      disabled: activeTab === tabId || disabledTabsMap?.get(tabId),
    }),
    [activeTab, disabledTabsMap, tabsLabelsMap, tabsRoutingMap],
  );

  const tabsFilter = useCallback((tabId) => tabsVisibility[tabId] !== false, [
    tabsVisibility,
  ]);

  return (
    <TabsHorizontalNavigation
      {...restProps}
      activeTab={activeTab}
      tabs={Object.values(tabs).filter(tabsFilter).map(tabsRenderer)}
    />
  );
};

PageTabsNavigation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tabs: objectOf(any),
  // eslint-disable-next-line react/forbid-prop-types
  tabsVisibility: objectOf(any),
  tabsLabelsMap: instanceOf(Map).isRequired,
  tabsRoutingMap: instanceOf(Map).isRequired,
  disabledTabsMap: instanceOf(Map),
};

PageTabsNavigation.defaultProps = {
  tabs: {},
  tabsVisibility: {},
  disabledTabsMap: null,
};

export default PageTabsNavigation;
