import { head } from 'rambda';
import { generateApiErrorFromResponse } from '@fondy/utils';
import fetchSplitAccountsUUID from './fetchSplitAccountsUUID';
import fetchSplitAccounts from './splitAccountsActions';
import { partyAssociationsKeys } from '../../utils/enums';
import { partyActionTypes } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const fetchUserPartyAction = (fetch, forceFetch = false) => async (
  dispatch,
  getState,
) => {
  const { parties } = await getState();

  if (!parties.data || forceFetch) {
    dispatch({
      type: partyActionTypes.UPDATE_PARTY_FETCHING,
    });

    try {
      const customerResponse = await fetch('/api/parties/associations', {
        cache: 'no-cache',
        credentials: 'same-origin',
        mode: 'same-origin',
        headers: {
          'Content-type': 'application/json',
        },
      });

      if (!customerResponse.ok) {
        throw await generateApiErrorFromResponse(customerResponse);
      }

      const customerData = await customerResponse.json();
      const customer = head(customerData);

      const splitAccountsIds = await fetchSplitAccountsUUID({
        fetch,
        customerId: customer[partyAssociationsKeys.PARTY_ID],
      });

      const splitAccountsDefaultWallet = splitAccountsIds?.length
        ? await fetchSplitAccounts({
            fetch,
            customerId: customer[partyAssociationsKeys.PARTY_ID],
            splitAccountsIds,
          })
        : null;

      dispatch({
        type: partyActionTypes.UPDATE_PARTY,
        userDetails: [
          {
            ...customer,
            splitAccountsIds,
            splitAccountsDefaultWallet,
          },
        ],
      });
    } catch (error) {
      dispatch({
        type: partyActionTypes.UPDATE_PARTY_ERROR,
        error,
      });
    }
  }
};

export const updateSplitAccountsDefaultWallet = (fetch) => async (
  dispatch,
  getState,
) => {
  const { parties } = await getState();

  if (parties.data.length) {
    const customer = head(parties.data);

    try {
      const splitAccountsDefaultWallet = customer.splitAccountsIds?.length
        ? await fetchSplitAccounts({
            fetch,
            customerId: customer[partyAssociationsKeys.PARTY_ID],
            splitAccountsIds: customer.splitAccountsIds,
          })
        : null;

      dispatch({
        type: partyActionTypes.UPDATE_PARTY,
        userDetails: [
          {
            ...customer,
            splitAccountsDefaultWallet,
          },
        ],
      });
    } catch (error) {
      dispatch({
        type: partyActionTypes.UPDATE_PARTY_ERROR,
        error,
      });
    }
  }
};
