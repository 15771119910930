export const splitAccountWalletPageTabs = Object.freeze({
  TRANSACTIONS: 1,
});

export const splitAccountWalletPageTabsLabelsMap = new Map([
  [splitAccountWalletPageTabs.TRANSACTIONS, 'Transactions'],
]);

export const splitAccountWalletPageTabsRoutingMap = new Map([
  [
    splitAccountWalletPageTabs.TRANSACTIONS,
    '/split-account/wallets/:splitAccountWalletId/transactions',
  ],
]);
