import React, { useCallback } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PageHeader } from '@fondy/layouts';
import { accountStatuses, accountTypes } from '@fondy/enums';
import { GridWidget } from '@fondy/data-display';
import { AppLayout } from '../../layouts';
import { AccountsTable, CreateAccountButton } from '../../organisms';
import { accountsTableColumns, mobileAccountsTableColumns } from '../../atoms';
import { accountKeys, walletKeys } from '../../../utils';

const useStyles = makeStyles(() => ({}));

function AccountsPage() {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });

  const accountsSelectorFn = useCallback(
    (state) => ({
      ...state.accounts,
      data: state.accounts.data
        .map((account, _, accounts) => ({
          ...account,
          isFilterActive: false,
          filteredWalletsCount: 0,
          wallets:
            account[accountKeys.TYPE] === accountTypes.MASTER
              ? accounts.filter(
                  (acc) =>
                    acc[walletKeys.PAYOUT_ACCOUNT_ID] ===
                      account[accountKeys.ID] &&
                    acc[walletKeys.TYPE] === accountTypes.WALLET,
                )
              : [],
        }))
        .filter(
          (account) =>
            account[accountKeys.TYPE] !== accountTypes.WALLET &&
            ![accountStatuses.SUSPENDED].includes(account[accountKeys.STATUS]),
        ),
    }),
    [],
  );

  return (
    <AppLayout>
      <PageHeader
        title="Accounts"
        actions={[<CreateAccountButton key="create-new-account" />]}
      />

      <GridWidget container noBottomPadding spacing={0}>
        <AccountsTable
          columns={isMobile ? mobileAccountsTableColumns : accountsTableColumns}
          accountsSelectorFn={accountsSelectorFn}
          renderCollapsible
          renderGridView
        />
      </GridWidget>
    </AppLayout>
  );
}

export default AccountsPage;
