import { string, object } from 'yup';
import { getRequiredErrorMessage } from '@fondy/utils';
import { tfaKeys } from '../../../utils';

const errorMessageRequired = getRequiredErrorMessage();
const lengthError = 'Must be exactly 6 digits';

const twoFactorAuthenticationActivationSchema = object().shape({
  [tfaKeys.CODE]: string()
    .required(errorMessageRequired)
    .matches(/^[\d]+$/, 'Must be only digits')
    .min(6, lengthError)
    .max(6, lengthError),
});

export default twoFactorAuthenticationActivationSchema;
