import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { bool, node, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  blurred: {
    display: 'block',
    position: 'relative',
    pointerEvents: 'none',
    userSelect: 'none',
    filter: 'blur(6px)',
    paddingTop: theme.spacing(2),
    '&:after': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'block',
      content: "''",
      width: '100%',
      height: '100%',
      background: '#fff',
      opacity: 0.4,
    },
  },
  message: {
    position: 'absolute',
    top: '50%',
    left: '10%',
    right: '10%',
    fontWeight: 500,
  },
  root: {
    position: 'relative',
    width: 'inherit',
    height: 'inherit',
  },
}));

const BlurredComponent = ({ children, blur, message }) => {
  const classes = useStyles();

  if (!blur) return children;

  return (
    <div className={classes.root}>
      <Box
        className={clsx({
          [classes.blurred]: blur,
        })}
      >
        {children}
      </Box>
      {!!message && blur && (
        <Typography variant="body2" align="center" className={classes.message}>
          {message}
        </Typography>
      )}
    </div>
  );
};

BlurredComponent.propTypes = {
  children: node.isRequired,
  blur: bool,
  message: string,
};

BlurredComponent.defaultProps = {
  blur: false,
  message: null,
};

export default BlurredComponent;
