import { head } from 'rambda';

const stateSelectSplitAccountDefaultWallet = (state) => ({
  splitAccountDefaultWallet: state.parties.data.length
    ? head(state.parties.data).splitAccountsDefaultWallet
    : {},
  isLoading: state.parties.isFetching,
  isError: !!state.parties.error,
  apiError: state.parties.error,
});

export default stateSelectSplitAccountDefaultWallet;
