import React, { useCallback } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'rambda';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from '@material-ui/core';
import { CheckRounded } from '@material-ui/icons';
import { arrayOf, func, object, string } from 'prop-types';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonVariant,
  buttonSize,
} from '@fondy/buttons';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(3, 2),
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.secondary.lightest,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: theme.palette.secondary.lightest,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.primary.main,
    },
  },
  dividerAfter: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  },
  dividerBefore: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
  },
  title: {
    padding: theme.spacing(3, 0),
  },
  centered: {
    textAlign: 'center',
  },
  productFeatureIcon: {
    color: theme.palette.success.main,
  },
  actionButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  feature: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  header: {
    width: '100%',
  },
  footer: {
    alignSelf: 'flex-end',
  },
}));

const ProductCard = ({
  title,
  icon: ProductIcon,
  features,
  actionText,
  onActionClick,
}) => {
  const classes = useStyles();

  const productFeaturesRenderer = useCallback(
    (feature, index) => (
      <ListItem
        key={`${feature}_${index}`}
        className={classes.feature}
        dense
        disableGutters
      >
        {!isEmpty(feature) && (
          <ListItemIcon className={classes.productFeatureIcon}>
            <CheckRounded color="inherit" fontSize="small" />
          </ListItemIcon>
        )}
        <ListItemText
          primary={feature}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </ListItem>
    ),
    [classes.feature, classes.productFeatureIcon],
  );

  return (
    <Grid container spacing={1} className={classes.root}>
      <Box className={classes.header}>
        {ProductIcon && (
          <Grid item xs={12} className={classes.centered}>
            <ProductIcon data-aio-id="productCardIcon" />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          className={clsx(classes.centered, classes.dividerAfter)}
        >
          <Typography
            variant="h3"
            className={classes.title}
            data-aio-id="productCardTitle"
          >
            {title}
          </Typography>
        </Grid>
      </Box>

      <Grid item xs={12} data-aio-id="productCardFeatures">
        <List>{features.map(productFeaturesRenderer)}</List>
      </Grid>

      <Grid
        item
        xs={12}
        className={clsx(
          classes.centered,
          classes.dividerBefore,
          classes.footer,
        )}
      >
        <Button
          onClick={onActionClick}
          color={buttonColor.PRIMARY}
          variant={buttonVariant.CONTAINED}
          shape={buttonShape.ROUNDED}
          size={buttonSize.MEDIUM}
          data-aio-id="productCardActionButton"
          className={classes.actionButton}
        >
          {actionText}
        </Button>
      </Grid>
    </Grid>
  );
};

ProductCard.propTypes = {
  title: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: object,
  features: arrayOf(string).isRequired,
  actionText: string.isRequired,
  onActionClick: func,
};

ProductCard.defaultProps = {
  icon: null,
  onActionClick: null,
};

export default ProductCard;
