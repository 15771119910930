import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { ArrowUpwardRounded } from '@material-ui/icons';
import { func, string } from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonSize,
  buttonVariant,
} from '@fondy/buttons';
import { accountTypes } from '@fondy/enums';
import { accountKeys } from '../../../utils';
import MakePaymentModal from '../MakePaymentModal';
import { stateSelectAccountsData } from '../../../redux';

const useStyles = makeStyles(() => ({
  makePaymentButton: {
    whiteSpace: 'nowrap',
  },
}));

export default function MakePaymentButton({ onSuccess, label }) {
  const { breakpoints } = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useParams();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
  const { hasAccounts, totalBalance, selectedAccount } = useSelector(
    stateSelectAccountsData(accountId),
  );
  const counterparty = history?.location?.state?.counterparty;
  const [showPaymentModal, setShowPaymentModal] = useState(!!counterparty);
  const paymentsBlocked =
    !hasAccounts ||
    (!!selectedAccount &&
      selectedAccount[accountKeys.TYPE] === accountTypes.MASTER);

  const handleMakePaymentClick = useCallback(() => {
    if (totalBalance <= 0) {
      enqueueSnackbar({
        message: 'Please top up the balance to activate the payment button',
        variant: 'info',
      });
    } else {
      setShowPaymentModal(true);
    }
  }, [enqueueSnackbar, totalBalance]);

  return (
    <>
      <Button
        fullWidth={isMobile}
        color={hasAccounts ? buttonColor.PRIMARY : buttonColor.DEFAULT}
        variant={buttonVariant.CONTAINED}
        shape={buttonShape.ROUNDED}
        size={buttonSize.LARGE}
        disabled={paymentsBlocked}
        startIcon={<ArrowUpwardRounded style={{ fontSize: 16 }} />}
        className={classes.makePaymentButton}
        onClick={handleMakePaymentClick}
        data-aio-id="makePaymentButton"
      >
        {label}
      </Button>
      {showPaymentModal && (
        <MakePaymentModal
          open={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}

MakePaymentButton.propTypes = {
  onSuccess: func,
  label: string,
};

MakePaymentButton.defaultProps = {
  onSuccess: null,
  label: 'Make a payment',
};
