import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import {
  Menu,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  SvgIcon,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMoreRounded, CheckRounded } from '@material-ui/icons';
import { any, func, number, oneOf, shape, string } from 'prop-types';
import {
  Button,
  buttonColor,
  buttonShape,
  buttonVariant,
  buttonSize,
} from '@fondy/buttons';
import { ChevronDownIcon } from '@fondy/icons';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: theme.spacing(0, 1),
  },
  item: {
    margin: 0,
  },
  actionButton: {
    color: theme.palette.text.secondaryDark,
  },
  checkmark: {
    color: theme.palette.secondary.main,
  },
  dropdownIconOpen: {
    transform: 'rotate(180deg)',
  },
}));

const getItemById = (options, id) => {
  const index = Object.keys(options).findIndex((itemId) => itemId === id);
  return Object.values(options)[index];
};

const WidgetOptionsDropdown = ({ options, defaultValue, onChange }) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);
  const [selectedId, setSelectedId] = useState(
    defaultValue || Object.entries(options)?.[0]?.[0],
  );

  const handleOpen = useCallback((event) => setAnchor(event.currentTarget), []);

  const handleSelect = useCallback(
    async (nextId) => {
      setSelectedId(nextId);
      if (onChange) {
        const item = getItemById(options, nextId);
        await onChange({ item, id: nextId });
      }
      setAnchor(null);
    },
    [onChange, options],
  );

  const renderElement = useCallback(
    ([id, { label }]) => {
      const isSelected = selectedId === id;
      return (
        <ListItem
          id={id}
          key={id}
          className={classes.item}
          selected={isSelected}
          onClick={!isSelected ? () => handleSelect(id) : null}
          button
        >
          <ListItemText primary={label} />
          {isSelected && (
            <ListItemSecondaryAction>
              <CheckRounded fontSize="small" className={classes.checkmark} />
            </ListItemSecondaryAction>
          )}
        </ListItem>
      );
    },
    [classes.checkmark, classes.item, handleSelect, selectedId],
  );

  const renderTag = useCallback(
    (id) => {
      const item = getItemById(options, id);
      return (
        <Typography variant="body2" color="inherit">
          {item?.label}
        </Typography>
      );
    },
    [options],
  );

  return (
    <>
      <Button
        onClick={handleOpen}
        aria-controls="widget-time-range-dropdown"
        aria-haspopup="true"
        color={buttonColor.SECONDARY}
        variant={buttonVariant.TEXT}
        shape={buttonShape.ROUNDED}
        size={buttonSize.MEDIUM}
        className={classes.actionButton}
        data-aio-id="accountSelectDropdown"
        endIcon={
          <SvgIcon
            component={ChevronDownIcon}
            className={clsx({
              [classes.dropdownIconOpen]: Boolean(anchor),
            })}
          />
        }
      >
        {renderTag(selectedId)}
      </Button>
      <Menu
        keepMounted
        id="accounts-drop-menu"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav" dense className={classes.list}>
          {Object.entries(options).map(renderElement)}
        </List>
      </Menu>
    </>
  );
};

WidgetOptionsDropdown.propTypes = {
  options: shape().isRequired,
  defaultValue: shape({
    label: string.isRequired,
    unit: oneOf(['days', 'weeks', 'months']).isRequired,
    value: number.isRequired,
  }),
  onChange: func,
};

WidgetOptionsDropdown.defaultProps = {
  defaultValue: null,
  onChange: null,
};

export default WidgetOptionsDropdown;
