/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useMemo } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from 'recharts';
import clsx from 'clsx';
import { stringify } from 'query-string';
import { identity } from 'rambda';
import { CircularProgress, Box } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line no-restricted-imports
import { alpha } from '@material-ui/core/styles/colorManipulator';
import {
  bool,
  number,
  shape,
  string,
  func,
  arrayOf,
  objectOf,
  any,
  oneOfType,
  node,
} from 'prop-types';
import { useApi, getQueryDelimiterFromUrl } from '@fondy/utils';
import { ErrorMessage, BasicAlert, alertTypes } from '@fondy/alerts';
import {
  userPrefersReducedMotion,
  getChartGradientIdFromKey,
  defaultTooltipFormatter,
  defaultTickFormatter,
  generateColorPalette,
} from '@fondy/charts/src/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .recharts-legend-item': {
      '&:not(:last-of-type)': {
        paddingRight: theme.spacing(3),
      },
      '& > *': {
        verticalAlign: 'middle',
      },
    },
  },
}));

const ConnectedComposedAreaLineChart = ({
  url,
  urlParams,
  dataMapper,
  height,
  margin,
  unit,
  colorPalette,
  xAxisProps,
  yAxisProps,
  chartAreasProps,
  tooltipProps,
  showLegend,
  legendProps,
  cartesianGridProps,
  className,
  onSuccess,
  onError,
  emptyMessage,
  emptyHeading,
  emptyComponent,
  ...restProps
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const disableAnimations = userPrefersReducedMotion();
  const { apiData, apiError, isLoading, isError, retry } = useApi(
    `${url}${getQueryDelimiterFromUrl(url)}${stringify(
      {
        page: 0,
        size: -1,
        ...urlParams,
      },
      { arrayFormat: 'comma', skipNull: true, skipEmptyString: true },
    )}`,
    {
      dataMapper,
      dependencies: [url, urlParams],
      onSuccess,
      onError,
    },
  );
  const hasData = !isLoading && !isError && !!apiData;

  const chartColors = useMemo(
    () =>
      colorPalette || [
        theme.palette.primary,
        theme.palette.secondaryPrimary,
        theme.palette.accent,
        theme.palette.secondaryAccent,
        ...[...Array(10)].map(() => generateColorPalette()),
      ],
    [
      colorPalette,
      theme.palette.accent,
      theme.palette.primary,
      theme.palette.secondaryAccent,
      theme.palette.secondaryPrimary,
    ],
  );

  const tooltipStyle = useMemo(
    () => ({
      boxShadow: `0px 0px 20px 10px ${alpha(theme.palette.primary.dark, 0.05)}`,
      borderRadius: theme.shape.borderRadius,
      border: 'none',
    }),
    [theme.palette.primary.dark, theme.shape.borderRadius],
  );

  const linearGradientsMapper = useCallback(
    (key, index) => {
      const color = chartColors[index].main;
      return (
        <linearGradient
          key={key}
          id={getChartGradientIdFromKey(key)}
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset="5%" stopColor={color} stopOpacity={0.3} />
          <stop offset="95%" stopColor={color} stopOpacity={0} />
        </linearGradient>
      );
    },
    [chartColors],
  );

  const chartAreaLineMapper = useCallback(
    (key, index) => {
      if (index !== 0) {
        return (
          <Line
            connectNulls
            type="monotone"
            stroke={chartColors[index].main}
            strokeWidth={2}
            strokeDasharray={3}
            dot={false}
            activeDot={{
              stroke: theme.palette.background.white,
              strokeWidth: 3,
              r: 6,
            }}
            isAnimationActive={!disableAnimations}
            dataKey={key}
            id={key}
            key={key}
            name={key}
          />
        );
      }

      return (
        <Area
          connectNulls
          type="monotone"
          stroke={chartColors[index].main}
          strokeWidth={2}
          fillOpacity={1}
          activeDot={{
            stroke: theme.palette.background.white,
            strokeWidth: 3,
            r: 6,
          }}
          {...chartAreasProps}
          isAnimationActive={!disableAnimations}
          fill={`url(#${getChartGradientIdFromKey(key)})`}
          dataKey={key}
          id={key}
          key={key}
          name={key}
        />
      );
    },
    [
      chartAreasProps,
      chartColors,
      disableAnimations,
      theme.palette.background.white,
    ],
  );

  if (isLoading && !isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={height}
      >
        <CircularProgress data-test-id="progress" color="primary" />
      </Box>
    );
  }

  if (isError) {
    return (
      <ErrorMessage error={apiError} retryFn={retry} data-test-id="error" />
    );
  }

  if (!apiData?.data || apiData?.data.length === 0) {
    return (
      emptyComponent || (
        <BasicAlert
          type={alertTypes.INFO}
          heading={emptyHeading}
          message={emptyMessage}
          data-test-id="emptyMessage"
        />
      )
    );
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart
        {...restProps}
        width="100%"
        height="inherit"
        data={apiData?.data || []}
        margin={margin}
        className={clsx(classes.root, className)}
      >
        <defs>{hasData && apiData.charts.map(linearGradientsMapper)}</defs>
        <XAxis
          tick={{ stroke: theme.palette.text.primary, strokeWidth: 0.3 }}
          axisLine={false}
          tickLine={false}
          allowDecimals={false}
          allowDuplicatedCategory={false}
          type="category"
          {...xAxisProps}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          allowDuplicatedCategory={false}
          tickFormatter={defaultTickFormatter(unit)}
          type="number"
          {...yAxisProps}
        />
        <CartesianGrid
          strokeDasharray="7 7"
          vertical={false}
          {...cartesianGridProps}
        />
        <Tooltip
          formatter={defaultTooltipFormatter(unit)}
          contentStyle={tooltipStyle}
          separator=": "
          {...tooltipProps}
          isAnimationActive={!disableAnimations}
        />
        {showLegend && (
          <Legend
            verticalAlign="top"
            align="right"
            iconType="circle"
            iconSize={10}
            height={36}
            margin={margin}
            {...legendProps}
          />
        )}
        {hasData && apiData.charts.map(chartAreaLineMapper)}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

ConnectedComposedAreaLineChart.propTypes = {
  url: string.isRequired,
  emptyComponent: node,
  urlParams: shape({
    dateFrom: string,
    dateTo: string,
    sort: arrayOf(string),
  }),
  height: number,
  margin: shape({
    top: number,
    bottom: number,
    left: number,
    right: number,
  }),
  unit: string,
  dataMapper: func,
  colorPalette: arrayOf(
    shape({
      main: string.isRequired,
    }),
  ),
  showLegend: bool,
  xAxisProps: shape({
    dataKey: string.isRequired,
  }),
  yAxisProps: objectOf(any),
  chartAreasProps: objectOf(any),
  tooltipProps: objectOf(any),
  legendProps: objectOf(any),
  cartesianGridProps: objectOf(any),
  className: string,
  onSuccess: func,
  onError: func,
  emptyMessage: oneOfType([string, bool]),
  emptyHeading: string,
};

ConnectedComposedAreaLineChart.defaultProps = {
  height: 300,
  emptyComponent: null,
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  unit: '',
  urlParams: {},
  dataMapper: identity,
  colorPalette: null,
  showLegend: true,
  xAxisProps: { dataKey: 'date' },
  yAxisProps: {},
  chartAreasProps: {},
  tooltipProps: {},
  legendProps: {},
  cartesianGridProps: {},
  className: '',
  onSuccess: null,
  onError: null,
  emptyMessage: "You don't have anything to plot yet",
  emptyHeading: 'No data',
};

export default ConnectedComposedAreaLineChart;
