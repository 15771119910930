import { head } from 'rambda';
import { createSelector } from 'reselect';
import { activeStatuses, complianceStatuses } from '@fondy/enums';
import { partyDetailsKeys } from '../../utils';

const userCustomerSelector = createSelector(
  (state) => state.parties.data,
  (parties) => {
    if (!parties || parties.length === 0) {
      return {
        isCustomerActive: false,
        isCustomerCompliant: false,
        isCustomerCompliantPending: false,
      };
    }
    const customer = head(parties);
    return {
      customer,
      isCustomerActive:
        customer[partyDetailsKeys.STATUS] === activeStatuses.ACTIVE,
      isCustomerCompliant:
        customer[partyDetailsKeys.COMPLIANCE_STATUS] ===
        complianceStatuses.COMPLIANT,
      isCustomerCompliantPending:
        customer[partyDetailsKeys.COMPLIANCE_STATUS] ===
        complianceStatuses.PENDING,
    };
  },
);

export default userCustomerSelector;
