/* eslint-disable react/prop-types */
import React from 'react';
import { MenuTableCell, AvatarDataCell } from '@fondy/tables';
import { formatSortCodeValue } from '@fondy/utils';
import {
  ibanToStringFormatter,
  paymentCounterpartyKeys,
  paymentCounterpartyKeysLabelsMapClean,
} from '../../../utils';

const renderCounterpartyRow = ({ isMobile }) => ({ row }) => (
  <AvatarDataCell
    title={row[paymentCounterpartyKeys.NAME]}
    subtitle={
      <>
        {isMobile ? (
          <>
            <span>{row[paymentCounterpartyKeys.CURRENCY]}</span>
            <br />
          </>
        ) : null}
        {row[paymentCounterpartyKeys.IBAN] ? (
          <span>
            {ibanToStringFormatter(row[paymentCounterpartyKeys.IBAN])}
          </span>
        ) : (
          <span>
            {formatSortCodeValue(row[paymentCounterpartyKeys.SORT_CODE])}
            &nbsp;&nbsp;&nbsp;
            {row[paymentCounterpartyKeys.ACCOUNT_NUMBER]}
          </span>
        )}
      </>
    }
  />
);

const counterpartiesTableColumns = ({ onEdit, onDelete } = {}) => [
  {
    id: paymentCounterpartyKeys.NAME,
    numeric: false,
    disablePadding: false,
    label: `${paymentCounterpartyKeysLabelsMapClean.get(
      paymentCounterpartyKeys.NAME,
    )} & details`,
    style: { width: '70%' },
    render: renderCounterpartyRow({ isMobile: false }),
  },
  {
    id: paymentCounterpartyKeys.CURRENCY,
    numeric: false,
    disablePadding: false,
    label: paymentCounterpartyKeysLabelsMapClean.get(
      paymentCounterpartyKeys.CURRENCY,
    ),
  },
  {
    id: 'more',
    numeric: false,
    disablePadding: false,
    label: '',
    render: ({ row }) => (
      <MenuTableCell
        data-aio-id="counterpartyMenuButton"
        items={[
          {
            label: 'Edit',
            onClick: (e) => onEdit(e, row),
            'data-aio-id': 'counterpartyEditButton',
          },
          {
            label: 'Delete',
            onClick: (e) => onDelete(e, row),
            'data-aio-id': 'counterpartyDeleteButton',
          },
        ]}
      />
    ),
  },
];

export const mobileCounterpartiesTableColumns = ({ onEdit, onDelete } = {}) => [
  {
    id: paymentCounterpartyKeys.NAME,
    numeric: false,
    disablePadding: false,
    label: paymentCounterpartyKeysLabelsMapClean.get(
      paymentCounterpartyKeys.NAME,
    ),
    render: renderCounterpartyRow({ isMobile: true }),
  },
  {
    id: 'more',
    numeric: false,
    disablePadding: false,
    label: '',
    style: {
      width: 40,
    },
    render: ({ row }) => (
      <MenuTableCell
        items={[
          {
            label: 'Edit',
            onClick: (e) => onEdit(e, row),
          },
          {
            label: 'Delete',
            onClick: (e) => onDelete(e, row),
          },
        ]}
      />
    ),
  },
];

export default counterpartiesTableColumns;
