import React, { useCallback, useState } from 'react';
import { head } from 'rambda';
import { endOfDay, startOfDay, subYears } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CircularProgress, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PageHeader } from '@fondy/layouts';
import { formatTimeZonedDate, useApi } from '@fondy/utils';
import { ErrorIllustration } from '@fondy/icons';
import { accountTypes, orderTypes } from '@fondy/enums';
import { GridWidget } from '@fondy/data-display';
import {
  AccountAliasTableCell,
  ConnectedFilteredTable,
  filterFieldTypes,
} from '@fondy/tables';
import { TabsHorizontalNavigation } from '@fondy/menu';
import { StatusMessage } from '@fondy/alerts';
import { accountStatuses } from '@fondy/enums/src/accountStatuses';
import { AppLayout } from '../../layouts';
import { createAccountModalStages } from '../../organisms/CreateAccountModal';
import { stateSelectAccountsData, stateTimeZoneSelector } from '../../../redux';
import {
  AccountActions,
  CreateAccountButton,
  MakePaymentButton,
  TransactionDetailsModal,
} from '../../organisms';
import {
  createTransactionsPdfStatementFooter,
  createTransactionsPdfStatementHeader,
  createTransactionsPdfStatementTableFooter,
  mobileTransactionsTableColumns,
  singleAccountTransactionsPdfTableColumns,
  singleAccountTransactionsTableColumns,
  TotalBalance,
  transactionsTableColumns,
} from '../../atoms';
import {
  accountsPageTabs,
  accountsPageTabsLabelsMap,
} from '../AccountsPage/utils';
import {
  accountKeys,
  csvValueAsStringFormatter,
  tableEmptyMessageResolver,
  transactionKeys,
  transactionKeysCsvLabelsMap,
  tableAmountFilter,
  tableAmountFilterLabelGetter,
  tableCurrencyFilter,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(1),
  },
  pageHeader: {
    marginBottom: theme.spacing(1),
  },
  flagIcon: {
    width: 20,
  },
  transactionsTableCell: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
    },
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
  },
  statusError: {
    margin: theme.spacing(2, 0),
    '& svg > path:first-child': {
      fill: theme.palette.secondary.lightest,
    },
  },
  accountSelector: {
    marginBottom: theme.spacing(1),
  },
}));

function TransactionsPage() {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const currentDate = new Date();
  const { accountId } = useParams();
  const isMobile = useMediaQuery(breakpoints.down('xs'), { noSsr: true });
  const { totalBalance, selectedAccount, allAccounts, isLoading } = useSelector(
    stateSelectAccountsData(accountId),
  );
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);
  const { accountsIsLoading, isError, apiError } = useSelector((state) => ({
    accountsIsLoading: state.accounts.isFetching,
    isError: !!state.accounts.getError,
    apiError: state.accounts.getError,
    activeAccountAliases: state.accounts.data.reduce(
      (activeAccounts, account) => ({
        ...activeAccounts,
        [account[accountKeys.ID]]: account[accountKeys.ALIAS],
      }),
      {},
    ),
  }));
  const userPartyId = useSelector(
    (state) => head(state.parties.data)[accountKeys.PARTY_ID],
  );

  const [transactionDetails, setTransactionDetails] = useState(null);
  const [transactionsTableDependency, refreshTransactions] = useState(false);

  const isAccountSelected = !!selectedAccount;

  const url = `/api/core-banking/transactions${
    accountId ? `?accountId=${accountId}` : ''
  }`;

  const {
    apiData: userPartyData,
    isLoading: isPartyLoading,
    isError: hasPartyFetchError,
  } = useApi(`/api/parties/${userPartyId}`, {
    dependencies: [userPartyId],
  });

  const handleTransactionRecordClick = useCallback(
    async (_, row) => setTransactionDetails(row),
    [],
  );

  const handleTransactionDetailsModalClose = useCallback(
    () => setTransactionDetails(null),
    [],
  );

  const allAccountsTransactionsTableColumns = isMobile
    ? mobileTransactionsTableColumns
    : transactionsTableColumns;

  const oneAccountTransactionsTableColumns = isMobile
    ? mobileTransactionsTableColumns
    : singleAccountTransactionsTableColumns;

  return (
    <AppLayout backButtonLabel="Back to accounts" backButtonUrl="/accounts">
      {isAccountSelected ? (
        <PageHeader
          classes={{ root: classes.pageHeader, title: classes.title }}
          preTitle={
            <AccountAliasTableCell
              fontSize={16}
              fontWeight={600}
              isLoading={isLoading}
              currencyIso={selectedAccount[accountKeys.CURRENCY]}
              accountAlias={selectedAccount[accountKeys.ALIAS]}
              accountType={selectedAccount[accountKeys.TYPE]}
              accountStatus={selectedAccount[accountKeys.STATUS]}
              classes={{ avatar: classes.flagIcon }}
            />
          }
          title={
            <TotalBalance
              currencyIso={selectedAccount[accountKeys.CURRENCY]}
              totalBalance={totalBalance}
            />
          }
          actions={[
            <AccountActions key="account-actions" account={selectedAccount} />,
            selectedAccount[accountKeys.TYPE] !== accountTypes.MASTER ? (
              <MakePaymentButton
                key="make-a-payment"
                onSuccess={() => refreshTransactions((prevState) => !prevState)}
              />
            ) : (
              <CreateAccountButton
                key="create-account"
                buttonLabel={
                  selectedAccount[accountKeys.TYPE] === accountTypes.MASTER
                    ? 'Create new wallet'
                    : 'Create new account'
                }
                disabled={
                  selectedAccount[accountKeys.STATUS] !== accountStatuses.ACTIVE
                }
                modalProps={{
                  initialStage:
                    selectedAccount[accountKeys.TYPE] === accountTypes.MASTER
                      ? createAccountModalStages.CREATE_WALLET_ACCOUNT
                      : undefined,
                }}
              />
            ),
          ]}
        />
      ) : null}

      {isAccountSelected &&
      selectedAccount[accountKeys.TYPE] === accountTypes.MASTER ? (
        <TabsHorizontalNavigation
          activeTab={accountsPageTabs.TRANSACTIONS}
          tabs={[
            {
              label: accountsPageTabsLabelsMap.get(accountsPageTabs.WALLETS),
              value: accountsPageTabs.WALLETS,
              to: `/accounts/${selectedAccount[accountKeys.ID]}/wallets`,
            },
            {
              label: accountsPageTabsLabelsMap.get(
                accountsPageTabs.TRANSACTIONS,
              ),
              value: accountsPageTabs.TRANSACTIONS,
            },
          ]}
        />
      ) : null}

      <GridWidget container noBottomPadding spacing={0}>
        {!isLoading && !accountsIsLoading && isError && (
          <StatusMessage
            error={apiError}
            illustration={ErrorIllustration}
            className={classes.statusError}
          />
        )}
        {(isLoading || accountsIsLoading) && !isError && (
          <Grid item xs={12} className={classes.spinnerWrapper}>
            <CircularProgress />
          </Grid>
        )}
        {!isLoading && !accountsIsLoading && !isError && (
          <ConnectedFilteredTable
            disableHeader={isMobile}
            defaultOrderBy={transactionKeys.CREATED_AT}
            defaultOrderDirection={orderTypes.DESCENDING}
            rowId={transactionKeys.ID}
            url={url}
            dependencies={[url, transactionsTableDependency]}
            emptyComponent={tableEmptyMessageResolver('transactions')}
            columns={
              isAccountSelected &&
              selectedAccount[accountKeys.TYPE] !== accountTypes.MASTER
                ? oneAccountTransactionsTableColumns
                : allAccountsTransactionsTableColumns
            }
            fileExport={{
              allowExportAll: true,
              enabled: true,
              buttonLabel: 'Export statement',
              disabledButton: isPartyLoading || hasPartyFetchError,
              pdfColumns: singleAccountTransactionsPdfTableColumns(
                legalEntityTimezone,
              ),
              csvColumns: [
                transactionKeys.BOOKING_DATE,
                transactionKeys.TRANSACTION_REFERENCE,
                transactionKeys.ID,
                transactionKeys.COUNTERPARTY_NAME,
                transactionKeys.COUNTERPARTY_SORT_CODE,
                transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER,
                transactionKeys.COUNTERPARTY_ACCOUNT_ALIAS,
                transactionKeys.COUNTERPARTY_ACCOUNT_ID,
                transactionKeys.DEBIT_CREDIT_CODE,
                transactionKeys.TRANSACTION_CURRENCY,
                transactionKeys.TRANSACTION_AMOUNT,
                transactionKeys.ACCOUNT_CLOSING_BALANCE,
              ],
              csvColumnsNamesMap: transactionKeysCsvLabelsMap,
              csvDataMapper: {
                [transactionKeys.BOOKING_DATE]: (value) =>
                  formatTimeZonedDate(value, legalEntityTimezone, {
                    dateFormat: 'dd/MM/yyyy',
                  }),
                [transactionKeys.COUNTERPARTY_SORT_CODE]: csvValueAsStringFormatter,
                [transactionKeys.COUNTERPARTY_ACCOUNT_NUMBER]: csvValueAsStringFormatter,
                [transactionKeys.TRANSACTION_REFERENCE]: csvValueAsStringFormatter,
                [transactionKeys.COUNTERPARTY_NAME]: csvValueAsStringFormatter,
              },
              filename: () =>
                `${selectedAccount[accountKeys.HOLDER_NAME]} ${
                  selectedAccount[accountKeys.ALIAS]
                } statement`,
              headerRenderer: (props) =>
                createTransactionsPdfStatementHeader({
                  ...props,
                  account: selectedAccount,
                  party: userPartyData,
                }),
              footerRenderer: createTransactionsPdfStatementFooter,
              tableFooterRenderer: (props) =>
                createTransactionsPdfStatementTableFooter({
                  ...props,
                  currencyIso: selectedAccount[accountKeys.CURRENCY],
                }),
            }}
            dateFilters={{
              timeZone: legalEntityTimezone,
              dependencies: [accountId],
              minDate: startOfDay(subYears(currentDate, 1)),
              maxDate: endOfDay(currentDate),
              twoFactorIsActive: false,
              dateFrom: {
                label: 'Start date',
              },
              dateTo: {
                label: 'End date',
              },
            }}
            // searchableParamName="filter"
            // filters={{
            //   [transactionKeys.DEBIT_CREDIT_CODE]: {
            //     label: 'Transaction type',
            //     values: ['Credit', 'Debit'],
            //     type: filterFieldTypes.CHECKBOX,
            //     selectItemLabelGetter: ({ value }) =>
            //       value === 'Debit' ? 'Money out' : 'Money in',
            //   },
            //   [accountKeys.CURRENCY]: {
            //     label: 'Currency',
            //     values: tableCurrencyFilter,
            //     type: filterFieldTypes.CHECKBOX,
            //   },
            //   [transactionKeys.TRANSACTION_AMOUNT]: {
            //     label: 'Amount',
            //     values: Object.keys(tableAmountFilter),
            //     selectItemLabelGetter: tableAmountFilterLabelGetter,
            //     type: filterFieldTypes.CHECKBOX,
            //   },
            // }}
            pagination={{
              size: 10,
            }}
            onCellClick={handleTransactionRecordClick}
            classes={{
              cell: classes.transactionsTableCell,
            }}
            data-aio-id="transactionsTable"
          />
        )}
      </GridWidget>

      {transactionDetails && (
        <TransactionDetailsModal
          open={!!transactionDetails}
          transaction={transactionDetails}
          onClose={handleTransactionDetailsModalClose}
        />
      )}
    </AppLayout>
  );
}

export default TransactionsPage;
