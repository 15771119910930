import React from 'react';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from '@fondy/keycloak';
import { NotFoundPage } from '@fondy/layouts';
import {
  SplitAccountDashboardPage,
  SplitAccountTransactionsListPage,
  SplitAccountWalletsListPage,
  SplitAccountProfilesListPage,
  SplitAccountWalletTransactionsListPage,
} from '../../components/pages';
import { privateRoutesRequiredPartyProps } from '../utils';

export default function SplitAccountRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        {...privateRoutesRequiredPartyProps}
        path={`${path}`}
        exact
        enableOffline
      >
        <SplitAccountDashboardPage />
      </PrivateRoute>

      <PrivateRoute
        {...privateRoutesRequiredPartyProps}
        path={`${path}/transactions`}
        exact
        enableOffline
      >
        <SplitAccountTransactionsListPage />
      </PrivateRoute>

      <PrivateRoute
        {...privateRoutesRequiredPartyProps}
        path={`${path}/wallets`}
        enableOffline
      >
        <Switch>
          <PrivateRoute
            {...privateRoutesRequiredPartyProps}
            path={`${path}/wallets`}
            exact
            enableOffline
          >
            <SplitAccountWalletsListPage />
          </PrivateRoute>
          <PrivateRoute
            {...privateRoutesRequiredPartyProps}
            path={`${path}/wallets/:splitAccountWalletId/transactions`}
            exact
            enableOffline
          >
            <SplitAccountWalletTransactionsListPage />
          </PrivateRoute>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </PrivateRoute>

      <PrivateRoute
        {...privateRoutesRequiredPartyProps}
        path={`${path}/profiles`}
        exact
        enableOffline
      >
        <SplitAccountProfilesListPage />
      </PrivateRoute>

      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
