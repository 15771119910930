import React, { useCallback, useMemo, useState } from 'react';
import { pick } from 'rambda';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, Grid, SvgIcon, Tooltip } from '@material-ui/core';
import { bool, func, string, shape } from 'prop-types';
import { useSelector } from 'react-redux';
import { AlertTriangleIcon, DownloadIcon, ShareIcon } from '@fondy/icons';
import { formatSortCodeValue } from '@fondy/utils';
import { Drawer, ObjectDataDisplay } from '@fondy/data-display';
import {
  conformToMask,
  ibanInputMaskPattern,
  bicSwiftInputMaskPattern,
} from '@fondy/forms';
import { accountTypes } from '@fondy/enums';
import { defaultThemeColors } from '@fondy/themes';
import { createAccountDetailsPdf } from '../../atoms';
import {
  accountKeys,
  accountKeysLabelsMap,
  walletKeys,
  walletKeysLabelsMap,
} from '../../../utils';
import { stateTimeZoneSelector } from '../../../redux';

const getNonWalletAccountDetails = pick([
  accountKeys.HOLDER_NAME,
  accountKeys.SORT_CODE,
  accountKeys.NUMBER,
  accountKeys.IBAN,
  accountKeys.BIC_SWIFT,
]);

const getWalletAccountDetails = pick([
  walletKeys.HOLDER_NAME,
  walletKeys.BUSINESS_REFERENCE,
  walletKeys.SORT_CODE,
  walletKeys.NUMBER,
  walletKeys.IBAN,
  walletKeys.BIC_SWIFT,
]);

const rewrittenAccountKeysLabelsMap = new Map([
  ...accountKeysLabelsMap,
  ...walletKeysLabelsMap,
  [accountKeys.HOLDER_NAME, 'Holder name'],
  [walletKeys.BUSINESS_REFERENCE, 'Business reference'],
]);

const useStyles = makeStyles((theme) => ({
  dataDisplay: {
    margin: 0,
  },
  collapse: {
    padding: 0,
  },
  row: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  value: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      justifyContent: 'space-between',
    },
  },
  progress: {
    color: theme.palette.common.white,
  },
}));

const businessReferenceTooltipTitle =
  'This business reference identifies the Wallet. Please always include it in the payment description field when receiving funds for this Wallet. The funds will be redirected to the “Default Wallet” if the business reference is not provided.';

const AccountDetailsModal = ({ open, account, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const legalEntityTimezone = useSelector(stateTimeZoneSelector);
  const masterAccounts = useSelector(({ accounts: { data } }) =>
    data.filter((i) => i[accountKeys.TYPE] === accountTypes.MASTER),
  );
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const accountDetails = useMemo(
    () =>
      account[accountKeys.TYPE] === accountTypes.WALLET
        ? getWalletAccountDetails(account)
        : getNonWalletAccountDetails(account),
    [account],
  );

  const handlePdfDownload = useCallback(
    async () =>
      createAccountDetailsPdf({
        account,
        theme,
        masterAccount:
          account[accountKeys.TYPE] === accountTypes.WALLET
            ? masterAccounts.find(
                (i) =>
                  i[accountKeys.ID] === account[walletKeys.PAYOUT_ACCOUNT_ID],
              )
            : null,
        loadingStateSetter: setIsGeneratingPdf,
        timeZone: legalEntityTimezone,
        filename: () =>
          `${account[accountKeys.HOLDER_NAME]} - ${
            account[accountKeys.ALIAS]
          } account details.pdf`,
      }),
    [account, legalEntityTimezone, masterAccounts, theme],
  );

  const handleShare = useCallback(async () => {
    const title = `${account[accountKeys.ALIAS]} account details`;
    const text = `${Object.entries(accountDetails).reduce(
      (reducer, [key, value]) =>
        `${reducer}${rewrittenAccountKeysLabelsMap.get(key)}: ${value}\n`,
      '',
    )}${accountKeysLabelsMap.get(accountKeys.CURRENCY)}: ${
      account[accountKeys.CURRENCY]
    }`;

    if (navigator.canShare) {
      try {
        await navigator.share({
          title,
          text,
        });
      } catch (e) {
        console.error('Sharing failed', e);
      }
    } else {
      window.open(
        `mailto:?subject=${title}&body=${text.replaceAll('\n', '%0D%0A')}`,
      );
    }
  }, [account, accountDetails]);

  const dataFieldsMapper = useMemo(
    () => ({
      [accountKeys.SORT_CODE]: formatSortCodeValue,
      [accountKeys.IBAN]: (value) =>
        conformToMask(value, ibanInputMaskPattern, {
          guide: false,
        }).conformedValue,
      [accountKeys.BIC_SWIFT]: (value) =>
        conformToMask(value, bicSwiftInputMaskPattern, {
          guide: false,
        }).conformedValue,
      [walletKeys.BUSINESS_REFERENCE]: (value) => (
        <Grid justifyContent="space-between" container>
          <span>{value}</span>
          <Tooltip
            title={businessReferenceTooltipTitle}
            placement="top"
            arrow
            interactive
          >
            <SvgIcon
              component={AlertTriangleIcon}
              style={{ fontSize: 20, color: defaultThemeColors.WARNING }}
            />
          </Tooltip>
        </Grid>
      ),
    }),
    [],
  );

  const objectDataDisplayClasses = useMemo(
    () => ({
      item: classes.row,
      value: classes.value,
      collapse: classes.collapse,
    }),
    [classes.collapse, classes.row, classes.value],
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      contentProps={{
        dividers: true,
      }}
      footerActions={[
        {
          label: 'Download',
          color: 'secondary',
          size: 'large',
          onClick: handlePdfDownload,
          startIcon: isGeneratingPdf ? (
            <CircularProgress size={14} className={classes.progress} />
          ) : (
            <SvgIcon style={{ fontSize: 20 }} component={DownloadIcon} />
          ),
          disabled: isGeneratingPdf,
          fullWidth: true,
          'data-aio-id': 'downloadAccountDetailsButton',
        },
        {
          label: 'Share',
          color: 'secondary',
          size: 'large',
          onClick: handleShare,
          startIcon: <SvgIcon style={{ fontSize: 20 }} component={ShareIcon} />,
          fullWidth: true,
          'data-aio-id': 'shareAccountDetailsButton',
        },
      ]}
      header="Details"
    >
      <ObjectDataDisplay
        classes={objectDataDisplayClasses}
        className={classes.dataDisplay}
        data={accountDetails}
        dataFieldsLabelsMap={rewrittenAccountKeysLabelsMap}
        dataFieldsMapper={dataFieldsMapper}
        grid={{ xs: 12 }}
        itemSpacing={1}
      />
    </Drawer>
  );
};

AccountDetailsModal.propTypes = {
  open: bool,
  account: shape({
    [accountKeys.ALIAS]: string,
    [accountKeys.CURRENCY]: string,
    [accountKeys.NUMBER]: string,
    [accountKeys.SORT_CODE]: string,
    [accountKeys.IBAN]: string,
    [accountKeys.BIC_SWIFT]: string,
  }).isRequired,
  onClose: func,
};

AccountDetailsModal.defaultProps = {
  open: false,
  onClose: undefined,
};

export default AccountDetailsModal;
